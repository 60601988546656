import {StyledDivTimeSlotOuter} from "../../../styles/MeetingRoomBookingMatrixStyles";
import React from "react";
import {TimeSlotOuterProps} from "./EmptyTimeSlot";

const TimeSlotOuter = (props: TimeSlotOuterProps) => {
    const {minutesDurationDisplayed, hoursDisplayed, content} = props;
    const width = (minutesDurationDisplayed / (hoursDisplayed * 60)) * 100;
    return (
        <StyledDivTimeSlotOuter style={{width: "" + width + "%"}}>
            {content}
        </StyledDivTimeSlotOuter>
    );
}

export default TimeSlotOuter;