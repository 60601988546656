import {Booking, Building, MeetingRoomBooking, Room} from "../API";
import {MeetingRoomWithLevel} from "./MeetingRoomWithLevel";


export type ManagePermission = {
    managingAllowed: boolean //edit and delete
}

export enum OwnedBy {
    USER = "USER",
    OTHER = "OTHER"
}

export type OwnerFlag = {
    ownedBy: OwnedBy
}

export type MeetingRoomWithLevelP = MeetingRoomWithLevel & ManagePermission

export type BuildingP = Building & ManagePermission

export type RoomP = Room & ManagePermission

export type BookingP = Booking & ManagePermission

export type MeetingRoomBookingP = MeetingRoomBooking & ManagePermission
