import {IconButton, Paper, TextField} from "@material-ui/core";
import Box from "@mui/material/Box";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import {t} from "i18next";
import {Building} from "../../API";
import {BtnButtonInputStyle, GeneralInputStyles} from "../../styles/InputFieldStyle";
import {checkForValidInputNaming, comparatorAlphanumericValues} from "../../Utils/Helpers";
import {useErrorContext} from "../../hooks/useErrorContext";
import {gql, useMutation, useQuery} from "@apollo/client";
import {listBuildings} from "../../graphql/queries";
import {createSecureBuilding as createBuildingTemplate} from "../../graphql/mutations";
import {v4 as uuidv4} from 'uuid';
import {maxBuildingNameLength} from "../../Utils/commons";

interface Props {
    setShowCreateBuilding: (value: boolean) => void;
    setSelectedBuilding: (value: Building | null) => void;
    setIsShowAlert: (value: boolean) => void;
}

const CreateBuildingComponent: React.FC<Props> = (props) => {
    const {setShowCreateBuilding, setSelectedBuilding, setIsShowAlert} = props;
    const {refetch} = useQuery(gql(listBuildings));
    const [createBuilding] = useMutation(gql(createBuildingTemplate));
    const {reportError} = useErrorContext();

    const [newBuildingName, setNewBuildingName] = useState("")
    const nameInputRef = useRef<HTMLInputElement>(null);
    useEffect(function focusTextFieldWhenRendered() {
        if (nameInputRef?.current) nameInputRef.current.focus();
    }, [])

    const classes = BtnButtonInputStyle();
    const isNewBuildingNameValid = checkForValidInputNaming(newBuildingName, maxBuildingNameLength)

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setNewBuildingName(event.target.value);
    }

    const isConflictingBuildingName = async (): Promise<boolean> => {
        const queryBuildings = await refetch().catch((err) => reportError(err, "A connection error occurred", "CreateBuildingComponent"));
        if (queryBuildings === undefined) return true;
        const newBuildingNameLowerCase = newBuildingName.toLowerCase().trim();
        return queryBuildings!.data.listBuildings.items.some((building: Building) => {
            return comparatorAlphanumericValues(building.buildingName.toLowerCase(), newBuildingNameLowerCase) === 0;
        });
    }

    const showErrorMsg = () => {
        setIsShowAlert(true);
        setTimeout(() => {
            setIsShowAlert(false)
        }, 4000);
    }

    const handleSave = async () => {
        const isConflicting = await isConflictingBuildingName();
        if (isConflicting) {
            showErrorMsg();
        } else {
           await handleCreateBuilding();
        }
    }

    const handleCreateBuilding = async () => {
        createBuilding({
            variables: {
                input: {
                    buildingId: uuidv4(),
                    buildingName: newBuildingName.trim(),
                    buildingNameLowerCased: newBuildingName.toLowerCase().trim(),
                    roleIds: [],
                }
            }
        }).then(() => {
            setSelectedBuilding(null);
            setShowCreateBuilding(false);
        }).catch((error) => {
            reportError(error, "A connection error occurred", "CreateBuildingComponent");
        })
    }

    return (
        <Paper style={GeneralInputStyles.paperStyle} data-testid={"createBuildingComponent"}>
            <Box style={GeneralInputStyles.textFieldBoxStyle}>
                <TextField
                    style={{marginRight: "10px", width: "100%"}}
                    label={t("building-add-text")}
                    value={newBuildingName}
                    onChange={handleInputChange}
                    inputRef={nameInputRef}
                    inputProps={{"data-testid": "buildingName-text"}}
                />
            </Box>

            <Box style={GeneralInputStyles.toolTipsBoxStyle}>
                <Tooltip title={t("confirm_dialog_ok_button-text")}>
                    <span>
                        <IconButton onClick={handleSave} disabled={!isNewBuildingNameValid} data-testid={"save-btn"}>
                            <SaveIcon className={isNewBuildingNameValid ? classes.btnEnabled : classes.btnDisabled}
                                      style={{cursor: "pointer"}} fontSize="large"/>
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={t("confirm_dialog_cancel_button-text")}>
                    <IconButton onClick={() => setShowCreateBuilding(false)} data-testid={"cancel-btn"}>
                        <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"/>
                    </IconButton>
                </Tooltip>
            </Box>
        </Paper>
    );
}

export default CreateBuildingComponent;