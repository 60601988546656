import SvgIcon from "@mui/material/SvgIcon";

function AdjustableTableIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="scale(0.75 0.75)">
                <g id="Group-2">
                    <rect id="Rectangle" fill="#FFFFFF" fillOpacity="0" x="0" y="0" width="32" height="32"></rect>
                    <line x1="2.28983499e-16" y1="16.5" x2="32" y2="16.5" id="Line" stroke="#000000"
                          strokeWidth="3"></line>
                    <line x1="3.5" y1="16.5" x2="0.5" y2="31.5" id="Line-2" stroke="#000000"
                          strokeLinecap="round"></line>
                    <line x1="31.5" y1="16.5" x2="28.5" y2="31.5" id="Line-2-Copy" stroke="#000000"
                          strokeLinecap="round"
                          transform="translate(30.000000, 24.000000) scale(-1, 1) translate(-30.000000, -24.000000) "></line>
                    <polygon id="Triangle" fill="#000000" points="16 8 18 12 14 12"></polygon>
                    <polygon id="Triangle-Copy" fill="#000000"
                             transform="translate(16.000000, 23.000000) scale(1, -1) translate(-16.000000, -23.000000) "
                             points="16 21 18 25 14 25"></polygon>
                </g>
            </g>
        </SvgIcon>
    );
}

export default AdjustableTableIcon;