import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import colors from "../styles/colors";
import uiElementMeasures from "../styles/inputElementMeasures";
import borders from "../styles/borders";
import CalenderIcon from "../styles/CalenderIcon";
import i18n from "i18next";

interface Props {
    showCalendar: boolean;
    setShowCalendar: (value: boolean) => void;
    selectedDate: Date;
    setSelectedDate: (value: Date) => void;
}

const CollapsibleCalendarComponent: React.FC<Props> = (props) => {

    const useStyles = makeStyles({
        divDatePickerContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: "auto",
            minHeight: uiElementMeasures.inputElementsInRoomPickerMinHeightLessTwo,
            borderRadius: borders.mediumBorderRadius
        },
        changeDateButton: {
            minWidth: "40px",
            color: colors.iconAndTextGrey,
        },
        showCalendarButton: {
            width: "-webkit-fill-available",
            textTransform: "capitalize",
        },
    });

    const classes = useStyles();

    function handleDayBackEvent() {
        let date = new Date(props.selectedDate.valueOf());
        date.setHours(0, 0, 0, 0)
        date.setDate(date.getDate() - 1);
        props.setSelectedDate(date);
    }

    function handleDayForwardEvent() {
        let date = new Date(props.selectedDate.valueOf());
        date.setHours(0, 0, 0, 0)
        date.setDate(date.getDate() + 1);
        props.setSelectedDate(date);
    }

    const localisation = i18n.language

    function handleFoldUnfoldEvent() {
        props.showCalendar ? props.setShowCalendar(false) : props.setShowCalendar(true);
    }

    return (
        <div className={classes.divDatePickerContainer}>
            <Button
                className={classes.changeDateButton}
                onClick={handleDayBackEvent}
                data-testid={"back-button"}
            >
                <ChevronLeft/>
            </Button>
            <Button
                className={classes.showCalendarButton}
                onClick={handleFoldUnfoldEvent}
                data-testid={"showCalendar-button"}>
                {props.selectedDate.toLocaleDateString(localisation, {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                })}
            </Button>
            <Button
                className={classes.changeDateButton}
                onClick={handleDayForwardEvent}
                data-testid={"forward-button"}
            >
                <ChevronRight/>
            </Button>
            <Button
                className={classes.changeDateButton}
                onClick={handleFoldUnfoldEvent}
                data-testid={"calendarDropdown-button"}
            >
                <CalenderIcon viewBox={"-4 -4 27 27"} fill={colors.iconAndTextGrey}/>
            </Button>
        </div>
    );
};
export default CollapsibleCalendarComponent;
