import {makeStyles} from "@material-ui/core";
import borders from "./borders";

export const useMultiBookingLegendStyles = makeStyles({
    legendTableContainer: {
        width: "auto",
        borderRadius: borders.mediumBorderRadius

    },
    legendDateDisplayTableCell: {
        paddingTop: "0.75rem",
        paddingBottom: "0.75rem"
    },
    legendDateDisplay: {
        borderRadius: "4px",
        boxShadow: "rgb(255, 255, 255) 0px 0px 0px",
        color: "black",
        height: "34px",
        position: "relative",
        width: "34px",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center"
    },
    legendLabel: {
        minWidth: "230px",
        paddingTop: "8px",
        paddingBottom: "8px",
        textAlign: "center",
        "&.MuiTableCell-root": {
            textAlign: "left",
        }
    }
})