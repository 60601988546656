import {User} from "../services/UserClient";
import {BuildingP, ManagePermission, MeetingRoomBookingP} from "../types/PermissionHandling";
import {intersectionNotEmpty} from "./Helpers";
import {Building, Neighborhood} from "../API";

export function initializeManagableState<T extends {
    bookerId: string
}>(element: T, user: User, buildingRoles: string[], roomPlanRoles: string[]): T & ManagePermission {
    const userRoles = user.admin2GetherRoles;
    const userCanManage = user.isAdmin
        || user.ID === element.bookerId
        || intersectionNotEmpty(userRoles, buildingRoles)
        || intersectionNotEmpty(userRoles, roomPlanRoles);
    return {...element, managingAllowed: userCanManage};
}

export function adjustManagableStateByNeighborhood<T extends ManagePermission>(element: T, neighborhoodId: string, neighborhoods: Neighborhood[], userRoles: string[]) {
    if (element.managingAllowed) {
        return element;
    }
    if (neighborhoodId === "") {
        return element;
    }
    const maybeNeighborhood = neighborhoods.find(n => n.neighborhoodId === neighborhoodId);
    if (!(maybeNeighborhood?.adminRoleIds)) {
        return element;
    }
    if (intersectionNotEmpty(maybeNeighborhood.adminRoleIds, userRoles)) {
        return {...element, managingAllowed: true};
    }
    return element;
}

export function adjustManagableStateByMeetingRoom(element: MeetingRoomBookingP, meetingRoomRoles: string[], userRoles: string[]) {
    if (element.managingAllowed) {
        return element;
    }
    if (intersectionNotEmpty(meetingRoomRoles, userRoles)) {
        return {...element, managingAllowed: true};
    }
    return element;
}

export function getBuildingManagePermissionConverter(user: User): (building: Building) => BuildingP {
    return (building: Building) => {
        const userCanManage = user.isAdmin || intersectionNotEmpty(building.roleIds, user.admin2GetherRoles)
        return {...building, managingAllowed: userCanManage}
    }
}
