import {RoomAndIconBookingColor, RoomBookingColor, RoomIconBookingColor} from "../Utils/Enums";
import {useEffect, useState} from "react";
import {MeetingRoomBookings} from "../types/MeetingRoomBookingType";
import {useUpdateBookingInfos} from "./useUpdateBookingInfos";
import {useGetFreeTimeWindows} from "./useGetFreeTimeWindows";

type ColorTypes = {
    roomAndIcon: RoomAndIconBookingColor,
    room: RoomBookingColor,
    icon: RoomIconBookingColor,
}

export default function useRoomColor(meetingRoomBookings: MeetingRoomBookings, currentUserID: string): ColorTypes {
    const {meetingRoom, bookings} = meetingRoomBookings;
    const [roomAndIconColor, setRoomAndIconColor] = useState(RoomAndIconBookingColor.GREEN)
    const [roomColor, setRoomColor] = useState(RoomBookingColor.GREEN)
    const [roomIconColor, setRoomIconColor] = useState(RoomIconBookingColor.GREEN)

    const bookingInfos = useUpdateBookingInfos(meetingRoomBookings.bookings, true);
    const freeTimeWindows = useGetFreeTimeWindows(bookingInfos, true, "08:00", "18:00");

    function setColoringGreen() {
        setRoomAndIconColor(RoomAndIconBookingColor.GREEN);
        setRoomColor(RoomBookingColor.GREEN);
        setRoomIconColor(RoomIconBookingColor.GREEN);
    }

    function setColoringBlue() {
        setRoomAndIconColor(RoomAndIconBookingColor.BLUE);
        setRoomColor(RoomBookingColor.BLUE);
        setRoomIconColor(RoomIconBookingColor.BLUE);
    }

    function setColoringYellow() {
        setRoomAndIconColor(RoomAndIconBookingColor.YELLOW);
        setRoomColor(RoomBookingColor.YELLOW);
        setRoomIconColor(RoomIconBookingColor.YELLOW);
    }

    function setColoringRed() {
        setRoomAndIconColor(RoomAndIconBookingColor.RED);
        setRoomColor(RoomBookingColor.RED);
        setRoomIconColor(RoomIconBookingColor.RED);
    }

    function setColoringOrange() {
        setRoomAndIconColor(RoomAndIconBookingColor.ORANGE);
        setRoomColor(RoomBookingColor.ORANGE);
        setRoomIconColor(RoomIconBookingColor.ORANGE);
    }

    useEffect(() => {
        if (!meetingRoom.isBookable) {
            return;
        }

        if (bookings.length === 0) {
            setColoringGreen();
            return;
        }

        const currentUserBookings = bookings.filter(b => b.bookerId === currentUserID);
        if (currentUserBookings.length > 0) {
            setColoringBlue();
            return;
        }

        if (freeTimeWindows.length === 0) {
            setColoringRed();
            return;
        }

        const isThereAtLeast3hourTimeWindow = freeTimeWindows.some(timeWindow => timeWindow.diff >= 3 * 60);
        if (isThereAtLeast3hourTimeWindow) {
            setColoringYellow();
            return;
        }

        setColoringOrange();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingRoomBookings, currentUserID]);

    return {
        roomAndIcon: roomAndIconColor,
        room: roomColor,
        icon: roomIconColor
    }
}