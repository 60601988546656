import {MeetingRoomType} from "./Enums";
import NeighborhoodIcon from '../svgs/neighborhood-svgrepo-com.svg';
import ClassroomIcon from '../svgs/classroom-icon.svg';
import VisitorsCenterIcon from '../svgs/visitor-identification-svgrepo-com.svg';
import InternalIcon from '../svgs/meeting-4-svgrepo-com.svg';

const iconStyle = {
    width: 24,
    height: 24,
    fill: 'currentColor'
};

export function meetingRoomIconFromType(meetingRoomType: MeetingRoomType) {
    switch (meetingRoomType) {
        case MeetingRoomType.CLASSROOM:
            return <img src={ClassroomIcon} alt="Classroom" style={iconStyle}/>
        case MeetingRoomType.NEIGHBORHOOD:
            return <img src={NeighborhoodIcon} alt="Neighborhood" style={iconStyle}/>
        case MeetingRoomType.VISITORS_CENTER:
            return <img src={VisitorsCenterIcon} alt="Visitor's Center" style={iconStyle}/>
        default:
            return <img src={InternalIcon} alt="Internal" style={iconStyle}/>
    }
}

export function meetingRoomTypeFromString(meetingRoomType: string) {
    switch (meetingRoomType) {
        case MeetingRoomType.CLASSROOM:
            return MeetingRoomType.CLASSROOM;
        case MeetingRoomType.NEIGHBORHOOD:
            return MeetingRoomType.NEIGHBORHOOD;
        case MeetingRoomType.VISITORS_CENTER:
            return MeetingRoomType.VISITORS_CENTER;
        default:
            return MeetingRoomType.INTERNAL
    }
}
