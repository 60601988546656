import {createContext} from "react";
import {roomPlanContextTypes} from "../types/RoomPlanContextTypes";

const RoomPlanContext = createContext<roomPlanContextTypes>({
    currentUserID: "",
    itemsToDisplay: [],
    setItemsToDisplay: () => {
    },
    bookingsToDisplay: [],
    setBookingsToDisplay: () => {
    }
})

export default RoomPlanContext