import {StyledDivMatrixRow} from "../../../styles/MeetingRoomBookingMatrixStyles";
import React from "react";
import EmptySlotsRange from "./EmptySlotsRange";
import BookingTimeSlot from "./BookingTimeSlot";
import {TimeSlotRowProps} from "./MeetingRoomBookingMatrixGridContainerComponent";

function TimeSlotRow(props: TimeSlotRowProps) {
    const {timeSlotData, startHour, endHour, hoursDisplayed, startBooking, meetingRoom} = props;
    const startMinutes = startHour * 60;
    const endMinutes = endHour * 60;
    let childKey = 0;

    const result: JSX.Element[] = [];
    let doneUpToMinute = startMinutes;

    let cutStart, cutEnd;
    let displayedStart, displayedEnd;
    for (const elem of timeSlotData) {
        cutStart = false;
        cutEnd = false;
        if (elem.timeEndInMinutes <= doneUpToMinute) {
            continue;
        }
        if (elem.timeStartInMinutes >= endMinutes) {
            continue;
        }
        if (elem.timeStartInMinutes < doneUpToMinute) {
            cutStart = true;
        }
        if (elem.timeEndInMinutes > endMinutes) {
            cutEnd = true;
        }
        if (elem.timeStartInMinutes > doneUpToMinute) {
            result.push(<EmptySlotsRange key={childKey++} startBooking={startBooking}
                                         hoursDisplayed={hoursDisplayed}
                                         startMinutes={doneUpToMinute}
                                         endMinutes={elem.timeStartInMinutes}
                                         meetingRoom={meetingRoom}/>);
        }

        displayedStart = cutStart ? doneUpToMinute : elem.timeStartInMinutes;
        displayedEnd = cutEnd ? endMinutes : elem.timeEndInMinutes;

        result.push(<BookingTimeSlot key={childKey++} minutesDuration={displayedEnd - displayedStart}
                                     hoursDisplayed={hoursDisplayed}
                                     onHoverStart={elem.onHoverStart}
                                     onHoverEnd={elem.onHoverEnd}
                                     onClick={elem.onClick}
                                     bookingItem={elem.bookingItem}/>);

        doneUpToMinute = displayedEnd;
        if (cutEnd || doneUpToMinute === endMinutes) {
            break;
        }
    }
    if (doneUpToMinute < endMinutes) {
        result.push(<EmptySlotsRange key={childKey++} startBooking={startBooking}
                                     hoursDisplayed={hoursDisplayed}
                                     startMinutes={doneUpToMinute}
                                     endMinutes={endMinutes}
                                     meetingRoom={meetingRoom}/>);
    }

    return (<StyledDivMatrixRow data-testid={"meeting-room-booking-matrix-time-slot-row-test-id"}>
        {result}
    </StyledDivMatrixRow>);
}

export default TimeSlotRow;