import {gql, useQuery} from "@apollo/client";
import {listNeighborhoods} from "../graphql/queries";
import {Neighborhood} from "../API";

export function useGetAllNeighborhoods(): { neighborhoods: Neighborhood[], neighborhoodLoading: boolean } {
    const variables: any = {
        limit: 4000,
    }
    const {
        data,
        subscribeToMore,
        refetch,
        loading: neighborhoodLoading
    } = useQuery(gql(listNeighborhoods), {variables});


    const neighborhoods = data?.listNeighborhoods?.items ?? []

    return {neighborhoods, neighborhoodLoading};
}
