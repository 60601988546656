import React, {useEffect, useRef, useState} from "react";
import {Booking, MeetingRoomBooking} from "../../../API";
import {MeetingRoomBookings} from "../../../types/MeetingRoomBookingType";
import Paper from "@mui/material/Paper";
import DeleteBookingButtons from "./DeleteBookingButtons";

interface MeetingRoomPaperProps {
    executeOtherBookingsButton: JSX.Element
    position: React.CSSProperties,
    offset: number,
    deletableBookings: MeetingRoomBooking[],
    removeSelectedBooking: (booking: Booking | MeetingRoomBooking) => void,
    meetingRoomBookingProps?: MeetingRoomBookings | null
}

export function MeetingRoomPaper({
                                     position,
                                     offset,
                                     executeOtherBookingsButton,
                                     deletableBookings,
                                     removeSelectedBooking,
                                     meetingRoomBookingProps
                                 }: MeetingRoomPaperProps) {

    const [elementPositionX, setElementPositionX] = useState(position.left);
    const [elementPositionY, setElementPositionY] = useState(position.top);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (elementRef.current && position.left && position.top) {
            if (window.innerWidth - offset < parseInt(position.left as string) + elementRef.current.offsetWidth) {
                setElementPositionX(window.innerWidth - elementRef.current.offsetWidth - offset);
            }
            if (window.innerHeight - offset < parseInt(position.top as string) + elementRef.current.offsetHeight) {
                setElementPositionY(window.innerHeight - elementRef.current.offsetHeight - offset);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef.current])

    return <Paper ref={elementRef} elevation={3} className={"SeatAlreadyBookedHint"} data-testid={"already-booked"}
                  sx={{
                      left: elementPositionX,
                      top: elementPositionY,
                      whiteSpace: "nowrap"
                  }}>
        {executeOtherBookingsButton}
        <DeleteBookingButtons
            bookingType={"meetingRoom"}
            meetingRoomBookingProps={meetingRoomBookingProps}
            deletableBookings={deletableBookings}
            handleDeleteBooking={removeSelectedBooking}
        />

    </Paper>
}