import React, {useEffect, useState} from "react";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import {itemToDisplay} from "../../types/RoomPlanContextTypes";
import {useRoomPlanContext} from "../../hooks/useRoomPlanContext";
import HintIcon from "../../styles/HintIcon";

export interface HintInterface {
    hintId?: string
    roomId?: string
    hintIconPosition: Position
    hintIconDimension: Dimensions
    text: string
    isVisible: boolean
}

interface Props {
    hint: HintInterface
}

type Position = {
    x: number,
    y: number,
    transform: string | undefined
}

type Dimensions = {
    width: number,
    height: number
}

const OfficeHintComponent = (props: Props) => {
    const [isMouseOverIcon, setIsMouseOverIcon] = useState(false);
    const {setItemsToDisplay} = useRoomPlanContext()

    const onMouseEnterFunc = () => {
        //timeout necessary to prevent onMouseLeave and onMouseEnter to trigger simultaneously on mobile
        setTimeout(() => setIsMouseOverIcon(true), 10);
    };

    const onMouseLeaveFunc = () => {
        setIsMouseOverIcon(false);
    };

    useEffect(function showMouseOver() {
        let mouseOverItems: itemToDisplay[] = [];
        if (isMouseOverIcon) {
            mouseOverItems = [{
                id: props.hint.hintId ?? "",
                icon: HintIcon(),
                text: props.hint.text,
                isHint: true,
                textOptional: null,
                isMeetingRoom: props.hint.hintId?.startsWith("Büro-") ?? false,
            }];
        }
        setItemsToDisplay(mouseOverItems)
    }, [setItemsToDisplay, isMouseOverIcon, props.hint.text])

    return (
        <g className="room-hint-icon"
           id={props.hint.hintId}
           style={{visibility: props.hint.isVisible ? "visible" : "hidden"}}
           transform={`translate(${props.hint.hintIconPosition.x},${props.hint.hintIconPosition.y})`}
           onMouseEnter={onMouseEnterFunc}
           onMouseLeave={onMouseLeaveFunc}
        >
            <rect width={props.hint.hintIconDimension.width}
                  height={props.hint.hintIconDimension.height}
                  style={{"fill": "transparent"}}/>
            <TextSnippetOutlinedIcon stroke="black" width={props.hint.hintIconDimension.width}
                                     height={props.hint.hintIconDimension.height}
                                     strokeWidth={0.3}/>
        </g>
    )
};

export default OfficeHintComponent;
