import React from "react";
import {Building} from "../../API";
import {useTranslation} from "react-i18next";
import GenericSelectorComponent from "./GenericSelectorComponent";
import {generateSimpleSingleSelectOptions} from "../../Utils/GenericSelectorUtil";

interface Props {
    buildings: Building[],
    selectedBuildingId: string | undefined,
    onBuildingSelect: (buildingId: string) => void
}

const BuildingSelectorDropdown: React.FC<Props> = ({buildings, selectedBuildingId, onBuildingSelect}) => {
    const {t} = useTranslation();

    function displayName(buildingId: string) {
        return buildings.find(b => b.buildingId === buildingId)?.buildingName ?? '';
    }

    function getIndex(selectedBuildingId: string | undefined) {
        if (!selectedBuildingId) {
            return 0;
        }
        const index = buildings.map(building => building.buildingId).indexOf(selectedBuildingId);
        return index === -1 ? 0 : index;
    }

    return (
        <GenericSelectorComponent
            disabled={false}
            labelText={t("general_building-singular")}
            style={{width: "100%", textAlign: "left"}}
            dataTestId="building-select"
            id="building-select-native-id"
            setSelected={onBuildingSelect}
            selectedIndex={getIndex(selectedBuildingId)}
            chooseFrom={generateSimpleSingleSelectOptions(buildings.map(building => building.buildingId), displayName)}
            isOutlined={true}
        />
    );
}

export default BuildingSelectorDropdown;
