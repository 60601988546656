import React, {useMemo} from "react";
import MultiSelectorDuplicities from "./MultiSelectorDuplicities";
import {CognitoUser} from "../hooks/useCognitoUserList";
import {useTranslation} from "react-i18next";
import {Inventory} from "../API";
import {Checkbox, FormControl, FormControlLabel, Paper} from "@material-ui/core";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {InvType} from "../Utils/Enums";
import {getInventoryLabel, inventoryFilter} from "../Utils/InventoryUtil";
import SeatConfigOwnerComponent from "./SeatConfigOwnerComponent";


export interface MassConfig {
    owner: string,
    isOwnerRegistered: boolean
    dockingStations: Inventory[]
    monitors: Inventory[]
    isSeatHeightAdjustable: boolean
    isBookable: boolean
    shouldBookingsBeDeleted: boolean
}


interface Props {
    state: MassConfig
    setState: (f: ((prev: MassConfig) => MassConfig)) => void
    cognitoUserList: CognitoUser[]
    inventoryItems: Inventory[]
}


function SeatMassConfigComponent(props: Props) {
    const {
        state, setState, cognitoUserList,
        inventoryItems
    } = props;
    const {t} = useTranslation();
    const {selectedOrgUnit} = useMainApplicationContext()

    function setDockingStations(dockingStations: Inventory[]) {
        setState(prev => ({...prev, dockingStations: dockingStations}));
    }

    function setMonitors(monitors: Inventory[]) {
        setState(prev => ({...prev, monitors: monitors}));
    }

    function changeSeatHightAdjustable() {
        setState(prev => ({...prev, isSeatHeightAdjustable: !prev.isSeatHeightAdjustable}));
    }

    function changeIsSeatBookable() {
        setState(prev => ({
            ...prev,
            shouldBookingsBeDeleted: prev.isBookable,
            isBookable: !prev.isBookable,
        }));
    }

    function setNewOwner(newOwner: string) {
        setState((prev) => ({...prev, owner: newOwner}));
    }

    function setIsNewOwnerRegistered(isRegistered: boolean) {
        setState((prev) => ({...prev, isOwnerRegistered: isRegistered}));
    }

    const dockingStationOptions = useMemo(() => {
        return inventoryItems.filter(inventoryFilter(InvType.Dockingstation, selectedOrgUnit?.orgId));
    }, [inventoryItems, selectedOrgUnit]);

    const monitorOptions = useMemo(() => {
        return inventoryItems.filter(inventoryFilter(InvType.Monitor, selectedOrgUnit?.orgId));
    }, [inventoryItems, selectedOrgUnit]);

    return (
        <Paper style={{
            marginTop: "0.625rem",
            marginBottom: "0.625rem",
            padding: "0.5rem",
            width: "inherit",
        }}>
            <SeatConfigOwnerComponent cognitoUserList={cognitoUserList} newOwner={state.owner}
                                      setNewOwner={setNewOwner}
                                      isNewOwnerRegistered={state.isOwnerRegistered}
                                      setIsNewOwnerRegistered={setIsNewOwnerRegistered}
            />
            <FormControl style={{
                width: "100%"
            }}>
                <MultiSelectorDuplicities
                    onChange={setDockingStations}
                    selected={state.dockingStations}
                    title={t('seat_info_dockingstations_title')}
                    options={dockingStationOptions}
                    getLabel={getInventoryLabel}
                />
                <MultiSelectorDuplicities
                    onChange={setMonitors}
                    selected={state.monitors}
                    title={t("seat_info_monitors_title")}
                    options={monitorOptions}
                    getLabel={getInventoryLabel}
                />
            </FormControl>
            <FormControlLabel style={{paddingLeft: "7px", height: "59px"}}
                              control={<Checkbox name="checkedC" checked={state.isSeatHeightAdjustable}
                                                 onChange={changeSeatHightAdjustable}/>}
                              label={t("height-adjustable-checkbox-label")}/>
            <FormControlLabel style={{paddingLeft: "7px", height: "59px"}}
                              control={<Checkbox name="checkedC" checked={state.isBookable}
                                                 onChange={changeIsSeatBookable}/>}
                              label={t("seat-is-bookable-checkbox-label")}/>
        </Paper>)
}

export default SeatMassConfigComponent