import React from "react";
import gregorian_de from "../../locales/calendar_de";
import {Calendar} from "react-multi-date-picker";
import {Button, makeStyles} from "@material-ui/core";
import uiElementMeasures from "../../styles/inputElementMeasures";
import shadows from "../../styles/shadows";
import borders from "../../styles/borders";
import colors from "../../styles/colors";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {Booking, MeetingRoomBooking} from "../../API";
import {isGreaterThanDate, isLowerThanDate, isSameDay, toDateISO} from "../../services/DateUtils";
import {BookedByStatus} from "../../Utils/Enums";
import {useMultiBookingDialogContext} from "../../hooks/useMultiBookingDialogContext"
import i18n from "../../i18n";
import gregorian_en from "../../locales/calender_en";
import {ClassNameMap} from "@mui/material";
import {BookingType} from "../../types/BookingType";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";

export interface MultiBookingCalendarComponentProps {
    today: Date
    selectedDates: Date[]
    onCalendarSelectionChange: (dates: any) => void
    bookings: (Booking | MeetingRoomBooking)[]
    greatestBookableDate: Date
    isBookingLimitReached: boolean
    isTimeActive: boolean | null | undefined
    isDateBookableForMe: (date: Date) => boolean
    isAnyTimeslotBookable: (date: Date) => boolean
    isBookingTimeFormatValid: () => boolean | undefined
    isBookingTimeInputComplete: () => boolean
    bookingType?: BookingType
}

const MultiBookingCalendarComponent: React.FC<MultiBookingCalendarComponentProps> = (props) => {
    const {
        today,
        selectedDates,
        onCalendarSelectionChange,
        bookings,
        greatestBookableDate,
        isBookingLimitReached,
        isTimeActive,
        isDateBookableForMe,
        isAnyTimeslotBookable,
        isBookingTimeFormatValid,
        isBookingTimeInputComplete,
        bookingType
    } = props

    const useStyles = makeStyles({
        calendar: {
            marginBottom: uiElementMeasures.marginBetweenElementsInColumn,
            boxShadow: shadows.innerInputElementShadow,
            border: borders.innerInputElementBorder,
            borderRadius: borders.mediumBorderRadius,
            "&.rmdp-wrapper": {
                width: "auto",
                display: "flex",
                justifyContent: "center",
                boxShadow: "0 0",
            },
        },
        changeDateButton: {
            minWidth: "40px",
            color: colors.iconAndTextGrey,
        },
        changeDateButtonDisabled: {
            minWidth: "40px",
            color: colors.iconAndTextGreyDisabled,
            pointerEvents: "none"
        },
    });
    const classes: ClassNameMap<"calendar" | "changeDateButton" | "changeDateButtonDisabled"> = useStyles()

    const {currentUser} = useMainApplicationContext()

    const localisation = i18n.language.substring(0, 2) === 'de' ? gregorian_de : gregorian_en;

    // @ts-ignore

    function createCalendarProps(dateJS: Date) {
        let calendarProps = {
            style: {},
            disabled: false
        }

        function undecidedTiming() {
            const isUndecidedTiming = isAnyTimeslotBookable(dateJS) &&
                (!isBookingTimeFormatValid() || !isBookingTimeInputComplete())
            if (!bookingType || bookingType !== "meetingRoom") return isTimeActive && isUndecidedTiming
            else return isUndecidedTiming;
        }

        function isNotBookable(): boolean {
            return (isBookingLimitReached && !isDateSelected(dateJS))
                || isGreaterThanDate(dateJS, greatestBookableDate);
        }

        const shouldBeDisabled = () => {
            return !isAnyTimeslotBookable(dateJS) || (isBookingLimitReached && !isDateSelected(dateJS))
                || isGreaterThanDate(dateJS, greatestBookableDate);
        }

        switch (getBookedByStatusForDate(dateJS)) {
            case BookedByStatus.CurrentUser: {
                if (undecidedTiming()) {
                    setDayStyle_Undecided(calendarProps)
                } else {
                    setDayStyle_BookedByCurrent(calendarProps)
                }

                if (isDateSelected(dateJS)) {
                    calendarProps.disabled = false;
                }
                break;
            }
            case BookedByStatus.TimeSlotUndecided: {
                if (isNotBookable()) {
                    setDayStyle_NotBookable(calendarProps, false)
                } else {
                    setDayStyle_Undecided(calendarProps)
                }
                break;
            }
            case BookedByStatus.NotAvailable: {
                if (isTimeActive || bookingType === "meetingRoom") {
                    setDayStyle_NotBookable(calendarProps, shouldBeDisabled())
                } else {
                    setDayStyle_NotBookable(calendarProps, true)
                }
                break;
            }
            case BookedByStatus.Bookable: {
                setDayStyle_Bookable(calendarProps)
                break;
            }
            case BookedByStatus.Past: {
                setDayStyle_Past(calendarProps)
                break;
            }
        }

        if (selectedDates.find((selectedDate: Date) => isSameDay(selectedDate, dateJS))) {
            setDayStyle_Selected(calendarProps)
        }

        if (isSameDay(today, dateJS)) setDayStyle_CurrentDay(calendarProps)

        if (dateJS.getDay() === 0 || dateJS.getDay() === 6) {
            setDayStyle_Weekend(calendarProps);
        }

        return calendarProps;
    }

    function getBookedByStatusForDate(date: Date): BookedByStatus {
        const bookingsToday: (Booking | MeetingRoomBooking)[] = bookings.filter((booking: Booking | MeetingRoomBooking): boolean =>
            booking.date === toDateISO(date)
        )

        if (isLowerThanDate(date, today)) {
            return BookedByStatus.Past
        }

        if (isGreaterThanDate(date, greatestBookableDate)) {
            return BookedByStatus.NotAvailable
        }

        if (!isTimeActive && bookingsToday.some((booking: Booking | MeetingRoomBooking) => isBookedByCurrentUser(booking))) {
            return BookedByStatus.CurrentUser
        }

        /*If the place is fully booked for the day, there is no ambiguity
        * and the place is not bookable*/
        if (!isAnyTimeslotBookable(date)) {
            return BookedByStatus.NotAvailable
        }

        if (isBookingLimitReached && !isDateSelected(date) && !isDateBookableForMe(date)) {
            return BookedByStatus.NotAvailable
        }


        if (isDateBookableForMe(date)) {
            return BookedByStatus.Bookable
        }

        if ((!isBookingTimeInputComplete() || !isBookingTimeFormatValid()) && isTimeActive) {
            return BookedByStatus.TimeSlotUndecided
        }

        return BookedByStatus.NotAvailable
    }

    const isDateSelected = (date: Date) => {
        return (selectedDates.map(d => toDateISO(d)).indexOf(toDateISO(date)) !== -1)
    }

    function isBookedByCurrentUser(booking: Booking | MeetingRoomBooking): boolean {
        if (!booking) return false
        return booking.bookerId === currentUser.ID
    }

    //STYLING
    function setDayStyle_Bookable(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            borderRadius: "4px",
            backgroundColor: colors.pastellgruen
        }
    }

    function setDayStyle_BookedByCurrent(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            borderRadius: "4px",
            backgroundColor: colors.pastellblau,
            color: "black"
        }
        calendarProps.disabled = !isTimeActive;
    }

    function setDayStyle_Past(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style
        }
        calendarProps.disabled = true
    }

    function setDayStyle_Undecided(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            borderRadius: "4px",
            backgroundColor: colors.pastellgelb,
            disabled: true,
            boxShadow: "0 0 0 #ffffff",
            color: "black",
        }
    }

    function setDayStyle_NotBookable(calendarProps: { style: {}; disabled: boolean }, shouldBeDisabled: boolean) {
        calendarProps.style = {
            ...calendarProps.style,
            borderRadius: "4px",
            backgroundColor: colors.pastellrot,
            disabled: true,
            boxShadow: "0 0 0 #ffffff",
            color: "black",
        }
        calendarProps.disabled = shouldBeDisabled;
    }

    function setDayStyle_Selected(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            borderStyle: "solid",
            borderColor: "black",
            borderWidth: "2px",
            color: "black"
        }
    }

    function setDayStyle_CurrentDay(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            color: "white"
        }
    }

    function setDayStyle_Weekend(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            color: "red"
        }
    }

    return (
        <>
            <Calendar
                className={classes.calendar}
                multiple={true}
                minDate={today}
                value={selectedDates}
                locale={localisation}
                weekStartDayIndex={1}
                onChange={onCalendarSelectionChange}
                renderButton={(direction: string, handleClick: any, disabled: boolean) => CustomCalenderArrowButton(direction, handleClick, disabled, classes)}
                mapDays={({date}) => {
                    const dateJS = new Date(date.toString())
                    return createCalendarProps(dateJS);
                }}
            />
        </>
    )
}
export default MultiBookingCalendarComponent

function CustomCalenderArrowButton(direction: string, handleClick: any, disabled: boolean, classes: ClassNameMap<"calendar" | "changeDateButton" | "changeDateButtonDisabled">) {

    let {currentMonth, setCurrentMonth} = useMultiBookingDialogContext();

    const handleMonthChange = () => {
        if (disabled) return
        const currentMonthToDate = new Date(currentMonth)
        if (direction === "right") {
            setCurrentMonth(new Date(currentMonthToDate.getFullYear(), currentMonthToDate.getMonth() + 1, 1).toString())
        } else {
            setCurrentMonth(new Date(currentMonthToDate.getFullYear(), currentMonthToDate.getMonth() - 1, 1).toString())
        }
        handleClick()
    }

    return (
        <i
            onClick={handleMonthChange}
            style={{
                padding: "0 10px",
                fontWeight: "bold",
            }}
        >
            {direction === "right"
                ? <Button className={disabled ? classes.changeDateButtonDisabled : classes.changeDateButton}
                          data-testid={"calendar-forward-button"}>
                    <ChevronRight/>
                </Button>
                : <Button className={disabled ? classes.changeDateButtonDisabled : classes.changeDateButton}
                          data-testid={"calendar-back-button"}>
                    <ChevronLeft/>
                </Button>}
        </i>
    )
}
