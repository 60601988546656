import {gql, useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {UserSettings} from "../API";
import {getUserSettings} from "../graphql/queries";

export function useUserSettings(userId: string, shouldRefetchUserSettings: boolean) {
    const skipQueryExecution = userId === "" || userId === undefined || userId === null || userId === "id"
    const {refetch} = useQuery(gql(getUserSettings), {variables: {userId: userId}});
    const [userSettings, setUserSettings] = useState<UserSettings | undefined>(undefined)
    const [settingsLoading, setSettingsLoading] = useState<boolean>(true)

    function refreshUserSettings() {
        if (skipQueryExecution) return;

        refetch()
            .then(response => setUserSettings(response.data.getUserSettings))
            .catch(error => console.error("useUserSettings refreshUserSettings", error, "userId", userId))
            .finally(() => setSettingsLoading(false));

    }

    useEffect(refreshUserSettings, [refetch, shouldRefetchUserSettings, skipQueryExecution, userId])

    return {userSettings: userSettings ?? undefined, settingsLoading: settingsLoading}
}