import React, {Dispatch, SetStateAction} from 'react';
import {Position} from '../SeatComponent'

interface Props {
    bookerNamePosition: Position
    bookerFirstName: string | undefined
    bookerLastName: string | undefined
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

const BookerName: React.FC<Props> = (props) => {
    const fontSize = props.bookerFirstName!.length > 10 || props.bookerLastName!.length > 10 ? 5.64 : 8.64;
    const bookerNamePosition = props.bookerNamePosition
    const bookerFirstName = props.bookerFirstName!.length > 10 ? props.bookerFirstName?.substring(0, 10).concat("...") : props.bookerFirstName
    const bookerLastName = props.bookerLastName!.length > 10 ? props.bookerLastName?.substring(0, 10).concat("...") : props.bookerLastName
    const setMouseOver = props.setMouseOver

    const onMouseEnter = () => {
        setMouseOver(true)
    }
    const onMouseLeave = () => {
        setMouseOver(false)
    }

    return (
        <text
            className="BookerName"
            xmlSpace="preserve"
            fill="#000"
            strokeWidth=".778"
            fontFamily="sans-serif"
            fontSize={fontSize}
            fontWeight="400"
            letterSpacing="0"
            onMouseOver={onMouseEnter}
            onMouseOut={onMouseLeave}
            x={bookerNamePosition.x}
            y={bookerNamePosition.y}
            transform={bookerNamePosition.transform}>
            <tspan x={bookerNamePosition.x}
                   y={bookerNamePosition.y}>{bookerFirstName !== undefined ? bookerFirstName : ""}</tspan>
            <tspan x={bookerNamePosition.x}
                   y={parseFloat(bookerNamePosition.y as string) + 7}>{bookerLastName !== undefined ? bookerLastName : ""}</tspan>
        </text>
    );
}

export default BookerName;