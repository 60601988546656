import {
    StyledDivOtherBookingTimeSlotInner,
    StyledDivOtherManageableBookingTimeSlotInner,
    StyledDivOwnBookingTimeSlotInner
} from "../../../styles/MeetingRoomBookingMatrixStyles";
import React from "react";
import {TimeSlotOuterProps} from "./EmptyTimeSlot";
import TimeSlotOuter from "./TimeSlotOuter";
import {getMeetingTypeLabel} from "../../../Utils/Enums";
import {Tooltip} from "@mui/material";
import {MeetingRoomBookingListItem} from "../../../types/MeetingRoomBookingListItemType";
import {useTranslation} from "react-i18next";
import {timestampToHourMinuteString} from "../MeetingRoomBookingManagerListComponent";
import {TIME_RANGE_SEPERATOR} from "../../../Utils/commons";
import {OwnedBy} from "../../../types/PermissionHandling";

interface Props {
    minutesDuration: number
    hoursDisplayed: number
    onHoverStart: () => void
    onHoverEnd: () => void
    onClick: () => void
    bookingItem: MeetingRoomBookingListItem
}

function MeetingRoomBookingTooltipContent(props: { bookingItem: MeetingRoomBookingListItem }) {
    const {bookingItem} = props;
    const {t} = useTranslation();
    return <>
        {`${t("meeting-name")}: ${bookingItem.meetingName ?? "-"}`}
        <br/>
        {`${t("general_time")}: ${timestampToHourMinuteString(bookingItem.timeBegin)}${TIME_RANGE_SEPERATOR}${timestampToHourMinuteString(bookingItem.timeEnd)}`}
        <br/>
        {`${t("number-of-guests")}: ${bookingItem.participantNumber ?? "-"}`}
        <br/>
        {`${t("booking-type")}: ${bookingItem.meetingType ? t(getMeetingTypeLabel(bookingItem.meetingType)) : "-"}`}
    </>;
}

function BookingTimeSlot(props: Props) {
    const {
        minutesDuration, hoursDisplayed, onHoverStart, onHoverEnd, onClick, bookingItem
    } = props

    const innerProps = {
        "data-testid": "meeting-room-booking-matrix-time-booking-time-slots-test-id",
        onMouseEnter: onHoverStart,
        onMouseLeave: onHoverEnd,
        onClick: onClick
    }

    const ownBooking = bookingItem.ownedBy === OwnedBy.USER;
    const managingAllowed = bookingItem.managingAllowed;
    let inner;
    if (ownBooking) {
        inner = <StyledDivOwnBookingTimeSlotInner {...innerProps} />;
    } else if (managingAllowed) {
        inner = <StyledDivOtherManageableBookingTimeSlotInner {...innerProps}/>;
    } else {
        inner = <StyledDivOtherBookingTimeSlotInner {...innerProps}/>;
    }

    const tooltipContent = <MeetingRoomBookingTooltipContent bookingItem={bookingItem}/>

    inner = <Tooltip arrow followCursor title={tooltipContent}>
        {inner}
    </Tooltip>

    const timeSlotOuterProps: TimeSlotOuterProps = {
        minutesDurationDisplayed: minutesDuration,
        hoursDisplayed: hoursDisplayed,
        content: inner
    }

    return <TimeSlotOuter {...timeSlotOuterProps} />
}

export default BookingTimeSlot