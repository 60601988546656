import {gql, useApolloClient} from "@apollo/client";
import {getSeatConfigs} from "../graphql/queries";
import {SeatConfig} from "../API";
import {useCallback} from "react";
import {comparatorAlphanumericValues} from "../Utils/Helpers";


export function useSeatConfigsOfRoom() {
    const client = useApolloClient();

    const fetchSingleSeatConfig = useCallback(async (roomId: string, seatName: string): Promise<SeatConfig | undefined> => {
        const variables = {
            roomId: roomId,
            seatName: {
                eq: seatName
            }
        };
        const result = await client.query({query: gql(getSeatConfigs), variables: variables});
        return result?.data?.getSeatConfigs?.items[0];

    }, [client]);

    const fetchSeatConfigs = useCallback(async (roomId: string, nextToken: string | undefined = undefined) => {
        const variables = {
            roomId: roomId,
            limit: 999,
            nextToken: nextToken === undefined ? null : nextToken
        };
        const result = await client.query({query: gql(getSeatConfigs), variables: variables});
        const seatConfigsResult = result?.data?.getSeatConfigs;
        if (seatConfigsResult === null) {
            return {items: [], nextToken: null}
        }
        const items: SeatConfig[] = seatConfigsResult.items;
        return {items: items, nextToken: seatConfigsResult.nextToken};
    }, [client]);

    const fetchAllSeatConfigs = useCallback(async (roomId: string) => {
        let result: SeatConfig[] = [];
        let {items, nextToken} = await fetchSeatConfigs(roomId);
        result = result.concat(items);
        while (nextToken !== null) {
            ({items, nextToken} = await fetchSeatConfigs(roomId, nextToken));
            result = result.concat(items);
        }
        return result.sort((a: SeatConfig, b: SeatConfig) => comparatorAlphanumericValues(a.seatName, b.seatName));
    }, [fetchSeatConfigs]);

    return {fetchAllSeatConfigs: fetchAllSeatConfigs, fetchSingleSeatConfig: fetchSingleSeatConfig};
}