import AddIcon from "@mui/icons-material/Add";
import React from "react";
import "./AddIcon.css";

function Sit2GetherAddIcon() {
    return (
        <div className="add-icon-container">
            <AddIcon />
        </div>
    );
}

export default Sit2GetherAddIcon;