import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {User} from "../services/UserClient";
import {Room} from "../API";

export type IOrgUnit = {
    orgId: string,
    orgName: string,
    deleted: boolean
}

interface OrgUnits {
    orgUnitList: IOrgUnit[],
    adminOrgUnitList: IOrgUnit[],
    allOrgUnitList: IOrgUnit[],
    orgUnitLoading: boolean
}

export function useOrgunit(user: User, rooms: Room[] = [], roomsLoading: boolean): OrgUnits {
    const [orgUnitList, setOrgUnitList] = useState<IOrgUnit[]>([])
    const [adminOrgUnitList, setAdminOrgUnitList] = useState<IOrgUnit[]>([]);
    const [allOrgUnitList, setAllOrgUnitList] = useState<IOrgUnit[]>([]);
    const allOrgUnitsURL = process.env.REACT_APP_ALL_ORG_UNIT_API_ENDPOINT!
    const ADMIN_PREFIX_LENGTH = 6;
    const [orgUnitLoading, setOrgUnitLoading] = useState<boolean>(true);

    function joinArraysWithoutDuplicates(array1: IOrgUnit[], array2: IOrgUnit[]): IOrgUnit[] {
        const uniqueMap = new Map();

        array1.forEach(element => {
            uniqueMap.set(element.orgId, element);
        });
        array2.forEach(element => {
            uniqueMap.set(element.orgId, element);
        });

        return Array.from(uniqueMap.values());
    }

    async function getAllValidOrgUnitsSorted(): Promise<IOrgUnit[]> {
        const cognitoUserSession = await Auth.currentSession();
        const response = await fetch(allOrgUnitsURL, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + cognitoUserSession.getAccessToken().getJwtToken()
            }
        });
        const allOrgUnits: IOrgUnit[] = await response.json();
        return allOrgUnits.filter(orgUnit => !orgUnit.deleted).sort(orgUnitCompareByName)
    }

    function getOrgUnitsUserIsAllowedToSee(allOrgUnits: IOrgUnit[]) {
        let result: IOrgUnit[]

        // org-units that have rooms we can see (includes public rooms that user doesn't belong to)
        const orgUnitsIdsFromRooms = Array.from(rooms.reduce(reduceToValidOrgUnits, new Set<string>()))
        const visibleOrgUnits = allOrgUnits.filter(orgUnit => orgUnitsIdsFromRooms.includes(orgUnit.orgId));

        result = joinArraysWithoutDuplicates(visibleOrgUnits, user.orgUnits)

        return result.sort(orgUnitCompareByName);

        function reduceToValidOrgUnits(prev: Set<string>, room: Room) {
            if (room.orgUnitId) prev.add(room.orgUnitId);
            return prev;
        }
    }

    function getOrgUnitsUserAllowedToManage(orgUnits: IOrgUnit[]) {

        if (user.isAdmin) return orgUnits
        else if (user.isOrgUnitAdmin) return (user.adminOrgUnits).map(mapToOrgUnitWithoutPrefix);
        else return [] as IOrgUnit[];

        function mapToOrgUnitWithoutPrefix(orgUnit: IOrgUnit) {
            return {...orgUnit, orgId: orgUnit.orgId.substring(ADMIN_PREFIX_LENGTH)}
        }
    }


    useEffect(() => {
        /*we depend on the user in getOrgUnitsUserIsAllowedToSee,
        and therefore we return when if dummyUser is detected,
        we also return when roomsLoading
        because we want to get the visible rooms from the roomList,
        and sometimes it takes longer time to fetch it*/
        if (user.ID === "id" || roomsLoading) {
            return
        }
        populateStates().then().finally(() => setOrgUnitLoading(false));

        async function populateStates() {
            try {
                const allValidOrgUnits = await getAllValidOrgUnitsSorted();
                setAllOrgUnitList(allValidOrgUnits)

                if (user.isAdmin) giveUserAccessToAllOrgUnits(allValidOrgUnits);
                else determineUserAccess(allValidOrgUnits);


            } catch (error: any) {
                console.error("Error in useOrgUnit: ", error);
            }

            function giveUserAccessToAllOrgUnits(allValidOrgUnits: IOrgUnit[]) {
                setOrgUnitList(allValidOrgUnits)
                setAdminOrgUnitList(allValidOrgUnits)
            }

            function determineUserAccess(allValidOrgUnits: IOrgUnit[]) {
                const orgUnitsUserIsAllowedToSee = getOrgUnitsUserIsAllowedToSee(allValidOrgUnits)
                const orgUnitsUserIsAllowedToManage = getOrgUnitsUserAllowedToManage(orgUnitsUserIsAllowedToSee)

                setOrgUnitList(orgUnitsUserIsAllowedToSee)
                setAdminOrgUnitList(orgUnitsUserIsAllowedToManage.sort(orgUnitCompareByName))
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.ID, roomsLoading])

    return {orgUnitList, adminOrgUnitList, allOrgUnitList, orgUnitLoading}
}

const orgUnitCompareByName = (a: IOrgUnit, b: IOrgUnit) => (a.orgName > b.orgName) ? 1 : -1

export async function getOrgUnit(): Promise<IOrgUnit[]> {
    const orgUnitURL = process.env.REACT_APP_COGNITO_USER_ENDPOINT! + "/self"
    const cognitoUserSession = await Auth.currentSession();
    const response = await fetch(orgUnitURL, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + cognitoUserSession.getAccessToken().getJwtToken()
        }
    });
    const data = await response.json();
    return [...data.orgUnits].sort(orgUnitCompareByName).filter((org: IOrgUnit) => !org.deleted);

}

export async function getAdminOrgUnit(): Promise<IOrgUnit[]> {
    const orgUnitURL = process.env.REACT_APP_COGNITO_USER_ENDPOINT! + "/self"
    const cognitoUserSession = await Auth.currentSession();
    const response = await fetch(orgUnitURL, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + cognitoUserSession.getAccessToken().getJwtToken()
        }
    });
    const data = await response.json();
    return [...data.adminOrgUnits].sort(orgUnitCompareByName).filter((org: IOrgUnit) => !org.deleted);

}