import React, {useEffect, useState} from "react";
import {Building, Hint, MeetingRoom, Neighborhood, Room} from "../API";

import "react-widgets/styles.css";
import "react-calendar/dist/Calendar.css";
import uiElementMeasures from "../styles/inputElementMeasures";
import Box from "@mui/material/Box";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {emptyUser} from "../pages/MainPage";
import {checkValidDefaultRoomConfig, comparatorAlphanumericValues, reformatNeighborhoodId} from "../Utils/Helpers";
import DayPickCalendarComponent from "./DayPickCalendarComponent";
import {RoomPickerComponentSelector} from "./SelectorComponents/RoomPickerComponentSelector";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import {ExpandLess} from "@material-ui/icons";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";
import RoomFinderComponent from "./SelectorComponents/RoomFinderComponent";
import {ApolloQueryResult, gql, useQuery} from "@apollo/client";
import {getHintsByBuilding} from "../graphql/queries";
import {useMeetingRoomList} from "../hooks/useMeetingRoomList";
import {useMeetingRoomInfoList} from "../hooks/useMeetingRoomInfoList";
import {MeetingRoomInfoWithCapacity} from "../types/MeetingRoomBookingListItemType";

interface Props {
    rooms: Room[]
    accessibleBuildings: Building[]
    selectedDate: Date
    selectedBuilding: Building | undefined,
    setSelectedBuilding: (b: Building | undefined) => void
    selectedRoom: Room | undefined
    selectedNeighborhood: Neighborhood | undefined
    setSelectedDate: (newDate: Date) => void
    onSelectedRoomChange: (newSelectedRoom: Room | undefined) => void
    onRoomFinderRoomSelected: (id: string) => void
    neighborhoodsOfSelectedRoom: Neighborhood[]
    onSelectedNeighborhoodChange: (neighborhood: Neighborhood | undefined) => void;
    setVisible: (arg: boolean) => void;
}

const RoomPickerComponent: React.FC<Props> = (props) => {
    const {
        selectedDate,
        setSelectedDate,
        rooms,  //rooms the user can use
        selectedRoom,
        onSelectedRoomChange,
        onRoomFinderRoomSelected,
        accessibleBuildings,
        selectedBuilding,
        setSelectedBuilding,
        neighborhoodsOfSelectedRoom,
        selectedNeighborhood,
        onSelectedNeighborhoodChange,
        setVisible,
    } = props;
    const {t} = useTranslation();

    /* TODO refactoring needed
       improve naming of variables and functions
       improve structure of effects and check dependency arrays
     */

    //user loaded and more than 0 rooms and buildings available
    const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false)

    //maybe: user has not changed selection with selectors; default selection could be applied, is in progress or is done and not changed by user selection
    const [doesInitialDefaultStateExist, setDoesInitialDefaultStateExist] = useState(false);

    //maybe: user preference/setting/config for room or neighborhood is invalid
    const [isDefaultStateInvalid, setIsDefaultStateInvalid] = useState(false);
    const {isMobile, isMobileDevice, isFirefox} = useDeviceMediaType();

    const [meetingRooms] = useMeetingRoomList(rooms.map(r => r.roomId));
    const [meetingRoomInfoList] = useMeetingRoomInfoList(selectedBuilding?.buildingId);

    const {
        currentUser,
        userSettingsObject,
        setIdOfDefaultBuilding
    } = useMainApplicationContext();
    let userSettings = userSettingsObject?.userSettings;
    const [hints, setHints] = useState<Hint[]>([]);
    const {refetch: refetchHints} = useQuery(gql(getHintsByBuilding), {
        variables: {
            buildingId: selectedBuilding?.buildingId,
        },
    });

    function waitForDocumentElement(elementId: string, tries: number = 80, waitMsBetweenTries = 100) {
        return new Promise((resolve, reject) =>
            waitForConditionLoop(() => !!document.getElementById(elementId), resolve, reject, tries, waitMsBetweenTries)
        );
    }

    function chooseDefaultNeighborhood() {
        if (!selectedBuilding || !selectedRoom) return;

        // Check if the user set preferences and fill accordingly before selecting the automatic default
        if (doesInitialDefaultStateExist && !isDefaultStateInvalid) {
            const preferenceNeighborhood = neighborhoodsOfSelectedRoom
                .find(neighborhood => neighborhood.neighborhoodId === userSettings?.defaultRoomConfig?.neighborhoodId);
            if (preferenceNeighborhood) {
                waitForDocumentElement(preferenceNeighborhood.neighborhoodId)
                    .then(
                        // zoom on neighborhood is triggered in roomplancomponent via seatbookinigcomponent
                        () => onSelectedNeighborhoodChange(preferenceNeighborhood)
                    )
                    .catch(
                        /* neighborhood element not found in document */
                        () => {
                            console.info("Can't zoom on default neighborhood because element is not ready.", preferenceNeighborhood.neighborhoodId);
                        });
                return;
            }
        }
        onSelectedNeighborhoodChange(undefined)
    }

    function handleOnSelectOfBuildingSelector(selection: Building | undefined): void {
        setDoesInitialDefaultStateExist(false);
        setSelectedBuilding(selection);
    }

    function handleOnSelectOfNeighborhoodSelector(neighborhood: undefined | Neighborhood) {
        setDoesInitialDefaultStateExist(false);
        onSelectedNeighborhoodChange(neighborhood)
    }

    useEffect(() => {
        if (currentUser.ID !== emptyUser.ID && rooms.length > 0 && accessibleBuildings.length > 0) {
            setInitiallyLoaded(true)
        }
    }, [currentUser, rooms, accessibleBuildings]);

    async function chooseDefaultBuilding(): Promise<void> {
        const buildingsSortedByName = [...accessibleBuildings].sort((b1, b2) => comparatorAlphanumericValues(b1.buildingNameLowerCased, b2.buildingNameLowerCased));
        const firstBuildingByName = buildingsSortedByName[0];
        setIdOfDefaultBuilding(firstBuildingByName?.buildingId ?? null);
        setSelectedBuilding(firstBuildingByName);
    }

    /**
     * The Method sets the initial state of the selection based on the saved user preferences
     */
    function chooseDefaultByUserPreference() {
        setDoesInitialDefaultStateExist(true);
        const config = userSettings?.defaultRoomConfig!;
        const roomConfiguredByUser = rooms.find(room => room.roomId === config.roomId);
        if (!roomConfiguredByUser?.buildingId) {
            //TODO maybe show error
            setIsDefaultStateInvalid(true);
            return;
        }
        const buildingOfRoom = accessibleBuildings.find(b => b.buildingId === roomConfiguredByUser.buildingId);
        if (!buildingOfRoom) {
            //TODO maybe show error
            setIsDefaultStateInvalid(true);
            return;
        }
        setIdOfDefaultBuilding(buildingOfRoom.buildingId)
        setSelectedBuilding(buildingOfRoom);
        onSelectedRoomChange(roomConfiguredByUser);
    }

    useEffect(() => {
        //Once the real user has been fetched, preselect a building
        if (userSettings?.defaultRoomConfig && !isDefaultStateInvalid && checkValidDefaultRoomConfig(userSettings.defaultRoomConfig, accessibleBuildings, rooms, neighborhoodsOfSelectedRoom)) {
            chooseDefaultByUserPreference();
        } else {
            chooseDefaultBuilding().catch(console.error);
        }

    }, [initiallyLoaded, isDefaultStateInvalid]);

    useEffect(() => {
        const fetchHints = async () => {
            try {
                const hints: ApolloQueryResult<Hint[]> = await refetchHints({
                    buildingId: selectedBuilding?.buildingId
                });
                // @ts-ignore data surely has this property
                setHints(hints?.data?.getHintsByBuilding || []);
            } catch (error) {
                console.error("Error fetching hints:", error);
            }
        };

        // Execute the fetchHints function
        fetchHints().catch(console.error);
    }, [rooms, selectedBuilding])

    useEffect(() => {
        /**If the currently selected building no longer has an accessible room, reselect the default building, but only
         * if there is still a building to switch to
         */
        if (selectedBuilding) {
            const roomPlansOfSelectedBuilding = getSelectableRoomPlans(rooms, selectedBuilding);
            if (roomPlansOfSelectedBuilding.length === 0 && rooms.length > 0) {
                chooseDefaultBuilding()
            }
        }
    }, [rooms, accessibleBuildings]);

    function onChangeBuilding() {
        const roomPlansOfSelectedBuilding = getSelectableRoomPlans(rooms, selectedBuilding);
        if (roomPlansOfSelectedBuilding.length === 0) {
            return; //TODO maybe deselect
        }
        const maybeDefaultRoomPlan = roomPlansOfSelectedBuilding.find(roomPlan => roomPlan.isDefault);
        if (maybeDefaultRoomPlan) {
            handleOnSelectOfRoomSelector(maybeDefaultRoomPlan);
            return;
        }
        const firstRoomPlanByName = [...roomPlansOfSelectedBuilding]
            .sort((r1, r2) => comparatorAlphanumericValues(r1.nameLowerCased, r2.nameLowerCased))[0];
        handleOnSelectOfRoomSelector(firstRoomPlanByName);
    }

    useEffect(() => {
        if (selectedBuilding && (!doesInitialDefaultStateExist || isDefaultStateInvalid)) {
            onChangeBuilding()
        }
    }, [selectedBuilding, rooms.length])

    function handleOnSelectOfRoomSelector(selection: undefined | Room) {
        setDoesInitialDefaultStateExist(false);
        onSelectedRoomChange(selection)
    }

    useEffect(() => {
        if (selectedRoom?.hasNeighborhood) {
            chooseDefaultNeighborhood();
        }
    }, [neighborhoodsOfSelectedRoom, selectedBuilding]);

    function handleRoomFinderRoomSelected(room: Room, svgElementId: string) {
        if (room !== selectedRoom) {
            onSelectedRoomChange(room);
        }
        waitForDocumentElement(svgElementId, 5, 1000)
            .then(() => onRoomFinderRoomSelected(svgElementId))
            .catch((r) => console.info(`Can't zoom on svg element with id '${svgElementId}'. Reason: ${r}`));
    }

    function getMeetingRoomsForSelectedBuilding(): MeetingRoomInfoWithCapacity[] {
        const roomIdsInSelectedBuilding = rooms
            .filter(r => r.buildingId === selectedBuilding?.buildingId)
            .map(r => r.roomId);
        return meetingRoomInfoList.filter(mr => roomIdsInSelectedBuilding.includes(mr.roomId));
    }

    return (
        <>
            {isMobile && (<Button onClick={() => setVisible(false)}
                                  startIcon={<ExpandLess/>}
                                  endIcon={<ExpandLess/>}
                                  style={{width: "100%", height: "40px"}}>{t("mobile_hide_filter")}</Button>
            )}
            <DayPickCalendarComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>

            <Box style={{marginBottom: uiElementMeasures.marginBetweenElementsInColumn}}>
                <RoomPickerComponentSelector selectorLabel={t("general_building-singular")}
                                             choices={accessibleBuildings}
                                             selected={selectedBuilding}
                                             setSelected={handleOnSelectOfBuildingSelector}
                                             getLabel={(b: Building) => b.buildingName}
                                             getIdentifier={(b: Building) => b.buildingId}
                                             includeNoneOption={false}/>
            </Box>

            <Box style={{marginBottom: uiElementMeasures.marginBetweenElementsInColumn}}>
                <RoomPickerComponentSelector selectorLabel={t("general_room-plan-singular")}
                                             choices={getSelectableRoomPlans(rooms, selectedBuilding)}
                                             selected={selectedRoom}
                                             setSelected={handleOnSelectOfRoomSelector}
                                             getLabel={(r: Room) => r.name}
                                             getIdentifier={(r: Room) => r.roomId}
                                             includeNoneOption={false}/>
            </Box>


            {selectedRoom?.hasNeighborhood && (
                <Box style={{marginBottom: uiElementMeasures.marginBetweenElementsInColumn}}>
                    <RoomPickerComponentSelector selectorLabel={t("general_neighborhood-singular")}
                                                 choices={neighborhoodsOfSelectedRoom}
                                                 selected={selectedNeighborhood}
                                                 setSelected={handleOnSelectOfNeighborhoodSelector}
                                                 getLabel={(n: Neighborhood) => n.name ?? reformatNeighborhoodId(n.neighborhoodId)}
                                                 getIdentifier={(n: Neighborhood) => n.neighborhoodId}
                                                 includeNoneOption={true}/>
                </Box>
            )}
            {
                (!isMobileDevice || !isFirefox) && // TODO: remove line when roomFinder is fixed on mobile firefox
                <Box style={{marginBottom: uiElementMeasures.marginBetweenElementsInColumn}}>
                    <RoomFinderComponent
                        selectorLabel={t("find_room_label")}
                        onSelection={handleRoomFinderRoomSelected}
                        hints={hints}
                        meetingRooms={getMeetingRoomsForSelectedBuilding()}
                        rooms={rooms}
                    />
                </Box>}
        </>
    );
};

function getSelectableRoomPlans(allRoomPlans: Room[], selectedBuilding: Building | undefined) {
    return allRoomPlans.filter(r => r.buildingId === selectedBuilding?.buildingId);
}

function waitForConditionLoop(checkCondition: () => boolean, resolve: (value: unknown) => void, reject: (reason?: any) => void,
                              tries: number = 80, waitMsBetweenTries = 100) {
    if (tries <= 0) {
        reject("not found");
        return;
    }
    if (checkCondition()) {
        resolve("found");
        return;
    }
    setTimeout(() => {
        waitForConditionLoop(checkCondition, resolve, reject, tries - 1, waitMsBetweenTries);
    }, waitMsBetweenTries);
}

export default RoomPickerComponent;
