/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onChangeRoomList = /* GraphQL */ `
  subscription OnChangeRoomList {
    onChangeRoomList {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
  }
`;
export const onChangeSeatBooking = /* GraphQL */ `
  subscription OnChangeSeatBooking {
    onChangeSeatBooking {
      response
    }
  }
`;
export const onChangeMeetingRoomBooking = /* GraphQL */ `
  subscription OnChangeMeetingRoomBooking {
    onChangeMeetingRoomBooking {
      response
    }
  }
`;
export const onChangeBuildings = /* GraphQL */ `
  subscription OnChangeBuildings {
    onChangeBuildings {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onChangeInventories = /* GraphQL */ `
  subscription OnChangeInventories {
    onChangeInventories {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onChangeHintsByRoom = /* GraphQL */ `
  subscription OnChangeHintsByRoom($roomId: ID!) {
    onChangeHintsByRoom(roomId: $roomId) {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeleteNeighborhoods = /* GraphQL */ `
  subscription OnCreateDeleteNeighborhoods {
    onCreateDeleteNeighborhoods {
      response
    }
  }
`;
export const onUpdateSecureMeetingRoom = /* GraphQL */ `
  subscription OnUpdateSecureMeetingRoom {
    onUpdateSecureMeetingRoom {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRestrictedNeighborhoods = /* GraphQL */ `
  subscription OnUpdateRestrictedNeighborhoods {
    onUpdateRestrictedNeighborhoods {
      statusCode
      message
    }
  }
`;
export const onCreateBuilding = /* GraphQL */ `
  subscription OnCreateBuilding($filter: ModelSubscriptionBuildingFilterInput) {
    onCreateBuilding(filter: $filter) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBuilding = /* GraphQL */ `
  subscription OnUpdateBuilding($filter: ModelSubscriptionBuildingFilterInput) {
    onUpdateBuilding(filter: $filter) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBuilding = /* GraphQL */ `
  subscription OnDeleteBuilding($filter: ModelSubscriptionBuildingFilterInput) {
    onDeleteBuilding(filter: $filter) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInventory = /* GraphQL */ `
  subscription OnCreateInventory(
    $filter: ModelSubscriptionInventoryFilterInput
  ) {
    onCreateInventory(filter: $filter) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInventory = /* GraphQL */ `
  subscription OnUpdateInventory(
    $filter: ModelSubscriptionInventoryFilterInput
  ) {
    onUpdateInventory(filter: $filter) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInventory = /* GraphQL */ `
  subscription OnDeleteInventory(
    $filter: ModelSubscriptionInventoryFilterInput
  ) {
    onDeleteInventory(filter: $filter) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMeetingRoomEquipment = /* GraphQL */ `
  subscription OnCreateMeetingRoomEquipment(
    $filter: ModelSubscriptionMeetingRoomEquipmentFilterInput
  ) {
    onCreateMeetingRoomEquipment(filter: $filter) {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMeetingRoomEquipment = /* GraphQL */ `
  subscription OnUpdateMeetingRoomEquipment(
    $filter: ModelSubscriptionMeetingRoomEquipmentFilterInput
  ) {
    onUpdateMeetingRoomEquipment(filter: $filter) {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMeetingRoomEquipment = /* GraphQL */ `
  subscription OnDeleteMeetingRoomEquipment(
    $filter: ModelSubscriptionMeetingRoomEquipmentFilterInput
  ) {
    onDeleteMeetingRoomEquipment(filter: $filter) {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSeatConfig = /* GraphQL */ `
  subscription OnCreateSeatConfig(
    $filter: ModelSubscriptionSeatConfigFilterInput
  ) {
    onCreateSeatConfig(filter: $filter) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSeatConfig = /* GraphQL */ `
  subscription OnUpdateSeatConfig(
    $filter: ModelSubscriptionSeatConfigFilterInput
  ) {
    onUpdateSeatConfig(filter: $filter) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSeatConfig = /* GraphQL */ `
  subscription OnDeleteSeatConfig(
    $filter: ModelSubscriptionSeatConfigFilterInput
  ) {
    onDeleteSeatConfig(filter: $filter) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookingConfig = /* GraphQL */ `
  subscription OnCreateBookingConfig(
    $filter: ModelSubscriptionBookingConfigFilterInput
  ) {
    onCreateBookingConfig(filter: $filter) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBookingConfig = /* GraphQL */ `
  subscription OnUpdateBookingConfig(
    $filter: ModelSubscriptionBookingConfigFilterInput
  ) {
    onUpdateBookingConfig(filter: $filter) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookingConfig = /* GraphQL */ `
  subscription OnDeleteBookingConfig(
    $filter: ModelSubscriptionBookingConfigFilterInput
  ) {
    onDeleteBookingConfig(filter: $filter) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMailConfig = /* GraphQL */ `
  subscription OnCreateMailConfig(
    $filter: ModelSubscriptionMailConfigFilterInput
    $userId: String
  ) {
    onCreateMailConfig(filter: $filter, userId: $userId) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMailConfig = /* GraphQL */ `
  subscription OnUpdateMailConfig(
    $filter: ModelSubscriptionMailConfigFilterInput
    $userId: String
  ) {
    onUpdateMailConfig(filter: $filter, userId: $userId) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMailConfig = /* GraphQL */ `
  subscription OnDeleteMailConfig(
    $filter: ModelSubscriptionMailConfigFilterInput
    $userId: String
  ) {
    onDeleteMailConfig(filter: $filter, userId: $userId) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDefaultRoomConfig = /* GraphQL */ `
  subscription OnCreateDefaultRoomConfig(
    $filter: ModelSubscriptionDefaultRoomConfigFilterInput
    $userId: String
  ) {
    onCreateDefaultRoomConfig(filter: $filter, userId: $userId) {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDefaultRoomConfig = /* GraphQL */ `
  subscription OnUpdateDefaultRoomConfig(
    $filter: ModelSubscriptionDefaultRoomConfigFilterInput
    $userId: String
  ) {
    onUpdateDefaultRoomConfig(filter: $filter, userId: $userId) {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDefaultRoomConfig = /* GraphQL */ `
  subscription OnDeleteDefaultRoomConfig(
    $filter: ModelSubscriptionDefaultRoomConfigFilterInput
    $userId: String
  ) {
    onDeleteDefaultRoomConfig(filter: $filter, userId: $userId) {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserSettings = /* GraphQL */ `
  subscription OnCreateUserSettings(
    $filter: ModelSubscriptionUserSettingsFilterInput
    $userId: String
  ) {
    onCreateUserSettings(filter: $filter, userId: $userId) {
      userId
      mailConfig {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        isReceiveMeetingRoomBooking
        createdAt
        updatedAt
      }
      defaultRoomConfig {
        userId
        roomId
        roomName
        neighborhoodId
        createdAt
        updatedAt
      }
      consent
      createdAt
      updatedAt
      userSettingsMailConfigId
      userSettingsDefaultRoomConfigId
    }
  }
`;
export const onUpdateUserSettings = /* GraphQL */ `
  subscription OnUpdateUserSettings(
    $filter: ModelSubscriptionUserSettingsFilterInput
    $userId: String
  ) {
    onUpdateUserSettings(filter: $filter, userId: $userId) {
      userId
      mailConfig {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        isReceiveMeetingRoomBooking
        createdAt
        updatedAt
      }
      defaultRoomConfig {
        userId
        roomId
        roomName
        neighborhoodId
        createdAt
        updatedAt
      }
      consent
      createdAt
      updatedAt
      userSettingsMailConfigId
      userSettingsDefaultRoomConfigId
    }
  }
`;
export const onDeleteUserSettings = /* GraphQL */ `
  subscription OnDeleteUserSettings(
    $filter: ModelSubscriptionUserSettingsFilterInput
    $userId: String
  ) {
    onDeleteUserSettings(filter: $filter, userId: $userId) {
      userId
      mailConfig {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        isReceiveMeetingRoomBooking
        createdAt
        updatedAt
      }
      defaultRoomConfig {
        userId
        roomId
        roomName
        neighborhoodId
        createdAt
        updatedAt
      }
      consent
      createdAt
      updatedAt
      userSettingsMailConfigId
      userSettingsDefaultRoomConfigId
    }
  }
`;
export const onCreateInfoText = /* GraphQL */ `
  subscription OnCreateInfoText($filter: ModelSubscriptionInfoTextFilterInput) {
    onCreateInfoText(filter: $filter) {
      id
      infoText
      important
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInfoText = /* GraphQL */ `
  subscription OnUpdateInfoText($filter: ModelSubscriptionInfoTextFilterInput) {
    onUpdateInfoText(filter: $filter) {
      id
      infoText
      important
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInfoText = /* GraphQL */ `
  subscription OnDeleteInfoText($filter: ModelSubscriptionInfoTextFilterInput) {
    onDeleteInfoText(filter: $filter) {
      id
      infoText
      important
      createdAt
      updatedAt
    }
  }
`;
