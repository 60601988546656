import {useRoomPlanContext} from "../../hooks/useRoomPlanContext"
import useRoomColor from "../../hooks/useRoomColor";
import {MeetingRoomBookings} from "../../types/MeetingRoomBookingType";
import React, {useEffect, useState} from "react";
import {Booking, MeetingRoom, MeetingRoomBooking} from "../../API";
import MeetingRoomIcon from "./meetingRoomRenderComponents/MeetingRoomIcon";
import MeetingRoomGround from "./meetingRoomRenderComponents/MeetingRoomGround";
import {Dimensions, Position} from "../../types/PositioningAndDimensionTypes";

export interface MeetingRoomInterface {
    meetingRoomID: string
    meetingRoomName: string
    roomCap: number
    roomId: string
    type: string,
    roleIds: string[]
    neighborhoodId: string,
    equipmentIds: string[],
    isBookable: boolean,
    meetingRoomGroupPosition: Position
    meetingRoomPosition: Position
    meetingRoomDimension: Dimensions
    meetingRoomShape: string
    meetingRoomIconPosition: Position
    meetingRoomIconDimension: Dimensions
}

interface Props {
    onClick: (bookings: MeetingRoomBookings) => void
    meetingRoomBookings: MeetingRoomBookings
    isTimeBookingActive: boolean
    meetingRoomsCurrentRoom: MeetingRoom[]
    hasAccess: boolean
}

const MeetingRoomComponent = (props: Props) => {
    const {meetingRoomBookings, hasAccess} = props
    const meetingRoomGroupPosition = meetingRoomBookings.meetingRoom.meetingRoomGroupPosition;
    const meetingRoomPosition = meetingRoomBookings.meetingRoom.meetingRoomPosition;
    const meetingRoom = meetingRoomBookings.meetingRoom;
    const {setBookingsToDisplay, currentUserID} = useRoomPlanContext()
    const roomBookingState = useRoomColor(meetingRoomBookings, currentUserID);
    const [isMouseOverIcon, setIsMouseOverIcon] = useState(false);

    useEffect(function showMouseOver() {
        let mouseOverBookings: (Booking | MeetingRoomBooking)[] = [];
        if (isMouseOverIcon) {
            mouseOverBookings = props.meetingRoomBookings.bookings;
        }

        mouseOverBookings.sort((a, b) => {
            return (a.timeBegin || "") < (b.timeBegin || "") ? -1 : 1
        })

        setBookingsToDisplay(mouseOverBookings)
    }, [isMouseOverIcon])

    const mr = props.meetingRoomsCurrentRoom.find(mr => mr.meetingRoomId === meetingRoomBookings.meetingRoom.meetingRoomID);
    if (mr != null) {
        meetingRoom.meetingRoomName = mr.name;
        let capacity = 0;
        if (mr?.capacity) {
            capacity = mr.capacity
        }
        meetingRoom.roomCap = capacity;
    }

    function handleOnClickSeat() {
        if (hasAccess && meetingRoom.isBookable) {
            props.onClick(meetingRoomBookings);
            setIsMouseOverIcon(false);
        }
    }

    return (
        <g className="meeting-room" id={props.meetingRoomBookings.meetingRoom.meetingRoomID}
           transform={meetingRoomGroupPosition.transform}
           onClick={() => handleOnClickSeat()}>
            <svg x={meetingRoomGroupPosition.x} y={meetingRoomGroupPosition.y}
                 className={roomBookingState.roomAndIcon}>
                <g className={"meeting-room-elements"}>
                    <svg x={meetingRoomPosition.x} y={meetingRoomPosition.y}
                         className={roomBookingState.room}>
                        {hasAccess && meetingRoom.isBookable && (
                            <MeetingRoomGround
                                elementPosition={meetingRoom.meetingRoomPosition}
                                elementShape={meetingRoom.meetingRoomShape}
                                isFiltered={false}
                            />
                        )}
                    </svg>
                    {meetingRoom.isBookable && <MeetingRoomIcon
                        elementPosition={meetingRoom.meetingRoomIconPosition}
                        elementDimensions={meetingRoom.meetingRoomIconDimension}
                        iconStyle={roomBookingState.icon}
                        hasAccess={hasAccess}
                        setMouseOver={setIsMouseOverIcon}
                    />}
                </g>
            </svg>
        </g>)
};

export default MeetingRoomComponent;