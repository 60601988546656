import {BookingInfo} from "../types/BookingInfoType";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import de from 'dayjs/locale/de';
import {TimeWindow} from "../types/TimeWindowType";

dayjs.locale(de)

export function useGetFreeTimeWindows(bookingInfos: BookingInfo[], isTimeBookingActive: boolean, startWorkDay: string, endWorkDay: string) {
    const [seatBookingTimeWindow, setSeatBookingTimeWindow] = useState<TimeWindow[]>([])

    useEffect(function updateFreeTimeWindows() {
        function getFreeTimeInterSection(bookings : BookingInfo[]) : TimeWindow[]{
            //Get all booking intervals for the currently selected days and sort them ascending first by start time,
            // and by end time if start times are equal
            let excludeIntervals :any = bookings
                .map(booking =>
                [booking.timeStart.format("HH:mm"),
                    booking.timeEnd.format("HH:mm")])
                .sort(
                (b1,b2) => b1[0] === b2[0] ? b1[1].localeCompare(b2[1]) : b1[0].localeCompare(b2[0])
            )

            let start = startWorkDay
            let end = endWorkDay
            let includeIntervals = []

            //For each of the booked time intervals, if the current start time is sooner than the start of booking,
            // create a new free time interval and update the start variable
            for (let exclude of excludeIntervals) {
                if (start < exclude[0]) {
                    includeIntervals.push([start,exclude[0]])
                }
                start = start > exclude[1] ? start : exclude[1]
            }

            //Handle the last time interval from the last booking to the end of day
            if (start < end) {
                includeIntervals.push([start,end]);
            }

            return includeIntervals.map(timeInterval => {
                let start = dayjs(timeInterval[0], "HH:mm")
                let end = dayjs(timeInterval[1], "HH:mm")
                let diff = end.diff(start, "minute")
                return {
                    start: start,
                    end: end,
                    diff: diff

                }})
        }

        if (isTimeBookingActive && bookingInfos) {
             setSeatBookingTimeWindow(getFreeTimeInterSection(bookingInfos))
        }
    }, [isTimeBookingActive, bookingInfos, setSeatBookingTimeWindow, startWorkDay, endWorkDay])
    return seatBookingTimeWindow;
}