import {gql, useQuery} from "@apollo/client";
import {getMeetingRoomBookings} from "../graphql/queries";
import {onChangeMeetingRoomBooking, onUpdateRestrictedNeighborhoods} from "../graphql/subscriptions";
import {useEffect} from "react";
import {MeetingRoomBooking} from "../API";
import {SUBSCRIPTION_DELAY} from "../Utils/commons";

export function useBookingByMeetingRoomId(roomId: string, roomOrgUnitId: string, dateISO: string, meetingRoomId: string): MeetingRoomBooking[] | [] {
    const date = new Date(dateISO);
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

    const {data, subscribeToMore, refetch, fetchMore} =
        useQuery(gql(getMeetingRoomBookings), {
            variables: {
                meetingRoomIds: [meetingRoomId],
                date: firstDayOfMonth,
                dateTo: lastDayOfMonth,
                roomIds: [roomId]
            },
            skip: (!roomId || roomId === "") || (!meetingRoomId || meetingRoomId === ""),
        });

    function subscribeToMutations() {
        if (roomId === "")
            return;

        let subscriptions: (() => void)[] = [];
        let timeout = setTimeout(() => {
            const unsubscribeOnChangeMeetingRoomBooking = subscribeToMore({
                document: gql(onChangeMeetingRoomBooking),
                updateQuery: (prev) => {
                    refetch().then().catch(error => {
                        console.error("refetch in useBookingByMeetingRoomId onChangeMeetingRoomBookingsSubscription: " + JSON.stringify(error));
                    });
                    return prev;
                },
                onError: (error: any) => console.error("onChangeMeetingRoomBookingsSubscription: " + JSON.stringify(error))
            });

            const unsubscribeMeetingRoomOnUpdateRestrictedNeighborhoods = subscribeToMore({
                document: gql(onUpdateRestrictedNeighborhoods),
                updateQuery: (prev) => {
                    refetch().then().catch(error => {
                        console.error("refetch in useBookingByMeetingRoomId onUpdateRestrictedNeighborhoodsSubscription: " + JSON.stringify(error));
                    });
                    return prev;
                },
                onError: (error: any) => console.error("onUpdateRestrictedNeighborhoodsSubscription: " + JSON.stringify(error))
            });

            subscriptions = [
                unsubscribeOnChangeMeetingRoomBooking, unsubscribeMeetingRoomOnUpdateRestrictedNeighborhoods
            ];
        }, SUBSCRIPTION_DELAY);

        function unsubscribe() {
            subscriptions.forEach(subscription => subscription());
            clearTimeout(timeout);
        }

        return () => unsubscribe();
    }

    useEffect(subscribeToMutations, [roomOrgUnitId, roomId, dateISO, meetingRoomId, subscribeToMore]);

    function fetchNextMonthInAdvanceAfterGettingCurrentMonth() {
        if (data) {// the if prevents querying next month before having the current month
            const lastDayOfNextMonth = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth() + 1, 1)
            fetchMore({
                variables: {
                    date: lastDayOfMonth,
                    dateTo: lastDayOfNextMonth
                }
            }).catch((err) => console.log(err));
        }
    }

    fetchNextMonthInAdvanceAfterGettingCurrentMonth();

    return data?.getMeetingRoomBookings?.items.map((b: MeetingRoomBooking) => {
        return {...b, __typename: "MeetingRoomBooking"}
    }) ?? [];
}