import {StyledDivEmptyTimeslotInner} from "../../../styles/MeetingRoomBookingMatrixStyles";
import React from "react";
import TimeSlotOuter from "./TimeSlotOuter";

interface Props {
    minutesDurationDisplayed: number
    hoursDisplayed: number
    onClick: () => void
}

export interface TimeSlotOuterProps {
    minutesDurationDisplayed: number
    hoursDisplayed: number
    content: JSX.Element
}

function EmptyTimeSlot(props: Props) {
    const {minutesDurationDisplayed, hoursDisplayed, onClick} = props;
    const inner = <StyledDivEmptyTimeslotInner onClick={onClick}
                                               data-testid="meeting-room-booking-matrix-empty-time-slot-row-test-id"
    />
    const timeSlotOuterProps: TimeSlotOuterProps = {
        minutesDurationDisplayed: minutesDurationDisplayed,
        hoursDisplayed: hoursDisplayed,
        content: inner
    }
    return <TimeSlotOuter {...timeSlotOuterProps}/>;
}

export default EmptyTimeSlot;