import React, {Dispatch, SetStateAction} from 'react';
import {SeatInterface} from "../SeatComponent";

interface Coordinates {
    x: number,
    y: number
}

interface Props {
    seatInterface: SeatInterface,
    elementPosition: Coordinates,
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

function AdjustableHeight(props: Props) {
    const elementPosition = props.elementPosition
    const setMouseOver = props.setMouseOver

    const onMouseEnter = () => {
        setMouseOver(true);
    }
    const onMouseLeave = () => {
        setMouseOver(false);
    }

    return (
        <g
            transform={`translate(${elementPosition.x}, ${elementPosition.y}) scale(0.22 0.22)`}
            onMouseOver={onMouseEnter} onMouseOut={onMouseLeave}
            data-testid={"isSeatHeightAdjustable-" + props.seatInterface.seatID}>
            <path fill="transparent" d="M85.959 90.337h33.866v33.866H85.958z"/>
            <path stroke="#000" strokeWidth="3" d="M0 16.5h32"
                  transform="matrix(1.0583 .00001 0 1.0583 85.959 90.337)"/>
            <path fill="#000" d="m98.659 100.92 4.233-8.466 4.233 8.466zM98.658 114.678l4.234 8.466 4.233-8.466z"/>
        </g>
    );
}

export default AdjustableHeight;