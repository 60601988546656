import {useRoomList} from "./useRoomList";
import {useOrgunit} from "./useOrgunit";
import {useUser} from "./useUser";
import {useBuildingList} from "./useBuildingList";
import {useInventoryList} from "./useInventoryList";
import {useUserSettings} from "./useUserSettings";
import {useGetAllNeighborhoods} from "./useGetAllNeighborhoods";
import {useMeetingRoomService} from "./useMeetingRoomService";
import {useBuildingEquipmentService} from "./useBuildingEquipmentService";

interface InitialiseProps {
    shouldRefetchUserSettings: boolean,
    signOut: () => Promise<void>
}

export function useInitialise(initialiseProps: InitialiseProps) {
    const {shouldRefetchUserSettings, signOut} = initialiseProps;

    const {currentUser, userLoading} = useUser({signOut});
    const {rooms, roomsLoading} = useRoomList();
    const {neighborhoods, neighborhoodLoading} = useGetAllNeighborhoods();
    const {orgUnitList, adminOrgUnitList, orgUnitLoading} = useOrgunit(currentUser, rooms, roomsLoading);
    const {buildingList, buildingsLoading} = useBuildingList();
    const {inventories, inventoryLoading} = useInventoryList();
    const {userSettings, settingsLoading} = useUserSettings(currentUser.ID, shouldRefetchUserSettings);
    const {meetingRoomArray, meetingRoomMap} = useMeetingRoomService();
    const {buildingEquipmentArray, buildingEquipmentMap} = useBuildingEquipmentService()
    //removed bookingsLoading, otherwise roomPickerComponent will do initialization for every new date
    const isLoading = userLoading || roomsLoading || orgUnitLoading || buildingsLoading || inventoryLoading || settingsLoading || neighborhoodLoading
    return {
        currentUser,
        rooms,
        neighborhoods,
        orgUnitList,
        adminOrgUnitList,
        buildingList,
        inventories,
        userSettings,
        isLoading,
        meetingRoomService: {meetingRoomArray: meetingRoomArray, meetingRoomMap: meetingRoomMap},
        buildingEquipmentService: {
            buildingEquipmentArray: buildingEquipmentArray,
            buildingEquipmentMap: buildingEquipmentMap
        },
    }
}