import React from "react";
import {MeetingRoom} from "../../../API";
import EmptyTimeSlot from "./EmptyTimeSlot";

interface Props {
    startBooking: (start: number, end: number, meetingRoom: MeetingRoom) => void;
    startMinutes: number
    endMinutes: number
    hoursDisplayed: number
    meetingRoom: MeetingRoom;
}

function EmptySlotsRange (props: Props) {
    const {startBooking, startMinutes, endMinutes, hoursDisplayed, meetingRoom} = props;
    const desiredDurationMinutes = 60;

    let nextSplit = startMinutes;
    let current = startMinutes;
    const result: JSX.Element[] = []

    while (current < endMinutes) {
        if ((endMinutes - current) < (2 * desiredDurationMinutes)) {
            nextSplit = endMinutes;
        } else if ((current % desiredDurationMinutes) !== 0) {
            nextSplit = current + (desiredDurationMinutes - (current % desiredDurationMinutes)) + desiredDurationMinutes;
        } else {
            nextSplit = current + desiredDurationMinutes;
        }

        const bookingStart = current;
        const bookingEnd = nextSplit;

        result.push(
            <EmptyTimeSlot
                key={startMinutes + "-" + bookingStart} minutesDurationDisplayed={nextSplit - current}
                           hoursDisplayed={hoursDisplayed}
                           onClick={() => {
                               startBooking(bookingStart, bookingEnd, meetingRoom)
                           }}/>
        );
        current = nextSplit;
    }
    return <>{result}</>;
}

export default EmptySlotsRange;