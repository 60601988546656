import React, {useEffect, useMemo, useState} from "react";
import {
    Box,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    IconButton,
    makeStyles,
    Paper,
    Theme,
    Tooltip
} from "@material-ui/core";
import {gql, useMutation} from "@apollo/client";
import {deleteBookingsBySeatIds, updateSeatConfigs} from "../graphql/mutations";
import {Inventory, SeatConfig} from "../API";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import {InvType} from "../Utils/Enums";
import MultiSelectorDuplicities from "./MultiSelectorDuplicities";
import {CognitoUser} from "../hooks/useCognitoUserList";
import SeatConfigOwnerComponent from "./SeatConfigOwnerComponent";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";
import {getInventoryLabel, inventoryFilter, multiplyInventoryByOccurence} from "../Utils/InventoryUtil";
import ConfirmationDialog from "./ConfirmationDialog";


interface Props {
    currentRoomOrgUnitAdmin: string | null | undefined
    setShowSeatConfiguration: (value: boolean) => void
    runAfterSave: () => void
    seat: SeatConfig
    inventoryItems: Inventory[]
    cognitoUserList: CognitoUser[]
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    divSeatConfiguration: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        margin: "20px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "80px"
        }
    },
    divRoomPicker: {
        width: "20%",
        maxWidth: "350px",
        minWidth: "250px",
        padding: "20px"
    },
    btnEnabled: {
        color: "green"
    },
    btnDisabled: {
        color: "disabled"
    },
    heightAdjustableCheckbox: {
        paddingLeft: "7px"
    },
    isBookableCheckbox: {
        paddingLeft: "7px",
        paddingRight: "7px"
    }
}));

const SeatConfigurationComponent: React.FC<Props> = (props) => {
    const classes = useStyles();

    const {
        currentRoomOrgUnitAdmin,
        setShowSeatConfiguration,
        runAfterSave,
        seat,
        inventoryItems,
        cognitoUserList
    } = props
    const {selectedOrgUnit, rerenderSeatConfigsTrigger, setRerenderSeatConfigsTrigger} = useMainApplicationContext()
    const [updateSeatConfigsRequest] = useMutation(gql(updateSeatConfigs));
    const [deleteBookingsBySeatIdsMutation] = useMutation(gql(deleteBookingsBySeatIds));
    const {reportError} = useErrorContext();

    const [selectedDockingStations, setSelectedDockingStations] = useState<Inventory[]>([])
    const [selectedMonitors, setSelectedMonitors] = useState<Inventory[]>([])
    const [newOwner, setNewOwner] = useState("")
    const [isNewOwnerRegistered, setIsNewOwnerRegistered] = useState(false)
    const [isSeatHeightAdjustable, setIsSeatHeightAdjustable] = useState(() => {
        return seat.isSeatHeightAdjustable ?? false
    })
    const [isSeatBookable, setIsSeatBookable] = useState(() => {
        return seat.isBookable ?? true
    })
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);


    const dockingStationOptions = useMemo(() => {
        return inventoryItems.filter(inventoryFilter(InvType.Dockingstation, selectedOrgUnit?.orgId));
    }, [inventoryItems, selectedOrgUnit]);

    const monitorOptions = useMemo(() => {
        return inventoryItems.filter(inventoryFilter(InvType.Monitor, selectedOrgUnit?.orgId));
    }, [inventoryItems, selectedOrgUnit]);

    useEffect(function setDockingSelection() {
        setSelectedDockingStations(dockingStationOptions
            .filter(dockStation => seat.inventory.includes(dockStation.inventoryId))
            .flatMap(multiplyInventoryByOccurence(seat.inventory))
        );
    }, [dockingStationOptions, seat]);

    useEffect(function setMonitorSelection() {
        setSelectedMonitors(monitorOptions
            .filter(monitor => seat.inventory.includes(monitor.inventoryId))
            .flatMap(multiplyInventoryByOccurence(seat.inventory))
        );
    }, [monitorOptions, seat]);

    useEffect(function setOwnerConfig() {
        if (seat.owner) {
            setNewOwner(seat.owner)
            if (seat.isOwnerRegistered) {
                setIsNewOwnerRegistered(seat.isOwnerRegistered)
            }
        }
    }, [seat]);

    const handleSaveButtonClick = () => {
        if (isSeatBookable) {
            handleSaveSeatConfig();
        } else {
            setShowConfirmationDialog(true);
        }
    }

    const handleSaveSeatConfig = () => {
        const inventoryIds = selectedDockingStations.concat(selectedMonitors).map(item => item.inventoryId);
        updateSeatConfigsRequest({
            variables: {
                input: {
                    roomId: seat.roomId,
                    seatName: seat.seatName,
                    isSeatHeightAdjustable: isSeatHeightAdjustable,
                    owner: newOwner,
                    isOwnerRegistered: isNewOwnerRegistered,
                    inventory: inventoryIds,
                    confirmed: true,
                    isBookable: isSeatBookable,
                    neighborhoodId: seat.neighborhoodId
                }
            }
        }).then(() => {
            if (!isSeatBookable) {
                deleteBookingsBySeatIdsMutation({
                    variables: {
                        input: {
                            seatIds: [seat.seatName],
                            roomOrgUnitAdmin: currentRoomOrgUnitAdmin
                        }
                    }
                }).catch((error) => reportError(error, undefined, "SeatConfigurationComponent deleteBookingsBySeatIds"));
                setRerenderSeatConfigsTrigger(!rerenderSeatConfigsTrigger);
            }
            setShowSeatConfiguration(false)
        }).catch((err) => {
            reportError(err, "", "SeatConfigurationComponent handleSaveSeatConfig")
        }).finally(() => runAfterSave())
    }

    const handleIsSeatHeightAdjustable = () => {
        setIsSeatHeightAdjustable(!isSeatHeightAdjustable)
    }

    const handleIsSeatBookable = () => {
        setIsSeatBookable(!isSeatBookable)
    }

    const {t} = useTranslation();
    return (
        <div>
            <ConfirmationDialog
                data-testid={"confirmation-dialog"}
                isOpen={showConfirmationDialog}
                setIsOpen={setShowConfirmationDialog}
                onCancel={() => {
                }}
                onOk={() => {
                    handleSaveSeatConfig();
                }}
            >
                <div
                    style={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        marginBottom: '12px'
                    }}>{t("seat_config_apply_confirm_bookings_deletion")}
                </div>
            </ConfirmationDialog>

            <Paper
                style={{
                    marginTop: "0.625rem",
                    marginBottom: "0.625rem",
                    padding: "0.5rem",
                    width: "inherit",
                }}
                data-testid={"configure-seat-component"}
            >
                <SeatConfigOwnerComponent cognitoUserList={cognitoUserList} newOwner={newOwner}
                                          setNewOwner={setNewOwner}
                                          isNewOwnerRegistered={isNewOwnerRegistered}
                                          setIsNewOwnerRegistered={setIsNewOwnerRegistered}
                />
                <Box>
                    <FormControl
                        style={{
                            width: "100%"
                        }}
                        data-testid={"input-fields"}>
                        <MultiSelectorDuplicities
                            onChange={(selected) => setSelectedDockingStations(selected)}
                            selected={selectedDockingStations}
                            title={t('seat_info_dockingstations_title')}
                            options={dockingStationOptions}
                            getLabel={getInventoryLabel}
                        />
                        <MultiSelectorDuplicities
                            onChange={(selected) => setSelectedMonitors(selected)}
                            selected={selectedMonitors}
                            title={t("seat_info_monitors_title")}
                            options={monitorOptions}
                            getLabel={getInventoryLabel}
                        />
                    </FormControl>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div style={{
                            display: "flex",
                        }}>
                            <FormControlLabel className={classes.heightAdjustableCheckbox}
                                              control={<Checkbox name="checkedC" checked={isSeatHeightAdjustable}
                                                                 onChange={handleIsSeatHeightAdjustable}/>}
                                              label={t("height-adjustable-checkbox-label")}/>
                            <FormControlLabel className={classes.isBookableCheckbox}
                                              control={<Checkbox name="checkedC" checked={isSeatBookable}
                                                                 onChange={handleIsSeatBookable}/>}
                                              label={t("seat-is-bookable-checkbox-label")}/>
                        </div>
                        <div style={{justifyContent: "flex-end"}}>
                            <Tooltip title={t("confirm_dialog_ok_button-text")}>
                                <IconButton onClick={handleSaveButtonClick} data-testid={"save-btn"}>
                                    <SaveIcon style={{cursor: "pointer"}} fontSize="large"
                                              className={classes.btnEnabled}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("confirm_dialog_cancel_button-text")}>
                                <IconButton onClick={() => setShowSeatConfiguration(false)} data-testid={"cancel-btn"}>
                                    <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </Box>
            </Paper>
        </div>
    )
}
export default SeatConfigurationComponent