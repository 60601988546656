import {useEffect, useState} from "react";
import UserClient, {User} from "../services/UserClient";
import {emptyUser} from "../pages/MainPage";

interface UserProps {
    signOut: () => Promise<void>
}

export function useUser({signOut}: UserProps) {

    const [currentUser, setUser] = useState<User>(emptyUser);
    const [userLoading, setUserLoading] = useState<boolean>(true);

    useEffect(function fetchUserAndManageLoadingState() {

        updateUserCredentials().then(/*Ignored*/)

        async function updateUserCredentials() {
            try {
                const userResult = await UserClient.getCurrentUser()
                setUser(userResult)
                setUserLoading(false)
            } catch (error) {
                console.error("Error by updateUserCredentials", error)
                setUserLoading(false)
                await signOut()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return {currentUser, userLoading};
}