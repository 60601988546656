import React from "react";
import {Button} from "@material-ui/core";
import {toDateISO} from "../../services/DateUtils";
import {v4 as uuidv4} from "uuid";
import {gql, useMutation} from "@apollo/client";
import {
    createBookings as createBookingsTemplate,
    createMeetingRoomBookings as createMeetingRoomBookingsTemplate
} from "../../graphql/mutations";
import {BookingFor, MeetingRoomType, MeetingType} from "../../Utils/Enums";
import {BookingConfigDays} from "../../types/BookingConfigDaysTypes";
import {handleLimitOfBookings} from "./MultiBookingDialogHelper";
import {Room} from "../../API";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {useErrorContext} from "../../hooks/useErrorContext";
import {useTranslation} from "react-i18next";
import {BookingType} from "../../types/BookingType";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";

dayjs.extend(utc)

export interface MeetingRoomPropsProps {
    meetingName: string,
    meetingType: MeetingType,
    numberOfParticipants: number | '',
    maxParticipantNumber: number
    visitors: string
    meetRoomType: MeetingRoomType
}

export interface MultiBookingDialogBookingButtonProps {
    handleClose: () => void,
    bookingConfig: BookingConfigDays,
    bookerName: string,
    bookerGivenName: string,
    bookerFamilyName: string,
    seatId: string,
    room: Room,
    selectedDates: Date[],
    setSelectedDates: (date: Date[]) => void,
    isDateBookableForMe: (date: Date) => boolean,
    bookedDays: Date[],
    bookingForState: BookingFor,
    bookingForInputUsername: string,
    setShowBookingDidNotComplete: (showBookingDidNotComplete: boolean) => void,
    beginTime: string,
    endTime: string,
    setIsInvalidBookingTime: (isInvalidBookingTime: boolean) => void,
    isInvalidBookingTime: boolean,
    isInvalidBookingTimeEndTimeIsInPast: boolean,
    shouldDisabled: boolean,
    meetingRoomProps?: MeetingRoomPropsProps
    bookingType: BookingType
}

const MultiBookingDialogBookingButton: React.FC<MultiBookingDialogBookingButtonProps> = (props) => {

    const {
        handleClose,
        bookingConfig,
        bookerName,
        bookerGivenName,
        bookerFamilyName,
        seatId,
        room,
        selectedDates,
        setSelectedDates,
        isDateBookableForMe,
        bookedDays,
        bookingForState,
        bookingForInputUsername,
        setShowBookingDidNotComplete,
        beginTime,
        endTime,
        setIsInvalidBookingTime,
        isInvalidBookingTime,
        isInvalidBookingTimeEndTimeIsInPast,
        shouldDisabled,
        meetingRoomProps,
        bookingType
    } = props

    const [createBookingsMutation] = useMutation(gql(createBookingsTemplate))
    const [createMeetingRoomBookingsMutation] = useMutation(gql(createMeetingRoomBookingsTemplate))
    const {rerenderSeatConfigsTrigger, setRerenderSeatConfigsTrigger} = useMainApplicationContext();
    const {reportError} = useErrorContext();
    const {currentUser} = useMainApplicationContext();

    async function tryBookingAndClose() {
        let {allBookable, bookingCountDiff} = areAllBookable();

        if (allBookable) {
            bookingType === "seat" ? bookSeatForSelectedDates() : bookMeetingRoomForSelectedDates();
        } else if (!allBookable && room.isTimeActive) {
            setIsInvalidBookingTime(true)
        } else {
            setShowBookingDidNotComplete(true);
            let arr = [...selectedDates];
            arr = arr.filter(value => isDateBookableForMe(value))
            if (bookingCountDiff > 0) {
                arr = arr.slice(0, (-1) * bookingCountDiff);
            }
            setSelectedDates(arr);
        }
    }

    const {t} = useTranslation();

    function bookSeatForSelectedDates(): void {
        if (!selectedDates || selectedDates.length === 0) {
            return;
        }
        let selectedDatesWithoutMyBookings = [...selectedDates]
        let bookingInputs = room.isTimeActive ?
            selectedDatesWithoutMyBookings.map(value => getSeatBookingInputForDateWithTime(value))
            : selectedDatesWithoutMyBookings.map(value => getSeatBookingInputForDate(value))

        createBookingsMutation({
                variables: {
                    input: bookingInputs
                },

            }
        ).then(() => handleClose())
            .catch((err) => {
                let errorMessage;
                if (err.message === "Seat is currently not bookable") {
                    errorMessage = t("seat_not_bookable_error");
                    setRerenderSeatConfigsTrigger(!rerenderSeatConfigsTrigger);
                } else {
                    errorMessage = t("booking_error");
                }

                reportError(err, errorMessage, "MultiBookingDialogBookingButton bookSeatForSelectedDates");
            });
    }

    function bookMeetingRoomForSelectedDates(): void {
        if (!selectedDates || selectedDates.length === 0) {
            return;
        }
        let selectedDatesWithoutMyBookings = [...selectedDates]
        let bookingInputs = selectedDatesWithoutMyBookings.map(value => getMeetingRoomBookingInputForDateWithTime(value))

        createMeetingRoomBookingsMutation({
                variables: {
                    input: bookingInputs
                }
            }
        ).then(() => handleClose())
            .catch((err) => reportError(err, t("booking_error"), "MultiBookingDialogBookingButton bookMeetingRoomForSelectedDates"))
    }

    function areAllBookable(): { allBookable: boolean, bookingCountDiff: number } {
        let allBookable = true;
        let bookingCountDiff = 0;


        selectedDates.forEach((date) => {
            if (!isDateBookableForMe(date)) {
                allBookable = false;
            }
        })
        return isAllBookableAndBookingDiff(allBookable, bookingCountDiff)
    }

    function isAllBookableAndBookingDiff(allBookable: boolean, bookingCountDiff: number) {
        if (handleLimitOfBookings(false, bookedDays, selectedDates, bookingConfig, bookingType)) {
            let tempArr = [...bookedDays.map(value => toDateISO(value)), ...selectedDates.map(value => toDateISO(value))];
            const newBookingCount = tempArr.filter((value, index) => tempArr.indexOf(value) === index).length
            if (newBookingCount > bookingConfig.maxBookableDays) {
                allBookable = false;
                bookingCountDiff = newBookingCount - bookingConfig.maxBookableDays;
            }
        }
        return {allBookable, bookingCountDiff};
    }

    const getSeatBookingInputForDateWithTime = (d: Date) => {
        const bookerId = currentUser.ID;
        let bookingFor = ""
        if (bookingForState === BookingFor.BOOKING_FOR_OTHERS && bookingForInputUsername.trim() !== "") {
            bookingFor = bookingForInputUsername.trim()
        }
        let timeBegin = dayjs(beginTime, "HH:mm").date(d.getDate()).month(d.getMonth()).year(d.getFullYear()).utc();
        let timeEnd = dayjs(endTime, "HH:mm").date(d.getDate()).month(d.getMonth()).year(d.getFullYear()).utc();
        let bookingId = uuidv4()
        return {
            bookingId: bookingId,
            date: toDateISO(d),
            seatId: seatId,
            roomId: room.roomId,
            bookerId: bookerId,
            bookerName: bookerName,
            bookerGivenName: bookerGivenName,
            bookerFamilyName: bookerFamilyName,
            orgUnitId: room.orgUnitId!!,
            orgUnitAdmin: "Admin-" + room.orgUnitId!!,
            bookingFor: bookingFor,
            timeBegin: timeBegin.toISOString()!!,
            timeEnd: timeEnd.toISOString()!!,
        }
    }

    function getSeatBookingInputForDate(d: Date) {
        const bookerId = currentUser.ID;
        let bookingFor = ""
        if (bookingForState === BookingFor.BOOKING_FOR_OTHERS && bookingForInputUsername.trim() !== "") {
            bookingFor = bookingForInputUsername.trim()
        }
        let bookingId = uuidv4()
        return {
            bookingId: bookingId,
            date: toDateISO(d),
            seatId: seatId,
            roomId: room.roomId,
            bookerId: bookerId,
            bookerName: bookerName,
            bookerGivenName: bookerGivenName,
            bookerFamilyName: bookerFamilyName,
            orgUnitId: room.orgUnitId,
            orgUnitAdmin: "Admin-" + room.orgUnitId!!,
            bookingFor: bookingFor,
            timeBegin: dayjs(d).hour(0).minute(0).utc().toISOString(),
            timeEnd: dayjs(d).hour(23).minute(59).utc().toISOString()
        }
    }

    const getMeetingRoomBookingInputForDateWithTime = (d: Date) => {
        const bookerId = currentUser.ID;
        let timeBegin = dayjs(beginTime, "HH:mm").date(d.getDate()).month(d.getMonth()).year(d.getFullYear()).utc();
        let timeEnd = dayjs(endTime, "HH:mm").date(d.getDate()).month(d.getMonth()).year(d.getFullYear()).utc();
        let bookingId = uuidv4()
        return {
            bookingId: bookingId,
            date: toDateISO(d),
            meetingRoomId: seatId,
            roomId: room.roomId,
            bookerId: bookerId,
            bookerName: bookerName,
            bookerGivenName: bookerGivenName,
            bookerFamilyName: bookerFamilyName,
            orgUnitId: room.orgUnitId!!,
            timeBegin: timeBegin.toISOString()!!,
            timeEnd: timeEnd.toISOString()!!,
            meetingName: meetingRoomProps?.meetingName ?? "",
            roomCapacity: Number(meetingRoomProps?.maxParticipantNumber),
            participantNumber: meetingRoomProps?.numberOfParticipants ?? 0,
            meetingType: meetingRoomProps?.meetingType ?? "",
            visitors: meetingRoomProps?.visitors
        }
    }

    const isDisabled = (isInvalidBookingTime || isInvalidBookingTimeEndTimeIsInPast || selectedDates.length === 0 || (bookingForState === BookingFor.BOOKING_FOR_OTHERS && bookingForInputUsername.trim().length === 0)) || shouldDisabled;

    return (
        <Button
            data-testid={"booking-btn"}
            variant={"contained"}
            color={"primary"}
            onClick={() => tryBookingAndClose().then()}
            disabled={isDisabled}
        >
            {t("book")}
        </Button>)
}

export default MultiBookingDialogBookingButton