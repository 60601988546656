import {useMultiBookingLegendStyles} from "../../../styles/MultiBookingLegendStyles";
import {TableCell} from "@material-ui/core";

interface LegendTextCellProps {
    text: string
}

function LegendTextCell({text}: LegendTextCellProps) {
    const classes = useMultiBookingLegendStyles();
    return (
        <TableCell className={classes.legendLabel}>{text}</TableCell>);
}

export default LegendTextCell