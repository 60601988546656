import {gql, useQuery} from "@apollo/client";
import {Neighborhood} from "../API"
import {useEffect} from "react";
import {getNeighborhoodsByRoomId} from "../graphql/queries";
import {onCreateDeleteNeighborhoods} from "../graphql/subscriptions";

const EMPTY_ARRAY: Neighborhood[] = [];

export function useNeighborhoodList(roomId: string | undefined, checkPermissions = false): [Neighborhood[], () => void] {

    const {data, subscribeToMore, refetch} = useQuery(gql(getNeighborhoodsByRoomId), {
        variables: {
            roomId: roomId,
            checkPermissions: checkPermissions
        },
        skip: !roomId || roomId === ""
    });

    useEffect(() => {
        if (!roomId) return;

        const unsubscribeOnChange = subscribeToMore({
            document: gql(onCreateDeleteNeighborhoods),
            variables: {roomId},
            updateQuery: (prev) => {
                refetch().then();
                return prev;
            },
            onError: (error: any) => console.error("useNeighborhoodList useEffect:subscribeToMore:onCreateDeleteNeighborhoods " + JSON.stringify(error))
        });

        return () => {
            unsubscribeOnChange()
        };
    }, [roomId]);

    function comparator(a: Neighborhood, b: Neighborhood) {
        return a.neighborhoodId.localeCompare(b.neighborhoodId, 'en', {numeric: true});
    }

    return [data?.getNeighborhoodsByRoomId?.items.sort(comparator) ?? EMPTY_ARRAY, refetch];
}