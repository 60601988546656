import {Building, MeetingRoom, Room} from "../API";
import {useMemo} from "react";
import {gql, useQuery} from "@apollo/client";
import {getMeetingRooms, getRooms} from "../graphql/queries";

/* TODO check if this is really needed
   only length of buildingsWithMeetingRooms is used
 */

export function useBuildingsWithMeetingRooms(buildings: Building[]) {
    const {
        loading: loadingRooms,
        data: roomsData
    } = useQuery(gql(getRooms));

    const {
        loading: loadingMeetingRooms,
        data: meetingRoomsData,
    } = useQuery(gql(getMeetingRooms), {
        variables: {
            roomIds: (roomsData?.getRooms?.items ?? []).map((r: Room) => r.roomId)
        },
        skip: !roomsData
    });

    const meetingRooms: [MeetingRoom] = useMemo(() => {
        return meetingRoomsData?.getMeetingRooms?.items ?? [];
    }, [meetingRoomsData]);

    const buildingsWithMeetingRooms = useMemo(() => {
        if (loadingRooms || loadingMeetingRooms || !roomsData || !meetingRoomsData) {
            return [];
        }
        const meetingRoomRoomIds = new Set(meetingRooms.map(room => room.roomId));
        const rooms: [Room] = roomsData?.getRooms?.items ?? [];
        return buildings.filter(building =>
            rooms.some(room =>
                meetingRoomRoomIds.has(room.roomId) && building.buildingId === room.buildingId
            )
        );
    }, [loadingRooms, loadingMeetingRooms, roomsData, meetingRoomsData, meetingRooms, buildings]);

    return {meetingRooms, buildingsWithMeetingRooms};
}