export const createSecureUserSettings = /* GraphQL */ `
    mutation CreateUserSettings(
        $input: CreateUserSettingsInput!
        $condition: ModelUserSettingsConditionInput
    ) {
        createUserSettings(input: $input, condition: $condition) {
            userId
            consent
            createdAt
            updatedAt
            userSettingsMailConfigId
            userSettingsDefaultRoomConfigId
        }
    }
`;

export const updateSecureUserSettings = /* GraphQL */ `
    mutation UpdateUserSettings(
        $input: UpdateUserSettingsInput!
        $condition: ModelUserSettingsConditionInput
    ) {
        updateUserSettings(input: $input, condition: $condition) {
            userId
            consent
            createdAt
            updatedAt
            userSettingsMailConfigId
            userSettingsDefaultRoomConfigId
        }
    }
`;