import React, {useState} from "react";
import {DialogContent, Tab, Tabs} from "@mui/material";
import {OwnSeatBookingsTabComponent} from "./OwnSeatBookingsTabComponent";
import {Button, Dialog, DialogActions, DialogTitle, makeStyles} from "@material-ui/core";
import {OwnMeetingBookingsTabComponent} from "./OwnMeetingBookingsTabComponent";
import {useTranslation} from "react-i18next";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";

export enum SelectedTab {
    SEAT_BOOKING_TAB,
    MEETING_ROOM_BOOKING_TAB
}

export interface OwnBookingsProps {
    showOwnBookingsManagerComponent: boolean
    setShowOwnBookingsManagerComponent: (value: boolean) => void
}

const OwnBookingsManagerComponent: React.FC<OwnBookingsProps> = (props) => {
    const {
        showOwnBookingsManagerComponent,
        setShowOwnBookingsManagerComponent,
    } = props
    const [tabIndex, setTabIndex] = useState<SelectedTab>(SelectedTab.SEAT_BOOKING_TAB);
    const [numberOfSelected, setNumberOfSelected] = useState(0);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const {t} = useTranslation();

    const useStyles = makeStyles({
        dialogPaper: {
            height: '60vh',
            overflowY: "auto"
        },
    })

    const {isMobile, isNoFullscreen, shouldAlignButtons} = useDeviceMediaType();

    const classes = useStyles()

    function handleCloseBookingManagerDialog(): void {
        setTabIndex(SelectedTab.SEAT_BOOKING_TAB);
        setShowOwnBookingsManagerComponent(false);
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: SelectedTab) => {
        setNumberOfSelected(0);
        setTabIndex(newValue);
    };

    return (
        <Dialog classes={{paper: classes.dialogPaper}}
                open={showOwnBookingsManagerComponent}
                fullWidth={true}
                maxWidth={"lg"}
                data-testid={"own-bookings-dialog-test-id"}
                onClose={handleCloseBookingManagerDialog}>
            <DialogTitle>
                {t("own_bookings_management_dialog_title")}
            </DialogTitle>
            <DialogContent>
                <Tabs value={tabIndex} onChange={handleChangeTab} variant={isMobile ? "scrollable" : "fullWidth"}
                      scrollButtons={isMobile}
                      allowScrollButtonsMobile={isMobile}>
                    <Tab label={t("seats")} value={SelectedTab.SEAT_BOOKING_TAB}></Tab>
                    <Tab label={t("meeting_rooms")} value={SelectedTab.MEETING_ROOM_BOOKING_TAB}></Tab>
                </Tabs>
                {tabIndex === SelectedTab.SEAT_BOOKING_TAB &&
                    <OwnSeatBookingsTabComponent
                        setNumberOfSelections={setNumberOfSelected}
                        showDeleteDialog={showDeleteDialog}
                        setShowDeleteDialog={setShowDeleteDialog}
                    />}
                {tabIndex === SelectedTab.MEETING_ROOM_BOOKING_TAB &&
                    <OwnMeetingBookingsTabComponent
                        showEditDialog={showEditDialog}
                        setShowEditDialog={setShowEditDialog}
                        showDeleteDialog={showDeleteDialog}
                        setShowDeleteDialog={setShowDeleteDialog}
                        setNumberOfSelections={setNumberOfSelected}
                    />}

            </DialogContent>

            <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
                <Button
                    disabled={numberOfSelected === 0}
                    size={shouldAlignButtons ? "small" : "medium"}
                    color={"primary"}
                    variant={"contained"}
                    data-testid={"btn-delete"}
                    onClick={() => setShowDeleteDialog(true)}
                >
                    {t("delete")}
                </Button>

                <Button
                    disabled={tabIndex === SelectedTab.SEAT_BOOKING_TAB || numberOfSelected !== 1}
                    size={shouldAlignButtons ? "small" : "medium"}
                    color={"primary"}
                    variant={"contained"}
                    data-testid={"btn-edit"}
                    onClick={() => setShowEditDialog(true)}
                >
                    {t("edit")}
                </Button>

                <Button
                    onClick={handleCloseBookingManagerDialog}
                    size={shouldAlignButtons ? "small" : "medium"}
                    color={"primary"}
                    variant={"contained"}
                    data-testid={"btn-close"}
                >
                    {t("confirm_dialog_cancel_button-text")}
                </Button>


            </DialogActions>
        </Dialog>

    );

}
export default OwnBookingsManagerComponent

