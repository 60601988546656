import React from "react";
import MultiSelectComboBox from "../MultiselectCombobox";
import {t} from "i18next";
import Box from "@mui/material/Box";
import {Role} from "../../types/RoleType";
import {useRoleList} from "../../hooks/useRoleList";


interface RoleSelectionProps {
    roleIds: string[],
    setRoleIds: (roles: string[]) => void,
    label: string,
}

export const RoleSelectionComponent = (props: RoleSelectionProps) => {
    const {roleIds, setRoleIds, label} = props;

    const {roles : allRoles, loading} = useRoleList()

    const handleRolesChange = (selectionRoles: string[]) => {
        setRoleIds(roleNameToRoleId(selectionRoles))
    };

    const roleNameToRoleId = (roleNames : string[]) => (
        allRoles.filter(role =>
            roleNames.includes(role.roleName)).map(role => role.roleId)
    )
    const roleIdToRoleName = (roleIds : string[]) => (
        allRoles.filter(role =>
            roleIds.includes(role.roleId)).map(role => role.roleName)
    )

    return (loading ? <div/> :
        <>
            <Box marginBottom={2} data-testid={"roles-selector"}>
                <MultiSelectComboBox
                    options={allRoles.map((role: Role) => role.roleName)}
                    label={t(label)}
                    selectedValues={roleIdToRoleName(roleIds)}
                    onChange={selectedValues => handleRolesChange(selectedValues)}
                    useDefaultStyles={false}
                />
            </Box>
        </>
    );
};
