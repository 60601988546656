import React, {useMemo} from "react";
import Sit2GetherLogo from "../logos/Sit2GetherLogo.png"
import {User} from "../services/UserClient";
import {MenuNav} from "@2gether/frontend-library";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useBuildingsWithMeetingRooms} from "../hooks/useBuildingsWithMeetingRooms";
import {Building, Neighborhood, Room} from "../API";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";
import {getBuildingsWithOrgunits} from "../Utils/Helpers";
import {usePermissionHelper} from "../hooks/usePermissionHelper";
import {NotificationElementProps} from "@2gether/frontend-library/build/NotificationElement";

export interface HeaderProps {
    setShowOwnBookingsManager: (value: boolean) => void,
    setShowRoomManager: (value: boolean) => void,
    setShowMeetingRoomBookingManager: (value: boolean) => void,
    setShowDockingstationManager: (value: boolean) => void,
    setShowMonitorManager: (value: boolean) => void,
    setShowMeetingRoomEquipmentManager: (value: boolean) => void,
    setShowBookingLimitationManager: (value: boolean) => void,
    setShowNotificationDialog: (value: boolean) => void,
    setShowManageBuildings: (value: boolean) => void,
    setShowSupportDialog: (value: boolean) => void,
    setShowInfoTextDialog: (value: boolean) => void,
    setShowInfoTextAdminDialog: (value: boolean) => void,
    currentUser: User,
    isLoading: boolean,
    signOut: () => Promise<void>,
    buildings: Building[],
    rooms: Room[],
    neighborhoods: Neighborhood[],
    infoText: string,
    isNotificationImportant: boolean,
}

const HeaderComponent: React.FC<HeaderProps> = (props) => {
    const {
        setShowOwnBookingsManager,
        setShowRoomManager,
        setShowMeetingRoomBookingManager,
        setShowDockingstationManager,
        setShowMeetingRoomEquipmentManager,
        setShowMonitorManager,
        setShowBookingLimitationManager,
        setShowNotificationDialog,
        setShowManageBuildings,
        setShowSupportDialog,
        setShowInfoTextDialog,
        setShowInfoTextAdminDialog,
        currentUser,
        isLoading,
        signOut,
        buildings,
        rooms,
        neighborhoods,
        infoText,
        isNotificationImportant
    } = props
    const {isMobile} = useDeviceMediaType()
    const {buildingsWithMeetingRooms} = useBuildingsWithMeetingRooms(buildings);
    const {t} = useTranslation();
    const localisation = i18n.language.substring(0, 2)
    const permissionHelper = usePermissionHelper({
        currentUser: currentUser,
        isLoading: isLoading,
        rooms: rooms,
        buildingList: buildings,
        neighborhoods: neighborhoods
    })

    const hasRolesForBuilding = useMemo(() => {
        return buildings.some(building => building.roleIds?.some(role => currentUser.admin2GetherRoles.includes(role)))
    }, [buildings, currentUser]);

    const managementItems = [
        {
            name: t("room_management_dialog-title"),
            show: currentUser ? (currentUser.isAdmin
                || getBuildingsWithOrgunits(buildings, currentUser, rooms).length > 0
                || hasRolesForBuilding
                || permissionHelper.hasAnyNeighborhoodManagement()
            ) : false,
            onClick: () => setShowRoomManager(true)
        },
        {
            name: t("button_manage_buildings-title"),
            show: currentUser ? (currentUser.isAdmin) : false,
            onClick: () => setShowManageBuildings(true)
        },
        {
            name: t("docking_mngmnt_dialog-title"),
            show: currentUser ? (currentUser.isAdmin || currentUser.isOrgUnitAdmin) : false,
            onClick: () => setShowDockingstationManager(true)
        },
        {
            name: t("monitor_mngmnt_dialog-title"),
            show: currentUser ? (currentUser.isAdmin || currentUser.isOrgUnitAdmin) : false,
            onClick: () => setShowMonitorManager(true)
        },
        {
            name: t("equipment_mngmnt_dialog-title"),
            show: currentUser ? (currentUser.isAdmin || permissionHelper.hasAnyBuildingManagement()) : false,
            onClick: () => setShowMeetingRoomEquipmentManager(true)
        },
        {
            name: t("booking_limitation_dialog_title"),
            show: currentUser ? (currentUser.isAdmin || currentUser.isOrgUnitAdmin) : false,
            onClick: () => setShowBookingLimitationManager(true)
        },
        {
            name: t("user-info-text-menu-item"),
            show: currentUser ? currentUser.isAdmin : false,
            onClick: () => setShowInfoTextAdminDialog(true)
        }
    ]
    const items = [
        {
            name: t("my_bookings_mngmt_dialog-title"),
            show: true,
            onClick: () => setShowOwnBookingsManager(true)
        },
        {
            name: t("meeting_room_booking_manager_dialog-title"),
            show: buildingsWithMeetingRooms.length > 0,
            onClick: () => setShowMeetingRoomBookingManager(true)
        },
        ...isMobile ? [] : managementItems,
        {
            name: t("button_my_settings-title"),
            show: true,
            onClick: () => setShowNotificationDialog(true)
        },
        {
            name: t("support_dialog-title"),
            show: true,
            onClick: () => setShowSupportDialog(true)
        },
    ]

    const notificationProps: NotificationElementProps = {
        isImportant: isNotificationImportant,
        setShowInfoNotification: setShowInfoTextDialog,
        importantStyle: {color: "#fc7674", padding: "10px", paddingTop: "12px"},
        normalStyle: {padding: "10px"}
    }

    return (
        <MenuNav
            key={currentUser + "-" + Math.random()}
            username={currentUser ? currentUser.name : ""}
            imageSource={Sit2GetherLogo}
            headerSize={25}
            items={isMobile ? items : []}
            userItems={isMobile ? [] : items}
            nestedItems={[]}
            onLogoClick={() => true}
            signout={signOut}
            language={localisation}
            notificationMobileTopDisplay={true}
            notificationProps={infoText ? notificationProps : undefined}
        />
    )
}
export default HeaderComponent