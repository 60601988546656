import HeaderComponent, {HeaderProps} from "../components/HeaderComponent";
import React, {useCallback, useMemo, useState} from "react";
import Auth from "@aws-amplify/auth";
import {User} from "../services/UserClient";
import {Building, Inventory, Room} from "../API";
import OwnBookingsManagerComponent, {
    OwnBookingsProps
} from "../components/OwnBookingsComponents/OwnBookingsManagerComponent";
import {IOrgUnit} from "../hooks/useOrgunit";
import MainApplicationContext from "../context/MainApplicationContext";
import ErrorContext from "../context/ErrorContext";
import RoomManagerComponent, {RoomManagerProps} from "../components/RoomManager/RoomManagerComponent";
import InventoryManagerComponent, {InventoryProps} from "../components/InventoryManagerComponent";
import {InvType} from "../Utils/Enums";
import BookingLimitationComponent, {BookingLimitProps} from "../components/BookingLimitationComponent";
import SeatBookingComponent, {SeatBookingProps} from "../components/SeatBookingComponent";
import MySettingsComponent, {SettingsProps} from "../components/MySettingsComponent";
import WarningSnackbar, {WarningProps} from "../components/WarningSnackbarComponent";
import ErrorBoundary from "../components/ErrorBoundary";
import BuildingManagerComponent, {BuildingProps} from "../components/BuildingManager/BuildingManagerComponent";
import {useTranslation} from "react-i18next";
import "../styles/NotMaternaEmployeeError.css";
import CircularProgress from '@mui/material/CircularProgress';
import MeetingRoomBookingManagerComponent, {
    MeetingRoomBookingManagerProps
} from "../components/MeetingRoomBookingManager/MeetingRoomBookingManagerComponent";
import {mainApplicationContextTypes} from "../types/mainApplicationContextTypes";
import {useInitialise} from "../hooks/useInitialise";
import {getBuildingsWithOrgunits} from "../Utils/Helpers";
import {ConsentDialog, ConsentDialogProps} from "../components/ConsentDialog";
import {SupportDialog, SupportDialogProps} from "../components/SupportDialog";
import {InfoTextAdminDialog, InfoTextAdminDialogProps} from "../components/InfoTextAdminDialog";
import InfoDialog, {InfoDialogProps} from "../components/InfoDialog";
import {useInfoText} from "../hooks/useInfoText";
import MeetingRoomEquipmentManager, {
    MeetingRoomEquipmentManagerProps
} from "../components/MeetingRoomEquipmentManager/MeetingRoomEquipmentManager";

export const emptyUser: User = {
    jwt: "",
    ID: "id",
    name: "username",
    email: "",
    givenName: "",
    familyName: "",
    orgUnits: [],
    adminOrgUnits: [],
    isAdmin: false,
    isOrgUnitAdmin: false,
    isMaternaEmployee: false,
    admin2GetherRoles: []
};

interface ParamStoreType {
    header: HeaderProps,
    warning: WarningProps,
    roomMngr: RoomManagerProps,
    dockingyMngr: InventoryProps,
    inventoryMngr: InventoryProps,
    equipmentMngr: MeetingRoomEquipmentManagerProps,
    bookinglimit: BookingLimitProps,
    buidlingMngs: BuildingProps,
    seatbooking: SeatBookingProps,
    bookingMngr: OwnBookingsProps,
    meetBookingMngr: MeetingRoomBookingManagerProps,
    settings: SettingsProps,
    support: SupportDialogProps,
    consent: ConsentDialogProps,
    infoTextAdmin: InfoTextAdminDialogProps
    infoText: InfoDialogProps
}

const MainPage: React.FC = () => {
    const [showOwnBookingsManager, setShowOwnBookingsManager] = useState(false)
    const [showNotificationDialog, setShowNotificationDialog] = useState(false)
    const [showRoomManager, setShowRoomManager] = useState(false)
    const [showDockingstationManager, setShowDockingstationManager] = useState(false)
    const [showManageBuildings, setShowManageBuildings] = useState(false)
    const [showMonitorManager, setShowMonitorManager] = useState(false)
    const [showBookingLimitation, setShowBookingLimitation] = useState(false)
    const [showMeetingRoomEquipmentManager, setShowMeetingRoomEquipmentManager] = useState<boolean>(false)
    const [selectedDate, setSelectedDate] = useState(normalizeDate(new Date()));
    const [selectedBuilding, setSelectedBuilding] = useState<Building | undefined>(undefined);
    const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);
    const [errors, setErrors] = useState<{ error: Error, customMessage: string }[]>([]);
    const [selectedOrgUnit, setSelectedOrgUnit] = useState<IOrgUnit | undefined>(undefined);
    const [rerenderSeatConfigsTrigger, setRerenderSeatConfigsTrigger] = useState<boolean>(false);
    const [showMeetingRoomBookingManager, setShowMeetingRoomBookingManager] = useState(false)
    const [shouldRefetchUserSettings, setShouldRefetchUserSettings] = useState<boolean>(false)
    const [idOfDefaultBuilding, setIdOfDefaultBuilding] = useState<string | null>(null);
    const [showSupportDialog, setShowSupportDialog] = useState(false);
    const [showInfoTextAdminDialog, setShowInfoTextAdminDialog] = useState(false);
    const [showInfoTextDialog, setShowInfoTextDialog] = useState(false);
    const signOut = useCallback(async () => {
        window.location.href = process.env.REACT_APP_LANDING_PAGE!;
        await Auth.signOut();
    }, [])

    const {infoText} = useInfoText()
    const {t} = useTranslation();

    const {
        currentUser,
        rooms,
        neighborhoods,
        orgUnitList,
        adminOrgUnitList,
        buildingList,
        inventories,
        userSettings,
        isLoading,
        meetingRoomService,
        buildingEquipmentService
    } = useInitialise({shouldRefetchUserSettings, signOut})

    const managedBuildings = useMemo(() => {
        if (currentUser.isAdmin) return buildingList;
        return getBuildingsWithOrgunits(buildingList, currentUser, rooms).concat(buildingList.filter(building =>
            building.roleIds.some(role => currentUser.admin2GetherRoles.includes(role))));
    }, [buildingList, currentUser]);


    function handleChangeSelectedRoom(room: Room | undefined) {
        setSelectedRoom(room)
    }

    function normalizeDate(date: Date): Date {
        return new Date(date.setHours(0, 0, 0, 0));
    }

    const onSelectedDateChange = useCallback((newlySelectedDate: Date): void => {
        setSelectedDate(normalizeDate(newlySelectedDate));
    }, [])

    const clearErrors = useCallback(() => {
        setErrors([]);
    }, [setErrors]);

    const errorContextValue = useMemo(() => {
        function reportError(error: Error, message?: string, source?: string) {
            console.error(source, error);
            setErrors([...errors, {error: error, customMessage: message ?? ""}])
        }

        return {reportError}
    }, [errors])

    const providerValues: mainApplicationContextTypes = useMemo((): mainApplicationContextTypes => {
        const userSettingsObject = {
            userSettings: userSettings,
            setShouldRefetchUserSettings: setShouldRefetchUserSettings
        }
        return {
            orgUnitList,
            buildingList,
            neighborhoods,
            managedBuildings,
            adminOrgUnitList,
            meetingRoomService,
            buildingEquipmentService,
            selectedOrgUnit,
            setSelectedOrgUnit,
            currentUser,
            rooms,
            userSettingsObject,
            idOfDefaultBuilding,
            setIdOfDefaultBuilding,
            rerenderSeatConfigsTrigger,
            setRerenderSeatConfigsTrigger,
            isLoading
        }
    }, [idOfDefaultBuilding, userSettings, orgUnitList, buildingList, managedBuildings, neighborhoods, adminOrgUnitList, selectedOrgUnit, currentUser, rooms, rerenderSeatConfigsTrigger]);


    //parameter store
    const ps: ParamStoreType = {
        header: {
            setShowOwnBookingsManager: setShowOwnBookingsManager,
            setShowRoomManager: setShowRoomManager,
            setShowMeetingRoomBookingManager: setShowMeetingRoomBookingManager,
            setShowDockingstationManager: setShowDockingstationManager,
            setShowMonitorManager: setShowMonitorManager,
            setShowBookingLimitationManager: setShowBookingLimitation,
            setShowNotificationDialog: setShowNotificationDialog,
            setShowManageBuildings: setShowManageBuildings,
            setShowSupportDialog: setShowSupportDialog,
            setShowInfoTextAdminDialog: setShowInfoTextAdminDialog,
            setShowInfoTextDialog: setShowInfoTextDialog,
            setShowMeetingRoomEquipmentManager,
            currentUser: currentUser,
            isLoading: isLoading,
            signOut: signOut,
            buildings: buildingList,
            rooms: rooms,
            neighborhoods: neighborhoods,
            infoText: infoText ? infoText.infoText : "",
            isNotificationImportant: infoText ? infoText.important : false,
        },
        warning: {
            errors: errors,
            backgroundColor: 'red',
            horizontalAlignment: 'left',
            verticalAlignment: 'bottom',
            clearErrors: clearErrors,
        },
        roomMngr: {
            showRoomManager: showRoomManager,
            setShowRoomManager: setShowRoomManager,
        },
        dockingyMngr: {
            showInventoryManager: showDockingstationManager,
            setShowInventoryManager: setShowDockingstationManager,
            inventories: inventories.filter((item: Inventory) => item.type === InvType.Dockingstation),
            type: InvType.Dockingstation,
        },
        inventoryMngr: {
            showInventoryManager: showMonitorManager,
            setShowInventoryManager: setShowMonitorManager,
            inventories: inventories.filter((item: Inventory) => item.type === InvType.Monitor),
            type: InvType.Monitor,
        },
        equipmentMngr: {
            showMeetingRoomEquipmentManager: showMeetingRoomEquipmentManager,
            setShowMeetingRoomEquipmentManager: setShowMeetingRoomEquipmentManager
        },
        bookinglimit: {
            showBookingLimitations: showBookingLimitation,
            setShowBookingLimitations: setShowBookingLimitation,
        },
        buidlingMngs: {
            showBuildingManager: showManageBuildings,
            setShowBuildingManager: setShowManageBuildings,
            buildings: buildingList,
            currentUser: currentUser,
            orgUnitList: orgUnitList,
        },
        seatbooking: {
            rooms: rooms.filter(room => room.roomPlanExisting),
            currentUser: currentUser,
            selectedDate: selectedDate,
            onSelectedDateChange: onSelectedDateChange,
            selectedBuilding: selectedBuilding,
            setSelectedBuilding: setSelectedBuilding,
            selectedRoom: selectedRoom,
            onSelectedRoomChange: handleChangeSelectedRoom,
        },
        bookingMngr: {
            showOwnBookingsManagerComponent: showOwnBookingsManager,
            setShowOwnBookingsManagerComponent: setShowOwnBookingsManager,
        },
        meetBookingMngr: {
            showMeetingRoomBookingManagerComponent: showMeetingRoomBookingManager,
            setShowMeetingRoomBookingManagerComponent: setShowMeetingRoomBookingManager,
        },
        settings: {
            showMySettingsDialog: showNotificationDialog,
            setShowMySettingsDialog: setShowNotificationDialog,
        },
        support: {
            showSupportDialog: showSupportDialog,
            setShowSupportDialog: setShowSupportDialog,
        },
        consent: {
            initialOpen: userSettings?.consent === null || userSettings?.consent === undefined,
        },
        infoTextAdmin: {
            showInfoTextDialog: showInfoTextAdminDialog,
            setShowInfoTextDialog: setShowInfoTextAdminDialog,
            infoText: infoText ? infoText.infoText : "",
            isImportant: infoText ? infoText.important : false,
        },
        infoText: {
            title: t("user-info-text-header"),
            content: infoText ? infoText.infoText : "",
            buttonText: t("close-button-text"),
            open: showInfoTextDialog,
            onClose: () => setShowInfoTextDialog(false),
        }
    }
    const loadedStateProps = {
        providerValues: providerValues, ps: ps, isMaternaEmployee: currentUser.isMaternaEmployee,
    }

    return (
        <ErrorContext.Provider value={errorContextValue}>
            <WarningSnackbar {...ps.warning}/>
            <HeaderComponent{...ps.header}/>
            {isLoading ? <CircularProgressBar/> : <LoadedState {...loadedStateProps}/>}
        </ErrorContext.Provider>
    );
}
export default MainPage

function CircularProgressBar() {
    return (
        <div style={{margin: "auto", paddingTop: "50px"}}>
            <CircularProgress data-testid={"loading-circle-test-id"}/>
        </div>
    )
}

function NotMaternaEmployeeWarning() {
    const {t} = useTranslation()
    return (<div className="not-materna-employee-error">{t('not_materna_employee')}</div>);
}

interface ApplicationProps {
    providerValues: mainApplicationContextTypes,
    ps: ParamStoreType
}

function LoadedState(props: ApplicationProps & { isMaternaEmployee: boolean }) {
    const {providerValues, ps, isMaternaEmployee} = props
    return <>
        {
            isMaternaEmployee ?
                <ApplicationBody providerValues={providerValues} ps={ps}/>
                :
                <NotMaternaEmployeeWarning/>
        }
    </>
}

function ApplicationBody(props: ApplicationProps) {
    const {providerValues, ps} = props
    return (<div style={{display: "flex", flexGrow: 1, overflow: "hidden"}}>
        <MainApplicationContext.Provider value={providerValues}>
            <RoomManagerComponent {...ps.roomMngr}/>
            <ErrorBoundary><InventoryManagerComponent {...ps.dockingyMngr}/></ErrorBoundary>
            <ErrorBoundary><InventoryManagerComponent {...ps.inventoryMngr}/></ErrorBoundary>
            <ErrorBoundary><MeetingRoomEquipmentManager {...ps.equipmentMngr}/></ErrorBoundary>
            <ErrorBoundary><BookingLimitationComponent {...ps.bookinglimit}/></ErrorBoundary>
            <ErrorBoundary><BuildingManagerComponent {...ps.buidlingMngs}/></ErrorBoundary>
            <ErrorBoundary><SeatBookingComponent {...ps.seatbooking}/></ErrorBoundary>
            <ErrorBoundary><OwnBookingsManagerComponent{...ps.bookingMngr}/></ErrorBoundary>
            <ErrorBoundary><MeetingRoomBookingManagerComponent{...ps.meetBookingMngr}/></ErrorBoundary>
            <ErrorBoundary><MySettingsComponent {...ps.settings}/></ErrorBoundary>
            <ErrorBoundary><ConsentDialog {...ps.consent}/></ErrorBoundary>
            <ErrorBoundary><SupportDialog {...ps.support}/></ErrorBoundary>
            <ErrorBoundary><InfoTextAdminDialog {...ps.infoTextAdmin}/></ErrorBoundary>
            <ErrorBoundary><InfoDialog {...ps.infoText}/></ErrorBoundary>
        </MainApplicationContext.Provider>
    </div>)
}

