import React, {FC, useMemo} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {MeetingRoomBookingListItem} from "../../types/MeetingRoomBookingListItemType";
import {getMeetingTypeLabel, MeetingRoomType} from "../../Utils/Enums";
import {meetingRoomIconFromType, meetingRoomTypeFromString} from "../../Utils/MeetingRoomBookingManagerComponentHelper";
import {EN_DASH} from "../../Utils/commons";
import {OwnedBy} from "../../types/PermissionHandling";
import {Neighborhood} from "../../API";

const DIGIT_TO_TRIM_TIME = "2-digit";
const DELIMITER_IN_TABLE: string = EN_DASH;

interface Props {
    bookingListItems: MeetingRoomBookingListItem[]
    selectedBooking: MeetingRoomBookingListItem | null
    setSelectedBooking: (booking: MeetingRoomBookingListItem | null) => void
    idOfHighlightedBooking: string | null,
    neighborhoods: Neighborhood[]
}

const ownBookingTextColor = "blue";
const rowHighlightedColor = "#c1c1c1";
const rowSelectedAndHighlightedColor = "#aaa";

const sxTableRowOtherBookingHighlighted = {
    "& td": {
        background: `${rowHighlightedColor}`
    },
    "&.Mui-selected td": {
        background: `${rowSelectedAndHighlightedColor} !important`
    }
};

const sxTableRowOwnBookingHighlighted = {
    "& td": {
        background: `${rowHighlightedColor}`,
        color: ownBookingTextColor
    },
    "&.Mui-selected td": {
        background: `${rowSelectedAndHighlightedColor} !important`
    }
};

const sxTableRowOwnBooking = {
    "& td": {
        color: ownBookingTextColor
    },
};

const MeetingRoomBookingManagerListComponent: FC<Props> = (props) => {
    const {
        bookingListItems,
        selectedBooking,
        idOfHighlightedBooking,
        setSelectedBooking,
        neighborhoods
    } = props

    const sortedListItems = useMemo(() => (
        bookingListItems.sort(compareListItems)), [bookingListItems])

    function getNeighborhoodDisplayNameById(id: string | null | undefined, roomId: string | null | undefined) {
        return neighborhoods.find(n => n.neighborhoodId === id && n.roomId === roomId)?.name ?? id ?? DELIMITER_IN_TABLE;
    }
    
    function compareListItems(a: MeetingRoomBookingListItem, b: MeetingRoomBookingListItem) {
        return compareDates(a.timeBegin, b.timeBegin)
            || compareDates(a.timeEnd, b.timeEnd)
            || compareStrings(a.meetingRoomType, b.meetingRoomType)
            || compareStrings(a.neighborhoodId, b.neighborhoodId)
            || compareNumbers(a.level, b.level)
            || compareStrings(a.meetingRoomName, b.meetingRoomName)
            || compareMeetingTypes(a.meetingType, b.meetingType);
    }

    function compareDates(dateA?: string | null, dateB?: string | null) {
        return dateToNumber(dateA) - dateToNumber(dateB)
    }

    function dateToNumber(dateString?: string | null) {
        const date = new Date(dateString ?? "");
        return isNaN(date.getTime()) ? 0 : date.getTime();
    }

    function compareStrings(strA?: string | null, strB?: string | null) {
        if (!strA || !strB) return 0;
        return strA.localeCompare(strB, "en");
    }

    function compareMeetingTypes(meetingTypeA: string | null | undefined, meetingTypeB: string | null | undefined) {
        const order: { [key: string]: number } = {"INTERNAL": 1, "EXTERNAL": 2, "VIP": 3};
        if (!meetingTypeA && !meetingTypeB) {
            return 0;
        }
        if (!meetingTypeA) {
            return 1;
        }
        if (!meetingTypeB) {
            return -1;
        }
        const orderValueA: number = order[meetingTypeA] || Number.MAX_VALUE;
        const orderValueB = order[meetingTypeB] || Number.MAX_VALUE;
        return orderValueA - orderValueB;
    }

    function compareNumbers(numA?: number | null, numB?: number | null) {
        return (numA ?? 0) - (numB ?? 0);
    }

    function roomNameAndIcon(item: MeetingRoomBookingListItem) {
        const meetingRoomType = meetingRoomTypeFromString(item.meetingRoomType ?? "");
        const icon = meetingRoomIconFromType(meetingRoomType);
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <span>{icon}</span>
                <span style={{marginLeft: '4px'}}>{item.meetingRoomName}</span>
            </div>
        );
    }


    const {t} = useTranslation();

    const getTableRowStyle = (item: MeetingRoomBookingListItem) => {
        if (item.bookingId === idOfHighlightedBooking && item.ownedBy === OwnedBy.USER) {
            return sxTableRowOwnBookingHighlighted;
        } else if (item.bookingId === idOfHighlightedBooking) {
            return sxTableRowOtherBookingHighlighted;
        } else if (item.ownedBy === OwnedBy.USER) {
            return sxTableRowOwnBooking;
        }
        return {};
    }

    const showVisitorsColumn = sortedListItems.some(item => item.meetingRoomType === MeetingRoomType.VISITORS_CENTER);

    return <>
        <TableContainer style={{marginTop: '15px'}} data-testid={"meeting-room-booking-list"}>
            <Table stickyHeader style={{maxWidth: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t("creator-of-booking")}
                        </TableCell>
                        <TableCell>
                            {t("meeting-room-name")}
                        </TableCell>
                        <TableCell>
                            {t("neighborhood-name")}
                        </TableCell>
                        <TableCell>
                            {t("room-level")}
                        </TableCell>
                        <TableCell>
                            {t("start-time")}
                        </TableCell>
                        <TableCell>
                            {t("end-time")}
                        </TableCell>
                        <TableCell>
                            {t("meeting-name")}
                        </TableCell>
                        <TableCell>
                            {t("number-of-guests")}
                        </TableCell>
                        {showVisitorsColumn ?
                            <TableCell>
                                {t("general_visitors")}
                            </TableCell> : null
                        }
                        <TableCell>
                            {t("booking-type")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedListItems.map((item: MeetingRoomBookingListItem, index) => (
                        <TableRow selected={item.bookingId === selectedBooking?.bookingId}
                                  sx={getTableRowStyle(item)}
                                  key={item.bookingId}
                                  onClick={() => setSelectedBooking(item)}
                        >
                            <TableCell>
                                {item.bookerName}
                            </TableCell>
                            <TableCell>
                                {roomNameAndIcon(item)}
                            </TableCell>
                            <TableCell>
                                {getNeighborhoodDisplayNameById(item.neighborhoodId, item.roomId)}
                            </TableCell>
                            <TableCell>
                                {item.level ?? DELIMITER_IN_TABLE}
                            </TableCell>
                            <TableCell>
                                {timestampToHourMinuteString(item.timeBegin)}
                            </TableCell>
                            <TableCell>
                                {timestampToHourMinuteString(item.timeEnd)}
                            </TableCell>
                            <TableCell>
                                {item.meetingName ?? DELIMITER_IN_TABLE}
                            </TableCell>
                            <TableCell>
                                {item.participantNumber}
                            </TableCell>
                            {showVisitorsColumn ?
                                <TableCell style={{wordWrap: "break-word", maxWidth: "100px"}}>
                                    {item.visitors ?? ""}
                                </TableCell> : null
                            }
                            <TableCell>
                                {item.meetingType ? t(getMeetingTypeLabel(item.meetingType)) : DELIMITER_IN_TABLE}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export function timestampToHourMinuteString(dateString: string | null | undefined): string {
    const date = new Date(dateString ?? "");
    const locale = navigator.language;
    const use12HourFormat = locale.startsWith("en");
    if (isNaN(date.getTime())) {
        return DELIMITER_IN_TABLE;
    }

    return date.toLocaleTimeString(undefined, {
        hour: DIGIT_TO_TRIM_TIME,
        minute: DIGIT_TO_TRIM_TIME,
        hour12: use12HourFormat
    });
}

export default MeetingRoomBookingManagerListComponent;