/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishRoomAccessChange = /* GraphQL */ `
  mutation PublishRoomAccessChange($roomId: ID!) {
    publishRoomAccessChange(roomId: $roomId)
  }
`;
export const createBookings = /* GraphQL */ `
  mutation CreateBookings($input: [CreateBookingInput]) {
    createBookings(input: $input) {
      response
    }
  }
`;
export const updateSecureSeatBooking = /* GraphQL */ `
  mutation UpdateSecureSeatBooking($input: UpdateSeatBookingInput) {
    updateSecureSeatBooking(input: $input) {
      response
    }
  }
`;
export const deleteSecureSeatBooking = /* GraphQL */ `
  mutation DeleteSecureSeatBooking($input: DeleteBookingInput) {
    deleteSecureSeatBooking(input: $input) {
      response
    }
  }
`;
export const deleteSecureSeatBookingsByUserId = /* GraphQL */ `
  mutation DeleteSecureSeatBookingsByUserId($input: DeleteBookingsByUserInput) {
    deleteSecureSeatBookingsByUserId(input: $input) {
      response
    }
  }
`;
export const createSecureBuilding = /* GraphQL */ `
  mutation CreateSecureBuilding($input: CreateBuildingInput) {
    createSecureBuilding(input: $input) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureBuilding = /* GraphQL */ `
  mutation UpdateSecureBuilding($input: UpdateBuildingInput) {
    updateSecureBuilding(input: $input) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureBuilding = /* GraphQL */ `
  mutation DeleteSecureBuilding($input: DeleteBuildingInput) {
    deleteSecureBuilding(input: $input) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const createMeetingRooms = /* GraphQL */ `
  mutation CreateMeetingRooms($input: [CreateMeetingRoomInput]) {
    createMeetingRooms(input: $input) {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureMeetingRoom = /* GraphQL */ `
  mutation UpdateSecureMeetingRoom($input: UpdateMeetingRoomInput) {
    updateSecureMeetingRoom(input: $input) {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureMeetingRoom = /* GraphQL */ `
  mutation DeleteSecureMeetingRoom($input: DeleteMeetingRoomInput) {
    deleteSecureMeetingRoom(input: $input) {
      response
    }
  }
`;
export const deleteAllMeetingRoomBookings = /* GraphQL */ `
  mutation DeleteAllMeetingRoomBookings($meetingRoomId: ID!) {
    deleteAllMeetingRoomBookings(meetingRoomId: $meetingRoomId)
  }
`;
export const createSecureRoom = /* GraphQL */ `
  mutation CreateSecureRoom($input: CreateRoomInput) {
    createSecureRoom(input: $input) {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureRoom = /* GraphQL */ `
  mutation UpdateSecureRoom($input: UpdateRoomInput, $updateType: String) {
    updateSecureRoom(input: $input, updateType: $updateType) {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureRoom = /* GraphQL */ `
  mutation DeleteSecureRoom($input: DeleteRoomInput) {
    deleteSecureRoom(input: $input) {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
  }
`;
export const createSecureSeatConfig = /* GraphQL */ `
  mutation CreateSecureSeatConfig($input: CreateSeatConfig) {
    createSecureSeatConfig(input: $input) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureSeatConfig = /* GraphQL */ `
  mutation DeleteSecureSeatConfig($input: DeleteSeatConfig) {
    deleteSecureSeatConfig(input: $input) {
      response
    }
  }
`;
export const createMeetingRoomBookings = /* GraphQL */ `
  mutation CreateMeetingRoomBookings($input: [CreateMeetingRoomBookingInput]) {
    createMeetingRoomBookings(input: $input) {
      response
    }
  }
`;
export const updateSecureMeetingRoomBooking = /* GraphQL */ `
  mutation UpdateSecureMeetingRoomBooking(
    $input: UpdateMeetingRoomBookingInput
  ) {
    updateSecureMeetingRoomBooking(input: $input) {
      response
    }
  }
`;
export const deleteSecureMeetingRoomBooking = /* GraphQL */ `
  mutation DeleteSecureMeetingRoomBooking(
    $input: DeleteMeetingRoomBookingInput
  ) {
    deleteSecureMeetingRoomBooking(input: $input) {
      response
    }
  }
`;
export const updateSeatConfigs = /* GraphQL */ `
  mutation UpdateSeatConfigs($input: UpdateSeatConfigsInput) {
    updateSeatConfigs(input: $input) {
      message
      cause
    }
  }
`;
export const deleteBookingsBySeatIds = /* GraphQL */ `
  mutation DeleteBookingsBySeatIds($input: DeleteBookingsBySeatIdsInput) {
    deleteBookingsBySeatIds(input: $input)
  }
`;
export const updateRestrictedNeighborhoods = /* GraphQL */ `
  mutation UpdateRestrictedNeighborhoods(
    $input: UpdateRestrictedNeighborhoodsInput
  ) {
    updateRestrictedNeighborhoods(input: $input) {
      statusCode
      message
    }
  }
`;
export const createSecureInventory = /* GraphQL */ `
  mutation CreateSecureInventory($input: InventoryInput) {
    createSecureInventory(input: $input) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureInventory = /* GraphQL */ `
  mutation DeleteSecureInventory($input: DeleteInventoryInput) {
    deleteSecureInventory(input: $input) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureInventory = /* GraphQL */ `
  mutation UpdateSecureInventory($input: InventoryInput) {
    updateSecureInventory(input: $input) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const createSecureNeighborhoods = /* GraphQL */ `
  mutation CreateSecureNeighborhoods($input: CreateNeighborhoodsInput) {
    createSecureNeighborhoods(input: $input) {
      response
    }
  }
`;
export const deleteSecureNeighborhoods = /* GraphQL */ `
  mutation DeleteSecureNeighborhoods($input: DeleteNeighborhoodsInput) {
    deleteSecureNeighborhoods(input: $input) {
      response
    }
  }
`;
export const createSecureMeetingRoomEquipment = /* GraphQL */ `
  mutation CreateSecureMeetingRoomEquipment(
    $input: CreateMeetingRoomEquipmentInput
  ) {
    createSecureMeetingRoomEquipment(input: $input) {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureMeetingRoomEquipment = /* GraphQL */ `
  mutation UpdateSecureMeetingRoomEquipment(
    $input: UpdateMeetingRoomEquipmentInput
  ) {
    updateSecureMeetingRoomEquipment(input: $input) {
      response
    }
  }
`;
export const deleteSecureMeetingRoomEquipment = /* GraphQL */ `
  mutation DeleteSecureMeetingRoomEquipment(
    $input: DeleteMeetingRoomEquipmentInput
  ) {
    deleteSecureMeetingRoomEquipment(input: $input) {
      response
    }
  }
`;
export const createBuilding = /* GraphQL */ `
  mutation CreateBuilding(
    $input: CreateBuildingInput!
    $condition: ModelBuildingConditionInput
  ) {
    createBuilding(input: $input, condition: $condition) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const updateBuilding = /* GraphQL */ `
  mutation UpdateBuilding(
    $input: UpdateBuildingInput!
    $condition: ModelBuildingConditionInput
  ) {
    updateBuilding(input: $input, condition: $condition) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const deleteBuilding = /* GraphQL */ `
  mutation DeleteBuilding(
    $input: DeleteBuildingInput!
    $condition: ModelBuildingConditionInput
  ) {
    deleteBuilding(input: $input, condition: $condition) {
      buildingId
      buildingName
      rooms {
        nextToken
      }
      roleIds
      buildingNameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      roomId
      buildingId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      floor
      hasMeetingRooms
      createdAt
      updatedAt
    }
  }
`;
export const createMeetingRoom = /* GraphQL */ `
  mutation CreateMeetingRoom(
    $input: CreateMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    createMeetingRoom(input: $input, condition: $condition) {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const updateMeetingRoom = /* GraphQL */ `
  mutation UpdateMeetingRoom(
    $input: UpdateMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    updateMeetingRoom(input: $input, condition: $condition) {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeetingRoom = /* GraphQL */ `
  mutation DeleteMeetingRoom(
    $input: DeleteMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    deleteMeetingRoom(input: $input, condition: $condition) {
      meetingRoomId
      roomId
      name
      nameLowerCased
      hintId
      type
      capacity
      roleIds
      neighborhoodId
      equipmentIds
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const createNeighborhood = /* GraphQL */ `
  mutation CreateNeighborhood(
    $input: CreateNeighborhoodInput!
    $condition: ModelNeighborhoodConditionInput
  ) {
    createNeighborhood(input: $input, condition: $condition) {
      neighborhoodId
      roomId
      restricted
      roleIds
      name
      adminRoleIds
      createdAt
      updatedAt
    }
  }
`;
export const updateNeighborhood = /* GraphQL */ `
  mutation UpdateNeighborhood(
    $input: UpdateNeighborhoodInput!
    $condition: ModelNeighborhoodConditionInput
  ) {
    updateNeighborhood(input: $input, condition: $condition) {
      neighborhoodId
      roomId
      restricted
      roleIds
      name
      adminRoleIds
      createdAt
      updatedAt
    }
  }
`;
export const deleteNeighborhood = /* GraphQL */ `
  mutation DeleteNeighborhood(
    $input: DeleteNeighborhoodInput!
    $condition: ModelNeighborhoodConditionInput
  ) {
    deleteNeighborhood(input: $input, condition: $condition) {
      neighborhoodId
      roomId
      restricted
      roleIds
      name
      adminRoleIds
      createdAt
      updatedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        buildingId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        floor
        hasMeetingRooms
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        buildingId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        floor
        hasMeetingRooms
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        buildingId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        floor
        hasMeetingRooms
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      createdAt
      updatedAt
    }
  }
`;
export const createMeetingRoomBooking = /* GraphQL */ `
  mutation CreateMeetingRoomBooking(
    $input: CreateMeetingRoomBookingInput!
    $condition: ModelMeetingRoomBookingConditionInput
  ) {
    createMeetingRoomBooking(input: $input, condition: $condition) {
      bookingId
      date
      meetingRoomId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      visitors
      createdAt
      updatedAt
    }
  }
`;
export const updateMeetingRoomBooking = /* GraphQL */ `
  mutation UpdateMeetingRoomBooking(
    $input: UpdateMeetingRoomBookingInput!
    $condition: ModelMeetingRoomBookingConditionInput
  ) {
    updateMeetingRoomBooking(input: $input, condition: $condition) {
      bookingId
      date
      meetingRoomId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      visitors
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeetingRoomBooking = /* GraphQL */ `
  mutation DeleteMeetingRoomBooking(
    $input: DeleteMeetingRoomBookingInput!
    $condition: ModelMeetingRoomBookingConditionInput
  ) {
    deleteMeetingRoomBooking(input: $input, condition: $condition) {
      bookingId
      date
      meetingRoomId
      roomId
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      timeBegin
      timeEnd
      meetingName
      roomCapacity
      participantNumber
      meetingType
      visitors
      createdAt
      updatedAt
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory(
    $input: CreateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    createInventory(input: $input, condition: $condition) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory(
    $input: UpdateInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    updateInventory(input: $input, condition: $condition) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory(
    $input: DeleteInventoryInput!
    $condition: ModelInventoryConditionInput
  ) {
    deleteInventory(input: $input, condition: $condition) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const createMeetingRoomEquipment = /* GraphQL */ `
  mutation CreateMeetingRoomEquipment(
    $input: CreateMeetingRoomEquipmentInput!
    $condition: ModelMeetingRoomEquipmentConditionInput
  ) {
    createMeetingRoomEquipment(input: $input, condition: $condition) {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const updateMeetingRoomEquipment = /* GraphQL */ `
  mutation UpdateMeetingRoomEquipment(
    $input: UpdateMeetingRoomEquipmentInput!
    $condition: ModelMeetingRoomEquipmentConditionInput
  ) {
    updateMeetingRoomEquipment(input: $input, condition: $condition) {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeetingRoomEquipment = /* GraphQL */ `
  mutation DeleteMeetingRoomEquipment(
    $input: DeleteMeetingRoomEquipmentInput!
    $condition: ModelMeetingRoomEquipmentConditionInput
  ) {
    deleteMeetingRoomEquipment(input: $input, condition: $condition) {
      equipmentId
      buildingId
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const createSeatConfig = /* GraphQL */ `
  mutation CreateSeatConfig(
    $input: CreateSeatConfigInput!
    $condition: ModelSeatConfigConditionInput
  ) {
    createSeatConfig(input: $input, condition: $condition) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const updateSeatConfig = /* GraphQL */ `
  mutation UpdateSeatConfig(
    $input: UpdateSeatConfigInput!
    $condition: ModelSeatConfigConditionInput
  ) {
    updateSeatConfig(input: $input, condition: $condition) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const deleteSeatConfig = /* GraphQL */ `
  mutation DeleteSeatConfig(
    $input: DeleteSeatConfigInput!
    $condition: ModelSeatConfigConditionInput
  ) {
    deleteSeatConfig(input: $input, condition: $condition) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      neighborhoodId
      isBookable
      createdAt
      updatedAt
    }
  }
`;
export const createBookingConfig = /* GraphQL */ `
  mutation CreateBookingConfig(
    $input: CreateBookingConfigInput!
    $condition: ModelBookingConfigConditionInput
  ) {
    createBookingConfig(input: $input, condition: $condition) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const updateBookingConfig = /* GraphQL */ `
  mutation UpdateBookingConfig(
    $input: UpdateBookingConfigInput!
    $condition: ModelBookingConfigConditionInput
  ) {
    updateBookingConfig(input: $input, condition: $condition) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookingConfig = /* GraphQL */ `
  mutation DeleteBookingConfig(
    $input: DeleteBookingConfigInput!
    $condition: ModelBookingConfigConditionInput
  ) {
    deleteBookingConfig(input: $input, condition: $condition) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const createMailConfig = /* GraphQL */ `
  mutation CreateMailConfig(
    $input: CreateMailConfigInput!
    $condition: ModelMailConfigConditionInput
  ) {
    createMailConfig(input: $input, condition: $condition) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
  }
`;
export const updateMailConfig = /* GraphQL */ `
  mutation UpdateMailConfig(
    $input: UpdateMailConfigInput!
    $condition: ModelMailConfigConditionInput
  ) {
    updateMailConfig(input: $input, condition: $condition) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
  }
`;
export const deleteMailConfig = /* GraphQL */ `
  mutation DeleteMailConfig(
    $input: DeleteMailConfigInput!
    $condition: ModelMailConfigConditionInput
  ) {
    deleteMailConfig(input: $input, condition: $condition) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      isReceiveMeetingRoomBooking
      createdAt
      updatedAt
    }
  }
`;
export const createDefaultRoomConfig = /* GraphQL */ `
  mutation CreateDefaultRoomConfig(
    $input: CreateDefaultRoomConfigInput!
    $condition: ModelDefaultRoomConfigConditionInput
  ) {
    createDefaultRoomConfig(input: $input, condition: $condition) {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
  }
`;
export const updateDefaultRoomConfig = /* GraphQL */ `
  mutation UpdateDefaultRoomConfig(
    $input: UpdateDefaultRoomConfigInput!
    $condition: ModelDefaultRoomConfigConditionInput
  ) {
    updateDefaultRoomConfig(input: $input, condition: $condition) {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
  }
`;
export const deleteDefaultRoomConfig = /* GraphQL */ `
  mutation DeleteDefaultRoomConfig(
    $input: DeleteDefaultRoomConfigInput!
    $condition: ModelDefaultRoomConfigConditionInput
  ) {
    deleteDefaultRoomConfig(input: $input, condition: $condition) {
      userId
      roomId
      roomName
      neighborhoodId
      createdAt
      updatedAt
    }
  }
`;
export const createUserSettings = /* GraphQL */ `
  mutation CreateUserSettings(
    $input: CreateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    createUserSettings(input: $input, condition: $condition) {
      userId
      mailConfig {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        isReceiveMeetingRoomBooking
        createdAt
        updatedAt
      }
      defaultRoomConfig {
        userId
        roomId
        roomName
        neighborhoodId
        createdAt
        updatedAt
      }
      consent
      createdAt
      updatedAt
      userSettingsMailConfigId
      userSettingsDefaultRoomConfigId
    }
  }
`;
export const updateUserSettings = /* GraphQL */ `
  mutation UpdateUserSettings(
    $input: UpdateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    updateUserSettings(input: $input, condition: $condition) {
      userId
      mailConfig {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        isReceiveMeetingRoomBooking
        createdAt
        updatedAt
      }
      defaultRoomConfig {
        userId
        roomId
        roomName
        neighborhoodId
        createdAt
        updatedAt
      }
      consent
      createdAt
      updatedAt
      userSettingsMailConfigId
      userSettingsDefaultRoomConfigId
    }
  }
`;
export const deleteUserSettings = /* GraphQL */ `
  mutation DeleteUserSettings(
    $input: DeleteUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    deleteUserSettings(input: $input, condition: $condition) {
      userId
      mailConfig {
        userId
        userMail
        isReceiveOwnBooking
        isReceiveForeignBooking
        isReceiveMeetingRoomBooking
        createdAt
        updatedAt
      }
      defaultRoomConfig {
        userId
        roomId
        roomName
        neighborhoodId
        createdAt
        updatedAt
      }
      consent
      createdAt
      updatedAt
      userSettingsMailConfigId
      userSettingsDefaultRoomConfigId
    }
  }
`;
export const createInfoText = /* GraphQL */ `
  mutation CreateInfoText(
    $input: CreateInfoTextInput!
    $condition: ModelInfoTextConditionInput
  ) {
    createInfoText(input: $input, condition: $condition) {
      id
      infoText
      important
      createdAt
      updatedAt
    }
  }
`;
export const updateInfoText = /* GraphQL */ `
  mutation UpdateInfoText(
    $input: UpdateInfoTextInput!
    $condition: ModelInfoTextConditionInput
  ) {
    updateInfoText(input: $input, condition: $condition) {
      id
      infoText
      important
      createdAt
      updatedAt
    }
  }
`;
export const deleteInfoText = /* GraphQL */ `
  mutation DeleteInfoText(
    $input: DeleteInfoTextInput!
    $condition: ModelInfoTextConditionInput
  ) {
    deleteInfoText(input: $input, condition: $condition) {
      id
      infoText
      important
      createdAt
      updatedAt
    }
  }
`;
export const createHint = /* GraphQL */ `
  mutation CreateHint(
    $input: CreateHintInput!
    $condition: ModelHintConditionInput
  ) {
    createHint(input: $input, condition: $condition) {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateHint = /* GraphQL */ `
  mutation UpdateHint(
    $input: UpdateHintInput!
    $condition: ModelHintConditionInput
  ) {
    updateHint(input: $input, condition: $condition) {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteHint = /* GraphQL */ `
  mutation DeleteHint(
    $input: DeleteHintInput!
    $condition: ModelHintConditionInput
  ) {
    deleteHint(input: $input, condition: $condition) {
      hintId
      roomId
      buildingId
      text
      createdAt
      updatedAt
    }
  }
`;
