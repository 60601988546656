import React, {Dispatch, SetStateAction} from 'react'
import {SeatInterface} from "../SeatComponent";

interface Coordinates {
    x: number,
    y: number
}

interface Props {
    seatInterface: SeatInterface,
    elementPosition: Coordinates,
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

export default function DockingStation(props: Props) {
    const onMouseEnter = () => {
        props.setMouseOver(true)
    }
    const onMouseLeave = () => {
        props.setMouseOver(false)
    }

    return (
        <g
            className="dockingStation"
            transform={`translate(${props.elementPosition.x}, ${props.elementPosition.y}) scale (0.25)`}
            fill={"rgba(255,255,255,0)"} stroke="#000" strokeWidth="1.5" fillRule="nonzero"
            onMouseOver={onMouseEnter} onMouseOut={onMouseLeave}
            data-testid={"DockingStation-" + props.seatInterface.seatID}
        >
            <rect fill="transparent" strokeWidth="0" width="21" height="35" x="93" y="63"/>
            <path
                d="M98.912 63.428a3.727 3.727 0 0 0-.6.221c-.33.172-.887.715-1.094 1.073-.408.686-.394.221-.394 13.656v12.375l-.729.036c-.508.029-.858.086-1.13.193a2.87 2.87 0 0 0-1.623 1.68c-.185.522-.185 2.138 0 2.66.258.75.873 1.387 1.623 1.687.365.15.537.165 2.145.186 1.602.022 1.773.007 1.995-.114.272-.143.508-.522.508-.8s-.236-.659-.508-.802c-.214-.114-.429-.128-1.88-.128h-1.63l-.558-.558v-1.601l.558-.558h15.328l.558.558v1.601l-.558.558h-4.797c-3.918 0-4.847.021-5.04.1-.78.322-.723 1.451.085 1.687.179.057 1.81.072 5.127.057 4.46-.021 4.89-.028 5.183-.15a2.993 2.993 0 0 0 1.694-1.694c.18-.465.18-2.252 0-2.717-.214-.544-.607-1.051-1.065-1.359-.543-.364-.786-.436-1.68-.479l-.736-.043V78.45c0-8.172-.029-12.462-.079-12.762a3.514 3.514 0 0 0-.257-.844c-.25-.507-.894-1.1-1.43-1.322l-.415-.165-4.111-.014c-3.389-.014-4.175 0-4.49.086zm8.422 1.844c.093.05.25.2.336.33l.165.242v24.93h-9.152v-24.93l.165-.243c.085-.128.243-.279.336-.329.257-.128 7.893-.128 8.15 0z"/>
            <path
                d="M105.533 66.223c-.394.208-.415.35-.415 2.424v1.895l.236.257c.314.343.75.407 1.122.157.415-.286.43-.336.43-2.366 0-2.024-.015-2.117-.43-2.367-.25-.143-.686-.143-.943 0zM105.54 72.165c-.45.278-.422-.308-.422 8.78 0 9.08-.029 8.557.429 8.793.443.229 1 .079 1.244-.321.107-.186.114-.944.114-8.509v-8.307l-.279-.28c-.235-.242-.321-.278-.586-.278-.171 0-.4.057-.5.122zM109.7 93.192c-.27.142-.507.521-.507.8 0 .444.486.93.93.93.1 0 .293-.057.421-.129.272-.143.508-.522.508-.8 0-.444-.486-.93-.93-.93-.1 0-.293.057-.421.129z"/>
        </g>
    )

}