import React from "react";
import {Neighborhood} from "../../API";
import {useTranslation} from "react-i18next";
import {generateSimpleSingleSelectOptionsWithNoneOption} from "../../Utils/GenericSelectorUtil";
import GenericSelectorWithNoneComponent from "./GenericSelectorWithNoneComponent";
import {reformatNeighborhoodId} from "../../Utils/Helpers";

interface Props {
    neighborhoods: Neighborhood[],
    selectedNeighborhoodId: string | undefined,
    selectedRoomId: string | undefined,
    onNeighborhoodSelect: (neighborhoodId: string | undefined) => void
}

const NeighborhoodSelectorDropdown: React.FC<Props> = ({
                                                           neighborhoods,
                                                           selectedNeighborhoodId,
                                                           selectedRoomId,
                                                           onNeighborhoodSelect
                                                       }) => {
    const {t} = useTranslation();
    const neighborhoodsForRoom = neighborhoods.filter(neighborhood => neighborhood.roomId === selectedRoomId);

    function displayName(neighborhoodId: string) {
        let neighborhood = neighborhoodsForRoom.find(n => n.neighborhoodId === neighborhoodId);
        return neighborhood?.name ?? reformatNeighborhoodId(neighborhoodId);
    }

    function getIndex(selectedNeighborhoodId: string | undefined) {
        if (!selectedNeighborhoodId) {
            return 0;
        }
        const index = neighborhoodsForRoom.map(n => n.neighborhoodId).indexOf(selectedNeighborhoodId);
        return index === -1 ? 0 : (index + 1);
    }

    if (neighborhoods.length === 0) {
        return <></>
    }

    return (
        <GenericSelectorWithNoneComponent
            disabled={false}
            labelText={t("general_neighborhood-singular")}
            style={{width: "100%", textAlign: "left"}}
            dataTestId="neighborhood-select"
            id="neighboorhood-select-native-id"
            setSelected={onNeighborhoodSelect}
            selectedIndex={getIndex(selectedNeighborhoodId)}
            chooseFrom={generateSimpleSingleSelectOptionsWithNoneOption(neighborhoodsForRoom.map(n => n.neighborhoodId), displayName, t("general_select_option_none"))}
            isOutlined={true}
        />);
}

export default NeighborhoodSelectorDropdown;