import {gql, useQuery} from "@apollo/client";
import {Hint} from "../API"
import {hintsByRoom} from "../graphql/queries";
import {useEffect} from "react";
import {onChangeHintsByRoom} from "../graphql/subscriptions";
import {useMainApplicationContext} from "./useMainApplicationContext";
import {HINT_TYPE} from "../Utils/AuthorizationLambdaConstants";

export function useHintList(roomId: string, buildingId: string): Hint[] | [] {
    const {currentUser} = useMainApplicationContext();
    const headers = {
        'x-type': HINT_TYPE,
        'x-method': "get"
    }

    const {data, refetch, subscribeToMore} = useQuery(gql(hintsByRoom), {
        variables: {
            roomId: roomId,
            buildingId: buildingId,
            limit: 999
        },
        context: {headers: {authorization: "Auth " + currentUser.jwt}}
    });

    useEffect(function subscribe() {
        const unsubscribeOnChange = subscribeToMore({
            document: gql(onChangeHintsByRoom),
            variables: {
                roomId: roomId
            },
            updateQuery: (prev) => {
                refetch().then();
                return prev;
            },
            onError: (error: any) => console.error("useHintList useEffect:subscribeToMore:onChangeHintsByRoom " + JSON.stringify(error))
        });


        return () => {
            unsubscribeOnChange()
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId]);

    return data?.hintsByRoom?.items ?? [];
}