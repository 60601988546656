import {makeStyles} from "@material-ui/core";

export const GeneralInputStyles = {
    paperStyle: {
        marginTop: "0.625rem",
        padding: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    textFieldBoxStyle: {
        paddingTop: "1rem",
        display: "flex",
        justifyContent: "flex-start",
        width: "74%"
    },
    toolTipsBoxStyle: {
        paddingTop: "1rem",
        display: "flex",
        justifyContent: "flex-end",
        width: "25%"
    }
}

export const BtnButtonInputStyle = makeStyles({
    btnEnabled: {
        color: "green"
    },
    btnDisabled: {
        color: "disabled"
    },
});
