import {Button} from "@material-ui/core";
import React from "react";

interface Props {
    handleBookOtherDateTime: () => void,
    text: string
}

export function ExecuteOtherBookingsButton({handleBookOtherDateTime, text}: Props) {
    return <Button className={"SeatAlreadyBookedHintButton"}
                   onClick={() => handleBookOtherDateTime()}
                   data-testid={"already-booked-book-other-days"}
                   color={"primary"}
                   variant={"contained"}
                   size="small"
    >
        {text}
    </Button>
}