import React from "react";
import Tooltip from "@mui/material/Tooltip";
import {
    StyledDivRoomNameIconContainer,
    StyledDivRoomNameInner,
    StyledDivRoomNameOuter
} from "../../../styles/MeetingRoomBookingMatrixStyles";
import {MeetingRoomDataWithCapacity} from "../MeetingRoomBookingMatrixComponent";
import MeetingRoomTooltipContent from "./MeetingRoomTooltipContent";
import {useDeviceMediaType} from "../../../hooks/useDeviceMediaType";
import {MeetingRoomEquipment} from "../../../API";

export type RoomNameProps = {
    data: MeetingRoomDataWithCapacity,
    typeIcon: JSX.Element,
    buildingEquipment: Map<string, MeetingRoomEquipment>,
}

function MeetingRoomBookingMatrixRoomNameComponent(props: RoomNameProps) {
    const {data, typeIcon, buildingEquipment} = props;

    const {isMobile} = useDeviceMediaType();

    const tooltipContent = <MeetingRoomTooltipContent buildingEquipment={buildingEquipment} {...data}/>;
    return (
        <Tooltip title={tooltipContent} arrow followCursor
                 data-testid={"meeting-room-booking-matrix-room-name-test-id"}>
            <StyledDivRoomNameOuter data-testid={"meeting-room-booking-matrix-room-name-outer-test-id"}>
                <StyledDivRoomNameIconContainer style={{display: isMobile ? "none" : "inline-flex"}}>
                    {typeIcon}
                </StyledDivRoomNameIconContainer>
                <StyledDivRoomNameInner data-testid={"meeting-room-booking-matrix-room-name-inner-test-id"}>
                    <span style={{color: data.managingAllowed ? '#c30a17' : ''}}>{data.name}</span>
                </StyledDivRoomNameInner>
            </StyledDivRoomNameOuter>
        </Tooltip>
    );
}

export default MeetingRoomBookingMatrixRoomNameComponent