import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import {MeetingRoom, MeetingRoomEquipment} from "../API";
import MultiSelectComboBox from "./MultiselectCombobox";
import {useTranslation} from "react-i18next";
import {getMeetingRoomTypeLabel, MeetingRoomType} from "../Utils/Enums";
import {useRoleList} from "../hooks/useRoleList";
import MultiSelectorDuplicities from "./MultiSelectorDuplicities";

interface ConfigureMeetingRoomProps {
    open: boolean;
    onClose: () => void;
    onSave: (meetingRoom: MeetingRoom) => void;
    selectedMeetingRoom: MeetingRoom | null;
    meetingRooms: MeetingRoom[];
    meetingRoomEquipment: MeetingRoomEquipment[];
    buildingEquipment: MeetingRoomEquipment[];
}

const EditMeetingRoom: React.FC<ConfigureMeetingRoomProps> = (props: ConfigureMeetingRoomProps
) => {
    const {
        open,
        onClose,
        onSave,
        selectedMeetingRoom,
        meetingRooms,
        meetingRoomEquipment,
        buildingEquipment
    } = props
    const [name, setName] = useState<string>('');
    const [capacity, setCapacity] = useState<number | string>('');
    const [type, setType] = useState<string>(MeetingRoomType.INTERNAL);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isCapacityValid, setIsCapacityValid] = useState(true);
    const [isBookable, setIsBookable] = useState(selectedMeetingRoom?.isBookable)
    const {roles: availableRoles} = useRoleList();
    const {t} = useTranslation();
    const [filteredEquipment, setFilteredEquipment] = useState<MeetingRoomEquipment[]>([]);


    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    };

    const handleCapacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValueNumber = parseInt(e.target.value);

        if (!isNaN(newValueNumber)) setCapacity(newValueNumber)
        else setCapacity('')
    };

    const handleNameBlur = () => {
        validateName();
    };

    const handleCapacityBlur = () => {
        validateCapacity();
    };

    const handleTypeChange = (e: React.ChangeEvent<{
        value: unknown
    }>) => {
        setType(e.target.value as string);
    };

    const handleRolesChange = (selectedRoles: string[]) => {
        setSelectedRoles(selectedRoles);
    };

    const handleIsBookableChange = () => {
        setIsBookable(prev => !prev);
    }


    const handleSave = () => {
        if (selectedMeetingRoom !== null) {
            const updatedMeetingRoom: MeetingRoom = {
                ...selectedMeetingRoom,
                type,
                roleIds: selectedRoles,
                isBookable: isBookable!,
                equipmentIds: filteredEquipment.map(equipment => equipment.equipmentId) ?? []
            };
            updatedMeetingRoom.roleIds = selectedRoles.map(role => {
                return availableRoles.find(roleObject => roleObject.roleName === role)?.roleId;
            }).filter((roleName): roleName is string => Boolean(roleName)) ?? [];

            if (typeof capacity === 'number') {
                updatedMeetingRoom.capacity = capacity;
            } else {
                updatedMeetingRoom.capacity = undefined;
            }

            const trimmedName = name.trim();
            if (selectedMeetingRoom.name !== trimmedName) {
                updatedMeetingRoom.name = trimmedName;
            }

            onSave(updatedMeetingRoom);
        }
        onClose();
    };


    const validateName = useCallback(() => {
        if (selectedMeetingRoom) {
            const trimmedName = name.trim();
            setIsNameValid(trimmedName !== '' && /^\S.{0,59}$/.test(trimmedName) && !meetingRooms
                .filter(meetingRoom => meetingRoom.name !== selectedMeetingRoom.name
                    && meetingRoom.roomId === selectedMeetingRoom.roomId)
                .some(meetingRoom => meetingRoom.nameLowerCased === trimmedName.toLowerCase()));
        }
    }, [meetingRooms, name, selectedMeetingRoom])

    const validateCapacity = useCallback(() => {
        let isCapacityValid = false;
        if (typeof capacity === 'number') {
            isCapacityValid = capacity >= 0 && capacity <= 200;
        }

        setIsCapacityValid(isCapacityValid);
    }, [capacity])

    useEffect(function populateSelectedMeetingRoomInformation() {
        if (selectedMeetingRoom) {
            setName(selectedMeetingRoom.name ?? '');
            setCapacity(selectedMeetingRoom.capacity ?? '');
            setType(selectedMeetingRoom.type ?? MeetingRoomType.INTERNAL);
            setIsBookable(selectedMeetingRoom.isBookable);
            const equipmentInMeetingRoom = selectedMeetingRoom.equipmentIds?.filter(id => {
                return buildingEquipment.some(el => el.equipmentId === id);
            }).map(id => buildingEquipment.find(el => el.equipmentId === id)!) ?? []
            setFilteredEquipment(equipmentInMeetingRoom)
            const roleNames = getRoleNamesOfSelectedMeetingRoom(selectedMeetingRoom);
            setSelectedRoles(roleNames);
        }

        function getRoleNamesOfSelectedMeetingRoom(selectedMeetingRoom: MeetingRoom) {
            return selectedMeetingRoom.roleIds?.map(getRoleNameById)
                .filter((roleName): roleName is string => Boolean(roleName)) ?? [];

            function getRoleNameById(id: string) {
                return availableRoles.find(roleObject => roleObject.roleId === id)?.roleName;
            }
        }

    }, [availableRoles, selectedMeetingRoom]);

    useEffect(function validateMeetingRoomName() {
        validateName();
    }, [name, validateName]);

    useEffect(function validateMeetingRoomCapacity() {
        validateCapacity()
    }, [capacity, validateCapacity]);

    return (
        <Dialog open={open} onClose={onClose} data-testid="edit-meeting-room">
            <DialogTitle>{t("configure_meeting_room")}</DialogTitle>
            <DialogContent>
                <Box marginBottom={2}>
                    <TextField
                        label={t("meeting_room_table_id_column")}
                        disabled={true}
                        value={selectedMeetingRoom ? selectedMeetingRoom.meetingRoomId : ""}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        error={!isNameValid}
                        helperText={!isNameValid && t("meeting_room_name_tooltip")}
                        type="text"
                        fullWidth
                        data-testid="edit-meeting-room-id"/>
                </Box>
                <Box marginBottom={2}>
                    <TextField
                        label={t("meeting_room_table_name_column")}
                        value={name}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        error={!isNameValid}
                        helperText={!isNameValid && t("meeting_room_name_tooltip")}
                        type="text"
                        fullWidth
                        data-testid="edit-meeting-room-name"/>
                </Box>
                <Box marginBottom={2}>
                    <TextField
                        label={t("meeting_room_capacity")}
                        value={capacity}
                        onChange={handleCapacityChange}
                        onBlur={handleCapacityBlur}
                        error={!isCapacityValid}
                        helperText={!isCapacityValid && t("meeting_room_capacity_tooltip")}
                        fullWidth
                        data-testid="edit-meeting-room-capacity"/>
                </Box>
                <Box marginBottom={2}>
                    <InputLabel>{t("meeting_room_manage_type")}</InputLabel>
                    <Select
                        MenuProps={{
                            disablePortal: true,
                        }}
                        value={type}
                        onChange={handleTypeChange}
                        onClick={(e) => e.stopPropagation()}
                        data-testid={"edit-meeting-room-type-selector"}
                        fullWidth
                    >
                        {Object.values(MeetingRoomType).map((roomType) => (
                            <MenuItem key={roomType} value={roomType}>
                                {t(getMeetingRoomTypeLabel(roomType))}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                {buildingEquipment?.length > 0 && <Box marginBottom={2}>
                    <FormControl style={{width: "100%"}}>
                        <MultiSelectorDuplicities
                            options={buildingEquipment}
                            title={t("general_equipment-singular")}
                            selected={filteredEquipment}
                            onChange={(selection) => setFilteredEquipment(selection)}
                            getLabel={(equipment: MeetingRoomEquipment) => equipment.name}/>
                    </FormControl>
                </Box>}
                <Box marginBottom={2} data-testid={"edit-meeting-room-roles-selector"}>
                    <MultiSelectComboBox
                        options={availableRoles.map((role: any) => role.roleName)}
                        label={t("meeting_room_manage_rights")}
                        selectedValues={selectedRoles}
                        onChange={selectedValues => handleRolesChange(selectedValues)}
                        useDefaultStyles={false}/>
                </Box>
                <Box marginBottom={2}>
                    <FormControlLabel style={{paddingLeft: "7px", height: "59px"}}
                                      control={<Checkbox name="checkedC"
                                                         checked={isBookable}
                                                         onChange={handleIsBookableChange}/>}
                                      label={t("meetingroom-is-bookable-checkbox-label")}/>
                </Box>
            </DialogContent><DialogActions>
            <Button
                onClick={handleSave}
                color="primary"
                variant={"contained"}
                disabled={!isNameValid || !isCapacityValid}
                data-testid={"edit-meeting-room-save-button"}>
                {t('button_save')}
            </Button>
            <Button onClick={onClose}
                    color="primary"
                    variant={"contained"}>
                {t('rm_showUploadConfirmationDialog_cancel_button-text')}
            </Button>
        </DialogActions>
        </Dialog>
    );
};

export default EditMeetingRoom;
