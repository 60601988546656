import SvgIcon from "@mui/material/SvgIcon";

function DockingIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <g transform="rotate(180 59.387 44.135)">
                <g transform="matrix(-4.4685e-4,0,0,4.4685e-4,112.96,81.813)" stroke="none" color="#424242">
                    <path
                        d="m-7718 14295c-85.687-23.638-197.97-65.004-248.2-91.597-135.92-70.913-366.39-295.47-452.07-443.21-168.42-283.65-162.51-91.596-162.51-5643.5v-5114.6l-301.38-14.774c-209.79-11.819-354.57-35.457-466.85-79.778-304.34-118.19-561.4-384.11-670.72-694.36-76.823-215.69-76.823-883.46 0-1099.2 106.37-310.25 360.48-573.22 670.72-697.31 150.69-62.049 221.6-67.959 886.42-76.823 661.86-8.8642 732.77-2.9547 824.37 47.276 112.28 59.094 209.79 215.69 209.79 330.93 0 115.23-97.506 271.83-209.79 330.93-88.642 47.276-177.28 53.185-777.09 53.185h-673.68l-230.47 230.47v661.86l230.47 230.47h6334.9l230.47-230.47v-661.86l-230.47-230.47h-1982.6c-1619.2 0-2003.3-8.8641-2083.1-41.366-322.06-132.96-298.43-599.81 35.457-697.31 73.868-23.638 747.54-29.547 2118.5-23.638 1843.7 8.8642 2021 11.819 2142.2 62.049 322.06 127.05 576.17 381.16 700.27 700.27 73.868 192.06 73.868 930.74 0 1122.8-88.642 224.56-251.15 434.34-440.25 561.4-224.56 150.69-325.02 180.24-694.36 197.97l-304.34 17.728v5085.1c0 3377.2-11.819 5150.1-32.502 5274.2-14.774 103.42-65.004 262.97-106.37 348.66-103.42 209.78-369.34 455.03-590.94 546.62l-171.37 67.959-1699 5.91c-1400.5 5.909-1725.6 0-1855.6-35.457zm3480.7-762.32c38.411-20.683 103.42-82.732 138.87-135.92l67.959-100.46v-10303h-3782v10303l67.959 100.46c35.457 53.185 100.46 115.23 138.87 135.92 106.37 53.185 3262 53.185 3368.4 0z"/>
                    <path
                        d="m-4872.6 13085c-162.51-85.687-171.37-144.78-171.37-1001.7v-783l97.506-106.37c130.01-141.83 310.25-168.42 463.89-65.004 171.37 118.19 177.28 138.87 177.28 978.01 0 836.19-5.9094 874.6-177.28 978.01-103.42 59.094-283.65 59.094-390.02 0z"/>
                    <path
                        d="m-4869.6 10629c-186.15-115.23-174.33 127.05-174.33-3628.4 0-3752.5-11.819-3536.8 177.28-3634.3 183.19-94.551 413.66-32.502 514.12 132.96 44.321 76.823 47.276 390.02 47.276 3516.1v3433.4l-115.23 115.23c-97.506 100.46-132.96 115.24-242.29 115.24-70.913 0-165.46-23.638-206.83-50.231z"/>
                    <path
                        d="m-3150 1939.6c-112.28-59.094-209.79-215.69-209.79-330.93 0-183.19 200.92-384.11 384.11-384.11 41.366 0 121.14 23.638 174.33 53.185 112.28 59.094 209.79 215.69 209.79 330.93 0 183.19-200.92 384.11-384.11 384.11-41.366 0-121.14-23.638-174.33-53.185z"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export default DockingIcon;
