import {Building} from "../API";
import {gql, useQuery} from "@apollo/client";
import {useEffect} from "react";
import {onChangeBuildings} from "../graphql/subscriptions";
import {comparatorAlphanumericValues} from "../Utils/Helpers";
import {listBuildings} from "../graphql/queries";

export function useBuildingList(): { buildingList: Building[], buildingsLoading: boolean } {
    const {data, subscribeToMore, refetch, loading: buildingsLoading} = useQuery(gql(listBuildings));

    useEffect(function subscribe() {
        const unsubscribeOnChangeBuildings = subscribeToMore({
            document: gql(onChangeBuildings),
            updateQuery: (prev) => {
                refetch().then()
                return prev;
            },
            onError: (error: any) => console.error("useBuildingList useEffect:subscribeToMore:onChangeBuildings " + JSON.stringify(error))
        });

        function unsubscribe() {
            unsubscribeOnChangeBuildings();
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function comparator(building1: Building, building2: Building) {
        return comparatorAlphanumericValues(building1.buildingName, building2.buildingName)
    }

    const buildingList = data?.listBuildings?.items.sort(comparator) ?? []
    return {buildingList, buildingsLoading};
}