import {Building, Inventory, MeetingRoomEquipment} from "../../API";
import {comparatorAlphanumericValues} from "../../Utils/Helpers";

export const getEquipmentForSelectedBuilding = (equipment: MeetingRoomEquipment[], building: Building) : MeetingRoomEquipment [] => {
    return equipment.filter(item => item.buildingId === building.buildingId)
}

export const isDuplicateName = (equipmentOfBuilding: MeetingRoomEquipment[], name: string) => {
    return equipmentOfBuilding.some(equipment => equipment.name.trim().toLowerCase() === name.trim().toLowerCase())
}

export const comparator = (item1: MeetingRoomEquipment, item2: MeetingRoomEquipment) => {
    return comparatorAlphanumericValues(item1.name, item2.name)
}