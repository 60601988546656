import {InvType} from "./Enums";
import {Inventory} from "../API";

export function inventoryFilter(type: InvType, orgUnitId: string | null | undefined) {
    return (item: Inventory) => item.type === type && item.orgUnitId === orgUnitId
}

export function getInventoryLabel(inventoryItem: Inventory) {
    return inventoryItem.name;
}

export function multiplyInventoryByOccurence(inventoryIds: string[]): (invItem: Inventory) => Inventory[] {
    return (invItem: Inventory) => {
        const count = inventoryIds.filter(id => id === invItem.inventoryId).length;
        return Array<Inventory>(count).fill(invItem);
    }
}