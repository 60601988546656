import {gql, useQuery} from "@apollo/client";
import {MeetingRoom} from "../API";
import {useEffect} from "react";
import {getMeetingRooms} from "../graphql/queries";
import {onUpdateSecureMeetingRoom} from "../graphql/subscriptions";

export function useMeetingRoomList(roomIds: string[]): [MeetingRoom[], () => void, boolean] {
    const EMPTY_ARRAY: MeetingRoom[] = [];

    const {data, subscribeToMore, refetch, loading} = useQuery(gql(getMeetingRooms), {
        variables: {
            roomIds: roomIds,
            limit: 999
        },
        skip: !roomIds || roomIds.length === 0
    });

    useEffect(() => {
        if (roomIds.length === 0) return;

        const unsubscribeFromUpdate = subscribeToMore({
            document: gql(onUpdateSecureMeetingRoom),
            variables: {},

            updateQuery: (prev, {subscriptionData}) => {
                if (prev.hasOwnProperty("getMeetingRooms")) {
                    if (!subscriptionData.data) return prev;
                    const updatedMeetingRoom = subscriptionData.data.onUpdateSecureMeetingRoom;

                    const existingItems = prev.getMeetingRooms.items;
                    let updatedItems = existingItems.map((item: MeetingRoom) =>
                        item.meetingRoomId === updatedMeetingRoom.meetingRoomId ? updatedMeetingRoom : item
                    );

                    if (!existingItems.find((item: MeetingRoom) => item.meetingRoomId === updatedMeetingRoom.meetingRoomId)) {
                        updatedItems = [...updatedItems, updatedMeetingRoom];
                    }

                    return {
                        ...prev,
                        getMeetingRooms: {
                            ...prev.getMeetingRooms,
                            items: updatedItems,
                        },
                    };
                }
            },
            onError: (error: any) => console.error("useMeetingRoomList useEffect:subscribeToMore:onUpdateSecureMeetingRoom " + JSON.stringify(error)),
        });

        return () => {
            unsubscribeFromUpdate();
        };
    }, [subscribeToMore, roomIds.length]);

    function comparator(a: MeetingRoom, b: MeetingRoom) {
        return a.meetingRoomId.localeCompare(b.meetingRoomId, 'en', {numeric: true});
    }

    return [data?.getMeetingRooms?.items.sort(comparator) ?? EMPTY_ARRAY, refetch, loading];
}
