export function isSameDay(first: Date, second: Date) {
    return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
}

export function isGreaterThanDate(first: Date, second: Date): boolean {
    if (!first || !second) return false
    return first > second
}

export function isLowerThanDate(first: Date, second: Date): boolean {
    if (!first) return false
    return first < second
}

export function toDateISO(d: Date): string {
    return d.toLocaleDateString('sv', {timeZone: 'Europe/Berlin'}).substring(0, 10)
}

export function createNewTodayDateWithoutHours(): Date {
    let today: Date = new Date()
    today.setHours(0, 0, 0, 0)

    return today
}