import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {IconButton, Paper, TextField} from "@material-ui/core";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import {gql, useMutation} from "@apollo/client";
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from "react-i18next";
import {Building, MeetingRoomEquipment} from "../../API";
import {
    createSecureMeetingRoomEquipment,
    updateSecureMeetingRoomEquipment
} from "../../graphql/mutations";
import {useErrorContext} from "../../hooks/useErrorContext";
import {BtnButtonInputStyle, GeneralInputStyles} from "../../styles/InputFieldStyle";
import {checkForValidInputNaming} from "../../Utils/Helpers";
import {isDuplicateName} from "./MeetingRoomEquipmentManagerUtil";

interface Props {
    setShowCreateMeetingRoomEquipmentDialog: (value: boolean) => void
    selectedBuilding: Building
    setIsShowAlert: (value: boolean) => void
    equipmentOfSelectedBuilding: MeetingRoomEquipment[]
    selectedEquipment: MeetingRoomEquipment | undefined
    setSelectedEquipment: (value: MeetingRoomEquipment | undefined) => void
}

const CreateOrUpdateMeetingRoomEquipmentDialog: React.FC<Props> = (props) => {

    const {setShowCreateMeetingRoomEquipmentDialog,
        setIsShowAlert,
        selectedBuilding,
        selectedEquipment,
        setSelectedEquipment,
        equipmentOfSelectedBuilding} = props;
    const {reportError} = useErrorContext();
    const [newEquipmentName, setNewEquipmentName] = useState<string>("")

    const [createEquipmentMutation] = useMutation(gql(createSecureMeetingRoomEquipment))
    const [updateEquipmentMutation] = useMutation(gql(updateSecureMeetingRoomEquipment))


    useEffect(function focusTextFieldWhenRendered() {
        if (nameInputRef?.current) nameInputRef.current.focus();
    }, [])

    useEffect(function updateTextFieldValue() {
        setNewEquipmentName(selectedEquipment ? selectedEquipment.name : "")
    }, [selectedEquipment])

    const nameInputRef = useRef<HTMLInputElement>(null);

    const showErrorMessage = () => {
        setIsShowAlert(true)
        setTimeout(() => {
            setIsShowAlert(false)
        }, 4000)
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setNewEquipmentName(event.target.value)
    }

    const handleSave = () => {
        const isConflicting = isDuplicateName(equipmentOfSelectedBuilding, newEquipmentName);
        if (isConflicting) {
            showErrorMessage()
            return;
        }

        if(selectedEquipment) {
            handleUpdateEquipment();
        } else {
            handleCreateEquipment();
        }
        setSelectedEquipment(undefined);
    }

    const handleCreateEquipment = () => {
        createEquipmentMutation({
            variables: {
                input: {
                    equipmentId: uuidv4(),
                    buildingId: selectedBuilding.buildingId,
                    name: newEquipmentName.trim()
                }
            }
        })
            .then(() => setShowCreateMeetingRoomEquipmentDialog(false))
            .catch((err) => reportError(err, "", "CreateMeetingRoomEquipmentDialog handleCreateEquipment"))
    }

    const handleUpdateEquipment = () => {
        if (selectedEquipment) {
            updateEquipmentMutation({
                variables: {
                    input: {
                        equipmentId: selectedEquipment.equipmentId,
                        name: newEquipmentName.trim()
                    }
                }
            })
                .then(() => setShowCreateMeetingRoomEquipmentDialog(false))
                .catch((err) => reportError(err, "", "CreateMeetingRoomEquipmentDialog handleUpdateEquipment"))
        }
    }

    const classes = BtnButtonInputStyle();

    const MAX_ITEM_NAME_LENGTH = 50;
    const isNewEquipmentNameValid = checkForValidInputNaming(newEquipmentName, MAX_ITEM_NAME_LENGTH)

    const styles = GeneralInputStyles;
    const {t} = useTranslation();

    return (
        <Paper style={styles.paperStyle}>
            <Box style={styles.textFieldBoxStyle}>
                <TextField
                    style={{marginRight: "10px", width: "100%"}}
                    label={t('general_equipment-singular')}
                    value={newEquipmentName}
                    onChange={handleInputChange}
                    inputRef={nameInputRef}
                />
            </Box>

            <Box style={styles.toolTipsBoxStyle}>
                <Tooltip title={t("confirm_dialog_ok_button-text")}>
                    <span>
                        <IconButton onClick={handleSave} disabled={!isNewEquipmentNameValid}>
                            <SaveIcon className={isNewEquipmentNameValid ? classes.btnEnabled : classes.btnDisabled}
                                      style={{cursor: "pointer"}} fontSize="large"/>
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={t("confirm_dialog_cancel_button-text")}>
                    <IconButton onClick={() => setShowCreateMeetingRoomEquipmentDialog(false)}>
                        <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"/>
                    </IconButton>
                </Tooltip>
            </Box>
        </Paper>
    )
}
export default CreateOrUpdateMeetingRoomEquipmentDialog;