import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {ChangeEvent} from "react";
import {useTranslation} from "react-i18next";


interface Props<T> {
    selectorLabel: string
    choices: T[]
    selected: T | undefined
    setSelected: (item: T | undefined) => void
    getLabel: (item: T) => string
    getIdentifier: (item: T) => string
    includeNoneOption: boolean
}

const valueForUndefined = "none"

export function RoomPickerComponentSelector<T>(props: Props<T>) {
    const {
        selectorLabel,
        choices, selected, setSelected,
        getIdentifier, getLabel,
        includeNoneOption
    } = props;

    const {t} = useTranslation();

    function handleChange(event: ChangeEvent<{ value: unknown }>) {
        const id = event.target.value;
        if (id === valueForUndefined) {
            setSelected(undefined);
            return;
        }
        const elem = choices.find(c => getIdentifier(c) === id);
        if (elem !== undefined) {
            setSelected(elem);
        }
    }

    if (choices.length === 0) {
        return <></>;
    }

    let validValues = choices.map(getIdentifier);
    if (includeNoneOption) {
        validValues.push(valueForUndefined);
    }

    const selectedValue = selected === undefined ? valueForUndefined : getIdentifier(selected);

    if (!validValues.includes(selectedValue)) {
        return <></>;
    }

    return <FormControl variant={'outlined'} style={{width: "100%", textAlign: "left"}}>
        <InputLabel>{selectorLabel}</InputLabel>
        <Select
            label={selectorLabel}
            fullWidth
            onChange={handleChange}
            value={selectedValue}>
            {includeNoneOption ?
                <MenuItem key={valueForUndefined}
                          value={valueForUndefined}>{t("general_select_option_none")}</MenuItem> : null}
            {
                choices.map((item, index) =>
                    <MenuItem key={getIdentifier(item)} value={getIdentifier(item)}>{getLabel(item)}</MenuItem>)
            }
        </Select>
    </FormControl>
}