import useMediaQuery from '@mui/material/useMediaQuery';
import {isMobile as isMobileDevice, isFirefox} from "react-device-detect";

export function useDeviceMediaType() {
    const isMobile = useMediaQuery("(max-width: 800px)"); // 800px width is the breakpoint of the frontend library for left-side menu
    const isSmallMobile = useMediaQuery("(max-width: 400px)"); // to handle real small device between 320 and 400px
    const isNoFullscreen = useMediaQuery("(max-width: 1300px)"); // the booking dialog can get up to around 1250 px
    const shouldAlignButtons = useMediaQuery("(max-width: 500px)"); // change dialog buttons to small

    return {
        isMobile: isMobile,
        isSmallMobile: isSmallMobile,
        isNoFullscreen: isNoFullscreen,
        shouldAlignButtons: shouldAlignButtons,
        isMobileDevice: isMobileDevice, // for mobiles and tablets regardless of resolution
        isFirefox: isFirefox,
    }
}
