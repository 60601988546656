import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

export interface SupportDialogProps {
    showSupportDialog: boolean;
    setShowSupportDialog: (value: boolean) => void;
}

export const SupportDialog = ({showSupportDialog, setShowSupportDialog}: SupportDialogProps) => {
    const {t} = useTranslation();

    const handleClose = () => {
        setShowSupportDialog(false);
    };

    return (
        <Dialog open={showSupportDialog} onClose={handleClose}>
            <DialogTitle>{t('support_dialog-title')}</DialogTitle>
            <DialogContent>
                {t('support_dialog-text')}{' '}
                <a href="mailto:sit2gether-support@materna.group">sit2gether-support@materna.group</a>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    {t('button_close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
