import {Room} from "../../API";
import {useTranslation} from "react-i18next";
import GenericSelectorComponent from "./GenericSelectorComponent";
import {generateSimpleSingleSelectOptions} from "../../Utils/GenericSelectorUtil";
import React from "react";

interface Props {
    rooms: Room[],
    selectedRoomId: string | undefined,
    onRoomSelect: (roomId: string) => void
    selectedBuildingId: string | undefined
}

const RoomSelectorDropdown: React.FC<Props> = ({rooms, selectedRoomId, onRoomSelect, selectedBuildingId}) => {
    const {t} = useTranslation();
    const roomsInBuilding = rooms.filter(room => room.buildingId === selectedBuildingId);

    function displayName(roomId: string) {
        return roomsInBuilding.find(room => room.roomId === roomId)?.name ?? '';
    }

    function getIndex(selectedRoomId: string | undefined) {
        if (!selectedRoomId) {
            return 0;
        }
        const index = roomsInBuilding.map(room => room.roomId).indexOf(selectedRoomId);
        return index === -1 ? 0 : index;
    }

    if (roomsInBuilding.length === 0) {
        return <></>
    }

    return (
        <GenericSelectorComponent
            disabled={false}
            labelText={t("general_room-plan-singular")}
            style={{width: "100%", textAlign: "left"}}
            dataTestId="room-select"
            id="room-select-native-id"
            setSelected={onRoomSelect}
            selectedIndex={getIndex(selectedRoomId)}
            chooseFrom={generateSimpleSingleSelectOptions(roomsInBuilding.map(room => room.roomId), displayName)}
            isOutlined={true}
        />
    );
}

export default RoomSelectorDropdown;