import {Inventory} from "../API";
import {gql, useQuery} from "@apollo/client";
import {getInventories} from "../graphql/queries";
import {useEffect} from "react";
import {comparatorAlphanumericValues} from "../Utils/Helpers";
import {onChangeInventories} from "../graphql/subscriptions";

export function useInventoryList(): { inventories: Inventory[], inventoryLoading: boolean } {
    const {data, subscribeToMore, refetch, loading: inventoryLoading} = useQuery(gql(getInventories));

    useEffect(function subscribe() {
        const unsubscribeOnChangeInventories = subscribeToMore({
            document: gql(onChangeInventories),
            updateQuery: (prev) => {
                refetch().then()
                return prev;
            },
            onError: (error: any) => console.error("useInventoryList useEffect:subscribeToMore:onChangeInventories "
                + JSON.stringify(error))
        });

        return () => unsubscribeOnChangeInventories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function comparator(item1: Inventory, item2: Inventory) {
        return comparatorAlphanumericValues(item1.name, item2.name)
    }

    const inventories = data?.getInventories?.items.sort(comparator) ?? []

    return {inventories, inventoryLoading};

}