import {gql, useQuery} from "@apollo/client";
import {onUpdateSeatConfig} from "../graphql/subscriptions";
import {SeatConfig} from "../API"
import {useEffect} from "react";
import {useErrorContext} from "./useErrorContext";
import {getSeatConfigs} from "../graphql/queries";

export function useSeatConfigList(roomId: string): [SeatConfig] | [] {

    const {data, subscribeToMore, refetch} = useQuery(gql(getSeatConfigs), {
        variables: {
            limit: 999,
            roomId: roomId,
        }, skip: (roomId === "")
    });

    const {reportError} = useErrorContext()

    useEffect(function subscribe() {
            const unsubscribeOnUpdateSeatConfig = subscribeToMore({

                document: gql(onUpdateSeatConfig),
                variables: {roomId: roomId},
                updateQuery: (prev) => {
                    refetch().then()
                    return prev;
                },
                onError: (error: any) => reportError(error, "", "useSeatConfig onUpdateSeatConfigSubscription")
            });

            return () => unsubscribeOnUpdateSeatConfig();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [subscribeToMore]);

    function comparator(a: SeatConfig, b: SeatConfig) {
        return a.seatName.localeCompare(b.seatName, 'en', {numeric: true})
    }

    return data?.getSeatConfigs?.items.sort(comparator) ?? [];
}