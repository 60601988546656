import React, {CSSProperties} from "react";

export const bookingConfigId = "OrgUnit_8d14da2f-3edf-4533-ae1d-e07035255f23"
export const bookingForMaxLength = 100

export const maxBuildingNameLength = 40
/*The subscription delay serves to delay subscriptions in cases where rapidly updating the dependencies (such as
 * changing the date using the calendar forward/back buttons) leads to existing subscriptions
 * not unsubscribing in time and eventually hitting the 100 subscription limit */
export const SUBSCRIPTION_DELAY = 1000
export const defaultOrgUnitId = "keine"
export const columnStyle: CSSProperties = {display: "flex", flexDirection: "column", justifyContent: "flex-start"};
export const rowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
};
export const columnStyleFixedWidth: CSSProperties = {display: "flex", flexDirection: "column", maxWidth: 350};

const NARROW_NO_BREAK_SPACE = "\u202F"
export const EN_DASH = "\u2013"
export const TIME_RANGE_SEPERATOR = NARROW_NO_BREAK_SPACE + EN_DASH + NARROW_NO_BREAK_SPACE;
