import {gql, useQuery} from "@apollo/client";
import {getSeatBookings} from "../graphql/queries";
import {useEffect} from "react";
import {Booking} from "../API"
import {SUBSCRIPTION_DELAY} from "../Utils/commons";
import {onChangeSeatBooking, onUpdateRestrictedNeighborhoods} from "../graphql/subscriptions";
import {toDateISO} from "../services/DateUtils";

export function useBookingBySeatId(roomId: string, roomOrgUnitId: string, dateISO: string, seatId: string): Booking[] | [] {
    const {data, subscribeToMore, client, refetch} = useQuery(gql(getSeatBookings), {
        variables: {
            input: {
                seatId: seatId,
                date: toDateISO(new Date(dateISO)),
                roomId: roomId,
                orgUnitId: roomOrgUnitId,
                typeOfQuery: "bookingsBySeatAndDateAndRoom",
            },
            nextToken: null,
            limit: 999
        },
        skip: (roomId === "") || (seatId === ""),
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
    });

    function subscribeToMutations() {
        if (roomId === "")
            return;

        let subscriptions: (() => void)[] = []
        let timeout = setTimeout(() => {
            const unsubscribeOnChangeSeatBooking = subscribeToMore({
                document: gql(onChangeSeatBooking),
                updateQuery: (prev) => {
                    refetch().then().catch(error => {
                        console.error("refetch in useBookingBySeatId onChangeSeatBookingSubscription: " + JSON.stringify(error));
                    });
                    return prev;
                },
                onError: (error: any) => console.error("onChangeSeatBookingSubscription: " + JSON.stringify(error))
            });

            const unsubscribeOnUpdateRestrictedNeighborhoods = subscribeToMore({
                document: gql(onUpdateRestrictedNeighborhoods),
                updateQuery: (prev) => {
                    refetch().then().catch(error => {
                        console.error("refetch in useBookingBySeatId onUpdateRestrictedNeighborhoodsSubscription: " + JSON.stringify(error));
                    });
                    return prev;
                },
                onError: (error: any) => console.error("onUpdateRestrictedNeighborhoodsSubscription: " + JSON.stringify(error))
            });

            subscriptions = [unsubscribeOnChangeSeatBooking, unsubscribeOnUpdateRestrictedNeighborhoods]
        }, SUBSCRIPTION_DELAY)


        function unsubscribe() {
            subscriptions.forEach(subscription => subscription())
            clearTimeout(timeout)
        }

        return () => unsubscribe()
    }


    useEffect(subscribeToMutations, [roomOrgUnitId, roomId, dateISO, seatId, subscribeToMore]);

    function clearCacheIfNoSeatIsSelected() {
        if (!seatId) {
            client.cache.evict({id: 'ROOT_QUERY', fieldName: 'bookingBySeatAndDate'})
        }
    }

    clearCacheIfNoSeatIsSelected();

    return data?.getSeatBookings?.items.map((b: Booking) => {
        return {...b, __typename: "Booking"}
    }) ?? [];

}