import CloseIcon from '@material-ui/icons/Close';
import React from "react";
import "./CloseIcon.css";

function Sit2GetherCloseIcon() {
    return (
        <div className="close-icon-container">
            <CloseIcon />
        </div>
    );
}

export default Sit2GetherCloseIcon;