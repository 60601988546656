import {createContext} from "react";
import {mainApplicationContextTypes} from "../types/mainApplicationContextTypes";

const MainApplicationContext = createContext<mainApplicationContextTypes>({
    orgUnitList: [],
    buildingList: [],
    neighborhoods: [],
    meetingRoomService: {
        meetingRoomArray: [],
        meetingRoomMap: new Map(),
    },
    buildingEquipmentService: {
        buildingEquipmentArray: [],
        buildingEquipmentMap: new Map(),
    },
    managedBuildings: [],
    adminOrgUnitList: [],
    selectedOrgUnit: {orgId: "", orgName: "", deleted: false},
    setSelectedOrgUnit: () => {
    },
    isLoading: true,
    currentUser: {
        ID: "",
        email: "",
        name: "",
        isOrgUnitAdmin: false,
        orgUnits: [],
        adminOrgUnits: [],
        isAdmin: false,
        familyName: "Wurst",
        givenName: "Wurst",
        isMaternaEmployee: true,
        admin2GetherRoles: [],
        jwt: ""
    },
    rooms: [],
    userSettingsObject: {
        userSettings: {
            __typename: "UserSettings",
            userId: "",
            mailConfig: {
                userId: "",
                userMail: "",
                isReceiveForeignBooking: false,
                isReceiveOwnBooking: false,
                __typename: "MailConfig",
                createdAt: "",
                updatedAt: ""
            },
            defaultRoomConfig: {
                __typename: "DefaultRoomConfig",
                userId: "",
                roomId: "",
                roomName: "",
                neighborhoodId: "",
                createdAt: "",
                updatedAt: "",
            },
            createdAt: "",
            updatedAt: "",
            userSettingsMailConfigId: "",
            userSettingsDefaultRoomConfigId: "",
        },
        setShouldRefetchUserSettings: () => {
        }
    },
    idOfDefaultBuilding: null,
    setIdOfDefaultBuilding: (id: string | null) => {
    },
    rerenderSeatConfigsTrigger: false,
    setRerenderSeatConfigsTrigger: (value: boolean) => {
    },
})


export default MainApplicationContext