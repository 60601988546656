import {StyledDivHourDisplayInner, StyledDivHourDisplayOuter} from "../../../styles/MeetingRoomBookingMatrixStyles";
import React from "react";

interface Props {
    time: string
}

function HourDisplay({time}: Props){
    return (<StyledDivHourDisplayOuter style={{minWidth: '28px'}}>
            <StyledDivHourDisplayInner>{time}</StyledDivHourDisplayInner>
        </StyledDivHourDisplayOuter>
    );
}

export default HourDisplay;