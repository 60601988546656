import {useState} from "react";
import {MeetingRoomBookingSettingsData} from "../components/MultiBookingDialogMeetingRoom/MeetingRoomBookingSettings";
import {MeetingType} from "../Utils/Enums";

function useMeetingRoomBookingSettingsStorage() {
    const [bookingSettings, setBookingSettings] = useState<MeetingRoomBookingSettingsData>(
        {
            meetingName: "",
            participantNumber: null,
            meetingType: MeetingType.INTERNAL,
            visitors: ""
        }
    )

    function setMeetingRoomBookingSettings(newData: Partial<MeetingRoomBookingSettingsData>) {
        setBookingSettings(prevState => ({...prevState, ...newData}))
    }

    return {meetingRoomBookingSettings: bookingSettings, setMeetingRoomBookingSettings}
}

export default useMeetingRoomBookingSettingsStorage



