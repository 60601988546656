import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import borders from "../../styles/borders";
import MeetingRoomBookingDropdown from "./MeetingRoomBookingDropdown";
import {MeetingRoomType, MeetingType} from "../../Utils/Enums";
import {useTranslation} from "react-i18next";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";

interface MeetingRoomBookingSettingsProps {
    maxParticipants: number | null
    meetingRoomType: MeetingRoomType
    settings: MeetingRoomBookingSettingsData
    setSettings: (newData: Partial<MeetingRoomBookingSettingsData>) => void
}

export interface MeetingRoomBookingSettingsData {
    meetingName: string
    participantNumber: number | null
    meetingType: MeetingType
    visitors: string
}

function MeetingRoomBookingSettings(props: MeetingRoomBookingSettingsProps) {
    const {maxParticipants, meetingRoomType, settings, setSettings} = props;
    const {isNoFullscreen} = useDeviceMediaType();
    const handleMeetingNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setSettings({meetingName: event.target.value});
    }

    const handleVisitorsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setSettings({visitors: event.target.value});
    }

    function handleMeetingTypeChange(meetingType: MeetingType) {
        setSettings({meetingType: meetingType});
    }

    const handleParticipantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const newValueNumber = parseInt(event.target.value);

        const isNumberValidInput = Number.isInteger(newValueNumber);
        const newData: Partial<MeetingRoomBookingSettingsData> = {participantNumber: isNumberValidInput ? newValueNumber : null}
        setSettings(newData);
    }

    const showWarning = maxParticipants !== null
        && settings.participantNumber !== null
        && settings.participantNumber > maxParticipants

    const {t} = useTranslation();
    return (
        <div style={{display: "flex", flexDirection: "row"}} data-testid={"time-window-table"}>
            <TableContainer sx={{
                minWidth: "300px",
                maxHeight: "500px", // increased for visitors field, TODO check if needed
                borderRadius: borders.mediumBorderRadius,
                overflow: "auto"
            }}>
                <Table stickyHeader>
                    {!isNoFullscreen &&
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}
                                           sx={{fontSize: "large"}}>
                                    {t('multibookingdialog_meetingroom_settings_title')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody data-testid={"time-window-table-body"}>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_meeting_name"}>
                                <TextField style={{width: "100%", backgroundColor: "white"}}
                                           label={t('multibookingdialog_meetingroom_settings_meeting_name')}
                                           variant="filled" required value={settings.meetingName}
                                           onChange={handleMeetingNameChange}
                                           inputProps={{maxLength: 30}}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_participant_number"}>
                                <TextField style={{width: "100%", backgroundColor: "white"}}
                                           label={t('multibookingdialog_meetingroom_settings_participant_number')}
                                           variant="filled"
                                           value={settings.participantNumber ?? ""}
                                           helperText={showWarning ? t("multibookingdialog_meetingroom_settings_participant_number_recommendation", {
                                               capacity: maxParticipants
                                           }) : ""}
                                           onChange={handleParticipantChange} required/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_dropdown"}>
                                <MeetingRoomBookingDropdown meetingTypeState={settings.meetingType}
                                                            setMeetingTypeState={handleMeetingTypeChange}/>
                            </TableCell>
                        </TableRow>
                        {meetingRoomType === MeetingRoomType.VISITORS_CENTER ?
                            <TableRow>
                                <TableCell align={'center'} colSpan={2} width={1}>
                                    <TextField style={{width: "100%", backgroundColor: "white"}}
                                               label={t("general_visitors")}
                                               variant="filled" value={settings.visitors}
                                               onChange={handleVisitorsChange}
                                               inputProps={{maxLength: 100}}/>
                                </TableCell>
                            </TableRow> : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>);

}

export default MeetingRoomBookingSettings
