import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import colors from "../../../styles/colors";
import {useTranslation} from "react-i18next";
import {BookingType} from "../../../types/BookingType";
import {useMultiBookingLegendStyles} from "../../../styles/MultiBookingLegendStyles";
import SquareLegendCell from "./SquareLegendCell";
import LegendTextCell from "./LegendTextCell";

export interface MultiBookingLegendComponentProps {
    isTimeActive: boolean | null | undefined
    isBookingTimeFormatValid: () => boolean | undefined
    bookingType: BookingType
}

export const TWO_DIGIT_DAY_TEMPLATE = "00";

const MultiBookingLegendComponent: React.FC<MultiBookingLegendComponentProps> = (props) => {
    // eslint-disable-next-line no-empty-pattern
    const {
        isTimeActive,
        isBookingTimeFormatValid,
        bookingType
    } = props

    const {t} = useTranslation();


    const BOOKING_TYPE_SEAT = "seat";

    const timeNotActiveOwnBookingLegend = bookingType === BOOKING_TYPE_SEAT ?
        t("multibookingdialog_legend_own_booking") :
        t("multibookingdialog_meetingroom_legend_own_booking");

    const noBookingLegend = bookingType === BOOKING_TYPE_SEAT ?
        t("multibookingdialog_legend_no_booking") :
        t("multibookingdialog_meetingroom_legend_no_booking");

    const onlyCertainSlotsAvailableLegend = t("multibookingdialog_legend_booking_available");

    const bookedByOtherUserLegend = bookingType === BOOKING_TYPE_SEAT ?
        t("multibookingdialog_legend_other_user") :
        t("multibookingdialog_meetingroom_legend_other_user");

    const daySelectedLegend = t("multibookingdialog_legend_marked");


    const classes = useMultiBookingLegendStyles();

    return <div style={{display: "flex", flexDirection: "row"}} data-testid={"booking-legend"}>

        <TableContainer className={classes.legendTableContainer}>
            <Table stickyHeader style={{marginTop: "auto"}}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}
                                   style={{fontSize: "large"}}>{t("multibookingdialog_legend_title")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isTimeActive &&
                        <TableRow>
                            <SquareLegendCell spanStyle={{backgroundColor: colors.pastellblau}}/>
                            <LegendTextCell text={timeNotActiveOwnBookingLegend}/>
                        </TableRow>}
                    <TableRow>
                        <SquareLegendCell spanStyle={{backgroundColor: colors.pastellgruen}}/>
                        <LegendTextCell text={noBookingLegend}/>
                    </TableRow>
                    {isTimeActive && !isBookingTimeFormatValid() &&
                        <TableRow>
                            <SquareLegendCell spanStyle={{backgroundColor: colors.pastellgelb}}/>
                            <LegendTextCell text={onlyCertainSlotsAvailableLegend}/>
                        </TableRow>}
                    <TableRow>
                        <SquareLegendCell spanStyle={{backgroundColor: colors.pastellrot}}/>
                        <LegendTextCell text={bookedByOtherUserLegend}/>
                    </TableRow>
                    <TableRow>
                        <SquareLegendCell
                            tableCellStyle={{paddingTop: "0.5625rem", paddingBottom: "0.5625rem"}}
                            spanStyle={{
                                backgroundColor: "argb(0,0,0,0)",
                                borderStyle: "solid",
                                borderColor: "rgb(52,73,29)"
                            }}
                        />
                        <LegendTextCell text={daySelectedLegend}/>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    </div>;
}

export default MultiBookingLegendComponent