import React from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {t} from "i18next";

interface Props {
    isShowDeleteDialog: boolean
    handleDelete: () => void
    setIsShowDeleteDialog: (value: boolean) => void
}

const DeleteEntryConfirmationDialogComponent: React.FC<Props> = (props) => {
    const {isShowDeleteDialog, handleDelete, setIsShowDeleteDialog} = props

    return (
        <Dialog open={isShowDeleteDialog}>
            <DialogContent data-testid={"deleteDialog"}>
                {t('delete_dialog_content')}
                <DialogActions>
                    <Button variant={"contained"} color={"primary"} component={"span"}
                            onClick={handleDelete} data-testid={"delete-dialog-confirm"}>
                        {t("confirm_dialog_ok_button-text")}
                    </Button>
                    <Button variant={"contained"} color={"primary"} component={"span"}
                            onClick={() => setIsShowDeleteDialog(false)}
                            data-testid={"delete-dialog-cancel"}>
                        {t("confirm_dialog_cancel_button-text")}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteEntryConfirmationDialogComponent;