import {MeetingRoomDataWithCapacity} from "../MeetingRoomBookingMatrixComponent";
import {useTranslation} from "react-i18next";
import {MeetingRoomType} from "../../../Utils/Enums";
import React, {useMemo} from "react";
import {reformatNeighborhoodId} from "../../../Utils/Helpers";
import {MeetingRoomEquipment} from "../../../API";
import Divider from "@material-ui/core/Divider";

interface Props extends MeetingRoomDataWithCapacity {
    buildingEquipment: Map<string, MeetingRoomEquipment>,
}

function MeetingRoomTooltipContent(data: Props) {
    const {t} = useTranslation();

    const displayNeighborhoodName = !!(data.neighborhoodId);
    const neighborhoodText = `${t("neighborhood-name")}: ${data.name ?? reformatNeighborhoodId(data.neighborhoodId ?? "")}`;

    const displayLevel = data.level !== null;
    const levelText = `${t("room-level")}: ${data.level === 0 ? t("ground_floor") : data.level}`

    let translatedMeetingRoomType;
    switch (data.type) {
        case MeetingRoomType.CLASSROOM:
            translatedMeetingRoomType = t("meetingroom_type_classroom");
            break;
        case MeetingRoomType.NEIGHBORHOOD:
            translatedMeetingRoomType = t("meetingroom_type_neighborhood");
            break;
        case MeetingRoomType.VISITORS_CENTER:
            translatedMeetingRoomType = t("meetingroom_type_visitors_center");
            break;
        default:
            translatedMeetingRoomType = t("meetingroom_type_internal");
    }
    const mrTypeText = `${t("meeting_room_type")}: ${translatedMeetingRoomType}`

    const displayMeetingRoomCapacity = data.meetingRoomCapacity !== null;
    const capacityText = `${t("meeting_room_capacity")}: ${data.meetingRoomCapacity}`;
    const occurrencesOfEquipment = new Map<string, number>();
    fillOccurrences();

    function fillOccurrences() {
        data.equipmentIds?.forEach(id => {
            if (occurrencesOfEquipment.has(id)) occurrencesOfEquipment.set(id, occurrencesOfEquipment.get(id)! + 1)
            else occurrencesOfEquipment.set(id, 1)
        })
    }

    const equipmentToDisplay = useMemo(() => {
            const array = data.equipmentIds?.map(id => data.buildingEquipment.get(id)!) ?? [];
            //Remove duplicates
            return array.filter((element, index) => (array.indexOf(element) === index) && element)
        },
        [data]);

    function renderEquipment() {
        if (equipmentToDisplay.length === 0) return <></>
        return (
            <>
                <Divider style={{margin: "5px 0 5px 0", backgroundColor: "white"}}/>
                {t("general_equipment-singular") + ":"}
                <br/>
                {equipmentToDisplay.map((item, index) => {
                    return (
                        <div key={item?.equipmentId + "_tooltip"}>
                                <span>
                                    {`${(occurrencesOfEquipment.get(item.equipmentId) ?? 0) > 1 ?
                                        "" + occurrencesOfEquipment.get(item.equipmentId) + "x " : ""}${item?.name}`}
                                </span>
                            <br/>
                        </div>)
                })
                }
            </>
        )
    }

    return <>
        {displayNeighborhoodName ?
            <>
                <span key={0}>{neighborhoodText}</span>
                <br key={1}/>
            </>
            : null}
        {displayLevel ?
            <>
                <span key={2}>{levelText}</span>
                <br key={3}/>
            </>
            : null}
        <span key={4}>{mrTypeText}</span>
        <br key={5}/>
        {displayMeetingRoomCapacity ?
            <span key={6}>{capacityText}</span>
            : null}
        {renderEquipment()}
    </>
}

export default MeetingRoomTooltipContent;