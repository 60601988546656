import {ArrowDownward, ArrowUpward, ImportExportRounded} from "@material-ui/icons";
import React from "react";
import {TableCell} from "@mui/material";
import {compareDates, compareMeetingTypes, compareNumbers, compareRoomNames, compareStrings} from "./Helpers";
import {Booking} from "../API";
import {OwnMeetingRoomBooking} from "../types/MeetingRoomBookingListItemType";

export const getSortIcon = (sortOrder: string) => {
    if (sortOrder === 'asc') {
        return <ArrowUpward style={{color: '#c30a17'}}/>;
    } else if (sortOrder === 'desc') {
        return <ArrowDownward style={{color: '#c30a17'}}/>;
    } else {
        return <ImportExportRounded/>;
    }
}

export const StyledTabTableCell = ({children}: {
    children: React.ReactNode
}) => {
    return <TableCell style={bookingTabTableCell}>{children}</TableCell>;
};

export const bookingTabTableCell: React.CSSProperties = {
    cursor: 'pointer',
    textAlign: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8
};

export const bookingTabHeaderCell: React.CSSProperties = {
    ...bookingTabTableCell,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
};

export const meetingBookingSortColumns: Array<{
    column: keyof OwnMeetingRoomBooking;
    compare: (a: any, b: any) => number
}> = [
    {column: 'date', compare: compareDates},
    {column: 'timeBegin', compare: compareDates},
    {column: 'timeEnd', compare: compareDates},
    {column: 'meetingType', compare: compareMeetingTypes},
    {column: 'meetingRoomName', compare: compareStrings},
    {column: 'meetingName', compare: compareStrings},
    {column: 'participantNumber', compare: compareNumbers},
];

export const seatBookingSortColumns: Array<{
    column: keyof Booking;
    compare: (a: any, b: any) => number
}> = [
    {column: 'date', compare: compareDates},
    {column: 'timeBegin', compare: compareDates},
    {column: 'timeEnd', compare: compareDates},
    {column: 'room', compare: compareRoomNames},
    {column: 'seatId', compare: compareStrings},
];


export const getSortOptionsByColumn = (
    column: string,
    sortOptions: {
        [key: string]: string
    }) => {
    const newSortOptions = {...sortOptions};

    Object.keys(newSortOptions).forEach((key) => {
        if (key === column) {
            switch (newSortOptions[key]) {
                case 'asc':
                    newSortOptions[key] = 'desc';
                    break;
                case 'desc':
                    newSortOptions[key] = '';
                    break;
                default:
                    newSortOptions[key] = 'asc';
                    break;
            }
        } else {
            newSortOptions[key] = '';
        }
    });
    newSortOptions["selectedColumn"] = column;

    return newSortOptions;
};
