import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {TimeWindow} from "../../types/TimeWindowType";
import {Dayjs} from "dayjs";

interface Props {
    timeWindow: TimeWindow
    onClick: (start: Dayjs, end: Dayjs) => void
}


const TimeWindowTableRowComponent: React.FC<Props> = (props) => {

    function onClick() {
        props.onClick(props.timeWindow.start, props.timeWindow.end)
    }

    const sx = {
        minWidth: "200px",
        paddingTop: "8px",
        paddingBottom: "8px",
        textAlign: "left",
        '&:hover': {
            cursor: "pointer"
        }
    };

    function getTime() {
        const Narrow_No_BreakSpace = "\u202F"
        const EN_DASH = "\u2013"
        const separator = Narrow_No_BreakSpace + EN_DASH + Narrow_No_BreakSpace
        const timeStart = props.timeWindow.start.format("HH:mm")
        const timeEnd = props.timeWindow.end.format("HH:mm");

        return timeStart + separator + timeEnd;
    }

    return (
        <TableRow
            data-testid={"time-window-table-row-component"}
            hover={true}
            onClick={onClick}
            sx={sx}>
            <TableCell data-testid={"time-window-table-cell-time-component"}>{getTime()}</TableCell>
        </TableRow>
    );
}

export default TimeWindowTableRowComponent;