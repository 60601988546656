import {Building, MeetingRoom, Neighborhood, Room} from "../API";
import {gql, useLazyQuery} from "@apollo/client";
import {getMeetingRooms, getNeighborhoodsByRoomId, getRooms, listMeetingRooms} from "../graphql/queries";
import {useEffect, useRef, useState} from "react";
import {useMainApplicationContext} from "./useMainApplicationContext";
import {BuildingP, MeetingRoomWithLevelP, RoomP} from "../types/PermissionHandling";
import {getBuildingManagePermissionConverter} from "../Utils/Roles";
import {intersectionNotEmpty} from "../Utils/Helpers";
import {usePermissionHelper} from "./usePermissionHelper";


export type NeighborhoodOfRoomPlan = {
    neighborhoodName: string;
    neighborhoodId: string;
    roomPlanId: string;
}

export function useMeetRoomBkngSelectorData() {

    const EMPTY_RESULT = useRef<[BuildingP[], RoomP[], NeighborhoodOfRoomPlan[], MeetingRoomWithLevelP[]]>([[], [], [], []]);
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState<[BuildingP[], RoomP[], NeighborhoodOfRoomPlan[], MeetingRoomWithLevelP[]]>(EMPTY_RESULT.current);
    const permissionHelper = usePermissionHelper();

    const [queryRoomPlans] = useLazyQuery(gql(getRooms), {
        variables: {
            limit: 999
        },
    })

    const [queryMeetingRooms] = useLazyQuery(gql(getMeetingRooms))
    const [queryMeetingRoomsNew] = useLazyQuery(gql(listMeetingRooms))
    const [queryNeighborhoods] = useLazyQuery(gql(getNeighborhoodsByRoomId))

    async function fetchNeighborhoodsByRoomId(roomId: string) {
        const variables = {roomId};
        const result = await queryNeighborhoods({variables});
        return result?.data?.getNeighborhoodsByRoomId?.items;
    }

    const {buildingList, currentUser, orgUnitList} = useMainApplicationContext(); //TODO check if rooms from this context can be used
    const roomPlanMap = new Map<string, Room>();
    const buildingMap = new Map<string, Building>();
    useEffect(() => {
        buildingList.forEach((building) => buildingMap.set(building.buildingId, building))
    }, [buildingList])

    async function fetchRoomPlans(): Promise<Room[]> {
        const result = await queryRoomPlans();
        return result?.data?.getRooms?.items ?? [];
    }

    async function fetchMeetingRooms(roomPlanIds: string[]): Promise<MeetingRoom[]> {
        const resultPromises = roomPlanIds.map(async (id) => {
            return fetchMeetingRoomsForRoomPlan(id)
        })
        const result = await Promise.all(resultPromises)
        const flat = result.flat()

        return flat;
    }

    async function fetchMeetingRoomsForRoomPlan(roomPlanId: string): Promise<MeetingRoom[]> {
        let variables: any = {
            roomIds: [roomPlanId],
            limit: 4000,
            filter: {
                roomId: {
                    eq: roomPlanId
                }
            }
        }

        const result = await queryMeetingRoomsNew(
            {
                variables: variables,
            });

        return result?.data?.listMeetingRooms?.items ?? [];
    }

    async function fetchAll(): Promise<[BuildingP[], RoomP[], NeighborhoodOfRoomPlan[], MeetingRoomWithLevelP[]]> {
        //TODO refactor, split up in multiple functions
        if (buildingList.length === 0) {
            return EMPTY_RESULT.current;
        }
        let roomPlans = await fetchRoomPlans();
        roomPlans.forEach((roomplan) => roomPlanMap.set(roomplan.roomId, roomplan))
        const buildingIdsFromRooms: string[] = roomPlans.reduce((acc: string[], current) => {
            if (current.buildingId) {
                acc.push(current.buildingId);
            }
            return acc;
        }, []);
        const buildingIds = buildingList.map(building => building.buildingId);
        const buildingIdsIntersection = buildingIds.filter(buildingId => buildingIdsFromRooms.includes(buildingId));

        const roomsWithBuilding = roomPlans.filter(room => room.buildingId && buildingIdsIntersection.includes(room.buildingId));
        const buildingsWithRooms = buildingList.filter(building => buildingIdsIntersection.includes(building.buildingId));

        const buildingsWithManageInfo: BuildingP[] = buildingsWithRooms.map(getBuildingManagePermissionConverter(currentUser));

        const roomPlansWithManageInfo: RoomP[] = roomsWithBuilding.map(room => {
            const building = buildingsWithManageInfo.find(building => building.buildingId === room.buildingId);
            let userCanManage = building?.managingAllowed ?? false;
            /*
            TODO 6396 roles for room plans ...
            userCanManage = userCanManage || intersectionNotEmpty(currentUser.admin2GetherRoles, room.manageRoleIds)
            */
            return {...room, managingAllowed: userCanManage}
        })

        const roomPlansWhereUserHasAccess = roomPlansWithManageInfo.filter(room => {
            const userHasOrgUnitOfRoom = orgUnitList.some(orgUnit => orgUnit.orgId === room.orgUnitId);
            return room.managingAllowed || (room.isActive && (room.isPublic || userHasOrgUnitOfRoom));
        });

        if (roomPlansWhereUserHasAccess.length === 0) {
            return EMPTY_RESULT.current;
        }
        const buildingIdsFromRoomPlans = roomPlansWhereUserHasAccess.map(r => r.buildingId ?? "") //buildingId should not be undefined here

        const buildingsWithRoomPlans = buildingsWithManageInfo.filter(building => buildingIdsFromRoomPlans.includes(building.buildingId));

        const filteredRoomPlans = filterRoomPlansIfActiveOrPublic(roomPlansWhereUserHasAccess);
        const roomPlanAllIds = filteredRoomPlans.map(roomPlan => roomPlan.roomId)
        let meetingRooms = await fetchMeetingRooms(roomPlanAllIds);
        meetingRooms = filterMeetingRoomsByPermissions(meetingRooms);

        function filterRoomPlansIfActiveOrPublic(roomPlans: Room[]) {
            return roomPlans.filter(roomPlan => roomPlan.isActive && roomPlan.isPublic)
        }

        function filterMeetingRoomsByPermissions(meetingRooms: MeetingRoom[]) {
            const isAdmin = currentUser.isAdmin;
            if (isAdmin) return meetingRooms
            return meetingRooms.filter((meetingRoom) => {
                if (!meetingRoom.neighborhoodId || meetingRoom.neighborhoodId === "") return true;
                const room = roomPlanMap.get(meetingRoom.roomId)!;
                const building = buildingMap.get(room.buildingId!)!
                const isBuildingAdmin = permissionHelper.hasManagementForBuilding(building);
                if (isBuildingAdmin) return true;
                return permissionHelper.hasAccessToNeighborhoodById(meetingRoom.neighborhoodId, meetingRoom.roomId)
            })

        }

        if (meetingRooms.length === 0) {
            return EMPTY_RESULT.current;
        }

        let neighborhoods;
        try {
            neighborhoods = await Promise.all(roomPlanAllIds.map(roomId => fetchNeighborhoodsByRoomId(roomId)))
        } catch (error) {
            //show error message
            return EMPTY_RESULT.current;
        }
        const NO_NEIGHBORHOOD = "";

        const neighborhoodsUserCanAccess: Neighborhood[] = neighborhoods.flat().filter((neighborhood: Neighborhood) => {
            return currentUser.isAdmin || !neighborhood.restricted ||
                intersectionNotEmpty(currentUser.admin2GetherRoles, neighborhood.roleIds ?? []) ||
                intersectionNotEmpty(currentUser.admin2GetherRoles, neighborhood.adminRoleIds ?? []);
        });

        const meetRoomsUserCanAccess = meetingRooms.filter(meetRoom => {
            return meetRoom.neighborhoodId === NO_NEIGHBORHOOD
                || neighborhoodsUserCanAccess.some(nbhd => nbhd.neighborhoodId === meetRoom.neighborhoodId && nbhd.roomId === meetRoom.roomId);
        })

        const neighborhoodsWithMeetRooms = neighborhoodsUserCanAccess.filter(function anyMeetRoomInside(nbhd) {
            return meetingRooms
                .some(mr => mr.neighborhoodId === nbhd.neighborhoodId && mr.roomId === nbhd.roomId); //neighborhoodID not unique
        });

        const roomPlansWithMeetingRooms: RoomP[] = roomPlansWhereUserHasAccess.filter(roomPlan =>
            meetRoomsUserCanAccess.some(meetRoom => meetRoom.roomId === roomPlan.roomId));

        const buildings = buildingsWithRoomPlans.filter(building =>
            roomPlansWithMeetingRooms.some(roomPlan => roomPlan.buildingId === building.buildingId)
        );

        const neighborhoodsOfRoomPlans: NeighborhoodOfRoomPlan[] = neighborhoodsWithMeetRooms
            .map((nbhd: Neighborhood) => ({
                roomPlanId: nbhd.roomId,
                neighborhoodName: nbhd.name ?? nbhd.neighborhoodId,
                neighborhoodId: nbhd.neighborhoodId
            }));

        //include no neighborhood (""), if there is a meeting room without neighborhood in the room plan
        roomPlansWithMeetingRooms.forEach((roomPlan) => {
            const meetRoomsOfRoom = meetRoomsUserCanAccess.filter(meetRoom => meetRoom.roomId === roomPlan.roomId);
            const meetRoomWithoutNbhd = meetRoomsOfRoom.some(meetRoom => meetRoom.neighborhoodId === NO_NEIGHBORHOOD);
            if (meetRoomWithoutNbhd) {
                neighborhoodsOfRoomPlans.push({
                    roomPlanId: roomPlan.roomId,
                    neighborhoodName: NO_NEIGHBORHOOD,
                    neighborhoodId: NO_NEIGHBORHOOD
                });
            }
        })

        const meetingRoomsWithLevel: MeetingRoomWithLevelP[] = meetingRooms.map(meetRoom => {
            const roomPlan = roomPlansWithMeetingRooms.find(roomPlan => roomPlan.roomId === meetRoom.roomId)
            const meetRoomLevel = roomPlan?.floor ?? null;
            //TODO later when neighborhood management roles are implemented check here
            const userCanManage = (roomPlan?.managingAllowed ?? false) || intersectionNotEmpty(currentUser.admin2GetherRoles, meetRoom.roleIds ?? []);
            return {...meetRoom, level: meetRoomLevel, managingAllowed: userCanManage};
        });

        return [buildings, roomPlansWithMeetingRooms, neighborhoodsOfRoomPlans, meetingRoomsWithLevel]
    }

    useEffect(function _fetchData() {
        async function fetchData() {
            const result = await fetchAll();
            setResult(result);
        }

        setLoading(true);
        fetchData()
            .then(() => setLoading(false));
    }, [buildingList]);

    return {loading, result};
}
