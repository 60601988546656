import {Box, Button, createStyles, makeStyles, TextField, Theme, Tooltip} from "@material-ui/core";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PersonIcon from "@material-ui/icons/Person";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import React, {RefObject, useRef, useState} from "react";
import {CognitoUser} from "../hooks/useCognitoUserList";
import {DropdownList} from "react-widgets";
import useEventListener from "react-use-event-listener"
import {useTranslation} from "react-i18next";

const useStyles = makeStyles<Theme>(() => createStyles({
    btnSelected: {
        "&&": {
            backgroundColor: "#eae0dd",
            color: "#3e3938"
        }
    }
}));

interface Props {
    cognitoUserList: CognitoUser[]
    newOwner: string
    setNewOwner: (value: string) => void
    isNewOwnerRegistered: boolean
    setIsNewOwnerRegistered: (value: boolean) => void
}


const SeatConfigOwnerComponent: React.FC<Props> = (props) => {

    const {t} = useTranslation();
    const emptyUser: CognitoUser = {
        username: "",
        email: "",
        givenName: t("no_user"),
        familyName: "",
        mattermostUsername: "",
        isAdmin: false,
        roles: [],
        applicationACL: [],
        orgUnits: []
    }
    const {cognitoUserList, newOwner, setNewOwner, isNewOwnerRegistered, setIsNewOwnerRegistered} = props
    const classes = useStyles();

    const [isTwogetherUser, setIsTwogetherUser] = useState<boolean>(true)
    const [selected2GetherUser, setSelected2GetherUser] = useState<CognitoUser>(emptyUser)
    const [otherUser, setOtherUser] = useState("")
    const [isTwogetherUsersDropdownFocussed, setIsTwogetherUsersDropdownFocussed] = useState<boolean>(false)

    const dropdownRef = useRef(null)

    type Handler = (event: MouseEvent) => void

    function useOnClickOutside<T extends HTMLElement = HTMLElement>(
        ref: RefObject<T>,
        handler: Handler,
        mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
    ): void {
        useEventListener(mouseEvent, (event: MouseEvent) => {
            const el = ref?.current

            // Do nothing if clicking ref's element or descendent elements
            if (!el || el.contains(event.target as Node)) {
                return
            }

            handler(event)
        })
    }

    const handleClickOutside = () => {
        // Your custom logic here
        setIsTwogetherUsersDropdownFocussed(false)
    }

    const handleOtherUserChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        e.preventDefault()
        setOtherUser(e.target.value)
    }

    const handleCognitoUserChange = (user: CognitoUser) => {
        setSelected2GetherUser(user)
    }

    const handleCognitoUserFilter = (user: CognitoUser, value: string) => {
        //trim and split at spaces
        const searchVal = value.toLocaleLowerCase().trim().split(/ +/);
        let found = true;

        //for every word of the search string, see if it partially matches
        searchVal.forEach((word) => {
            found = found && (user.givenName.toLocaleLowerCase().includes(word)
                || user.familyName.toLocaleLowerCase().includes(word)
                || user.email.toLocaleLowerCase().includes(word))
        })
        return found;
    }

    const handleApplySeatOwner = () => {
        if (isTwogetherUser && JSON.stringify(selected2GetherUser) !== JSON.stringify(emptyUser)) {
            setNewOwner(selected2GetherUser.familyName.concat(", " + selected2GetherUser.givenName))
        } else if (!isTwogetherUser && otherUser.trim() !== "") {
            setNewOwner(otherUser.trim())
        } else {
            setNewOwner("")
        }
        setIsNewOwnerRegistered(isTwogetherUser)
    }

    const handleOwnerSourceChange = (newIsTwogetherUser: boolean) => {
        if (newIsTwogetherUser !== null) {
            setIsTwogetherUser(newIsTwogetherUser)
        }
    }

    useOnClickOutside(dropdownRef, handleClickOutside)

    const buildName = (item: CognitoUser) => {
        if (item.givenName !== "" && item.familyName !== "") {
            return item.familyName + ", " + item.givenName
        }
        return item.givenName + " " + item.familyName;
    }

    return (
        <Box style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "0.5rem 0.5rem 0rem 0.5rem",
            alignItems: "center",
        }}>
            <div style={{width: "40%"}}>
                <span>{t("owner-column")}</span><br/>
                <span style={isNewOwnerRegistered ? {color: "green"} : {color: "red"}}
                      data-testid={"owner-label"}>
                            {newOwner !== "" ? " " + newOwner : t("no_owner")}
                    </span>
            </div>
            <ToggleButtonGroup value={isTwogetherUser} exclusive={true}
                               onChange={(event, value) => handleOwnerSourceChange(value)} size={"small"}
                               data-testid={"owner-toggle"}>
                <Tooltip title={"2Gether Nutzer"}>
                    <ToggleButton value={true} style={{width: "100%"}}
                                  className={isTwogetherUser ? classes.btnSelected : ""}
                                  data-testid={"owner-toggle-twogether-user"}>
                        <PersonIcon fontSize="small"/>
                    </ToggleButton>
                </Tooltip>
                <Tooltip title={"Andere Nutzer"}>
                    <ToggleButton value={false} style={{width: "100%"}}
                                  className={!isTwogetherUser ? classes.btnSelected : ""}
                                  data-testid={"owner-toggle-other-user"}>
                        <PermIdentityIcon/>
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
            <Box style={{width: "45%", height: "80%", paddingRight: "1rem"}}>
                {isTwogetherUser ?
                    <div
                        ref={dropdownRef}>
                        <DropdownList
                            data-testid={"twogether-user-dropdown"}
                            data={[emptyUser, ...cognitoUserList]}
                            defaultValue={emptyUser}
                            open={isTwogetherUsersDropdownFocussed}
                            onClick={() => setIsTwogetherUsersDropdownFocussed(true)}
                            //onMouseLeave={() => setIsTwogetherUsersDropdownFocussed(false)}
                            dataKey="username"
                            textField="familyName"
                            renderListItem={({item}) => (
                                <span
                                    data-testid={"dropdown-option-" + item.username}>{buildName(item)}</span>
                            )}
                            renderValue={({item}) => (
                                <span>{buildName(item)}</span>
                            )}
                            value={selected2GetherUser}
                            onSelect={(user) => handleCognitoUserChange(user)}
                            filter={handleCognitoUserFilter}
                            style={{width: "100%"}}
                        />
                    </div> :
                    <TextField
                        size={"small"}
                        className={classes.txtRoomName}
                        style={{width: "100%", height: "80%"}}
                        label={t("other_user")}
                        value={otherUser}
                        onChange={handleOtherUserChange}
                        inputProps={{"data-testid": "other-user-input"}}
                        variant={"outlined"}
                    />
                }
            </Box>
            <Button
                onClick={handleApplySeatOwner}
                color={"primary"}
                variant={"contained"}
                disabled={(!isTwogetherUser && otherUser.trim() === "")}
                data-testid={"owner-apply"}
            >
                {t("apply")}
            </Button>
        </Box>
    )
}

export default SeatConfigOwnerComponent
