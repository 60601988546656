import {Auth} from "aws-amplify";
import {getAdminOrgUnit, getOrgUnit, IOrgUnit} from "../hooks/useOrgunit";

export interface User {
    jwt: string
    ID: string
    name: string
    email: string
    givenName: string
    familyName: string
    orgUnits: IOrgUnit[]
    adminOrgUnits: IOrgUnit[]
    isAdmin: boolean
    isOrgUnitAdmin: boolean,
    isMaternaEmployee: boolean,
    admin2GetherRoles: string[]
}

export default class UserClient {

    public static async getJwt(): Promise<any> {
        return await Auth.currentSession().then(session => session.getIdToken())
    }

    public static async getCurrentUser(): Promise<User> {
        const jwt = await UserClient.getJwt();
        const idTokenPayload: any = jwt.payload
        let orgUnits: IOrgUnit[] = [];
        let adminOrgUnits: IOrgUnit[] = [];
        let admin2GetherRoles = idTokenPayload["admin2getherRoles"].split(",").map((role: string) => role.trim());
        await getOrgUnit().then((_orgUnits) => orgUnits = _orgUnits, (err) => console.error(err));
        await getAdminOrgUnit().then((_orgUnits) => adminOrgUnits = _orgUnits, (err) => console.error(err));
        let isSit2GetherAdmin = false;
        let isSit2GetherOrgUnitAdmin = false;
        (idTokenPayload["cognito:groups"] as String[])
            .forEach((role) => {
                if (role === "Sit2Gether-Admin")
                    isSit2GetherAdmin = true;
                else if (role.match("^(Admin-)"))
                    isSit2GetherOrgUnitAdmin = true;
            })
        return {
            jwt: jwt.jwtToken,
            ID: idTokenPayload.sub,
            name: idTokenPayload.given_name + " " + idTokenPayload.family_name,
            email: idTokenPayload.email,
            givenName: idTokenPayload.given_name,
            familyName: idTokenPayload.family_name,
            orgUnits: orgUnits,
            adminOrgUnits: adminOrgUnits,
            isAdmin: isSit2GetherAdmin,
            isOrgUnitAdmin: isSit2GetherOrgUnitAdmin,
            isMaternaEmployee: idTokenPayload.isMaternaEmployee === "true",
            admin2GetherRoles: admin2GetherRoles
        };
    }
}