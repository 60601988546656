import React from 'react';

interface Coordinates {
    x: number,
    y: number,
}

interface Props {
    elementPosition: Coordinates
    elementShape: string
    isFiltered: boolean | undefined
}

function MeetingRoomGround(props: Props) {
    return (
        <g transform={`translate(${props.elementPosition.x}, ${props.elementPosition.y})`}
           className={props.isFiltered ? "highlighted" : "meetingRoomGround"}>
            <path
                stroke="black"
                strokeWidth="0.651"
                d={props.elementShape}
            ></path>
        </g>
    );
}

export default MeetingRoomGround;