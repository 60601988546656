import React, {useEffect, useState} from "react";
import {isGreaterThanDate} from "../../services/DateUtils";
import {useTranslation} from "react-i18next";
import {DialogTitle} from "@material-ui/core";

export interface MultiBookingDialogTitleProps {
    dateSelectedInCalendar: Date,
    greatestBookableDate: Date,
    isGreaterThanGreatestBookableDate: boolean,
    selectedSeatIsBookedOnSelectedDay: boolean
}

const MultiBookingDialogTitle: React.FC<MultiBookingDialogTitleProps> = (props) => {
    const {
        dateSelectedInCalendar,
        greatestBookableDate,
        isGreaterThanGreatestBookableDate,
        selectedSeatIsBookedOnSelectedDay
    } = props
    const [useBookOtherDaysDialogTitle, setUseBookOtherDaysDialogTitle] = useState(false)
    const {t} = useTranslation();
    useEffect(function displayWarningWhenDateIsOutOfReach() {
        if (selectedSeatIsBookedOnSelectedDay || isGreaterThanDate(dateSelectedInCalendar, greatestBookableDate) || isGreaterThanDate(dateSelectedInCalendar, greatestBookableDate))
            setUseBookOtherDaysDialogTitle(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateSelectedInCalendar, isGreaterThanGreatestBookableDate])

    return <DialogTitle>
        {useBookOtherDaysDialogTitle ?
            t("multibookingdialog_title_book_other_days") :
            t("multibookingdialog_title_normal")}
    </DialogTitle>

}

export default MultiBookingDialogTitle
