import {TextField} from "@material-ui/core";
import React, {useState} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Hint, Room} from "../../API";
import {useTranslation} from "react-i18next";
import {MeetingRoomInfoWithCapacity} from "../../types/MeetingRoomBookingListItemType";


interface Props {
    selectorLabel: string;
    onSelection: (room: Room, svgElementId: string) => void;
    hints: Hint[];
    meetingRooms: MeetingRoomInfoWithCapacity[];
    rooms: Room[]
}

interface RoomFinderDropdownElement {
    displayName: string,
    hintId: string,
    zoomSvgElementId: string,
    room: Room | undefined
}

const RoomFinderComponent: React.FC<Props> = (props) => {
    const {
        selectorLabel,
        onSelection,
        hints,
        meetingRooms,
        rooms
    } = props;

    const [inputValue, setInputValue] = useState("");
    const {t} = useTranslation();

    function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter') {
            const inputLowercase = inputValue.toLowerCase();
            const meetingRoomsByInput = meetingRooms.find(meetingRoom => meetingRoom.nameLowerCased === inputLowercase || meetingRoom.meetingRoomId.toLowerCase() === inputLowercase);
            if (meetingRoomsByInput) {
                handleChange(undefined, meetingRoomToDropdownElement(meetingRoomsByInput), "select-option");
                return;
            }
            const hintsByInput = hints.find(hint => hint.hintId.toLowerCase() === inputLowercase);
            if (hintsByInput) {
                handleChange(undefined, hintToDropdownElement(hintsByInput), "select-option");
            }
        }
    }

    function handleChange(event: any, value: RoomFinderDropdownElement | null, reason: string) {
        if (reason !== "select-option" || value === null || !value.room) {
            return;
        }
        return onSelection(value.room, value.zoomSvgElementId);
    }

    function meetingRoomToDropdownElement(meetingRoom: MeetingRoomInfoWithCapacity): RoomFinderDropdownElement {
        return {
            displayName: meetingRoom.name,
            hintId: meetingRoom.hintId,
            zoomSvgElementId: meetingRoom.meetingRoomId,
            room: rooms.find(room => room.roomId === meetingRoom.roomId)
        };
    }

    function hintToDropdownElement(hint: Hint): RoomFinderDropdownElement {
        return {
            displayName: hint.hintId,
            hintId: hint.hintId,
            zoomSvgElementId: hint.hintId,
            room: rooms.find(room => room.roomId === hint.roomId)
        };
    }

    function getOptionsByInputValue() {
        if (inputValue.length < 5) return [];

        // process meeting room for dropdown list
        const meetingRoomsByInput = meetingRooms.filter(meetingRoom => (meetingRoom.nameLowerCased.includes(inputValue.toLowerCase()) || meetingRoom.meetingRoomId.toLowerCase().includes(inputValue.toLowerCase())));
        const meetingRoomDropdownList = meetingRoomsByInput.map(meetingRoom => meetingRoomToDropdownElement(meetingRoom));

        // process normal room for dropdown list
        const hintsByInput = hints.filter(hint => hint.hintId.toLowerCase().includes(inputValue.toLowerCase()));
        // filter normal rooms by not selecting those hints which have a Büro- prefix because those are meeting room hints
        const normalRoomHints = hintsByInput.filter(hint => !hint.hintId.startsWith("Büro-"));
        const hintDropdownList = normalRoomHints.map(hint => hintToDropdownElement(hint));


        // Contains the hint IDs of all meeting rooms
        const meetingRoomDropdownListHintIds = meetingRoomDropdownList.map(room => {
            return room.hintId;
        })

        // Remove all meeting room hints from the hint list, as we want meeting room names and not their hint ids
        const hintDropdownListWithoutMeetingRooms = hintDropdownList.filter(hint => {
            return !meetingRoomDropdownListHintIds.includes(hint.hintId);
        });


        const dropdownList = [...meetingRoomDropdownList, ...hintDropdownListWithoutMeetingRooms];
        return dropdownList.sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    return (
        <Autocomplete
            filterOptions={(options, object) => options}
            options={getOptionsByInputValue()}
            noOptionsText={t("roomfinder_no_options_text")}
            getOptionLabel={(option) => option.displayName}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            value={null}
            blurOnSelect={true}
            clearOnBlur={true}
            forcePopupIcon={false}
            clearOnEscape
            onChange={handleChange}
            renderInput={(params) => (
                <TextField {...params} label={selectorLabel} variant="outlined" onKeyDown={handleKeyDown}/>
            )}
        />
    );
}

export default RoomFinderComponent;