import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';

/**
 * Info dialog component, which is used to display information to the user.
 * The title and the content of the dialog can be set via the props.
 * @param props
 * @constructor
 */

export interface InfoDialogProps {
    title: string;
    content: string;
    open: boolean;
    onClose: () => void;
    buttonText?: string;
}

function InfoDialog(props: InfoDialogProps) {
    const {open, onClose, title, content, buttonText} = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography style={{whiteSpace: "pre-line"}}>
                    {content}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}
                        color={"primary"}
                        variant={"contained"}>{buttonText ? buttonText : "OK"}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default InfoDialog;