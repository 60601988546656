import React, {useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    ClickAwayListener,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme
} from "@material-ui/core";
import {DataGrid, GridRowSelectionModel} from "@mui/x-data-grid"
import {Room, SeatConfig, UpdateSeatConfigsError} from "../API";
import SeatConfigurationComponent from "./SeatConfigurationComponent";
import {InvType} from "../Utils/Enums";
import {useInventoryList} from "../hooks/useInventoryList";
import {CognitoUser} from "../hooks/useCognitoUserList";
import {useTranslation} from "react-i18next";
import SeatMassConfigComponent, {MassConfig} from "./SeatMassConfigComponent";
import ConfirmationDialog from "./ConfirmationDialog";
import {createTheme, Skeleton, Stack, ThemeProvider} from "@mui/material";
import maternaTheme from "../styles/materna_theme";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {gql, useMutation} from "@apollo/client";
import {deleteBookingsBySeatIds, updateSeatConfigs} from "../graphql/mutations";
import {useErrorContext} from "../hooks/useErrorContext";
import {useSeatConfigsOfRoom} from "../hooks/useSeatConfigsOfRoom";
import {inventoryFilter, multiplyInventoryByOccurence} from "../Utils/InventoryUtil";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {usePermissionHelper} from "../hooks/usePermissionHelper";


interface Props {
    room: Room
    showSeatManager: boolean
    setShowSeatManager: (value: boolean) => void
    cognitoUserList: CognitoUser[]
}

const tableTheme = createTheme({
    palette: {
        primary: maternaTheme.palette.secondary,
    }
});

const useStyles = makeStyles<Theme>(() => createStyles({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        maxWidth: "200px",
        '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
            wordBreak: "break-word",
        },
        height: 'auto'
    },
}));

const nothingConfigured = {
    owner: "", isOwnerRegistered: false,
    dockingStations: [], monitors: [],
    isSeatHeightAdjustable: false, isBookable: true,
    shouldBookingsBeDeleted: false
}

const SeatManagerComponent: React.FC<Props> = (props) => {
    const {showSeatManager, setShowSeatManager, room, cognitoUserList} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        rooms,
        rerenderSeatConfigsTrigger,
        setRerenderSeatConfigsTrigger,
        setSelectedOrgUnit,
        orgUnitList
    } = useMainApplicationContext();
    const permissionHelper = usePermissionHelper();
    const [showSeatConfig, setShowSeatConfig] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [massApplState, setMassApplState] = useState<MassConfig>(nothingConfigured);
    const [rowSelectionModel, setRowSelectionModel] = useState<number[]>([]);
    const [updateSeatConfigsRequest] = useMutation(gql(updateSeatConfigs));
    const {inventories: inventoryItems} = useInventoryList();
    const {reportError} = useErrorContext();

    const {fetchAllSeatConfigs} = useSeatConfigsOfRoom();

    const [allSeats, setAllSeats] = useState<SeatConfig[]>([]);
    const [fetchAllTrigger, setFetchAllTrigger] = useState({});
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    const [deleteBookingsBySeatIdsMutation] = useMutation(gql(deleteBookingsBySeatIds));

    useEffect(() => {
        setSelectedOrgUnit(orgUnitList.find((orgUnit) => orgUnit.orgId === room.orgUnitId));
    }, [orgUnitList, room, setSelectedOrgUnit]);

    useEffect(function initialFetch() {
        if (!showSeatManager) {
            return;
        }
        setIsLoading(true);
        fetchAllSeatConfigs(room.roomId).then((seats) => {
            setAllSeats(seats.filter(seat => permissionHelper.hasManagementForSeat(seat)));
            setIsLoading(false);
        });

    }, [room, showSeatManager, fetchAllTrigger]);

    const selectedSeats = useMemo(() => {
        return rowSelectionModel.map(i => allSeats[i]);
    }, [rowSelectionModel, allSeats]);

    const currentRoomOrgUnitAdmin = useMemo(() => {
        if (allSeats.length > 0 && rooms) {
            const seatRoomId = allSeats[0].roomId;
            const matchingRoom = rooms.find(room => room.roomId === seatRoomId);

            return matchingRoom ? matchingRoom.orgUnitAdmin : null;
        }
    }, [allSeats, rooms]);

    const dataGridRows = useMemo(() => {
        return allSeats.map((seat, index) => {
            return {
                id: index, seatName: seat.seatName, seat: seat, bookable: seat.isBookable
            }
        })
    }, [allSeats]);

    function resetMassApplState() {
        setMassApplState(nothingConfigured);
    }

    function inventoryFromSelected(selectedSeat: SeatConfig, invType: InvType) {
        return inventoryItems
            .filter(invItem => selectedSeat.inventory.includes(invItem.inventoryId))
            .filter(inventoryFilter(invType, room.orgUnitId))
            .flatMap(multiplyInventoryByOccurence(selectedSeat.inventory));
    }

    function handleCloseSeatManager() {
        setShowSeatManager(false);
        setShowSeatConfig(false);
        resetMassApplState();
        setRowSelectionModel([]);
    }

    function onRowSelectionChange(newSelection: GridRowSelectionModel) {
        const rowIndices = newSelection.filter(id => typeof id === "number").map(id => id as number);
        setRowSelectionModel(rowIndices);
        if (rowIndices.length !== 1) {
            setShowSeatConfig(false);
        }
    }

    function handleStatus(status: UpdateSeatConfigsError) {
        setUpdateInProgress(false);
        if (!showSeatManager) {
            return;
        }
        switch (status) {
            case UpdateSeatConfigsError.OK:
                setFetchAllTrigger({});
                resetMassApplState();
                setRowSelectionModel([]);
                break;
            case UpdateSeatConfigsError.CONFIRMATION_REQUIRED:
                setShowConfirmationDialog(true);
                break;
            case UpdateSeatConfigsError.TRANSACTION_FAILED:
                setFetchAllTrigger({});
                reportError(new Error(t("update_seat_configs_partially_failed")), t("update_seat_configs_partially_failed"), "SeatManagerComponent updateSeatConfigs");
                break;
            default:
                //show error message, no access or invalid input or other failure
                reportError(new Error(), undefined, "SeatManagerComponent updateSeatConfigs");
                break;
        }
    }

    function onlyUnique(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }

    function massApplyConfig(confirmed: boolean) {
        const dockIds = massApplState.dockingStations.map(dockStation => dockStation.inventoryId);
        const monitorIds = massApplState.monitors.map(monitor => monitor.inventoryId);
        const inventoryIds = dockIds.concat(monitorIds);
        const neighborhoodIds = selectedSeats.map(seat => seat.neighborhoodId).filter(onlyUnique)
        const neighborhoodId = neighborhoodIds.length === 1 ? neighborhoodIds[0] : undefined;

        setUpdateInProgress(true);
        updateSeatConfigsRequest({
            variables: {
                input: {
                    roomId: room.roomId,
                    seatName: selectedSeats.map(seat => seat.seatName),
                    isSeatHeightAdjustable: massApplState.isSeatHeightAdjustable,
                    owner: massApplState.owner,
                    isOwnerRegistered: massApplState.isOwnerRegistered,
                    inventory: inventoryIds,
                    confirmed: confirmed,
                    isBookable: massApplState.isBookable,
                    neighborhoodId: neighborhoodId
                }
            }
        })
            .then((result) => {
                if (massApplState.shouldBookingsBeDeleted) {
                    deleteBookingsBySeatIdsMutation({
                        variables: {
                            input: {
                                seatIds: selectedSeats.map(selectedSeat => selectedSeat.seatName),
                                roomOrgUnitAdmin: currentRoomOrgUnitAdmin
                            }
                        }
                    }).catch((error) => reportError(error, undefined, "SeatManagerComponent deleteBookingsBySeatIds"))
                }
                handleStatus(result?.data?.updateSeatConfigs?.message ?? "");
                setRerenderSeatConfigsTrigger(!rerenderSeatConfigsTrigger);
            })
            .catch((error) => reportError(error, undefined, "SeatManagerComponent updateSeatConfigs"))
    }

    function handleApplyToSelectedButton() {
        if (massApplState.shouldBookingsBeDeleted) {
            setShowConfirmationDialog(true);
        } else {
            massApplyConfig(false);
        }
    }

    function handleClickAway(e: React.MouseEvent<Document, MouseEvent>) {
        const target = e.target;
        if (target instanceof HTMLElement && target.nodeName === "BODY") {
            return; //click on dockingstaion or monitor selector
        }
        if (!showSeatConfig && !showConfirmationDialog) {
            setRowSelectionModel([]);
        }
    }

    function afterSingleSeatConfigSave() {
        setFetchAllTrigger({})
        setRowSelectionModel([])
    }

    const buttonSingleSeatConfigDisabled = selectedSeats.length !== 1 || showSeatConfig || updateInProgress;
    const buttonApplyToSelectedDisabled = selectedSeats.length === 0 || showSeatConfig || updateInProgress;
    const showSeatConfigComponent = !updateInProgress && selectedSeats.length === 1 && showSeatConfig;
    const showMassConfigComponent = !updateInProgress && !showSeatConfigComponent

    function noRowsPlaceholder() {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                {isLoading ? <CircularProgress/> : <span>{t("no_seats_available")}</span>}
            </Stack>
        );
    }

    return (
        <>
            <ConfirmationDialog
                isOpen={showConfirmationDialog}
                setIsOpen={setShowConfirmationDialog}
                onCancel={() => {
                }}
                onOk={() => {
                    massApplyConfig(true);
                }}
            >
                <div
                    style={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                        marginBottom: '12px'
                    }}>{t("seat_config_mass_apply_confirm_overwrite")}</div>
                {massApplState.shouldBookingsBeDeleted && (
                    <div>
                        <p style={{
                            fontWeight: 'bold',
                            marginBottom: '8px'
                        }}>{t("bookings_for_these_seats_will_be_deleted")}</p>
                        <ul>
                            {selectedSeats.filter((seat: SeatConfig) => seat.isBookable).map((seat: SeatConfig) => (
                                <li key={seat.seatName}>{seat.seatName}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </ConfirmationDialog>


            <Dialog fullWidth={true} maxWidth={"lg"} style={{width: "100%", flexGrow: 1}} open={showSeatManager}
                    data-testid={"seatManager"}
                    disableEnforceFocus={true}//see https://github.com/gregnb/mui-datatables/issues/1546
            >
                <ClickAwayListener onClickAway={e => handleClickAway(e)}>
                    <div>
                        <DialogTitle>{t("seat_management_dialog-title")}</DialogTitle>
                        <Box>
                            <DialogContent style={{overflow: "visible"}}>
                                {updateInProgress ? <Skeleton width={"100%"} height={300}></Skeleton> : null}
                                {showSeatConfigComponent ? <SeatConfigurationComponent
                                    currentRoomOrgUnitAdmin={currentRoomOrgUnitAdmin}
                                    inventoryItems={inventoryItems}
                                    seat={selectedSeats[0]}
                                    setShowSeatConfiguration={setShowSeatConfig}
                                    cognitoUserList={cognitoUserList}
                                    runAfterSave={afterSingleSeatConfigSave}/> : null}
                                {showMassConfigComponent ?
                                    <SeatMassConfigComponent state={massApplState} setState={setMassApplState}
                                                             cognitoUserList={cognitoUserList}
                                                             inventoryItems={inventoryItems}/> : null}
                            </DialogContent>
                        </Box>

                        <DialogContent style={{height: 361}}>
                            {updateInProgress ? <Skeleton width={"100%"} height={"100%"}></Skeleton> :

                                <ThemeProvider theme={tableTheme}>
                                    <DataGrid
                                        sx={{
                                            "& .MuiDataGrid-cell:focus-within": {outline: "none"},
                                            "& .MuiDataGrid-columnHeader:focus-within": {
                                                outline: "none",
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle": {
                                                fontWeight: "bold",
                                            },
                                            '& .MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                            },
                                            '& .MuiDataGrid-virtualScroller': {
                                                overflowX: "hidden",
                                                overflowY: "scroll"
                                            }
                                        }}


                                        localeText={{
                                            MuiTablePagination: {
                                                labelDisplayedRows: ({from, to, count}) =>
                                                    `${from} - ${to} ${t("pagination_of")} ${count}`,
                                                labelRowsPerPage: `${t("entries_per_page")}:`,
                                            },
                                            footerRowSelected: (count) =>
                                                count !== 1
                                                    ? `${count.toLocaleString()} ${t("seats")} ${t("selected")}`
                                                    : `${count.toLocaleString()} ${t("seat")} ${t("selected")}`,
                                        }}

                                        slots={{
                                            noRowsOverlay: noRowsPlaceholder
                                        }}

                                        disableColumnFilter={true}
                                        disableColumnMenu={true}
                                        disableColumnSelector={true}
                                        checkboxSelection={true}
                                        getRowHeight={() => "auto"}
                                        rowSelectionModel={rowSelectionModel}
                                        onRowSelectionModelChange={onRowSelectionChange}

                                        rows={dataGridRows}
                                        columns={[
                                            {
                                                field: "seatName", headerName: t("seat_name-column"),
                                                sortable: false, maxWidth: 130, flex: 1
                                            },
                                            {
                                                field: "docking-stations", headerName: t("docking_station-column"),
                                                flex: 1,
                                                align: "center", headerAlign: "center",
                                                sortable: false,
                                                renderCell: (params) =>
                                                    (<div style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "center"
                                                    }}>
                                                        {inventoryFromSelected(params.row.seat, InvType.Dockingstation).map((inventoryItem, index: number) => (
                                                            <Chip key={index}
                                                                  label={inventoryItem?.name}
                                                                  className={classes.chip}


                                                            />
                                                        ))}
                                                    </div>)
                                            },
                                            {
                                                sortable: false,
                                                field: "monitors", headerName: t("monitor-column"),
                                                flex: 1,
                                                align: "center", headerAlign: "center",
                                                renderCell: (params) =>
                                                    (<div style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "center"
                                                    }}>
                                                        {inventoryFromSelected(params.row.seat, InvType.Monitor).map((inventoryItem, index: number) => (
                                                            <Chip key={index}
                                                                  label={inventoryItem?.name}
                                                                  className={classes.chip}
                                                            />
                                                        ))}
                                                    </div>)
                                            },
                                            {
                                                field: "seatOwner", headerName: t("owner-column"),
                                                maxWidth: 200,
                                                flex: 1,
                                                sortable: false,
                                                renderCell: (params) => (
                                                    <span
                                                        style={params.row.seat.isOwnerRegistered ? {color: "green"} : {color: "red"}}>
                                                    {params.row.seat.owner}
                                                </span>
                                                )
                                            },
                                            {
                                                sortable: false,
                                                maxWidth: 140,
                                                flex: 1,
                                                field: "height-adjustable", headerName: t("height-adjustable-column"),
                                                align: "center", headerAlign: "center",
                                                renderCell: (params) => {
                                                    return !!params.row.seat!.isSeatHeightAdjustable ?
                                                        <CheckCircleIcon style={{color: "green"}}/> :
                                                        <CancelIcon style={{color: "red"}}/>
                                                }
                                            },
                                            {
                                                sortable: false,
                                                maxWidth: 140,
                                                flex: 1,
                                                field: "bookable", headerName: t("bookable-column"),
                                                align: "center", headerAlign: "center",
                                                renderCell: (params) => {
                                                    return !!params.row.seat!.isBookable ?
                                                        <CheckCircleIcon style={{color: "green"}}/> :
                                                        <CancelIcon style={{color: "red"}}/>
                                                }
                                            },
                                        ]}
                                    />
                                </ThemeProvider>}
                        </DialogContent>

                        <DialogActions style={{display: "flex", justifyContent: "center"}}>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowSeatConfig(true);
                                }}
                                color={"primary"}
                                variant={"contained"}
                                data-testid={"show-seat-config-btn"}
                                disabled={buttonSingleSeatConfigDisabled}
                            >
                                {t('configure_seat')}
                            </Button>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleApplyToSelectedButton();
                                }}
                                color={"primary"}
                                variant={"contained"}
                                disabled={buttonApplyToSelectedDisabled}>
                                {t("apply_to_selected-button-text")}
                            </Button>
                            <Button
                                onClick={() => handleCloseSeatManager()}
                                color={"primary"}
                                variant={"contained"}
                                data-testid={"close-btn"}
                            >
                                {t('button_close')}
                            </Button>
                        </DialogActions>
                    </div>
                </ClickAwayListener>
            </Dialog>
        </>
    )
}


export default SeatManagerComponent

