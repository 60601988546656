import {CSSProperties} from "react";
import {useMultiBookingLegendStyles} from "../../../styles/MultiBookingLegendStyles";
import {TableCell} from "@material-ui/core";
import {TWO_DIGIT_DAY_TEMPLATE} from "./MultiBookingLegendComponent";

interface SquareLegendCellProps {
    spanStyle?: CSSProperties
    tableCellStyle?: CSSProperties
}

function SquareLegendCell({spanStyle, tableCellStyle}: SquareLegendCellProps) {
    const classes = useMultiBookingLegendStyles();
    return (
        <TableCell className={classes.legendDateDisplayTableCell}
                   style={tableCellStyle}>
                <span className={classes.legendDateDisplay}
                      style={spanStyle}>
                    {TWO_DIGIT_DAY_TEMPLATE}
                </span>
        </TableCell>);
}

export default SquareLegendCell