import TextField from "@mui/material/TextField";
import React from "react";

export function CustomTextFieldLeft(props: any) {
    return (
        <TextField {...props} data-testid={"time-picker-left"}/>
    );
}

export function CustomTextFieldRight(props: any) {
    return (
        <TextField {...props} data-testid={"time-picker-right"}/>
    );
}