import {createContext} from "react";
import {filterContextTypes} from "../types/FilterContextTypes";

const FilterContext = createContext<filterContextTypes>({
    selectedMonitor: "",
    setSelectedMonitor: () => {
    },
    selectedDockingstation: "",
    setSelectedDockingstation: () => {
    },
    showHeightAdjustableDesks: false,
    setShowHeightAdjustableDesks: () => {
    },
    seatConfigurationList: [],
    monitorOptions: [],
    dockingStationOptions: [],
    allInventories: [],

    isRoomDropdownFocussed: false,
    setIsRoomDropdownFocussed: (isRoomDropdownFocussed) => {
    },

    isOrgUnitDropdownFocused: false,
    setIsOrgUnitDropdownFocused: (isOrgUnitDropdownFocused) => {
    },
    isNeighborhoodDropdownFocused: false,
    setIsNeighborhoodDropdownFocused : () =>{

    },

    isBuildingDropdownFocused: false,
    setIsBuildingDropdownFocused: () => {
    }
})

export default FilterContext