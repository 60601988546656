import {FormControl, InputLabel, NativeSelect} from "@material-ui/core";
import {IOrgUnit} from "../../hooks/useOrgunit";
import React, {useEffect, useState} from "react";
import {Building, Room} from "../../API";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {useTranslation} from "react-i18next";
import {usePermissionHelper} from "../../hooks/usePermissionHelper";

interface Props {
    buildings: Building[]
    showMeetingRoomEquipmentManager: boolean
    setSelectedBuilding: (building: Building) => void
}

const MeetingRoomEquipmentManagerBuildingDropdown: React.FC<Props> = (props) => {
    const {buildings, showMeetingRoomEquipmentManager, setSelectedBuilding: setSelectedBuildingInManager} = props;
    const [buildingsToManage, setBuildingsToManage] = useState<Building[]>([]);
    const [selectedBuilding, setSelectedBuilding] = useState<Building | undefined>(undefined);
    const permissionHelper = usePermissionHelper();

    useEffect(() => {
        if (showMeetingRoomEquipmentManager) {
            setBuildingsToManage(buildings.filter(building => {
                return permissionHelper.hasManagementForBuilding(building);
            }));
            setSelectedBuilding(buildingsToManage[0])
        }
    }, [showMeetingRoomEquipmentManager]);

    useEffect(() => {
        setSelectedBuilding(buildingsToManage[0])
    }, [buildingsToManage]);

    useEffect(() => {
        if(selectedBuilding) {
            setSelectedBuildingInManager(selectedBuilding)
        }
    }, [selectedBuilding]);

    const {t} = useTranslation();

    return (
        <FormControl style={{width: "100%"}}>
            <NativeSelect
                value={selectedBuilding ? selectedBuilding.buildingName : ""}
                onChange={(event) => {
                    let newSelectedBuildingName = event.target.value;
                    let newSelectedBuilding = buildingsToManage.find(building => building.buildingName === newSelectedBuildingName)!
                    setSelectedBuilding(newSelectedBuilding)
                    setSelectedBuildingInManager(newSelectedBuilding)
                }}
            >
                {buildingsToManage.map((building: Building) => {
                    return <option
                        key={building.buildingId}
                        value={building.buildingName}>
                        {building.buildingName}
                    </option>
                })}
            </NativeSelect>

        </FormControl>
    )
}

export default MeetingRoomEquipmentManagerBuildingDropdown