import React, {Dispatch, SetStateAction} from 'react'

interface Coordinates {
    x: number,
    y: number
}

type Props = {
    elementPosition: Coordinates
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

export default function Chair({elementPosition, setMouseOver}: Props) {

    const onMouseEnter = () => {
        setMouseOver(true);
    }
    const onMouseLeave = () => {
        setMouseOver(false);
    }

    return (

        <g id="chair" transform={`translate(${elementPosition.x}, ${elementPosition.y})`} onMouseOver={onMouseEnter}
           onMouseOut={onMouseLeave}>
            <path
                stroke="black"
                strokeWidth="0.651"
                d="M12.93 13.192h1.775l.897-.214h.878l.877.214.449.449v.663l-.449.663-.429.449-.663.214-1.112.234h-1.111l-1.541.215h-1.775l-2.887-.215-2.203-.234-1.327-.663-.234-.215-.214-.448.214-.663.449-.449.878-.214 1.56.214h1.775v-1.326H6.962l-.897-.215-.878-.448-.449-.663-.214-.663V8.102H3.198v1.775l-.449.663-.448.234-.664.214-.429-.214-.448-.234-.215-.215-.234-.448V3.46l.234-.448.215-.215.448-.449h1.093l.448.449.449.663v1.99h1.326v-.878l.214-.663.235-.897 1.326-1.327L8.074.808l2.652-.449 2.867.449 2.009.877 1.092 1.327.449 1.56v.877h1.326V3.46l.234-.449.215-.214.448-.449h1.093l.448.449.448.214v7.315l-.448.214-.449.234-.429.214-.663-.214-.448-.234-.215-.215-.234-.448V8.102h-1.326v1.775l-.215.663-.448.663-.878.448-.897.215H12.93v1.326zm-8.406-5.09V5.449zM3.198 5.449v2.653zm15.27 2.653V5.449zm-1.325-2.653v2.653zm-8.406 7.743h4.193zm4.193-1.326H8.737z"
            ></path>
        </g>


    )
}