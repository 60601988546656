import {Building, Room} from "../API";
import {comparatorAlphanumericValues, reformatNeighborhoodId} from "./Helpers";
import {generateSelectAllOption, generateSingleSelectOptions} from "./GenericSelectorUtil";
import {NeighborhoodOfRoomPlan} from "../hooks/useMeetRoomBkngSelectorData";
import {MeetingRoomWithLevelP} from "../types/PermissionHandling";

export interface SelectItem<T> {
    label: string
    element: T
}

export interface SelectorOptions<T> {
    options: SelectItem<T[]>[],
    defaultSelection: SelectItem<T[]>,
    defaultSelectionIndex: number
}

export function getBuildingDefaultOption(options: SelectItem<Building[]>[], idOfDefaultBuilding: string | null): {
    option: SelectItem<Building[]>, index: number
} {
    let index = -1;
    if (idOfDefaultBuilding !== null) {
        index = options.findIndex(option => option.element.length === 1 && option.element[0].buildingId === idOfDefaultBuilding);
    }
    index = index === -1 ? 0 : index;
    return {option: options[index], index: index};
}

export function getBuildingOptions(buildings: Building[], idOfDefaultBuilding: string | null): SelectorOptions<Building> | undefined {
    if (buildings.length === 0) {
        return undefined;
    }
    const buildingsCopy = [...buildings];
    buildingsCopy.sort((a, b) => comparatorAlphanumericValues(a.buildingName, b.buildingName))
    const options = generateSingleSelectOptions(buildingsCopy,
        building => building.buildingName);
    const defaultOption = getBuildingDefaultOption(options, idOfDefaultBuilding);
    return {options: options, defaultSelection: defaultOption.option, defaultSelectionIndex: defaultOption.index};
}

export function getRoomPlanDefaultOption(options: SelectItem<Room[]>[]): {
    option: SelectItem<Room[]>, index: number
} {
    //search for select all option
    let index = options.findIndex(option => option.element.length > 1);
    index = index === -1 ? 0 : index;
    return {option: options[index], index: index};
}

export function getRoomPlanOptions(roomPlans: Room[], labelSelectAll: string): SelectorOptions<Room> | undefined {
    if (roomPlans.length === 0) {
        return undefined;
    }
    const roomPlansCopy = roomPlans;
    roomPlansCopy.sort((a, b) => comparatorAlphanumericValues(a.name, b.name));
    const singleSelectOptions = generateSingleSelectOptions(roomPlansCopy,
        roomPlan => roomPlan.name);
    const selectAllOption = generateSelectAllOption(roomPlansCopy, labelSelectAll);
    const options = (roomPlansCopy.length === 1) ? singleSelectOptions : [selectAllOption, ...singleSelectOptions]
    const defaultOption = getRoomPlanDefaultOption(options);
    return {options: options, defaultSelection: defaultOption.option, defaultSelectionIndex: defaultOption.index};
}

export function getNeighborhoodOptions(neighborhoods: NeighborhoodOfRoomPlan[],
                                       labelSelectAll: string,
                                       labelNoNeighborhood: string)
                                        : SelectorOptions<NeighborhoodOfRoomPlan> | undefined {
    if (neighborhoods.length === 0) {
        return undefined;
    }
    const neighborhoodsCopy = neighborhoods;
    neighborhoodsCopy.sort((a, b) => comparatorAlphanumericValues(a.neighborhoodName, b.neighborhoodName));
    let singleSelectOptions = generateSingleSelectOptions(neighborhoodsCopy,
        neighborhood => neighborhood.neighborhoodName === "" ? labelNoNeighborhood : reformatNeighborhoodId(neighborhood.neighborhoodName));
    const condensedNoNeighborhoodOptions: {
        label: string,
        element: NeighborhoodOfRoomPlan[]
    } = {
        label: "-",
        element: []
    }

    singleSelectOptions.forEach(option => {
        if (option.label === "–") {
            condensedNoNeighborhoodOptions.element.push(...option.element);
        }
    })
    singleSelectOptions = condensedNoNeighborhoodOptions.element.length > 1
        ? [condensedNoNeighborhoodOptions, ...singleSelectOptions.filter(option => option.label !== "–")]
        : singleSelectOptions;
    const selectAllOption = generateSelectAllOption(neighborhoodsCopy, labelSelectAll);
    const options = (neighborhoodsCopy.length === 1) ? singleSelectOptions : [selectAllOption, ...singleSelectOptions]
    return {options: options, defaultSelection: options[0], defaultSelectionIndex: 0};
}


export function getRoomPlansOfBuilding(roomPlans: Room[], building: Building | undefined) {
    if (building === undefined) {
        return [];
    }
    return roomPlans.filter(roomPlan => roomPlan.buildingId === building.buildingId);
}

export function getNeighborhoodsOfRoomPlans(neighborhoods: NeighborhoodOfRoomPlan[], roomPlans: Room[] | undefined) {
    if (roomPlans === undefined) {
        return [];
    }
    return neighborhoods
        .filter(nbhd => roomPlans
            .find(roomPlan => roomPlan.roomId === nbhd.roomPlanId) !== undefined);
}

export function getMeetRoomsOfNeighborhoods(meetRooms: MeetingRoomWithLevelP[], neighborhoods: NeighborhoodOfRoomPlan[]) {
    return meetRooms
        .filter(meetRoom =>
            neighborhoods
                .some(nbhd =>
                    nbhd.neighborhoodId === meetRoom.neighborhoodId &&
                    nbhd.roomPlanId === meetRoom.roomId)
        );
}

