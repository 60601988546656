import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {Building, MeetingRoom, Neighborhood, Room, SeatConfig} from "../API";
import {intersectionNotEmpty} from "../Utils/Helpers";
import {User} from "../services/UserClient";

interface PermissionHelperProps {
    currentUser: User,
    isLoading: boolean,
    rooms: Room[],
    buildingList: Building[],
    neighborhoods: Neighborhood[]
}

export function usePermissionHelper(props?: PermissionHelperProps) {
    let {currentUser, isLoading, rooms, buildingList, neighborhoods} = useMainApplicationContext();
    if (props) {
        currentUser = props.currentUser;
        isLoading = props.isLoading;
        rooms = props.rooms;
        buildingList = props.buildingList;
        neighborhoods = props.neighborhoods;
    }
    const roles = currentUser.admin2GetherRoles;
    const isAdmin = currentUser.isAdmin;

    const isInvalid = (): boolean => {
        return !currentUser || isLoading || (!isAdmin && !roles.length);

    }

    const hasAnyBuildingManagement = (): boolean => {
        if (isInvalid()) return false;
        if (isAdmin) return true;

        for (const building of buildingList) {
            if (hasManagementForBuilding(building)) {
                return true;
            }
        }

        return false;
    }

    const hasBuildingManagementForRoom = (roomId: string): boolean => {
        if (isInvalid()) return false;
        if (isAdmin) return true;

        const roomByRoomId = rooms.find(room => room.roomId === roomId);
        if (!roomByRoomId) return false;

        return buildingList.some(building =>
            hasManagementForBuilding(building) && building.buildingId === roomByRoomId.buildingId
        );
    };


    const hasManagementForBuilding = (building: Building): boolean => {
        if (isInvalid() || !building) return false;
        if (isAdmin) return true;
        return intersectionNotEmpty(building.roleIds, roles);
    }

    const hasManagementForBuildingById = (buildingId: string): boolean => {
        if (isInvalid() || !buildingId) return false;
        const building = buildingList.filter(building => building.buildingId === buildingId)[0]
        return building && hasManagementForBuilding(building)

    }

    const hasAnyRoomManagement = (): boolean => {
        if (isInvalid()) return false;
        if (isAdmin) return true;
        if (hasAnyBuildingManagement()) {
            return true
        }
        //TODO LATER implement roles for rooms once available
        return false;
    }
    const hasAccessToNeighborhoodById = (neighborhoodId: string, roomId: string) => {
        const neighborhood = neighborhoods.filter((neighborhood) =>
            neighborhood.neighborhoodId === neighborhoodId && neighborhood.roomId === roomId)[0]
        if (!neighborhood) return false;
        return !neighborhood.restricted || intersectionNotEmpty(neighborhood.roleIds ?? [], roles) || intersectionNotEmpty(neighborhood.adminRoleIds ?? [], roles)
    }
    const hasManagementForRoom = (room: Room): boolean => {
        if (isInvalid() || !room) return false;
        if (isAdmin) return true;
        if (hasManagementForBuildingById(room.buildingId!)) return true;
        //TODO LATER implement check for room role once available
        return hasManagedNeighborhoodInRoom(room);
    }

    const hasManagementForRoomById = (roomId: string): boolean => {
        if (isInvalid() || !roomId) return false;
        const room = rooms.filter(room => room.roomId === roomId)[0]
        return room && hasManagementForRoom(room)
    }

    const hasAnyNeighborhoodManagement = (): boolean => {
        if (isInvalid()) return false;
        if (isAdmin || hasAnyBuildingManagement()) return true
        //TODO LATER implement check if management rights for room exist once available
        for (const neighborhood of neighborhoods) {
            if (neighborhood.adminRoleIds && intersectionNotEmpty(neighborhood.adminRoleIds, roles)) return true
        }

        return false
    }

    const hasManagementForNeighborhood = (neighborhood: Neighborhood): boolean => {
        if (isInvalid() || !neighborhood) return false;
        if (isAdmin || hasBuildingManagementForRoom(neighborhood.roomId)) return true;
        return intersectionNotEmpty(neighborhood.adminRoleIds ?? [], roles);
    }

    const hasAccessForNeighborhood = (neighborhood: Neighborhood): boolean => {
        if (isInvalid() || !neighborhood) return false;
        if (isAdmin || hasBuildingManagementForRoom(neighborhood.roomId)) return true;
        return !neighborhood.restricted
            || intersectionNotEmpty(neighborhood.roleIds ?? [], roles)
            || intersectionNotEmpty(neighborhood.adminRoleIds ?? [], roles);
    }

    const hasManagementForNeighborhoodByIdAndRoomId = (roomId: string, neighboorhoodId: string): boolean => {
        if (isInvalid() || !roomId || !neighboorhoodId) return false;
        const neighborhood = neighborhoods
            .find(neighborhood =>
                neighborhood.roomId === roomId &&
                neighborhood.neighborhoodId === neighboorhoodId);
        return !!neighborhood && hasManagementForNeighborhood(neighborhood);
    }

    function hasAnyMeetingRoomManagement(): boolean {
        if (isInvalid()) return false;
        return true;
    }

    const hasManagementForMeetingRoom = (meetingRoom: MeetingRoom): boolean => {
        if (isInvalid()) return false;
        if (isAdmin || hasBuildingManagementForRoom(meetingRoom.roomId)) {
            return true;
        }
        if (intersectionNotEmpty(meetingRoom.roleIds || [], roles)) return true;
        return !!meetingRoom.neighborhoodId && hasManagementForNeighborhoodByIdAndRoomId(meetingRoom.roomId, meetingRoom.neighborhoodId);
    }

    function hasManagedNeighborhoodInRoom(room: Room): boolean {
        return hasManagedNeighborhoodInRoomById(room.roomId);
    }

    const hasManagedNeighborhoodInRoomById = (roomId: string): boolean => {
        if (isInvalid()) return false
        const neighborhoodsInRoom = neighborhoods.filter(neigh => neigh.roomId === roomId);
        return neighborhoodsInRoom.some(neigh => neigh.adminRoleIds && intersectionNotEmpty(neigh.adminRoleIds, roles));
    }

    function hasManagedNeighborhoodInBuilding(building: Building): boolean {
        return hasManagedNeighborhoodInBuildingById(building.buildingId);
    }

    const hasManagedNeighborhoodInBuildingById = (buildingId: string): boolean => {
        if (isInvalid()) return false

        const managedNeighborhoods = neighborhoods.filter(neigh => intersectionNotEmpty(neigh.adminRoleIds || [], roles))
        const roomsWithManagedNeighborhoods = rooms.filter(room => managedNeighborhoods.some(neigh => neigh.roomId === room.roomId))
        const buildingsWithManagedNeighborhoods = buildingList.filter(building => roomsWithManagedNeighborhoods.some(room => room.buildingId === building.buildingId))

        return buildingsWithManagedNeighborhoods.some(building => building.buildingId === buildingId)
    }

    const hasManagementForSeat = (seat: SeatConfig): boolean => {
        if (isInvalid()) return false;
        if (isAdmin || hasBuildingManagementForRoom(seat.roomId)) return true;
        return !!seat.neighborhoodId && hasManagementForNeighborhoodByIdAndRoomId(seat.roomId, seat.neighborhoodId);
    }

    return {
        hasAnyBuildingManagement,
        hasManagementForBuilding,
        hasManagementForBuildingById,
        hasAnyRoomManagement,
        hasManagementForRoom,
        hasManagementForRoomById,
        hasAnyNeighborhoodManagement,
        hasManagementForNeighborhood,
        hasManagementForNeighborhoodByIdAndRoomId,
        hasAccessForNeighborhood,
        hasManagedNeighborhoodInRoom,
        hasManagedNeighborhoodInRoomById,
        hasManagedNeighborhoodInBuilding,
        hasManagedNeighborhoodInBuildingById,
        hasManagementForSeat,
        hasManagementForMeetingRoom,
        hasAccessToNeighborhoodById,
    }
};
