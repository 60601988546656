export function generateSimpleSingleSelectOptions<T>(items: T[], displayName: (item: T) => string): ({
    element: T,
    label: string
})[] {
    return items.map(item => {
        return {element: item, label: displayName(item)};
    });
}

export function generateSimpleSingleSelectOptionsWithNoneOption<T>(items: T[], displayName: (item: T) => string, labelNone: string): ({
    element: T | undefined,
    label: string
})[] {
    const singleSelectOptions = generateSimpleSingleSelectOptions(items, displayName);
    const selectNone = {
        element: undefined,
        label: labelNone
    }
    return [selectNone, ...singleSelectOptions];
}

export function generateSingleSelectOptions<T>(items: T[], displayName: (item: T) => string): ({
    element: T[],
    label: string
})[] {
    return items.map(item => {
        return {element: [item], label: displayName(item)};
    });
}

export function generateSelectAllOption<T>(items: T[], labelSelectAll: string): ({
    element: T[],
    label: string
}) {
    return {
        element: items,
        label: labelSelectAll
    }
}

export function generateSelectorOptionsWithSelectAll<T>(items: T[], displayName: (item: T) => string, labelSelectAll: string): ({
    element: T[],
    label: string
})[] {
    const singleSelectOptions = generateSingleSelectOptions(items, displayName);
    const selectAllOption = generateSelectAllOption(items, labelSelectAll);
    return [selectAllOption, ...singleSelectOptions];
}