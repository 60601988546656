import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {User} from "../services/UserClient";
import {Inventory} from "../API";
import {comparatorAlphanumericValues} from "../Utils/Helpers";

export interface CognitoUser {
    username: string
    email: string
    givenName: string
    familyName: string
    mattermostUsername: string
    isAdmin: boolean
    roles: string[]
    applicationACL: string[]
    orgUnits: any[]
}

export function useCognitoUserList(currentUser: User) {
    const [userList, setUserList] = useState<CognitoUser[]>([]);
    const [errors, setErrors] = useState<any[]>([]);
    const userRequestUrl = process.env.REACT_APP_COGNITO_USER_ENDPOINT!;

    const getUsers = async () => {
        const cognitoUserSession = await Auth.currentSession();
        const response = await fetch(userRequestUrl, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + cognitoUserSession.getAccessToken().getJwtToken(),
            },
        });

        return response.json();
    };

    useEffect(() => {
        if (currentUser.isAdmin || currentUser.isOrgUnitAdmin) {
            getUsers()
                .then((data) => {
                    // Sort the data before updating the state
                    const sortedUserList = data.sort(comparator);
                    setUserList(sortedUserList);
                })
                .catch((err) => setErrors(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    function comparator(user1: CognitoUser, user2: CognitoUser) {
        let result = comparatorAlphanumericValues(user1.familyName, user2.familyName)
        if (result === 0) result = comparatorAlphanumericValues(user1.givenName, user2.givenName)
        return result
    }

    return { userList, errors };
}