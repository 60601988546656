import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, createStyles, makeStyles, Typography} from "@material-ui/core";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ToggleButton from "@mui/material/ToggleButton";
import AdjustableTableIcon from "../styles/AdjustableTableIcon";
import {DropdownList} from "react-widgets";
import {Inventory} from "../API";
import {InvType} from "../Utils/Enums";
import shadows from "../styles/shadows";
import uiElementMeasures from "../styles/inputElementMeasures";
import borders from "../styles/borders";
import colors from "../styles/colors";
import {useFilterContext} from "../hooks/useFilterContext";
import {useTranslation} from "react-i18next";

interface Props {
    hasDockingStations: boolean
    hasMonitors: boolean
    hasHeightAdjustableDesk: boolean
    dockingStationListItems: Inventory[]
    monitorListItems: Inventory[]
}

const CollapsibleFilterComponent: React.FC<Props> = (props) => {
    const {
        hasDockingStations,
        hasMonitors,
        hasHeightAdjustableDesk,
        dockingStationListItems,
        monitorListItems,
    } = props

    const {
        selectedMonitor,
        setSelectedMonitor,
        selectedDockingstation,
        setSelectedDockingstation,
        showHeightAdjustableDesks,
        setShowHeightAdjustableDesks
    } = useFilterContext()

    const [isDockingstationDropdownFocussed, setIsDockingstationDropdownFocussed] = useState(false)
    const [isMonitorDropdownFocussed, setIsMonitorDropdownFocussed] = useState(false)

    const useStyles = makeStyles(() =>
        createStyles({
            filterDescription: {
                textAlign: 'left',
                fontSize: '16px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0px',
                lineHeight: '24px',
                marginBottom: uiElementMeasures.marginBetweenElementsInColumn,
            },
            filterHeader: {
                "&.MuiAccordionSummary-root": {
                    paddingLeft: '0.857em',
                    paddingRight: '4px'
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    marginTop: '12px',
                    marginBottom: '12px'
                },
                "&.MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: '48px'
                }
            },
            toggleButton: {
                width: "100%",
                "&.MuiButtonBase-root": {
                    minHeight: "38px",
                    marginBottom: "10px",
                    boxShadow: shadows.innerInputElementShadow,
                    borderRadius: borders.mediumBorderRadius
                }
            },
            accordionBaseStyle: {
                border: borders.innerInputElementBorder,
                backgroundColor: "rgba(245, 245, 246, 1)",
                boxShadow: shadows.innerInputElementShadow,
                "&.MuiAccordion-rounded:first-child": {
                    borderTopLeftRadius: borders.mediumBorderRadius,
                    borderTopRightRadius: borders.mediumBorderRadius
                },
                "&.MuiAccordion-rounded:last-child": {
                    borderBottomLeftRadius: borders.mediumBorderRadius,
                    borderBottomRightRadius: borders.mediumBorderRadius
                }
            },
            accordionDetails: {
                flexDirection: "column",
                paddingTop: "0px",
                paddingBottom: "0px",
                paddingLeft: "10px",
                paddingRight: "10px"
            }
        })
    );
    const classes = useStyles();

    const handleSelectInventory = (inv: Inventory) => {
        if (inv.type === InvType.Dockingstation) {
            setSelectedDockingstation(inv.inventoryId)
        } else if (inv.type === InvType.Monitor) {
            setSelectedMonitor(inv.inventoryId)
        }
    }

    function handleCloseFilters() {
        setIsDockingstationDropdownFocussed(false)
        setIsMonitorDropdownFocussed(false)
    }

    const {t} = useTranslation();
    return (
        <Accordion className={classes.accordionBaseStyle}>
            <AccordionSummary
                className={classes.filterHeader}
                expandIcon={<KeyboardArrowDown fontSize={"small"} style={{color: colors.iconAndTextGrey}}/>}
                data-testid={"dropdown-summary"}
            >
                <Typography style={{color: 'rgba(128,128,144,1)'}}>
                    Filter
                </Typography>
            </AccordionSummary>
            <ClickAwayListener onClickAway={() => handleCloseFilters()}>
                <AccordionDetails className={classes.accordionDetails}>
                    {hasDockingStations &&
                        <DropdownList
                            selectIcon={<KeyboardArrowDown fontSize={"small"} style={{color: colors.iconAndTextGrey}}/>}
                            className={classes.filterDescription}
                            data={dockingStationListItems}
                            open={isDockingstationDropdownFocussed}
                            dataKey='inventoryId'
                            textField='name'
                            placeholder={t("docking_station-column")}
                            value={selectedDockingstation}
                            onSelect={(selection) => {
                                handleCloseFilters()
                                handleSelectInventory(selection)
                            }}
                            onClick={() => setIsDockingstationDropdownFocussed(!isDockingstationDropdownFocussed)}
                            onMouseLeave={() => setIsDockingstationDropdownFocussed(false)}
                            data-testid="docking-station-select-list"
                            dropUp
                        />
                    }
                    {hasMonitors &&
                        <DropdownList
                            selectIcon={<KeyboardArrowDown fontSize={"small"} style={{color: colors.iconAndTextGrey}}/>}
                            className={classes.filterDescription}
                            data={monitorListItems}
                            open={isMonitorDropdownFocussed}
                            dataKey='inventoryId'
                            textField='name'
                            placeholder={t("monitor-column")}
                            value={selectedMonitor}
                            onSelect={(selection) => {
                                handleCloseFilters()
                                handleSelectInventory(selection)
                            }}
                            onClick={() => setIsMonitorDropdownFocussed(!isMonitorDropdownFocussed)}
                            onMouseLeave={() => setIsMonitorDropdownFocussed(false)}
                            data-testid="monitor-select-list"
                            dropUp
                        />
                    }
                    {hasHeightAdjustableDesk &&
                        <ToggleButton className={classes.toggleButton}
                                      value={showHeightAdjustableDesks}
                                      selected={showHeightAdjustableDesks}
                                      onChange={() => {
                                          setShowHeightAdjustableDesks(!showHeightAdjustableDesks)
                                      }}
                                      data-testid="height-adjustable-toggle-button"
                        >
                            <AdjustableTableIcon fontSize="large"/>
                        </ToggleButton>
                    }
                </AccordionDetails>
            </ClickAwayListener>
        </Accordion>
    )
}
export default CollapsibleFilterComponent
