import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import colors from "../../styles/colors";
import {BookingFor} from "../../Utils/Enums";
import React from "react";
import {DropdownList} from "react-widgets";
import {makeStyles} from "@material-ui/core";
import uiElementMeasures from "../../styles/inputElementMeasures";
import borders from "../../styles/borders";
import {useTranslation} from "react-i18next";

interface Props {
    bookingForState: BookingFor,
    setBookingForState: (state: BookingFor) => void
}

const MultiBookingDialogDropdown: React.FC<Props> = (props) => {
    const {
        bookingForState,
        setBookingForState
    } = props
    const {t} = useTranslation();
    const bookingForOptions = [BookingFor.BOOKING_FOR_ME, BookingFor.BOOKING_FOR_OTHERS]

    const useStyles = makeStyles({
        leftSideChild: {
            marginBottom: uiElementMeasures.marginBetweenElementsInColumn,
            width: "auto",
            color: colors.iconAndTextGrey,
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: borders.mediumBorderRadius,
                },
            },
        }
    })

    const classes = useStyles()

    return <>
        <DropdownList
            data-testid={"booking-for-dropdown"}
            data={bookingForOptions}
            value={bookingForState}
            selectIcon={<KeyboardArrowDown fontSize={"small"} style={{color: colors.iconAndTextGrey}}/>}
            className={classes.leftSideChild}
            dropUp
            filter={false}
            renderListItem={({item}) => (
                <span
                    data-testid={"dropdown-option-" + item}>{(item === BookingFor.BOOKING_FOR_ME) ? t("multibookingdialog_dropdown_booking_for_me") : t("multibookingdialog_dropdown_booking_for_others")}</span>
            )}
            renderValue={({item}) => (
                <span>{(item === BookingFor.BOOKING_FOR_ME) ? t("multibookingdialog_dropdown_booking_for_me") : t("multibookingdialog_dropdown_booking_for_others")}</span>
            )}
            onSelect={val => setBookingForState(val)}
        />
    </>
}

export default MultiBookingDialogDropdown
