import {gql, useQuery} from "@apollo/client";
import {getBookingConfig} from "../graphql/queries";
import {useEffect} from "react";
import {onCreateBookingConfig, onUpdateBookingConfig} from "../graphql/subscriptions";

const defaultBookingConfig = {
    maxDaysInAdvance: 300,
    maxBookableDays: 300
}

export function useBookingLimitation(orgUnitId: string) {

    const {data} = useQuery(gql(getBookingConfig), {
        variables: {
            orgUnitId: orgUnitId
        },
        skip: orgUnitId === ""
    })

    if (data?.getBookingConfig) {
        return data.getBookingConfig
    } else {
        return defaultBookingConfig;
    }
}