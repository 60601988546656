import {
    Button,
    Checkbox,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import {t} from "i18next";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import {Building, Room} from "../../API";
import {comparatorAlphanumericValues} from "../../Utils/Helpers";
import {IOrgUnit} from "../../hooks/useOrgunit";

interface Props {
    assignedRooms : Room[]
    setAssignedRooms : (value: Room[]) => void
    availableRooms : Room[]
    setAvailableRooms : (value: Room[]) => void
    selectedOrgUnit:IOrgUnit
    selectedBuilding: Building
    orgUnitList: IOrgUnit[]
}

const ManageBuildingAvailableRoomsComponent: React.FC<Props> = (props) => {
    const {assignedRooms, setAssignedRooms, availableRooms,
        setAvailableRooms, selectedOrgUnit, selectedBuilding} = props;
    const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);
    const sortRoomsListByName = (rooms: Room[]) => {
        return rooms.sort((roomOne: Room | null, roomTwo: Room | null) => {
            const nameOne = roomOne!.nameLowerCased;
            const nameTwo = roomTwo!.nameLowerCased
            return comparatorAlphanumericValues(nameOne, nameTwo);
        })
    };

    const assignRoom = () => {

        // @ts-ignore
        const roomWithNewBuildingId: Room = {
            ...selectedRoom,
            buildingId: selectedBuilding.buildingId
        };

        const newAssignedRooms = [...assignedRooms, roomWithNewBuildingId];
        const newAvailableRooms = availableRooms.filter(room => room?.roomId !== selectedRoom?.roomId);

        setAssignedRooms(newAssignedRooms);
        setAvailableRooms(sortRoomsListByName(newAvailableRooms));
        setSelectedRoom(null);
    }

    const handleFloorInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, room: Room | null) => {
        const input = Number(event.target.value);
        if (typeof Number(input)) {
            const updatedRoom = {
                ...room!,
                floor: input,
            };
            setSelectedRoom(updatedRoom);
            const updatedAvailableRooms = availableRooms.map(room => {
                if (room?.roomId === updatedRoom.roomId) {
                    return updatedRoom;
                }
                return room;
            });
            setAvailableRooms(updatedAvailableRooms);
        }
    };

    return (
        <Box>
            <TableContainer style={{ maxHeight: "440px", margin: "25px 0px",tableLayout: "fixed"}}>
                <Table stickyHeader data-testid={"room-manager-table"} style={{ margin: "0px", maxWidth: "100%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell data-testid={"building-room-plan-name-floor-column"}>
                                {t("building-room-plan-name-column")}
                            </TableCell>
                            <TableCell data-testid={"building-room_active-column"}>
                                {t("building-room_active-column")}
                            </TableCell>
                            <TableCell data-testid={"building-room_public-active"}>
                                {t("building-room_public-active")}
                            </TableCell>
                            <TableCell data-testid={"building-floor-column"}>
                                {t("building-floor-column")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-testid={"building-list"}>

                        {availableRooms !== undefined && sortRoomsListByName(availableRooms!).map((room: Room | null, index: number) => {

                            if (room!.orgUnitId === selectedOrgUnit.orgId) {
                                return (<TableRow
                                    data-testid={`room-${room!.roomId}`}
                                    onClick={() => setSelectedRoom(room!)}
                                    key={"row-" + room!.roomId}
                                    selected={availableRooms![index] === selectedRoom}
                                >
                                    <TableCell
                                        style={{maxWidth: "150px", wordBreak: "break-word"}}>
                                        {room!.name}
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            name={"isActive"}
                                            checked={room!.isActive ?? false}
                                            disabled={true}
                                            data-testid={`room_active-checkbox-test-${room!.roomId}`}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            name={"isPublic"}
                                            checked={room!.isPublic ?? false}
                                            disabled={true}
                                            data-testid={`room_public-checkbox-test-${room!.roomId}`}
                                        />
                                    </TableCell>
                                    <TableCell id={room!.roomId + "-floor-cell"} data-testid={room!.roomId + "-floor-cell"}>
                                        <TextField
                                            id={room!.roomId + "-floor"}
                                            data-testid={room!.roomId + "-floor"}
                                            label="Number"
                                                type="number"
                                            onChange={(event) => handleFloorInput(event, room)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            placeholder={room!.floor?.toString()}
                                        />
                                    </TableCell>
                                </TableRow>)
                            }
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    onClick={() => assignRoom()}
                    color={"primary"}
                    variant={"contained"}
                    disabled={!(!!selectedRoom)}
                    data-testid={"manage-building-assign-btn"}
                >
                    {t("building-assign-button")}
                </Button>
            </DialogActions>
        </Box>
    );
};

export default ManageBuildingAvailableRoomsComponent;