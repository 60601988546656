import {Inventory} from "../API";
import {useEffect} from "react";

export function useUpdateIsFiltered(selectedMonitor: string | null | undefined, selectedDockingstation: string | null | undefined, monitorList: Inventory[], dockingList: Inventory[], setIsFiltered: (value: (((prevState: boolean) => boolean) | boolean)) => void, showHeightAdjustableDesks: boolean, isHeightAdjustable: boolean) {
    useEffect(function updateIsFilter() {
        function isMonitorFilterSet(): boolean {
            return selectedMonitor !== null && selectedMonitor !== undefined && selectedMonitor !== "";
        }

        function isDockingstationFilterSet(): boolean {
            return selectedDockingstation !== null && selectedDockingstation !== undefined && selectedDockingstation !== "";
        }

        function isMonitorFiltered(): boolean {
            return monitorList.some(monitor => monitor.inventoryId === selectedMonitor);
        }

        function isDockingstationFiltered(): boolean {
            return dockingList.some(dock => dock.inventoryId === selectedDockingstation);
        }

        setIsFiltered(() => {
            if (isMonitorFilterSet() && isDockingstationFilterSet() && showHeightAdjustableDesks)
                return isMonitorFiltered() && isDockingstationFiltered() && isHeightAdjustable;
            if (isMonitorFilterSet() && isDockingstationFilterSet())
                return isMonitorFiltered() && isDockingstationFiltered();
            if (isMonitorFilterSet() && showHeightAdjustableDesks)
                return isMonitorFiltered() && isHeightAdjustable;
            if (isDockingstationFiltered() && showHeightAdjustableDesks)
                return isDockingstationFiltered() && isHeightAdjustable;
            return isMonitorFiltered() ? true : isDockingstationFiltered() ? true : isHeightAdjustable && showHeightAdjustableDesks;
        })

    }, [showHeightAdjustableDesks, isHeightAdjustable, monitorList, selectedMonitor, dockingList, selectedDockingstation, setIsFiltered])
}