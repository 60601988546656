import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Divider from "@material-ui/core/Divider";
import {gql, useMutation, useQuery} from "@apollo/client";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {Building, MeetingRoomEquipment} from "../../API";
import MeetingRoomEquipmentManagerBuildingDropdown from "./MeetingRoomEquipmentManagerBuildingDropdown";
import {usePermissionHelper} from "../../hooks/usePermissionHelper";
import {listMeetingRoomEquipments} from "../../graphql/queries";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import Sit2GetherCloseIcon from "../../styles/Sit2GetherCloseIcon";
import Sit2GetherAddIcon from "../../styles/Sit2GetherAddIcon";
import CreateOrUpdateMeetingRoomEquipmentDialog from "./CreateOrUpdateMeetingRoomEquipmentDialog";
import DeleteEntryConfirmationDialogComponent from "../DeleteEntryConfirmationDialogComponent";
import {deleteSecureMeetingRoomEquipment} from "../../graphql/mutations";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Edit from "@material-ui/icons/Edit";

export interface MeetingRoomEquipmentManagerProps {
    showMeetingRoomEquipmentManager: boolean
    setShowMeetingRoomEquipmentManager: (value: boolean) => void
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MeetingRoomEquipmentManager: React.FC<MeetingRoomEquipmentManagerProps> = (props) => {
    let {showMeetingRoomEquipmentManager, setShowMeetingRoomEquipmentManager} = props
    let {
        buildingList
    } = useMainApplicationContext()
    const [selectedEquipment, setSelectedEquipment] = useState<MeetingRoomEquipment | undefined>(undefined)
    const [showCreateEquipmentDialog, setShowCreateEquipmentDialog] = useState<boolean>(false)
    const [showDeleteEquipmentDialog, setShowDeleteEquipmentDialog] = useState<boolean>(false)
    const [isShowCreationOrUpdateAlert, setIsShowCreationOrUpdateAlert] = useState(false)
    const [isShowDeletionAlert, setIsShowDeletionAlert] = useState(false)
    const {t} = useTranslation();
    const [selectedBuilding, setSelectedBuilding] = useState<Building | undefined>(undefined)
    const [equipmentOfSelectedBuilding, setEquipmentOfSelectedBuilding] = useState<MeetingRoomEquipment[]>([]);

    const {refetch} = useQuery(gql(listMeetingRoomEquipments))
    const [deleteEquipmentMutation] = useMutation(gql(deleteSecureMeetingRoomEquipment))

    const handleCreateEquipment = () => {
        setSelectedEquipment(undefined)
        setShowCreateEquipmentDialog(true)
    }

    useEffect(() => {
        if (selectedBuilding || !showCreateEquipmentDialog || !showDeleteEquipmentDialog) {
            refetch({ filter: { buildingId: { eq: selectedBuilding?.buildingId }}})
                .then(({ data }) => {
                    let equipment = [...data?.listMeetingRoomEquipments?.items];
                    if (equipment) {
                        setEquipmentOfSelectedBuilding( () => {
                                return equipment.sort((a: MeetingRoomEquipment, b: MeetingRoomEquipment) => a.name.localeCompare(b.name));
                            }
                        );
                    }
                })
                .catch( (err) => console.error(err + " Error refetching Equipment Data in MeetingRoomEquipmentManager"));
        }
    }, [selectedBuilding, showCreateEquipmentDialog, showDeleteEquipmentDialog]);


    const handleDeleteEquipment = () => {
        if (selectedEquipment) {
            deleteEquipmentMutation({
                variables: {
                    input: {
                        equipmentId: selectedEquipment?.equipmentId
                    }
                }
            })
                .then(() => setShowDeleteEquipmentDialog(false))
                .then(() => setSelectedEquipment(undefined))
                .catch(err =>{
                    console.error(err + " Error deleting Equipment data in deleteEquipmentMutation");
                    setShowDeleteEquipmentDialog(false);
                    setSelectedEquipment(undefined);
                    showErrorMessage();
                })
        }
    }

    const showErrorMessage = () => {
        setIsShowDeletionAlert(true)
        setTimeout(() => {
            setIsShowDeletionAlert(false)
        }, 4000)
    }

    return (
        <Dialog style={{width: "100%"}} open={showMeetingRoomEquipmentManager}>
            {isShowCreationOrUpdateAlert && <Alert severity="error">{t("equipment_mngmnt_error_create_equipment")}</Alert>}
            {isShowDeletionAlert && <Alert severity="error">{t("equipment_mngmnt_error_delete_equipment")}</Alert>}
            <DialogTitle>
                {t("equipment_mngmnt_dialog-title")}
            </DialogTitle>
            <Box>
                <DialogContent>
                    <MeetingRoomEquipmentManagerBuildingDropdown
                        buildings={buildingList}
                        showMeetingRoomEquipmentManager={showMeetingRoomEquipmentManager}
                        setSelectedBuilding={setSelectedBuilding}
                    />

                    {showCreateEquipmentDialog && selectedBuilding &&
                        <CreateOrUpdateMeetingRoomEquipmentDialog
                            setShowCreateMeetingRoomEquipmentDialog={setShowCreateEquipmentDialog}
                            setIsShowAlert={setIsShowCreationOrUpdateAlert}
                            selectedBuilding={selectedBuilding}
                            selectedEquipment={selectedEquipment}
                            setSelectedEquipment={setSelectedEquipment}
                            equipmentOfSelectedBuilding={equipmentOfSelectedBuilding}/>
                    }
                    {showDeleteEquipmentDialog && selectedEquipment && <DeleteEntryConfirmationDialogComponent
                        isShowDeleteDialog={showDeleteEquipmentDialog}
                        handleDelete={handleDeleteEquipment}
                        setIsShowDeleteDialog={setShowDeleteEquipmentDialog}
                    />}

                    <TableContainer style={{maxHeight: "440px", marginTop: "1rem"}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('general_equipment-singular')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedBuilding ?
                                    equipmentOfSelectedBuilding.map((item: MeetingRoomEquipment, index: number) => (
                                        <TableRow
                                            key={item.equipmentId}
                                            selected={selectedEquipment === item}
                                            onClick={() => setSelectedEquipment(item)}
                                        >
                                            <TableCell>
                                                {item.name}
                                            </TableCell>
                                        </TableRow>
                                    )) : null
                                }
                            </TableBody>

                        </Table>
                    </TableContainer>
                </DialogContent>

                <Divider/>

                <DialogActions style={{display: "flex", justifyContent: "center"}}>
                    <Button variant={"contained"}
                            color={"primary"}
                            startIcon={<Sit2GetherAddIcon/>}
                            onClick={handleCreateEquipment}
                            data-testid={"btn-create"}
                    >
                        {t("equipment_mngmnt_add_button-text")}
                    </Button>
                    <Button variant={"contained"}
                            color={"primary"}
                            startIcon={<Edit/>}
                            disabled={!selectedEquipment}
                            onClick={() => setShowCreateEquipmentDialog(true)}
                            data-testid={"btn-edit"}
                    >
                        {t("equipment_mngmnt_edit_button-text")}
                    </Button>
                    <Button variant={"contained"}
                            color={"primary"}
                            startIcon={<DeleteForeverIcon/>}
                            disabled={!selectedEquipment}
                            onClick={() => setShowDeleteEquipmentDialog(true)}
                            data-testid={"btn-delete"}
                    >
                        {t("equipment_mngmnt_delete_button-text")}
                    </Button>
                    <Button
                        onClick={() => setShowMeetingRoomEquipmentManager(false)}
                        color={"primary"}
                        variant={"contained"}
                        startIcon={<Sit2GetherCloseIcon/>}
                    >
                        {t("equipment_mngmnt_close_dialog_button-text")}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>

    )
}
export default MeetingRoomEquipmentManager;