import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {ChangeEvent, CSSProperties} from "react";

export interface GenericSelectorProps<T> {
    disabled: boolean
    labelText: string
    chooseFrom: SelectItem<T>[]
    selectedIndex: number
    setSelected: (selected: T | undefined, index?: number) => void
    dataTestId?: string
    id?: string
    style: CSSProperties | undefined
    isOutlined: boolean
}

interface SelectItem<T> {
    label: string
    element: T | undefined
}

function GenericSelectorComponent<T>(props: GenericSelectorProps<T>) {
    const {
        disabled, labelText, chooseFrom, setSelected, selectedIndex,
        dataTestId, id, style, isOutlined
    } = props;

    function handleChange(event: ChangeEvent<{ value: unknown }>) {
        const selectedIndex = event.target.value;
        const index: number = Number.isInteger(selectedIndex) ? Number(selectedIndex) : 0;
        const elem: T | undefined = chooseFrom[index].element;
        setSelected(elem, index);
    }

    return <FormControl variant={isOutlined ? 'outlined' : 'standard'} style={style}>
        <InputLabel id={labelText + "-select-label"}>{labelText}</InputLabel>
        <Select disabled={disabled}
                fullWidth
                labelId={labelText + "-select-label"}
                id={id ?? labelText + "-label"}
                label={labelText}
                data-testid={dataTestId ?? labelText + "-select"}
                onChange={handleChange}
                value={selectedIndex}>
            {
                chooseFrom.map((item, index) => {
                    return <MenuItem key={index} value={index}>{item.label}</MenuItem>;
                })
            }
        </Select>
    </FormControl>
}


export default GenericSelectorComponent
