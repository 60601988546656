import React, {Dispatch, SetStateAction} from 'react';
import {SeatInterface} from "../SeatComponent";

interface Coordinates {
    x: number,
    y: number
}

interface Props {
    seatInterface: SeatInterface,
    elementPosition: Coordinates,
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

function Owner(props: Props) {
    const onMouseEnter = () => {
        props.setMouseOver(true)
    }
    const onMouseLeave = () => {
        props.setMouseOver(false)
    }

    return (
        <g className="owner" transform={`translate(${props.elementPosition.x}, ${props.elementPosition.y})`}
           onMouseOver={onMouseEnter} onMouseOut={onMouseLeave} data-testid={"Owner-" + props.seatInterface.seatID}>
            <rect fill="transparent" width="5.9" height="6" x="223" y="112.2"/>
            <path
                fill="#012"
                strokeWidth="0.099"
                d="M228.887 117.962c-.227-1.094-.979-1.98-1.96-2.352-.295.172-.639.273-1.007.276a2.03 2.03 0 01-1.008-.276c-.98.371-1.732 1.258-1.959 2.351-.036.177-.143.583.399.583h5.136c.541 0 .435-.406.399-.582zm-2.974-2.521c.872 0 1.578-.7 1.578-1.563a1.57 1.57 0 00-1.578-1.563c-.872 0-1.579.7-1.579 1.563a1.57 1.57 0 001.579 1.563z"
            />
        </g>
    );
}

export default Owner;