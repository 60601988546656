import {Inventory, SeatConfig} from "../API";
import {useEffect} from "react";

export function useSeatInventoryLists(setIsHeightAdjustable: (value: (((prevState: boolean) => boolean) | boolean)) => void, seatConfigurationList: SeatConfig[], seatID: string, setMonitorList: (value: (((prevState: Inventory[]) => Inventory[]) | Inventory[])) => void, setDockingList: (value: (((prevState: Inventory[]) => Inventory[]) | Inventory[])) => void, monitorOptions: Inventory[], dockingStationOptions: Inventory[], allInventories: Inventory[]) {
    useEffect(function updateInventoryLists() {
        function updateSeatOptions(seatConfig: SeatConfig) {
            setIsHeightAdjustable(seatConfig.isSeatHeightAdjustable ? seatConfig!.isSeatHeightAdjustable : false);
        }

        function getSeatInventoryFromOptionsList(seatConfig: SeatConfig, InventoryOptions: Inventory[]) {
            const inventoryList: Inventory[] = []
            seatConfig.inventory.forEach((seatInventory) => {
                let matchingItem = InventoryOptions.find(inventoryOption => inventoryOption.inventoryId === seatInventory)
                if (matchingItem !== undefined)
                    inventoryList.push(matchingItem)
            })
            return inventoryList
        }

        let seatConfig = seatConfigurationList.find(seatConfig => seatConfig.seatName === seatID)
        if (seatConfig === undefined || seatConfig === null) {
            setMonitorList([]);
            setDockingList([]);
        } else {
            setMonitorList(getSeatInventoryFromOptionsList(seatConfig, monitorOptions));
            setDockingList(getSeatInventoryFromOptionsList(seatConfig, dockingStationOptions));
            updateSeatOptions(seatConfig);
        }
    }, [seatConfigurationList, monitorOptions, dockingStationOptions, seatID, setIsHeightAdjustable, setMonitorList, setDockingList, allInventories])
}