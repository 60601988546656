import React from "react";
import MeetingRoomBookingMatrixRoomNameComponent, {RoomNameProps} from "./MeetingRoomBookingMatrixRoomNameComponent";
import {comparatorAlphanumericValues} from "../../../Utils/Helpers";
import {MeetingRoomEquipment} from "../../../API";

interface Props {
    roomNameProps: RoomNameProps[],
    buildingEquipment: Map<string, MeetingRoomEquipment>,
}

function MeetingRoomBookingMatrixRoomNamesComponent(props: Props) {
    const {roomNameProps} = props;

    roomNameProps.sort((a, b) => comparatorAlphanumericValues(a.data.name, b.data.name));

    return <>
        {
            roomNameProps.map((props, index) => {
                return <MeetingRoomBookingMatrixRoomNameComponent
                    key={props.data.meetingRoomId + `-${index}`} {...props} />
            })
        }
    </>
}

export default MeetingRoomBookingMatrixRoomNamesComponent