import React, {useEffect, useMemo} from "react";
import {IconButton} from "@material-ui/core";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "@mui/material/ButtonGroup";

interface Props {
    zoomIn: () => void,
    zoomOut: () => void,
    reset: () => void,
    zoomInDisabled: boolean,
    zoomOutDisabled: boolean
}

const ZoomControlComponent: React.FC<Props> = (props) => {
    const {zoomIn, zoomOut, reset, zoomInDisabled, zoomOutDisabled} = props

    useEffect(function resetOnOrientationChange() {
        if (window.screen.orientation) {
            window.screen.orientation.addEventListener("change", reset);
            return () => window.screen.orientation.removeEventListener("change", reset);
        }
    }, [reset]);
    
    const renderZoomControls = useMemo(() => {
        return (
            <div className={"zoomControl"}>
                <IconButton
                    data-testid={'zoomReset_btn'}
                    onClick={() => reset()}
                    size="small"
                    className={"resetButton"}
                >
                    <CircleOutlinedIcon/>
                </IconButton>
                <ButtonGroup
                    orientation="vertical"
                    variant="outlined"
                    sx={{
                        border: "2px grey solid",
                        borderRadius: "8px",
                        backgroundColor: "white",
                    }}
                >
                    <IconButton
                        data-testid={'zoomIn_btn'}
                        disabled={zoomInDisabled}
                        onClick={() => zoomIn()}
                        size="small"
                    >
                        <AddIcon/>
                    </IconButton>

                    <IconButton
                        data-testid={'zoomOut_btn'}
                        disabled={zoomOutDisabled}
                        onClick={() => zoomOut()}
                        size="small"
                    >
                        <RemoveIcon/>
                    </IconButton>
                </ButtonGroup>

            </div>
        )
    }, [reset, zoomIn, zoomInDisabled, zoomOut, zoomOutDisabled])

    return (
        <>
            {renderZoomControls}
        </>
    )
}
export default ZoomControlComponent
