import {Booking, MeetingRoomBooking} from "../../../API";
import React, {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {MeetingRoomBookings} from "../../../types/MeetingRoomBookingType";
import {SeatPaper} from "./SeatPaper";
import {MeetingRoomPaper} from "./MeetingRoomPaper";
import {ExecuteOtherBookingsButton} from "./ExecuteOtherBookingsButton";
import {BookingType} from "../../../types/BookingType";
import {useTranslation} from "react-i18next";
import {deleteSecureMeetingRoomBooking, deleteSecureSeatBooking} from "../../../graphql/mutations";

interface Props {
    position: React.CSSProperties
    offset: number
    isTimeActive: boolean
    deletableBookings: (Booking | MeetingRoomBooking)[]
    handleBookOtherDateTime: () => void
    dateISO: string
    closeDeleteBookingDialog: () => void
    bookingType: BookingType,
    meetingRoomBookingProps?: MeetingRoomBookings | null
}

const ItemClickedDialog: React.FC<Props> = (props) => {

    const {
        position,
        offset,
        isTimeActive,
        deletableBookings,
        handleBookOtherDateTime,
        dateISO,
        closeDeleteBookingDialog,
        bookingType,
        meetingRoomBookingProps
    } = props

    const [deleteSeatBookingMutation] = useMutation(gql(deleteSecureSeatBooking))
    const [deleteMeetingRoomBookingMutation] = useMutation(gql(deleteSecureMeetingRoomBooking))

    const removeSelectedBooking = useCallback((booking: Booking | MeetingRoomBooking) => {
        if (bookingType === "meetingRoom") {
            const meetingRoomBooking = booking as MeetingRoomBooking;
            deleteMeetingRoomBookingMutation({
                variables: {
                    input: {
                        bookingId: meetingRoomBooking.bookingId,
                        bookerId: meetingRoomBooking.bookerId,
                        orgUnitId: meetingRoomBooking.orgUnitId,
                        meetingRoomId: meetingRoomBooking.meetingRoomId,
                        roomId: meetingRoomBooking.roomId,
                        date: meetingRoomBooking.date
                    }
                }
            }).catch((err) => {
                console.error("ItemClickedDialog removeSelectedMeetRoomBooking:", err);
            }).finally(closeDeleteBookingDialog)
        } else if (bookingType === "seat") {
            const seatBooking = booking as Booking;
            deleteSeatBookingMutation({
                variables: {
                    input: {
                        bookingId: seatBooking.bookingId,
                        bookerId: seatBooking.bookerId,
                        orgUnitId: seatBooking.orgUnitId,
                        seatId: seatBooking.seatId,
                        roomId: seatBooking.roomId,
                        date: seatBooking.date
                    }
                }
            })
                .catch((err) => console.error(err))
                .finally(closeDeleteBookingDialog)
        }
    }, [closeDeleteBookingDialog, dateISO, deleteMeetingRoomBookingMutation, deleteSeatBookingMutation])

    const {t} = useTranslation();
    const getExecuteOtherBookingsButtonText = function () {
        const nameBasedOnTimeActivityForSeatPopup = isTimeActive ? t("button_book_other_days_times") : t("button_book_other_days");
        return bookingType === "meetingRoom" ? t("open_another_booking_dialog") : nameBasedOnTimeActivityForSeatPopup;
    }

    const executeOtherBookingsButton = <ExecuteOtherBookingsButton handleBookOtherDateTime={handleBookOtherDateTime}
                                                                   text={getExecuteOtherBookingsButtonText()}/>
    return <>{(bookingType === "meetingRoom") ?
        <MeetingRoomPaper
            deletableBookings={deletableBookings
                .filter(b => b.__typename === "MeetingRoomBooking")
                .map(b => b as MeetingRoomBooking)}
            removeSelectedBooking={removeSelectedBooking}
            executeOtherBookingsButton={executeOtherBookingsButton}
            position={position}
            offset={offset}
            meetingRoomBookingProps={meetingRoomBookingProps}
        />
        :
        <SeatPaper
            deletableBookings={deletableBookings
                .filter(b => b.__typename === "Booking")
                .map(b => b as Booking)}
            removeSelectedBooking={removeSelectedBooking}
            executeOtherBookingsButton={executeOtherBookingsButton}
            position={position}
            offset={offset}
            isTimeActive={isTimeActive}/>
    }
    </>
}

export default ItemClickedDialog





