import {gql, useQuery} from "@apollo/client";
import {getMeetingRoomBookings} from "../graphql/queries";
import {onChangeMeetingRoomBooking, onUpdateRestrictedNeighborhoods} from "../graphql/subscriptions";
import {MeetingRoomBooking} from "../API";
import {SUBSCRIPTION_DELAY} from "../Utils/commons";
import {useEffect} from "react";

export function useBookingsByMeetingRoom(roomId?: string, dateISO?: string, meetingRoomId?: string): MeetingRoomBooking[] | [] {

    const {data, subscribeToMore, refetch} =
        useQuery(gql(getMeetingRoomBookings), {
            variables: {
                meetingRoomIds: [meetingRoomId],
                date: dateISO ? new Date(dateISO) : "undefined",
                dateTo: dateISO ? new Date(dateISO) : "undefined",
                roomIds: [roomId]
            },
            skip: (roomId === undefined) || (meetingRoomId === undefined) || (dateISO === undefined),
        });

    function subscribe() {
        if ((roomId === undefined) || (meetingRoomId === undefined) || (dateISO === undefined))
            return;

        let subscriptions: (() => void)[] = [];
        let timeout = setTimeout(() => {
            const unsubscribeOnChangeMeetingRoomBooking = subscribeToMore({
                document: gql(onChangeMeetingRoomBooking),
                updateQuery: (prev) => {
                    refetch().then().catch(error => {
                        console.error("refetch in useBookingByMeetingRoomId onChangeMeetingRoomBookingsSubscription: " + JSON.stringify(error));
                    });
                    return prev;
                },
                onError: (error: any) => console.error("onChangeMeetingRoomBookingsSubscription: " + JSON.stringify(error))
            });

            const unsubscribeMeetingRoomOnUpdateRestrictedNeighborhoods = subscribeToMore({
                document: gql(onUpdateRestrictedNeighborhoods),
                updateQuery: (prev) => {
                    refetch().then().catch(error => {
                        console.error("refetch in useBookingByMeetingRoomId onUpdateRestrictedNeighborhoodsSubscription: " + JSON.stringify(error));
                    });
                    return prev;
                },
                onError: (error: any) => console.error("onUpdateRestrictedNeighborhoodsSubscription: " + JSON.stringify(error))
            });

            subscriptions = [
                unsubscribeOnChangeMeetingRoomBooking, unsubscribeMeetingRoomOnUpdateRestrictedNeighborhoods
            ];
        }, SUBSCRIPTION_DELAY);

        function unsubscribe() {
            subscriptions.forEach(subscription => subscription());
            clearTimeout(timeout);
        }

        return () => unsubscribe();
    }

    useEffect(subscribe, [roomId, dateISO, meetingRoomId, subscribeToMore]);


    return data?.getMeetingRoomBookings?.items.map((b: MeetingRoomBooking) => {
        return {...b, __typename: "MeetingRoomBooking"}
    }) ?? [];
}