import {gql, useQuery} from "@apollo/client";
import {listInfoTexts} from "../graphql/queries";
import {useEffect} from "react";
import {onUpdateInfoText} from "../graphql/subscriptions";

export function useInfoText() {
    const {data, subscribeToMore, refetch, loading} = useQuery(gql(listInfoTexts), {
        variables: {
            id: "0",
            limit: 1
        },
    });

    useEffect(() => {
        const unsubscribeFromUpdate = subscribeToMore({
            document: gql(onUpdateInfoText),
            variables: {},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                return {
                    ...prev,
                    listInfoTexts: {
                        ...prev.listInfoTexts,
                        items: [subscriptionData.data.onUpdateInfoText]
                    }
                }
            },
            onError: (error: any) => console.error("useMeetingRoomList useEffect:subscribeToMore:onUpdateSecureMeetingRoom " + JSON.stringify(error)),
        });

        return () => {
            unsubscribeFromUpdate();
        };
    }, []);

    if (data) {
        return {infoText: data.listInfoTexts.items[0], refetch, loading}
    }

    return {infoText: undefined, refetch, loading}

}