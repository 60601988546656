import React from 'react';

interface Coordinates {
    x: number,
    y: number
}

type Props = {
    elementPosition: Coordinates
    isFiltered: boolean | undefined
    isBookable: boolean | undefined
    hasAccess: boolean | undefined
}

function Table({elementPosition, isFiltered, hasAccess, isBookable}: Props) {
    return (
        <g
            transform={`translate(${elementPosition.x}, ${elementPosition.y})`}
            className={isFiltered ? "highlighted" : "table"}>
            {hasAccess && isBookable && <rect fill="#c4c4c4" width="54" height="27"/>}
        </g>
    );
}

export default Table;