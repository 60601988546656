import React from "react";
import {StyledDivTimeHeader} from "../../../styles/MeetingRoomBookingMatrixStyles";
import HourDisplay from "./HourDisplay";

interface Props {
    startTimeHours: number
    hoursDisplayed: number
}

function MeetingRoomBookingMatrixTimeHeaderComponent(props: Props){
    const {startTimeHours, hoursDisplayed} = props;
    const hours: number[] = Array.from(Array(hoursDisplayed).keys());
    return <StyledDivTimeHeader data-testid={"meeting-room-booking-matrix-time-header-test-id"}>
        {
            hours.map((hour, index) => {
                const timeString = (hour + startTimeHours).toString().padStart(2, "0") + ":00";
                return (
                    <HourDisplay key={hour} time={timeString}></HourDisplay>
                );
            })
        }
    </StyledDivTimeHeader>
}

export default MeetingRoomBookingMatrixTimeHeaderComponent