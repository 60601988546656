import React, {useEffect, useMemo, useState} from "react";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {useErrorContext} from "../../hooks/useErrorContext";
import {createNewTodayDateWithoutHours, toDateISO} from "../../services/DateUtils";
import {Booking} from "../../API";
import {formatDate, timestampToHourMinuteString} from "../../Utils/Helpers";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {Button, CircularProgress, Dialog, DialogActions, DialogContentText, DialogTitle} from "@material-ui/core";
import {DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {
    bookingTabHeaderCell,
    bookingTabTableCell,
    getSortIcon,
    getSortOptionsByColumn,
    seatBookingSortColumns,
    StyledTabTableCell
} from "../../Utils/OwnBookingTabHelper";
import {deleteSecureSeatBooking} from "../../graphql/mutations";
import {getSeatBookings} from "../../graphql/queries";

export interface OwnSeatBookingsProps {
    setNumberOfSelections: (count: number) => void
    showDeleteDialog: boolean
    setShowDeleteDialog: (value: boolean) => void
}

interface DetailedSeatBooking extends Booking {
    roomName: string | undefined;
}

export const OwnSeatBookingsTabComponent: React.FC<OwnSeatBookingsProps> = (props) => {
    const {
        setNumberOfSelections,
        showDeleteDialog,
        setShowDeleteDialog
    } = props
    let {rooms, currentUser} = useMainApplicationContext()
    const {reportError} = useErrorContext();

    const today: string = toDateISO(createNewTodayDateWithoutHours());
    const {t, i18n} = useTranslation();
    const is_german_locale = i18n.language.substring(0, 2) === 'de'

    const [bookings, setBookings] = useState<DetailedSeatBooking[]>([]);
    const [selectedBookings, setSelectedBookings] = useState<Booking[]>([])

    const bookerId = currentUser.ID;
    const [deleteSeatBookingMutation] = useMutation(gql(deleteSecureSeatBooking))
    const {
        refetch: refetchBookingsByBooker,
        loading: areBookingsLoading
    } = useQuery(gql(getSeatBookings), {
        variables: {
            input: {
                bookerId: bookerId,
                date: today,
                typeOfQuery: 'bookingsByBookerAndDate'
            },
            limit: 999
        }
    })

    const columnHeaders = [
        {label: t('date'), key: 'date'},
        {label: t("roomplan"), key: 'room'},
        {label: t('start-time'), key: 'timeBegin'},
        {label: t('end-time'), key: 'timeEnd'},
        {label: t('seat'), key: 'seatId'}
    ];
    const [sortOptions, setSortOptions] = useState<{ [key: string]: string }>({
        date: 'asc',
        room: 'default',
        timeBegin: 'default',
        timeEnd: 'default',
        seatId: 'default',
    });


    const hasTimeBooking = useMemo(() => {
        return bookings.some((booking: Booking) => {
            let roomOfTheBooking = rooms.find((room) => room.roomId === booking.roomId)
            return roomOfTheBooking?.isTimeActive
        })
    }, [bookings, rooms])

    useEffect(function fetchBookings() {
        const fetchDataAndUpdateState = async () => {
            await updateMyBookedDays();
        };

        fetchDataAndUpdateState().then();
    }, []);

    useEffect(function propagateSelected() {
        setNumberOfSelections(selectedBookings.length);
    }, [selectedBookings]);

    async function updateMyBookedDays() {
        try {
            const bookingsByBookerData = await refetchBookingsByBooker();
            setBookings(
                bookingsByBookerData.data.getSeatBookings.items.map((b: Booking) => ({
                    ...b,
                    roomName: rooms.find((room) => room.roomId === b.roomId)?.name,
                    __typename: "Booking"
                }))
            );
        } catch (err) {
            if (err instanceof Error) {
                reportError(err, "", "OwnBookingsManagerComponent updateMyBookedDays");
            } else {
                console.error("An unknown error occurred:", err);
            }
        }
    }

    const handleRowClick = (booking: DetailedSeatBooking) => {
        const newSelectedRows = new Set(selectedBookings);
        if (newSelectedRows.has(booking)) {
            newSelectedRows.delete(booking);
        } else {
            newSelectedRows.add(booking);
        }
        setSelectedBookings(Array.from(newSelectedRows));
    };

    const handleHeaderSortClick = (column: string) => {
        const newSortOptions = getSortOptionsByColumn(column, sortOptions);
        setSortOptions(newSortOptions);

        const selectedColumn = newSortOptions.selectedColumn;
        const primarySortColumn = seatBookingSortColumns.find((sortItem) => sortItem.column === selectedColumn);
        const secondarySortColumns = seatBookingSortColumns.filter((sortItem) => sortItem.column !== selectedColumn);

        const sortedBookings = bookings.slice().sort((a, b) => {
            const primarySortComparison = primarySortColumn?.compare(a[primarySortColumn.column]!, b[primarySortColumn.column]!) || 0;
            const sortOrder = newSortOptions[selectedColumn] === 'desc' ? -1 : 1;
            const comparison = sortOrder * primarySortComparison;

            return comparison !== 0 ? comparison : secondarySortColumns.reduce((acc, sortColumn) => {
                if (acc === 0) {
                    return sortColumn.compare(a[sortColumn.column]!, b[sortColumn.column]!);
                }
                return acc;
            }, 0);
        });

        setBookings(sortedBookings);
    };

    function renderOwnBookingsDialog() {
        return <>{areBookingsLoading ? (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                    <CircularProgress/>
                </div>
            ) :
            <TableContainer>
                <Table style={{marginRight: 0, marginLeft: 0, maxWidth: '100%'}}>
                    <TableHead>
                        <TableRow>
                            {columnHeaders.map((column) => {
                                if ((hasTimeBooking || (column.key !== 'timeBegin' && column.key !== 'timeEnd'))) {
                                    return (
                                        <TableCell
                                            key={column.key}
                                            onClick={() => handleHeaderSortClick(column.key)}
                                            style={bookingTabTableCell}
                                        >
                                            <div style={bookingTabHeaderCell}>
                                                {getSortIcon(sortOptions[column.key])}
                                                {column.label}
                                            </div>
                                        </TableCell>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookings.map((item: DetailedSeatBooking) => (
                            <TableRow
                                key={item.bookingId}
                                selected={selectedBookings.some(seatBooking => seatBooking.bookingId === item.bookingId)}
                                onClick={() => handleRowClick(item)}
                            >
                                <StyledTabTableCell>{formatDate(item.date, is_german_locale)}</StyledTabTableCell>
                                <StyledTabTableCell>{item.roomName}</StyledTabTableCell>
                                {hasTimeBooking && (
                                    <StyledTabTableCell>{timestampToHourMinuteString(item.timeBegin)}</StyledTabTableCell>)}
                                {hasTimeBooking && (
                                    <StyledTabTableCell>{timestampToHourMinuteString(item.timeEnd)}</StyledTabTableCell>)}
                                <StyledTabTableCell>{item.seatId}</StyledTabTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </>
    }

    function renderAlertDialog() {
        function handleDeleteSelectedBookings(): void {
            selectedBookings.forEach((booking) => {
                deleteBooking(booking)
            })

            setSelectedBookings([])
        }

        function deleteBooking(booking: Booking): void {
            deleteSeatBookingMutation({
                variables: {
                    input: {
                        bookingId: booking.bookingId,
                        bookerId: booking.bookerId,
                        orgUnitId: booking.orgUnitId,
                        seatId: booking.seatId,
                        roomId: booking.roomId,
                        date: booking.date
                    }
                }
            }).then(() => {
                updateMyBookedDays().then(() => setShowDeleteDialog(false))
            }).catch((err) => reportError(err, "", "OwnBookingsManagerComponent deleteBooking"))
        }

        return (
            <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                maxWidth={"xs"}
                data-testid={"own-bookings-alert-dialog"}
            >
                <DialogTitle>
                    {t("own_bookings_management_alert_dialog_title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("own_bookings_management_alert_dialog_amount_bookings")}: {selectedBookings.length}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={selectedBookings.length === 0}
                        color={"primary"}
                        variant={"contained"}
                        data-testid={"alert-dialog-btn-delete"}
                        onClick={handleDeleteSelectedBookings}
                    >
                        {t("delete")}
                    </Button>
                    <Button
                        onClick={() => setShowDeleteDialog(false)}
                        color={"primary"}
                        variant={"contained"}
                        data-testid={"alert-dialog-btn-close"}
                    >
                        {t("confirm_dialog_cancel_button-text")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            {renderOwnBookingsDialog()}
            {renderAlertDialog()}
        </>
    )
}
