import {useEffect, useMemo, useRef} from "react";
import {gql, useQuery} from "@apollo/client";
import {getMeetingRoomBookings} from "../graphql/queries";
import {MeetingRoomBooking} from "../API";
import {MeetingRoomBookingListItem} from "../types/MeetingRoomBookingListItemType";
import {MeetingRoomWithLevel} from "../types/MeetingRoomWithLevel";
import {User} from "../services/UserClient";
import {onChangeMeetingRoomBooking, onUpdateRestrictedNeighborhoods} from "../graphql/subscriptions";
import {SUBSCRIPTION_DELAY} from "../Utils/commons";
import {MeetingRoomWithLevelP, OwnedBy} from "../types/PermissionHandling";

export function useMeetingRoomBookingsOfMeetingRoomsByDate(
    date: Date,
    user: User,
    meetingRooms: MeetingRoomWithLevelP[] | undefined): [MeetingRoomBookingListItem[], boolean, () => void] {
    const NO_BOOKINGS = useRef<MeetingRoomBookingListItem[]>([]);
    const dateStr = date.getFullYear().toString() + "-"
        + (date.getMonth() + 1).toString().padStart(2, "0") + "-"
        + date.getDate().toString().padStart(2, "0");


    function convertBooking(booking: MeetingRoomBooking, meetingRoom: MeetingRoomWithLevelP): MeetingRoomBookingListItem {
        const ownedBy = user.ID === booking.bookerId ? OwnedBy.USER : OwnedBy.OTHER;
        const userCanManage = ownedBy === OwnedBy.USER || meetingRoom.managingAllowed;
        return {
            ...booking,
            neighborhoodId: meetingRoom.neighborhoodId,
            meetingRoomName: meetingRoom.name,
            meetingRoomType: meetingRoom.type,
            meetingRoomCapacity: meetingRoom.capacity,
            level: meetingRoom.level,
            managingAllowed: userCanManage,
            ownedBy: ownedBy, //for red and blue coloring
        }
    }

    const {
        data,
        loading: isLoading,
        refetch: refetchMeetingRoomBookings,
        subscribeToMore: subscribeToMoreMtRoomBookings
    } = useQuery(gql(getMeetingRoomBookings), {
        variables: {
            meetingRoomIds: meetingRooms?.map(meetingRoom => meetingRoom.meetingRoomId),
            roomIds: Array.from(new Set(meetingRooms?.map(meetingRoom => meetingRoom.roomId))),
            date: dateStr
        },
        skip: meetingRooms === undefined || meetingRooms.length === 0
    });

    const convertedBookings = useMemo(() => {
        if (data?.getMeetingRoomBookings?.items) {
            return data.getMeetingRoomBookings.items.map((booking: MeetingRoomBooking) => {
                const matchingRoom = meetingRooms?.find((room: MeetingRoomWithLevel) => room.meetingRoomId === booking.meetingRoomId);
                if (matchingRoom) {
                    return convertBooking(booking, matchingRoom);
                }
                return null;
            }).filter((booking: MeetingRoomBooking | null) => booking !== null) as MeetingRoomBookingListItem[];
        }
        return NO_BOOKINGS.current;
    }, [data, meetingRooms]);

    useEffect(() => {
        let subscriptions: (() => void)[] = [];
        let timeout = setTimeout(() => {
            const unsubscribeOnChangeMeetingRoomBooking = subscribeToMoreMtRoomBookings({
                document: gql(onChangeMeetingRoomBooking),
                updateQuery: (prev) => {
                    refetchMeetingRoomBookings().then().catch(error => {
                        console.error("refetch in useMeetingRoomBookingsOfMeetingRoomsByDate onChangeMeetingRoomBookingsSubscription: " + JSON.stringify(error));
                    });
                    return prev;
                },
                onError: (error: any) => console.error("onChangeMeetingRoomBookingsSubscription: " + JSON.stringify(error))
            });

            const unsubscribeOnUpdateRestrictedNeighborhoods = subscribeToMoreMtRoomBookings({
                document: gql(onUpdateRestrictedNeighborhoods),
                updateQuery: (prev) => {
                    refetchMeetingRoomBookings().then().catch(error => {
                        console.error("refetch in useMeetingRoomBookingsOfMeetingRoomsByDate onUpdateRestrictedNeighborhoodsSubscription: " + JSON.stringify(error));
                    });
                    return prev;
                },
                onError: (error: any) => console.error("onUpdateRestrictedNeighborhoodsSubscription: " + JSON.stringify(error))
            });

            subscriptions = [unsubscribeOnChangeMeetingRoomBooking, unsubscribeOnUpdateRestrictedNeighborhoods];
        }, SUBSCRIPTION_DELAY);

        function unsubscribe() {
            subscriptions.forEach((subscription) => subscription());
            clearTimeout(timeout);
        }

        return () => unsubscribe();
    }, []);

    return [convertedBookings, isLoading, refetchMeetingRoomBookings];
}