export enum FileTypes {
    SVG = "svg"
}

export enum BookingFor {
    BOOKING_FOR_ME = 'BOOKING_FOR_ME',
    BOOKING_FOR_OTHERS = 'BOOKING_FOR_OTHERS'
}

export enum MeetingType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    VIP = 'VIP'
}

export enum MeetingRoomType {
    NEIGHBORHOOD = 'Neighborhood',
    INTERNAL = 'Internal',
    VISITORS_CENTER = 'Visitors Center',
    CLASSROOM = 'Classroom'
}


export function getMeetingTypeLabel(meetingType: string | null | undefined): string {
    switch (meetingType) {
        case MeetingType.INTERNAL.toString():
            return "meetingroom_booking_type_internal";
        case MeetingType.EXTERNAL.toString():
            return "meetingroom_booking_type_external";
        case MeetingType.VIP.toString():
            return "meetingroom_booking_type_vip";
    }
    return "";
}

export function getMeetingRoomTypeLabel(meetingRoomType: string): string {
    switch (meetingRoomType) {
        case MeetingRoomType.CLASSROOM.toString():
            return "meetingroom_type_classroom";
        case MeetingRoomType.NEIGHBORHOOD.toString():
            return "meetingroom_type_neighborhood";
        case MeetingRoomType.VISITORS_CENTER.toString():
            return "meetingroom_type_visitors_center";
        default:
            return "meetingroom_type_internal";
    }
}

export function getMeetingRoomType(meetingRoomType: string): MeetingRoomType {
    switch (meetingRoomType) {
        case MeetingRoomType.NEIGHBORHOOD.toString():
            return MeetingRoomType.NEIGHBORHOOD;
        case MeetingRoomType.VISITORS_CENTER.toString():
            return MeetingRoomType.VISITORS_CENTER;
        default:
            return MeetingRoomType.INTERNAL;
    }
}

export enum InvType {
    Dockingstation = "Dockingstation",
    Monitor = "Monitor"
}

export enum BookedByStatus {
    Past,
    Bookable,
    CurrentUser,
    NotAvailable,
    TimeSlotUndecided
}

export enum SeatBookingColor {
    RED = "seatStyleBooked",
    ORANGE = "seatStyleOrange",
    YELLOW = "seatStyleYellow",
    GREEN = "seatStyleNotBooked",
    BLUE = "seatStyleBookedByMe",
    NO_COLOR = "seatStyleNoColor",
}

export enum RoomBookingColor {
    RED = "roomStyleBooked",
    ORANGE = "roomStyleOrange",
    YELLOW = "roomStyleYellow",
    GREEN = "roomStyleNotBooked",
    BLUE = "roomStyleBookedByMe",
}

export enum RoomIconBookingColor {
    RED = "roomIconStyleBooked",
    ORANGE = "roomIconStyleOrange",
    YELLOW = "roomIconStyleYellow",
    GREEN = "roomIconStyleNotBooked",
    BLUE = "roomIconStyleBookedByMe",
}

export enum RoomAndIconBookingColor {
    RED = "roomAndIconStyleBooked",
    ORANGE = "roomAndIconStyleOrange",
    YELLOW = "roomAndIconStyleYellow",
    GREEN = "roomAndIconStyleNotBooked",
    BLUE = "roomAndIconStyleBookedByMe",
}