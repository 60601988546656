import React from "react";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';


function HintIcon() {
    return (
        <TextSnippetOutlinedIcon stroke="black" style={{color: "black"}} strokeWidth={0.3}/>
    );
}

export default HintIcon;