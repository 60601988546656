import {IOrgUnit} from "../../hooks/useOrgunit";
import {FormControl, InputLabel, NativeSelect} from "@material-ui/core";
import React from "react";
import {t} from "i18next";

interface Props {
    orgUnits: IOrgUnit []
    setSelectedOrgUnit: (value: IOrgUnit) => void
    selectedOrgUnit: IOrgUnit
}

const OrgUnitInterfaceBuildingManager: React.FC<Props> = (props) => {
    const {orgUnits, setSelectedOrgUnit, selectedOrgUnit} = props

    return (
        <FormControl style={{width: "100%"}}>
            <InputLabel shrink htmlFor="org-unit">
                {t("daily_bookings_table_orgunit_column")}
            </InputLabel>
            <NativeSelect
                value={selectedOrgUnit.orgId}
                onChange={(e) => {
                    const orgUnit = orgUnits.find(o => o.orgId === e.target.value);
                    setSelectedOrgUnit(orgUnit ?? orgUnits[0]) /*Consider the rooms with no org unit*/
                }}
                data-testid={"select-org-unit"}
            >
                {orgUnits.map((org: IOrgUnit) => {
                    return <option
                        key={org.orgId}
                        value={org.orgId}
                        data-testid={"org-dropdown-option-" + org.orgName}>
                        {org.orgName}
                    </option>
                })}
            </NativeSelect>
        </FormControl>
    );
};

export default OrgUnitInterfaceBuildingManager;