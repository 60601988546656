import {gql, useLazyQuery} from "@apollo/client";
import {getNeighborhoodsByRoomId} from "../graphql/queries";
import {Neighborhood} from "../API";
import {User} from "../services/UserClient";

export function useHasAccessByNeighborhoodAndRoomId(roomId: string, currentUser: User) {
    const [queryNeighborhoods] = useLazyQuery(gql(getNeighborhoodsByRoomId))

    function hasCurrentUserAccessToNeighborhood(neighborhood: Neighborhood) {
        const rolesOfCurrentUser = currentUser?.admin2GetherRoles;
        const rolesOfNeighborhood = [...neighborhood?.roleIds || [], ...neighborhood?.adminRoleIds || []];
        let hasAccess = false;
        if (!neighborhood?.restricted) {
            hasAccess = true;
        } else if (neighborhood.restricted) {
            hasAccess = rolesOfNeighborhood?.some(role => rolesOfCurrentUser.includes(role)) ?? false;
        }

        return hasAccess;
    }

    async function fetchNeighborhoodsByRoomId() {
        const variables = {roomId};
        const result = await queryNeighborhoods({variables});
        return result?.data?.getNeighborhoodsByRoomId?.items;
    }

    async function calculateIfUserHasAccessToNeighborhoods() {
        const hasNeighborhoodAccessMap: Map<string, boolean> = new Map();
        const neighborhoods = await fetchNeighborhoodsByRoomId();
        neighborhoods?.forEach((neighborhood: Neighborhood) => {
            const hasAccess = hasCurrentUserAccessToNeighborhood(neighborhood);
            hasNeighborhoodAccessMap.set(neighborhood.neighborhoodId, hasAccess);
        });
        return hasNeighborhoodAccessMap;
    }

    return {calculateIfUserHasAccessToNeighborhoods};
}