import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import locales_de from './locales/de';
import locales_en from './locales/en';

const resources = {
    de: {translation: locales_de},
    en: {translation: locales_en},
}

const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources,
        defaultNS: 'translation',
        fallbackLng: 'en',
        supportedLngs: ['de', 'en'],
        interpolation: {
            escapeValue: false,
        },
        load: 'languageOnly',
        debug: false,
    });

export default i18n