import React, {useState} from "react";
import {Button, makeStyles} from "@material-ui/core";
import uiElementMeasures from "../styles/inputElementMeasures";
import shadows from "../styles/shadows";
import borders from "../styles/borders";
import colors from "../styles/colors";
import i18n from "../i18n";
import gregorian_de from "../locales/calendar_de";
import gregorian_en from "../locales/calender_en";
import CollapsibleCalendarComponent from "./CollapsibleCalendarComponent";
import {Calendar} from "react-multi-date-picker";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

interface Props {
    selectedDate: Date
    setSelectedDate: (newDate: Date) => void
}

const DayPickCalendarComponent: React.FC<Props> = (props) => {
    const {
        selectedDate,
        setSelectedDate,
    } = props

    const [showCalendar, setShowCalendar] = useState<boolean>(false);

    const useStyles = makeStyles({
        divCalendarContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: uiElementMeasures.marginBetweenElementsInColumn,
            boxShadow: shadows.innerInputElementShadow,
            border: borders.innerInputElementBorder,
            borderRadius: borders.mediumBorderRadius,
            minWidth: "40px",
            color: colors.iconAndTextGrey,
        },
        divCalendar: {
            fontSize: "12px",
            marginBottom: "5px"
        },
        calender: {
            "&.rmdp-wrapper": {
                width: "auto",
                display: "flex",
                justifyContent: "center",
                boxShadow: "0 0",
            },
        },
        changeDateButton: {
            minWidth: "40px",
            color: colors.iconAndTextGrey,
        },
        '@global': {
            ".rw-widget-input": {
                borderRadius: borders.mediumBorderRadius,
                boxShadow: shadows.innerInputElementShadow,
                borders: borders.innerInputElementBorder,
                minHeight: uiElementMeasures.inputElementsInRoomPickerMinHeight,
            }
        }
    });
    const classes = useStyles();

    const handleChange = (date: any) => {
        const newDate = new Date(date.toString())
        if (newDate) newDate.setHours(0, 0, 0, 0)
        setSelectedDate(newDate)
    }

    const isSameDay = (first: Date, second: Date) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    function setDayStyle_Selected(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            color: "white",
        }
    }

    function setDayStyle_Weekend(calendarProps: { style: {}; disabled: boolean }) {
        calendarProps.style = {
            ...calendarProps.style,
            color: "red"
        }
    }

    function createCalendarProps(dateJS: Date) {
        let calendarProps = {
            style: {
                borderRadius: "4px",
            },
            disabled: false
        }
        if (dateJS.getDay() === 0 || dateJS.getDay() === 6) {
            setDayStyle_Weekend(calendarProps);
        }
        if (isSameDay(selectedDate, dateJS)) {
            setDayStyle_Selected(calendarProps)
        }

        return calendarProps;
    }

    const localisation = i18n.language.substring(0, 2) === 'de' ? gregorian_de : gregorian_en;

    //TODO from Hossam:
    // mapDays you can move the const dateJS inside the createCalendarProps and thus simplify the mapDays
    return <div className={classes.divCalendarContainer} data-testid={"meeting-room-booking-manager-date-picker-id"}>
        <CollapsibleCalendarComponent
            showCalendar={showCalendar}
            setShowCalendar={setShowCalendar}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
        />
        {showCalendar && (
            <div className={classes.divCalendar}>
                <Calendar
                    className={classes.calender}
                    value={selectedDate}
                    locale={localisation}
                    weekStartDayIndex={1}
                    onChange={handleChange}
                    mapDays={({date}) => {
                        const dateJS = new Date(date.toString())
                        return createCalendarProps(dateJS);
                    }}
                    renderButton={(direction: 'left' | 'right', handleClick: (() => void)) => CustomButton(direction, handleClick, classes.changeDateButton)}
                />
            </div>
        )}
    </div>
}

export default DayPickCalendarComponent

//TODO from Hossam: if i'm not mistaken this function exists in another component that uses the same buttons
//if so then store this function elsewhere and use it where applicable
function CustomButton(direction: 'left' | 'right', handleClick: (() => void), className: string) {
    return (
        <i onClick={handleClick} style={{
            padding: "0 10px",
            fontWeight: "bold",
            color: "blue"
        }}>
            <Button className={className} data-testid={"back-button"}>
                {direction === "right" ? <ChevronRight/> : <ChevronLeft/>}
            </Button>
        </i>
    )
}