import {useState} from 'react';

export const useNeighborhoodIdValidation = (maxIdLength: number) => {
    const [neighborhoodWithTooLongId, setNeighborhoodWithTooLongId] = useState("");
    const [isNeighborhoodIdMaxLengthDialogOpen, setIsNeighborhoodIdMaxLengthDialogOpen] = useState(false);

    const areNeighborhoodIdsValid = (neighIds: string[]): boolean => {
        for (let id of neighIds) {
            if (!isNeighborhoodIdTooLong(id)) {
                setNeighborhoodWithTooLongId(id);
                setIsNeighborhoodIdMaxLengthDialogOpen(true);
                return false;
            }
        }
        return true;
    };

    const isNeighborhoodIdTooLong = (name: string): boolean => {
        if (name.length > maxIdLength) {
            return false;
        }
        return true;
    };

    return {
        neighborhoodWithTooLongId,
        isNeighborhoodIdMaxLengthDialogOpen,
        setIsNeighborhoodIdMaxLengthDialogOpen,
        areNeighborhoodIdsValid
    };
};