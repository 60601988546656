import {listMeetingRooms} from "../graphql/queries";
import {gql, useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {MeetingRoom} from "../API";

export function useMeetingRoomService() {
    const [meetingRoomMap, setMeetingRoomMap] = useState<Map<string, MeetingRoom>>(new Map());
    const {data, refetch, loading} = useQuery(gql(listMeetingRooms), {
        variables: {
            limit: 4000,
        }
    });
    useEffect(() => {
        const newMap = new Map<string, MeetingRoom>();
        data?.listMeetingRooms.items.forEach((el: MeetingRoom) => newMap.set(el.meetingRoomId + "_" + el.roomId, el))
        setMeetingRoomMap(newMap)
    }, [data]);
    return {meetingRoomArray: (data?.listMeetingRooms.items ?? []) as MeetingRoom[], meetingRoomMap, refetch, loading}
}