import {
    Box,
    Button,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, {useState} from "react";
import {t} from "i18next";
import {Room} from "../../API";
import {IOrgUnit} from "../../hooks/useOrgunit";
import {comparatorAlphanumericValues} from "../../Utils/Helpers";

interface Props {
    orgUnitList: IOrgUnit[];
    assignedRooms: Room[];
    setAssignedRooms: (value: Room[]) => void;
    availableRooms: Room[]
    setAvailableRooms: (value: Room[]) => void
}

const ManageBuildingAssignedRoomsComponent: React.FC<Props> = (props) => {
    const {orgUnitList, assignedRooms, setAssignedRooms,
        availableRooms, setAvailableRooms} = props;
    const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);

    const sortRoomsListByFloor = (rooms: (Room | null)[]) => {
        return rooms.sort((roomOne: Room | null, roomTwo: Room | null) => {
            const floorOne = roomOne!.floor!.toString();
            const floorTwo = roomTwo!.floor!.toString();
            const compareFloorResult = comparatorAlphanumericValues(floorOne, floorTwo);

            if (compareFloorResult === 0 && roomOne!.orgUnitId && roomTwo!.orgUnitId) {
                const orgUnitOne = orgUnitList.find(orgUnit => orgUnit.orgId === roomOne!.orgUnitId)!.orgName
                const orgUnitTwo = orgUnitList.find(orgUnit => orgUnit.orgId === roomTwo!.orgUnitId)!.orgName
                const compareOrgUnitResult = comparatorAlphanumericValues(orgUnitOne, orgUnitTwo);

                if (compareOrgUnitResult === 0) {
                    const nameOne = roomOne!.nameLowerCased;
                    const nameTwo = roomTwo!.nameLowerCased
                    return comparatorAlphanumericValues(nameOne, nameTwo);
                }

                return compareOrgUnitResult;
            } else if (compareFloorResult === 0 && (roomOne!.orgUnitId === null || roomTwo!.orgUnitId === null)) {
                const nameOne = roomOne!.nameLowerCased;
                const nameTwo = roomTwo!.nameLowerCased
                return comparatorAlphanumericValues(nameOne, nameTwo);
            }
            return compareFloorResult;
        });
    };

    const handleRemoveEntry = () => {

        // @ts-ignore
        const selectedRoomWithoutBuildingId: Room = {
            ...selectedRoom,
            buildingId: null,
            floor: 0, //Set to default value
        }

        const newAssignedRooms = assignedRooms.filter(room => room?.roomId !== selectedRoom?.roomId);
        const newAvailableRooms = [...availableRooms, selectedRoomWithoutBuildingId];

        setAssignedRooms(newAssignedRooms)
        setAvailableRooms(newAvailableRooms)

        setSelectedRoom(null);

    };

    const getOrgUnitName = (id: string | null | undefined) => {
        const orgUnit = orgUnitList.find(orgUnit => orgUnit.orgId === id);
        return orgUnit?.orgName;
    }

    return (
        <Box>
            <TableContainer style={{maxHeight: "440px", margin: "25px 0px", tableLayout: "fixed"}}>
                <Table stickyHeader data-testid={"room-manager-table"} style={{margin: "0px", maxWidth: "100%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell data-testid={"floor-column"}>
                                {t("building-floor-column")}
                            </TableCell>
                            <TableCell data-testid={"org-unit-column"}>
                                {t("building-org-unit-column")}
                            </TableCell>
                            <TableCell data-testid={"room-plan-name-column"}>
                                {t("building-room-plan-name-column")}
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody data-testid={"building-list"}>
                        {sortRoomsListByFloor(assignedRooms).map((room: Room | null, index: number) => (
                            <TableRow
                                data-testid={`room-${index}`}
                                key={"room" + room?.roomId} // Use room.buildingId here
                                style={{cursor: "pointer"}}
                                onClick={() => setSelectedRoom(room)}
                                selected={assignedRooms[index] === selectedRoom} // You might need to adjust this condition based on your use case
                            >
                                <TableCell
                                    key={"floor" + room?.roomId}
                                    style={{maxWidth: "200px"}}
                                >
                                    {room?.floor}
                                </TableCell>
                                <TableCell
                                    key={"orgUnit" + room?.roomId}
                                    style={{maxWidth: "200px"}}
                                >
                                    {getOrgUnitName(room?.orgUnitId)}
                                </TableCell>
                                <TableCell
                                    key={"name" + room?.roomId}
                                    style={{maxWidth: "150px", wordBreak: "break-word"}}
                                >
                                    {room?.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <DialogActions style={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    onClick={() => handleRemoveEntry()}
                    color={"primary"}
                    variant={"contained"}
                    disabled={!selectedRoom}
                    data-testid={"manage-building-remove-btn"}
                >
                    {t("building-remove-button")}
                </Button>
            </DialogActions>
        </Box>
    );
};

export default ManageBuildingAssignedRoomsComponent; // Corrected export statement
