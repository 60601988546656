import {StyledDivMatrixTimeContainer} from "../../../styles/MeetingRoomBookingMatrixStyles";
import React from "react";
import {MeetingRoom} from "../../../API";
import TimeSlotRow from "./TimeSlotRow";
import {TimeSlotData} from "../MeetingRoomBookingMatrixComponent";
import {comparatorAlphanumericValues} from "../../../Utils/Helpers";

interface Props {
    rowProps: TimeSlotRowProps[]
    hoursDisplayed: number
    numberOfColumns: number
}

export interface TimeSlotRowProps {
    startHour: number
    endHour: number
    timeSlotData: TimeSlotData[]
    startBooking: (a: number, b: number, meetingRoom: MeetingRoom) => void;
    hoursDisplayed: number
    meetingRoom: MeetingRoom;
}

function MeetingRoomBookingMatrixGridContainerComponent(props: Props) {
    const {rowProps, hoursDisplayed, numberOfColumns} = props;

    const gridColumnPercentage = 100 / hoursDisplayed;
    const gridRowPercentage = 100 / numberOfColumns;
    const bgSize = `${gridColumnPercentage}% ${gridRowPercentage}%`;

    rowProps.sort((a, b) => comparatorAlphanumericValues(a.meetingRoom.name, b.meetingRoom.name));

    return (
        <StyledDivMatrixTimeContainer style={{
            backgroundSize: bgSize
        }} data-testid={"meeting-room-booking-matrix-grid-container-test-id"}>
            {rowProps.map((singleRowProps, index) =>
                <TimeSlotRow key={singleRowProps.meetingRoom.meetingRoomId + `-${index}`} {...singleRowProps}/>
            )}
        </StyledDivMatrixTimeContainer>
    );
}

export default MeetingRoomBookingMatrixGridContainerComponent