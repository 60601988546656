import React, {Dispatch, SetStateAction} from 'react';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import {Coordinates, Dimensions} from "../../../types/PositioningAndDimensionTypes";

interface Props {
    elementPosition: Coordinates
    elementDimensions: Dimensions
    setMouseOver: Dispatch<SetStateAction<boolean>>
    iconStyle: string
    hasAccess: boolean
}

function MeetingRoomIcon(props: Props) {
    const {hasAccess, elementPosition, iconStyle, elementDimensions} = props;
    const onMouseEnter = () => {
        props.setMouseOver(true)
    }
    const onMouseLeave = () => {
        props.setMouseOver(false)
    }

    return (
        <g transform={`translate(${elementPosition.x}, ${elementPosition.y})`}
           className={hasAccess ? iconStyle : ''}>
            {hasAccess && <GroupWorkIcon stroke="black" width={elementDimensions.width}
                                         height={elementDimensions.height}
                                         visibility={"visible"}/>}
            {!hasAccess && <GroupWorkIcon color={"disabled"}
                                          width={elementDimensions.width}
                                          height={elementDimensions.height}/> }
                <circle r={elementDimensions.width / 2 - 1} cx={elementDimensions.width / 2}
                    cy={elementDimensions.width / 2}
                    style={{"fill": "transparent"}} onMouseOver={onMouseEnter} onMouseOut={onMouseLeave}/>
                </g>
                );
            }

            export default MeetingRoomIcon;