import {FileTypes} from "./Enums";
import {BookingInfo} from "../types/BookingInfoType";
import dayjs, {Dayjs} from "dayjs";
import {TimeWindow} from "../types/TimeWindowType";
import {Building, DefaultRoomConfig, MeetingRoom, Neighborhood, Room} from "../API";
import {MeetingRoomAndNeighborhood, SeatAndNeighborhood} from "../components/RoomManager/RoomManagerComponent";
import {User} from "../services/UserClient";

const detectSVG = require("detect-svg");

export const isSvgFileValid = (fileName: string, fileContent: string): boolean => {
    const fileExtension = fileName.split(".").pop();
    if (fileExtension !== FileTypes.SVG) {
        return false;
    }
    return detectSVG(fileContent);
}

const getCookieValue = (name: string) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

export const getCognitoIdTokenFromLocalStorage = (): string => {
    return getCookieValue(".*LastAuthUser")
}

export function getBuildingsWithOrgunits(buildings: Building[], currentUser: User, rooms: Room[]): Building[] {
    const buildingIds: string[] = [];
    rooms?.forEach(room => {
        if (currentUser.adminOrgUnits.filter(orgUnit => {
            return orgUnit.orgId === room.orgUnitAdmin
        }).length > 0) buildingIds.push(room.buildingId!)
    })
    return buildings?.filter(building => buildingIds.some(id => building.buildingId === id)) ?? [];

}

/**
 * Returns all items from L1 that are not in L2
 */
export const getRelativeComplement = (L1: string[], L2: string[]): string[] => {
    let surplus: string[] = []
    L1.forEach(el => {
        if (L2.indexOf(el) === -1) {
            surplus.push(el)
        }
    })
    return surplus;
}

/**
 Compares elements from the current room plan against the newly uploaded and returns which ones to create or delete
 */
export const getElementsToAddAndDelete = (currentList: string[], newList: string[]): string[][] => {
    let toAdd: string[] = newList.filter(el => currentList.indexOf(el) === -1)
    let toDelete: string[] = currentList.filter(el => newList.indexOf(el) === -1)
    return [toAdd, toDelete];
};

export const getSeatsToAddAndDelete = (currentList: SeatAndNeighborhood[], newList: SeatAndNeighborhood[]): SeatAndNeighborhood[][] => {
    const toAdd: SeatAndNeighborhood[] = [];
    const toDelete: SeatAndNeighborhood[] = [];
    const toUpdate: SeatAndNeighborhood[] = [];

    // Check for elements to add and update
    for (const newItem of newList) {
        const matchingItem = currentList.find(item => item.seatId === newItem.seatId);
        if (!matchingItem) {
            toAdd.push(newItem);
        } else if (matchingItem.neighborhoodId !== newItem.neighborhoodId) {
            toUpdate.push(newItem);
        }
    }

    // Check for elements to delete
    for (const currentItem of currentList) {
        const matchingItem = newList.find(item => item.seatId === currentItem.seatId);
        if (!matchingItem) {
            toDelete.push(currentItem);
        }
    }

    return [toAdd, toDelete, toUpdate];
};

export const getMeetingRoomIdsToAddAndDelete = (currentList: MeetingRoomAndNeighborhood[], newList: MeetingRoomAndNeighborhood[], room: Room, dbMeetingRooms: Map<string, MeetingRoom>): MeetingRoomAndNeighborhood[][] => {
    const toAdd: MeetingRoomAndNeighborhood[] = [];
    const toDelete: MeetingRoomAndNeighborhood[] = [];
    const toUpdate: MeetingRoomAndNeighborhood[] = [];
    // Check for elements to add and update
    for (const newItem of newList) {
        const matchingItem = currentList.find(item => item.meetingRoomId === newItem.meetingRoomId);
        if (!matchingItem) {
            toAdd.push(newItem);
        } else if (matchingItem.neighborhoodId !== newItem.neighborhoodId) {
            toUpdate.push(newItem);
        } else if (matchingItem) {
            const itemIsInDb = !!dbMeetingRooms.get(newItem.meetingRoomId + "_" + room.roomId)
            if (!itemIsInDb)
                toAdd.push(newItem)
        }
    }
    // Check for elements to delete
    for (const currentItem of currentList) {
        const matchingItem = newList.find(item => item.meetingRoomId === currentItem.meetingRoomId);
        if (!matchingItem) {
            toDelete.push(currentItem);
        }
    }

    return [toAdd, toDelete, toUpdate];
};

/**
 * Returns intersection of items from L1 anr items from L2.
 */
export const getIntersection = (L1: string[], L2: string[]): string[] => {
    let intersectionList: string[] = []
    L1.forEach(el => {
        if (L2.indexOf(el) > -1) {
            intersectionList.push(el)
        }
    })
    return intersectionList;
}

function handleEndOfDay(endOfDay: dayjs.Dayjs, lastEndTime: dayjs.Dayjs, timeWindowsWithDiff: TimeWindow[], currentDate: dayjs.Dayjs) {
    if (endOfDay.diff(lastEndTime) > 0)
        timeWindowsWithDiff.push({
            diff: endOfDay.diff(lastEndTime, "minutes"),
            start: lastEndTime,
            end: endOfDay
        })
}

function extractFreeTimeWindow(booking: BookingInfo, lastEndTime: dayjs.Dayjs, bookingEndTime: dayjs.Dayjs, timeWindowsWithDiff: TimeWindow[], bookingStartTime: dayjs.Dayjs) {
    if (booking.timeStart.isSame(lastEndTime, "minute")) {
        lastEndTime = bookingEndTime;
    }
    if (booking.timeStart.isAfter(lastEndTime, "minute")) {
        timeWindowsWithDiff.push({
            diff: bookingStartTime.diff(lastEndTime, "minutes"),
            start: lastEndTime,
            end: bookingStartTime
        })
        lastEndTime = bookingEndTime;
    }
    return lastEndTime;
}

export function getAllOpenTimeWindowsInMinutes(bookings: BookingInfo[]) {
    let timeWindowsWithDiff: TimeWindow[] = []
    if (bookings.length > 0) {
        let currentDate: Dayjs = dayjs(bookings[0].date);
        let endOfDay: Dayjs = dayjs("23:59", "HH:mm").date(currentDate.date()).month(currentDate.month())
        let lastEndTime: Dayjs = dayjs("00:00", "HH:mm").date(currentDate.date()).month(currentDate.month())
        bookings.forEach((booking: BookingInfo) => {
            let currentBookingDate = dayjs(booking.date)
            let bookingStartTime = booking.timeStart
            let bookingEndTime = booking.timeEnd
            if (currentBookingDate.isSame(currentDate, "day")) {
                lastEndTime = extractFreeTimeWindow(booking, lastEndTime, bookingEndTime, timeWindowsWithDiff, bookingStartTime);
            } else if (currentBookingDate.isAfter(currentDate, "day")) {
                handleEndOfDay(endOfDay, lastEndTime, timeWindowsWithDiff, currentDate);
                lastEndTime = dayjs(booking.date).hour(0).minute(0)
                endOfDay = endOfDay.month(currentBookingDate.month()).date(currentBookingDate.date())
                currentDate = dayjs(booking.date);
                lastEndTime = extractFreeTimeWindow(booking, lastEndTime, bookingEndTime, timeWindowsWithDiff, bookingStartTime);
            }
        })
        if (!lastEndTime.isSame(lastEndTime.minute(0).hour(0), "minute"))
            handleEndOfDay(endOfDay, lastEndTime, timeWindowsWithDiff, currentDate);
        return timeWindowsWithDiff;
    }
    return []
}

export function formatTimeWindow(begin: string | null | undefined, end: string | null | undefined): string {
    const timeBegin = begin ? dayjs(begin).format("HH:mm") + "\u202F\u2013\u202F" : ""
    const timeEnd = end ? dayjs(end).format("HH:mm") : ""
    return timeBegin + timeEnd
}

export const comparatorAlphanumericValues = (value: string, compValue: string) => {
    return value.localeCompare(compValue, "en", {numeric: true})
}

export const checkForValidInputNaming = (input: string, maxLength: number): boolean => {
    return (input.trim().length > 0 && input.trim().length <= maxLength)
}
export const checkValidDefaultRoomConfig = (roomConfig: DefaultRoomConfig, buildings: Building[], rooms: Room[], neighborhoods: Neighborhood[]): boolean => {
    //Check room
    if (!roomConfig.roomId) return false;
    const room = rooms.find(room => room.roomId === roomConfig.roomId);
    if (!room) return false;
    //check building
    if (!buildings.some(build => build.buildingId === room.buildingId)) return false;
    //check neighborhood
    return !(roomConfig.neighborhoodId && (!room.hasNeighborhood || neighborhoods.some(neighbor => neighbor.neighborhoodId === roomConfig.neighborhoodId)));
}

export const haveDifferentContent = (array1: Array<any> | undefined | null, array2: Array<any> | null | undefined) => {
    if (!array1 && !array2) return false;
    if (!array1 || !array2) return true;
    if (array1.length !== array2.length) return true
    return !(array1.every(el => array2.includes(el)) && array2.every(el => array1.includes(el)))
}

export function compareDates(dateA?: string | null, dateB?: string | null) {
    return dateToNumber(dateA) - dateToNumber(dateB)
}

function dateToNumber(dateString?: string | null) {
    const date = new Date(dateString ?? "");
    return isNaN(date.getTime()) ? 0 : date.getTime();
}

export function compareStrings(strA?: string | null, strB?: string | null) {
    if (!strA || !strB) return 0;
    return strA.localeCompare(strB, "en");
}

export function compareRoomNames(roomA: Room, roomB: Room) {
    const strA = roomA?.name;
    const strB = roomB?.name;

    if (!strA || !strB) return 0;
    return strA.localeCompare(strB, "en");
}

export function compareMeetingTypes(meetingTypeA: string | null | undefined, meetingTypeB: string | null | undefined) {
    const order: { [key: string]: number } = {"VIP": 1, "Internal": 2, "External": 3};
    if (!meetingTypeA && !meetingTypeB) {
        return 0;
    }
    if (!meetingTypeA) {
        return 1;
    }
    if (!meetingTypeB) {
        return -1;
    }
    const orderValueA: number = order[meetingTypeA] || Number.MAX_VALUE;
    const orderValueB = order[meetingTypeB] || Number.MAX_VALUE;
    return orderValueA - orderValueB;
}

export function compareNumbers(numA?: number | null, numB?: number | null) {
    return (numA ?? 0) - (numB ?? 0);
}

export function intersectionNotEmpty(a: string[], b: string[]) {
    if (!a || !b || !a.length || !b.length) {
        return false;
    }
    return a.some(elem => b.includes(elem));
}

export function timestampToHourMinuteString(dateString: string | null | undefined): string {
    const DIGIT_TO_TRIM_TIME = "2-digit";
    const DELIMITER_IN_TABLE: string = "-";
    const date = new Date(dateString ?? "");
    const locale = navigator.language;
    const use12HourFormat = locale.startsWith("en");
    if (isNaN(date.getTime())) {
        return DELIMITER_IN_TABLE;
    }

    return date.toLocaleTimeString(undefined, {
        hour: DIGIT_TO_TRIM_TIME,
        minute: DIGIT_TO_TRIM_TIME,
        hour12: use12HourFormat
    });
}

export function formatDate(dateString: string, is_german_locale: boolean = false) {
    const months_en = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const days_en = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const months_de = [
        "Jan.", "Feb.", "März", "Apr.", "Mai", "Juni",
        "Juli", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."
    ];
    const days_de = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

    const dateObj = new Date(dateString);

    let dayOfWeek;
    let month;

    if (is_german_locale) {
        dayOfWeek = days_de[dateObj.getDay()];
        month = months_de[dateObj.getMonth()];
    } else {
        dayOfWeek = days_en[dateObj.getDay()];
        month = months_en[dateObj.getMonth()];
    }
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    return is_german_locale ? `${dayOfWeek}., ${day}. ${month} ${year}` : `${dayOfWeek}, ${month} ${day}, ${year}`;
}

export function reformatNeighborhoodId(id: string): string {
    return id.replaceAll("$", " ");
}

export function reformatHintId(id: string): string {
    return id.replace("Büro-", "");
}

export function getUniqueBuildings(allowedBuildings: Building[]): Building[] {
    const uniqueBuildings: Building[] = [];

    allowedBuildings.forEach((building: Building) => {
        if (!uniqueBuildings.includes(building)) {
            uniqueBuildings.push(building)
        }
    })

    return uniqueBuildings;
}