import {meetingRoomTypeFromString} from "./MeetingRoomBookingManagerComponentHelper";
import {MeetingRoomType} from "./Enums";
import {comparatorAlphanumericValues} from "./Helpers";
import {TimeSlotData} from "../components/MeetingRoomBookingManager/MeetingRoomBookingMatrixComponent";

const ORDERED_MEETING_ROOM_TYPES = [MeetingRoomType.VISITORS_CENTER, MeetingRoomType.INTERNAL, MeetingRoomType.NEIGHBORHOOD, MeetingRoomType.CLASSROOM]

function compareMeetingRoomType(typeA: MeetingRoomType, typeB: MeetingRoomType) {
    return ORDERED_MEETING_ROOM_TYPES.indexOf(typeA) - ORDERED_MEETING_ROOM_TYPES.indexOf(typeB);
}

export function compareMeetingRoomTypeStrings(typeA: string, typeB: string) {
    const mrTypeA = meetingRoomTypeFromString(typeA);
    const mrTypeB = meetingRoomTypeFromString(typeB);
    return compareMeetingRoomType(mrTypeA, mrTypeB);
}

export function compareMeetingRoomsWithLevel<T extends {
    type: string,
    neighborhoodId: string | null,
    level: number | null,
    name: string
}>(a: T, b: T) {
    return compareMeetingRoomTypeStrings(a.type, b.type)
        || comparatorAlphanumericValues(a.neighborhoodId ?? "", b.neighborhoodId ?? "")
        || (a.level ?? 0) - (b.level ?? 0)
        || comparatorAlphanumericValues(a.name, b.name);
}

export function compareTimeSlotData(a: TimeSlotData, b: TimeSlotData) {
    return a.timeStartInMinutes - b.timeStartInMinutes;
}