import {useEffect, useState} from "react";
import {MeetingRoomEquipment} from "../API";
import {gql, useQuery} from "@apollo/client";
import {listMeetingRoomEquipments} from "../graphql/queries";

export function useBuildingEquipmentService() {
    const [buildingEquipmentMap, setBuildingEquipmentMap] = useState<Map<string, MeetingRoomEquipment>>(new Map());
    const {data, refetch, loading} = useQuery(gql(listMeetingRoomEquipments), {
        variables: {
            limit: 4000,
        }
    });
    useEffect(() => {
        const newMap = new Map<string, MeetingRoomEquipment>();
        data?.listMeetingRoomEquipments.items.forEach((el: MeetingRoomEquipment) => newMap.set(el.equipmentId, el))
        setBuildingEquipmentMap(newMap)
    }, [data]);
    return {
        buildingEquipmentArray: (data?.listMeetingRoomEquipments.items ?? []) as MeetingRoomEquipment[],
        buildingEquipmentMap,
        refetch,
        loading
    }
}
