import React, {useEffect, useState} from "react";
import {Booking, MeetingRoomBooking} from "../../API";
import {useUpdateBookingInfos} from "../../hooks/useUpdateBookingInfos";
import {useGetFreeTimeWindows} from "../../hooks/useGetFreeTimeWindows";
import TimeWindowTableRowComponent from "./TimeWindowTableRowComponent";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import borders from "../../styles/borders";
import {useTranslation} from "react-i18next";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";

export interface TimeWindowTableComponentProps {
    bookings: (Booking | MeetingRoomBooking)[]
    selectedDates: Date[]
    handleClickOnTimeWindow: (start: Dayjs, end: Dayjs) => void
}

const TimeWindowTableComponent: React.FC<TimeWindowTableComponentProps> = (props) => {
    const [filteredBookings, setFilteredBooking] = useState<(Booking | MeetingRoomBooking)[]>([])

    useEffect(function filterBookingBySelectedDates() {
        if (props.bookings.length > 0) {
            setFilteredBooking((props.bookings.filter((booking: Booking | MeetingRoomBooking) => {
                return props.selectedDates.some((date: Date) => dayjs(date).isSame(dayjs(booking.date), "day"))
            })))
        }
    }, [props.bookings, props.selectedDates])

    const bookingInfos = useUpdateBookingInfos(filteredBookings, true);
    const freeTimeWindows = useGetFreeTimeWindows(bookingInfos, true, "00:00", "23:59");
    const {t} = useTranslation();

    const {isNoFullscreen} = useDeviceMediaType();

    const createTimeWindowRows = () => {
        let timeWindows = freeTimeWindows
        if (timeWindows && timeWindows.length > 0) {
            return timeWindows.map((timeWindow, index) => {
                return <TimeWindowTableRowComponent onClick={props.handleClickOnTimeWindow}
                                                    timeWindow={timeWindow} key={index}/>
            })
        }
    }

    const noFreeTimeWindows = () => {
        return !freeTimeWindows || freeTimeWindows.length === 0
    }

    const hasSelectedDate = () => {
        return props.selectedDates.length !== 0;
    }

    return (
        <div style={{display: "flex", flexDirection: "row"}} data-testid={"time-window-table"}>
            <TableContainer sx={{
                minWidth: "300px",
                maxHeight: "400px",
                borderRadius: borders.mediumBorderRadius,
                overflow: "auto"
            }}>
                <Table stickyHeader>
                    {!isNoFullscreen &&
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}
                                           sx={{fontSize: "large"}}>
                                    {t("multibookingdialog_time_window_title")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody data-testid={"time-window-table-body"}>
                        {hasSelectedDate() &&
                            createTimeWindowRows()
                        }
                        {noFreeTimeWindows() &&
                            <TableRow>
                                <TableCell align={'center'} colSpan={2} width={1}
                                           data-testid={"time-window-table-no-common-time-slot"}>
                                    {t("multibookingdialog_time_window_no_common_time_slot")}
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    )
}

export default TimeWindowTableComponent
