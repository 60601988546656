import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {MouseEvent, PropsWithChildren} from "react";
import {t} from "i18next";

interface ConfirmationDialogProps {
    isOpen: boolean
    setIsOpen: (open: boolean) => void
    title?: string
    onCancel: () => void
    onOk: () => void
}

function ConfirmationDialog(props: PropsWithChildren<ConfirmationDialogProps>) {
    const {
        isOpen, setIsOpen,
        title,
        onCancel, onOk, children
    } = props;

    function handleCloseButtonPressed(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
        e.stopPropagation();
        setIsOpen(false);
        onCancel();
    }

    function handleOkButtonPressed(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
        e.stopPropagation();
        setIsOpen(false);
        onOk();
    }

    return (
        <Dialog open={isOpen}>
            {title !== undefined ? <DialogTitle>{title}</DialogTitle> : <></>}
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"}
                        onClick={(e) => handleOkButtonPressed(e)}>
                    {t("confirm_dialog_ok_button-text")}
                </Button>
                <Button variant={"contained"} color={"primary"}
                        onClick={(e) => handleCloseButtonPressed(e)}>
                    {t("confirm_dialog_cancel_button-text")}
                </Button>
            </DialogActions>
        </Dialog>);
}

export default ConfirmationDialog