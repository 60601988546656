import {Chip, FormControl, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, {useMemo} from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import Box from "@mui/material/Box";

interface SelectorProps<T> {
    options: T[]
    title: string
    onChange: (selected: T[]) => any
    selected?: T[]
    disabled?: boolean
    getLabel: (x: T) => string
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: "100%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            marginTop: `${ITEM_HEIGHT}px`,
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function MultiSelector<T>(props: SelectorProps<T>) {
    const {options, getLabel, selected, onChange} = props;
    const classes = useStyles()
    const selectedOptions: T[] = selected ?? []

    // no longer necessary as we want to override default behaviour
    const handleChange = () => {
    }

    // deletes only the chip which was clicked "x" on
    const handleDelete = (e: React.MouseEvent, index: number) => {
        e.preventDefault();
        onChange(selectedOptions.filter((_, idx) => idx !== index));
    };

    // multiple clicks on item from dropdown add item multiple times
    const handleMenuItem = (value: T) => {
        onChange([...selectedOptions, value]);
    };

    const labelsOfSelection = useMemo(() => {
        return selectedOptions.map(getLabel);
    }, [selected, getLabel]);

    // https://codesandbox.io/s/deletable-chip-in-multi-select-vk113?fontsize=14&hidenavigation=1&theme=dark&file=/demo.tsx
    return (
        <FormControl className={classes.formControl}>
            <InputLabel id={props.title + "-multiple-chip-label"}>{props.title}</InputLabel>

            <Select
                data-testid={props.title + "-multiple-chip-testid"}
                labelId={props.title + "-multiple-chip-labelId"}
                label={props.title + "-multiple-chip-label"}
                id={props.title + "-demo-multiple-chip"}
                multiple
                value={labelsOfSelection}
                onChange={handleChange}
                IconComponent={KeyboardArrowDownIcon}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {(selected as string[]).map((value, index) => (
                            // unique key value really important
                            <Chip
                                data-testid={props.title + "-chip"}
                                key={value + index}
                                label={value}
                                clickable
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                    />
                                }
                                // className={classes.chip}
                                onDelete={(e) => handleDelete(e, index)}
                            />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >


                {options.map((option, index) => (
                    <MenuItem
                        onClick={() => handleMenuItem(option)}
                        key={index} value={index}>
                        {getLabel(option)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>)
}

export default MultiSelector