import {createMuiTheme} from "@material-ui/core/styles";

const maternaTheme = createMuiTheme({
    palette: {
        primary: {main: '#c30a17'},
        secondary: {main: '#3a3E41'},
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 801,
            lg: 1200,
            xl: 1536,
        }
    },
});

export default maternaTheme;