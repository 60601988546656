import { useState, useEffect } from "react";
import {Role} from "../types/RoleType";
import {Auth} from "aws-amplify";

export const useRoleList = () => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const roles = await getAllAdmin2GetherRoles();
            setRoles(roles);
            setLoading(false);
        };
        fetchData().then();
    }, []);
    return {roles, loading };


    async function getAllAdmin2GetherRoles(): Promise<Role[]> {
        const admin2gGetherRolesEndpoint = process.env.REACT_APP_ALL_ADMIN2GETHER_ROLES_API_ENDPOINT!

        const cognitoUserSession = await Auth.currentSession();
        const response = await fetch(admin2gGetherRolesEndpoint, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + cognitoUserSession.getAccessToken().getJwtToken()
            }
        });
        return response.json()
    }
};