import {gql, useQuery} from "@apollo/client";
import {MeetingRoom} from "../API";
import {useEffect} from "react";
import {getMeetingRoomsByBuildingId} from "../graphql/queries";
import {onUpdateSecureMeetingRoom} from "../graphql/subscriptions";
import {MeetingRoomInfoWithCapacity} from "../types/MeetingRoomBookingListItemType";

export function useMeetingRoomInfoList(buildingId?: string, roomIds?: string[]): [MeetingRoomInfoWithCapacity[], () => void, boolean] {
    const EMPTY_ARRAY: MeetingRoom[] = [];

    const {data, subscribeToMore, refetch, loading} = useQuery(gql(getMeetingRoomsByBuildingId), {
        variables: {buildingId: buildingId},
        skip: !buildingId,
    });

    useEffect(() => {
        if (!buildingId) return;

        const unsubscribeFromUpdate = subscribeToMore({
            document: gql(onUpdateSecureMeetingRoom),
            variables: {},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const updatedMeetingRoom = subscriptionData.data.onUpdateSecureMeetingRoom;

                const existingItems = prev.getMeetingRoomsByBuildingId;
                let updatedItems = existingItems.map((item: MeetingRoom) =>
                    item.meetingRoomId === updatedMeetingRoom.meetingRoomId ? updatedMeetingRoom : item
                );

                if (!existingItems.find((item: MeetingRoom) => item.meetingRoomId === updatedMeetingRoom.meetingRoomId)) {
                    updatedItems = [...updatedItems, updatedMeetingRoom];
                }

                return {
                    ...prev,
                    getMeetingRoomsByBuildingId: updatedItems,
                };
            },
            onError: (error: any) => console.error("useMeetingRoomList useEffect:subscribeToMore:onUpdateSecureMeetingRoom " + JSON.stringify(error)),
        });

        return () => {
            unsubscribeFromUpdate();
        };
    }, [subscribeToMore, buildingId, roomIds?.length]);

    function comparator(a: MeetingRoom, b: MeetingRoom) {
        return a.meetingRoomId.localeCompare(b.meetingRoomId, 'en', {numeric: true});
    }

    const filteredMeetingRooms: MeetingRoomInfoWithCapacity[] =  data?.getMeetingRoomsByBuildingId.sort(comparator)
        .filter((mr: MeetingRoom) => !roomIds || !roomIds.length || roomIds.includes(mr.roomId))
        .map((mr: MeetingRoom) => ({
            meetingRoomId: mr.meetingRoomId,
            type: mr.type,
            name: mr.name,
            nameLowerCased: mr.name.toLowerCase(),
            capacity: mr.capacity,
            roomId: mr.roomId,
            hintId: mr.hintId
        }));

    return [filteredMeetingRooms ?? EMPTY_ARRAY, refetch, loading];
}
