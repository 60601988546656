import React, {Dispatch, SetStateAction} from 'react'
import {SeatInterface} from "../SeatComponent";


interface Coordinates {
    x: number,
    y: number
}

interface Props {
    seatInterface: SeatInterface,
    elementPosition: Coordinates,
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

export default function Monitor(props: Props) {
    const elementPosition = props.elementPosition
    const setMouseOver = props.setMouseOver

    const onMouseEnter = () => {
        setMouseOver(true)
    }
    const onMouseLeave = () => {
        setMouseOver(false)
    }
    return (
        <g className="monitor" transform={`translate(${elementPosition.x}, ${elementPosition.y}) scale(0.13 0.13)`}
           onMouseOver={onMouseEnter} onMouseOut={onMouseLeave}
           data-testid={"Monitor-" + props.seatInterface.seatID}>
            <path fill="#000"
                  d="M139.886 64.61v53.445H60.692v-52.09c-.744.528-1.23 1.143-1.488 1.853-1.176 3.138-1.561 16.862-.777 30.592l1.174 25.12 16.85 1.173c9.016.785 16.47 2.74 16.47 5.094 0 1.961-1.567 3.522-3.918 3.522-1.96 0-3.92 1.582-3.92 3.936 0 1.961 7.054 3.919 15.677 3.919 8.624 0 15.677-1.958 15.677-3.92 0-2.353-1.567-3.935-3.919-3.935-1.96 0-3.92-1.56-3.92-3.522 0-2.354 7.444-4.309 16.851-5.094l16.47-1.174V64.676z"/>
            <rect width="9.51" height="6.616" x="257.87" y="94.146" fill="transparent" stroke="#000" strokeWidth="0.4"
                  transform="translate(-2123.6 -730.8) scale(8.4667)"/>
        </g>
    )

}