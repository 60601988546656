import {BookingInfo} from "../types/BookingInfoType";
import {useEffect, useState} from "react";
import {Booking, MeetingRoomBooking} from "../API";
import dayjs from "dayjs";

export function useUpdateBookingInfos(bookings: (Booking | MeetingRoomBooking)[], isTimeBookingActive: boolean) {
    const [bookingInfos, setBookingInfos] = useState<BookingInfo[]>([]);

    useEffect(
        function updateBookingInfo() {
            function formatBookerName(bookerName: string) {
                const lastIndexOfSpace = bookerName.lastIndexOf(" ");
                const firstGivenName = bookerName.substring(0, lastIndexOfSpace);
                const familyName = bookerName.substring(lastIndexOfSpace);
                return [firstGivenName, familyName];
            }

            function handleForeignBookerName(tempBookingInfo: BookingInfo, booking: Booking) {
                tempBookingInfo.foreignBooking = true
                tempBookingInfo.foreignBookerName = booking.bookerName
                let name = formatBookerName(booking.bookingFor!)
                tempBookingInfo.firstName = name[0]
                tempBookingInfo.lastName = name[1] ? name[1] : ""
            }

            function handleBookerName(booking: Booking | MeetingRoomBooking, tempBookingInfo: BookingInfo) {
                if(!!booking.bookerGivenName && !!booking.bookerFamilyName) {
                    tempBookingInfo.firstName = booking.bookerGivenName
                    tempBookingInfo.lastName = booking.bookerFamilyName
                } else {
                    let name = formatBookerName(booking.bookerName)
                    tempBookingInfo.firstName = name[0]
                    tempBookingInfo.lastName = name[1] ? name[1] : ""
                }
            }

            const temp: BookingInfo[] = []

            bookings.forEach((booking: Booking | MeetingRoomBooking) => {
                let tempBookingInfo: BookingInfo = {
                    date: "",
                    firstName: "",
                    lastName: "",
                    foreignBooking: false,
                    foreignBookerName: "",
                    timeStart: dayjs(),
                    timeEnd: dayjs()
                }

                tempBookingInfo.date = booking.date

                if (booking.__typename === "Booking" && !!booking.bookingFor && booking.bookingFor.trim() !== "") {
                    handleForeignBookerName(tempBookingInfo, booking);
                } else {
                    handleBookerName(booking, tempBookingInfo);
                }
                if (isTimeBookingActive) {
                    tempBookingInfo.timeStart = dayjs(booking.timeBegin)
                    tempBookingInfo.timeEnd = dayjs(booking.timeEnd)
                }
                temp.push(tempBookingInfo)
            })
            temp.sort((a: BookingInfo, b: BookingInfo) => {
                return a.timeStart.isBefore(b.timeStart) ? -1 : a.timeStart.isSame(b.timeStart) ? 0 : 1
            })
            setBookingInfos(temp)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isTimeBookingActive, bookings.length, setBookingInfos]
    )
    return bookingInfos;
}