import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useGetOwnMeetingRoomBookingsByBooker} from "../../hooks/useBookingsByBooker";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {FetchResult, gql, useMutation} from "@apollo/client";
import {deleteSecureMeetingRoomBooking} from "../../graphql/mutations";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import {useErrorContext} from "../../hooks/useErrorContext";
import MeetingRoomBookingEditComponent from "../MeetingRoomBookingManager/MeetingRoomBookingEditComponent";
import {useMeetingRoomList} from "../../hooks/useMeetingRoomList";
import {formatDate, timestampToHourMinuteString} from "../../Utils/Helpers";
import {
    bookingTabHeaderCell,
    bookingTabTableCell,
    getSortIcon,
    getSortOptionsByColumn,
    meetingBookingSortColumns,
    StyledTabTableCell
} from "../../Utils/OwnBookingTabHelper";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {OwnMeetingRoomBooking} from "../../types/MeetingRoomBookingListItemType";
import {getMeetingTypeLabel, MeetingRoomType} from "../../Utils/Enums";

export interface OwnMeetingBookingsProps {
    setNumberOfSelections: (count: number) => void;
    showEditDialog: boolean
    setShowEditDialog: (value: boolean) => void
    showDeleteDialog: boolean
    setShowDeleteDialog: (value: boolean) => void
}

export const OwnMeetingBookingsTabComponent: React.FC<OwnMeetingBookingsProps> = (props) => {
    const {
        setNumberOfSelections,
        showEditDialog,
        setShowEditDialog,
        showDeleteDialog,
        setShowDeleteDialog
    } = props
    const {t, i18n} = useTranslation();
    const is_german_locale = i18n.language.substring(0, 2) === 'de'
    const {reportError} = useErrorContext();
    const {currentUser} = useMainApplicationContext();
    const [bookings, refetch] = useGetOwnMeetingRoomBookingsByBooker(currentUser);
    const [meetingRooms, , areMeetingRoomsLoading] = useMeetingRoomList(Array.from(new Set(bookings.map(b => b.roomId))));

    const [deleteMeetingRoomBookingMutation] = useMutation(gql(deleteSecureMeetingRoomBooking));

    const [selectedBookings, setSelectedBookings] = useState<OwnMeetingRoomBooking[]>([]);
    const [isDeleteAndRefetchComplete, setIsDeleteAndRefetchComplete] = useState<boolean>(true);
    const [sortOptions, setSortOptions] = useState<{
        [key: string]: string
    }>({
        date: 'asc',
        timeBegin: 'default',
        timeEnd: 'default',
        participantNumber: 'default',
        meetingRoomName: 'default',
        meetingName: 'default',
        meetingType: 'default',
        selectedColumn: 'default'
    });
    const columnHeaders = [
        {label: t('date'), key: 'date'},
        {label: t('general_meeting-room-singular'), key: 'meetingRoomName'},
        {label: t('start-time'), key: 'timeBegin'},
        {label: t('end-time'), key: 'timeEnd'},
        {label: t('meeting-name'), key: 'meetingName'},
        {label: t('number_of_participants'), key: 'participantNumber'},
        {label: t('meeting_room_type'), key: 'meetingType'}
    ];

    const detailedBookings = useMemo(((): OwnMeetingRoomBooking[] => {
        if (areMeetingRoomsLoading) return []
        return bookings.map(booking => {
            const matchedMeetingRoom = meetingRooms.find((meetingRoom) => meetingRoom.meetingRoomId === booking.meetingRoomId);
            return {
                ...booking,
                meetingRoomName: matchedMeetingRoom?.name ?? "",
                meetingRoomType: matchedMeetingRoom?.type ?? MeetingRoomType.INTERNAL,
                managingAllowed: true
            }
        })
    }), [areMeetingRoomsLoading, bookings])

    const sortedDetailedBookings: OwnMeetingRoomBooking[] = useMemo(() => {
        const selectedColumn = sortOptions.selectedColumn;
        const primarySortColumn = meetingBookingSortColumns.find((sortItem) => sortItem.column === selectedColumn);
        const secondarySortColumns = meetingBookingSortColumns.filter((sortItem) => sortItem.column !== selectedColumn);

        return detailedBookings.sort((a, b) => {
            const primarySortComparison = primarySortColumn?.compare(a[primarySortColumn.column]!, b[primarySortColumn.column]!) || 0;
            const sortOrder = sortOptions[selectedColumn] === 'desc' ? -1 : 1;
            const comparison = sortOrder * primarySortComparison;

            //  Secondary Sorting:
            //    - If the primary sort comparison is '0' (indicating equal values), the sorting continues using the
            //      'secondarySortColumns' in a sequential manner.
            //    - Each 'secondarySortColumn' is compared in turn to break the tie in case of equal primary sort comparisons.
            //    - The 'reduce' function iterates through 'secondarySortColumns', applying comparisons until a non-zero value is found.
            //    - The result determines the final sorting order of 'a' and 'b'.

            return comparison !== 0 ? comparison : secondarySortColumns.reduce((acc, sortColumn) => {
                if (acc === 0) {
                    return sortColumn.compare(a[sortColumn.column]!, b[sortColumn.column]!);
                }
                return acc;
            }, 0);
        });
    }, [detailedBookings, sortOptions]);

    useEffect(function propagateSelected() {
        setNumberOfSelections(selectedBookings.length);
    }, [selectedBookings]);

    const shouldShowLoadingWheel = areMeetingRoomsLoading || !isDeleteAndRefetchComplete;

    function renderOwnBookingsDialog() {
        const handleRowClick = (booking: OwnMeetingRoomBooking) => {
            const newSelectedRows = new Set(selectedBookings);

            const hasSelectedBooking = Array.from(newSelectedRows).some((selectedBooking) => selectedBooking.bookingId === booking.bookingId);

            if (hasSelectedBooking) {
                newSelectedRows.forEach((selectedBooking) => {
                    if (selectedBooking.bookingId === booking.bookingId) {
                        newSelectedRows.delete(selectedBooking);
                    }
                });
            } else {
                newSelectedRows.add(booking);
            }

            setSelectedBookings(Array.from(newSelectedRows));
        };

        const handleHeaderSortClick = (column: string) => {
            setSortOptions(getSortOptionsByColumn(column, sortOptions));
        };

        return (
            <>
                {shouldShowLoadingWheel ? (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                        <CircularProgress/>
                    </div>
                ) : <TableContainer>
                    <Table style={{marginLeft: 0, marginRight: 0, maxWidth: '100%'}}>
                        <TableHead>
                            <TableRow>
                                {columnHeaders.map((column) => (
                                    <TableCell
                                        key={column.key}
                                        onClick={() => handleHeaderSortClick(column.key)}
                                        style={bookingTabTableCell}
                                    >
                                        <div style={bookingTabHeaderCell}>
                                            {getSortIcon(sortOptions[column.key])}
                                            {column.label}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedDetailedBookings.map((item: OwnMeetingRoomBooking) => (
                                <TableRow
                                    key={item.bookingId}
                                    selected={selectedBookings.some(meetingRoomBooking => meetingRoomBooking.bookingId === item.bookingId)}
                                    onClick={() => handleRowClick(item)}
                                >
                                    <StyledTabTableCell>{formatDate(item.date, is_german_locale)}</StyledTabTableCell>
                                    <StyledTabTableCell>{item.meetingRoomName}</StyledTabTableCell>
                                    <StyledTabTableCell>{timestampToHourMinuteString(item.timeBegin)}</StyledTabTableCell>
                                    <StyledTabTableCell>{timestampToHourMinuteString(item.timeEnd)}</StyledTabTableCell>
                                    <StyledTabTableCell>{item.meetingName}</StyledTabTableCell>
                                    <StyledTabTableCell>{item.participantNumber}</StyledTabTableCell>
                                    <StyledTabTableCell>{t(getMeetingTypeLabel(item.meetingType))}</StyledTabTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </>
        );
    }

    function renderEditDialog() {
        return (
            <MeetingRoomBookingEditComponent
                bookingToEdit={selectedBookings[0]}
                refetchBookings={refetch}
                setShowEditDialog={setShowEditDialog}
                show={showEditDialog}
                onAttemptDeleteBooking={() => {
                }}
            />
        )
    }

    function renderDeleteDialog() {
        function deleteMeetingRoomBookings() {
            if (selectedBookings) setIsDeleteAndRefetchComplete(false);
            const deletionPromises: Promise<FetchResult>[] = [];
            selectedBookings.forEach(meetingRoomBooking =>
                deletionPromises.push(deleteMeetingRoomBookingMutation({
                            variables: {
                                input: {
                                    bookingId: meetingRoomBooking?.bookingId,
                                    bookerId: meetingRoomBooking?.bookerId,
                                    orgUnitId: meetingRoomBooking?.orgUnitId,
                                    meetingRoomId: meetingRoomBooking?.meetingRoomId,
                                    date: meetingRoomBooking?.date,
                                    roomId: meetingRoomBooking?.roomId
                                }
                            }
                        }
                    )
                ))

            setShowDeleteDialog(false);
            setSelectedBookings([])
            Promise.all(deletionPromises)
                .then(() => {
                    refetch()
                        .then(() => setIsDeleteAndRefetchComplete(true))
                        .catch((err) => reportError(err, "Error while refetching meeting room bookings", "OwnMeetingBookingsBookingTabComponent deleteMeetingRoomBooking"))
                })
                .catch((err) => reportError(err, "Error while deleting meeting room bookings", "OwnMeetingBookingsBookingTabComponent deleteMeetingRoomBooking"))
        }

        return (
            <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                maxWidth={"md"}
                data-testid={"own-bookings-alert-dialog"}
            >
                <DialogTitle>
                    {t("own_bookings_management_alert_dialog_title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("own_bookings_management_alert_dialog_amount_bookings")}: {selectedBookings.length}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={selectedBookings.length === 0}
                        color={"primary"}
                        variant={"contained"}
                        data-testid={"alert-dialog-btn-delete"}
                        onClick={deleteMeetingRoomBookings}
                    >
                        {t("delete")}
                    </Button>
                    <Button
                        onClick={() => setShowDeleteDialog(false)}
                        color={"primary"}
                        variant={"contained"}
                        data-testid={"alert-dialog-btn-close"}
                    >
                        {t("confirm_dialog_cancel_button-text")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            {renderOwnBookingsDialog()}
            {renderDeleteDialog()}
            {selectedBookings.length === 1 && renderEditDialog()}
        </>
    )

}