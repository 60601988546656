import {MeetingRoomBooking} from "../API";
import dayjs, {Dayjs} from "dayjs";

export function isBookingTimeValid(beginTime: Dayjs | null, endTime: Dayjs | null): boolean {
    if (!beginTime?.isValid() || !endTime?.isValid()) {
        return false
    }
    return endTime.isAfter(beginTime);
}

export function isBookingOverlapping(
    beginTime: Dayjs | null,
    endTime: Dayjs | null,
    bookingToEdit: MeetingRoomBooking,
    bookings: MeetingRoomBooking[]
): boolean {

    return bookings.some((booking) => {
        if (booking.bookingId === bookingToEdit.bookingId) {
            return false;
        }
        const bookingStart = dayjs(booking.timeBegin);
        const bookingEnd = dayjs(booking.timeEnd);

        return beginTime?.isBefore(bookingEnd) && endTime?.isAfter(bookingStart);
    });
}
 