import {TimeWindow} from "../types/TimeWindowType";
import {Booking} from "../API";
import {SeatBookingColor} from "../Utils/Enums";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function useSeatColor(seatBookingTimeWindow: TimeWindow[], currentUserID: string, bookings: Booking[] | null | undefined, hasAccess: boolean | undefined, isBookable: boolean, isTimeBookingActive?: boolean): SeatBookingColor {
    const [seatColor, setSeatColor] = useState<SeatBookingColor>(SeatBookingColor.GREEN)

    useEffect(function updateSeatColor() {
        function hasThreeHourWindow(): boolean {
            const windowDuration = dayjs.duration({"hours": 3})
            return seatBookingTimeWindow.some((timeWindow) => {
                return timeWindow.diff >= windowDuration.asMinutes();
            })
        }

        function hasBookingByMe(bookings: Booking[]): boolean {
            return bookings.some((booking: Booking) => {
                return booking.bookerId === currentUserID
            })
        }

        function handleTimeBookingActivColors() {
            if (!hasAccess || !isBookable) {
                setSeatColor(SeatBookingColor.NO_COLOR)
            } else if (!bookings || bookings.length === 0) {
                setSeatColor(SeatBookingColor.GREEN)
            } else if (bookings.length > 0 && hasBookingByMe(bookings)) {
                setSeatColor(SeatBookingColor.BLUE)
            } else if (hasThreeHourWindow()) {
                setSeatColor(SeatBookingColor.YELLOW)
            } else if (seatBookingTimeWindow.length > 0) {
                setSeatColor(SeatBookingColor.ORANGE)
            } else {
                setSeatColor(SeatBookingColor.RED)
            }
        }

        function handleBookingColors() {
            if (!hasAccess || !isBookable) {
                setSeatColor(SeatBookingColor.NO_COLOR)
            } else if (!bookings || bookings.length === 0) {
                setSeatColor(SeatBookingColor.GREEN)
            } else if (bookings.length === 1 && hasBookingByMe(bookings)) {
                setSeatColor(SeatBookingColor.BLUE)
            } else {
                setSeatColor(SeatBookingColor.RED)
            }
        }

        isTimeBookingActive ? handleTimeBookingActivColors() : handleBookingColors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserID, isTimeBookingActive, bookings?.length, seatBookingTimeWindow, hasAccess, isBookable])
    return seatColor
}