import React, {Dispatch, SetStateAction} from 'react'

interface Coordinates {
    x: number,
    y: number
}

interface Props {
    elementPosition: Coordinates,
    setMouseOver: Dispatch<SetStateAction<boolean>>
}

export default function ExternalBooking(props: Props) {
    const onMouseEnter = () => {
        props.setMouseOver(true)
    }
    const onMouseLeave = () => {
        props.setMouseOver(false)
    }
    return (
        <g transform={`translate(${props.elementPosition.x}, ${props.elementPosition.y}) scale(0.09 0.09)`}
           fill={"black"} onMouseOver={onMouseEnter} onMouseOut={onMouseLeave}>
            <rect fill="transparent" width="70" height="37" x="70" y="87"/>
            <path
                d="M104.943 91.945a27.47 27.47 0 0 1-8.65-1.425c-2.962-.994-6.204-.994-8.912-2.023-7.019-2.673-26.299 6.437-12.546 25.293 13.706 18.793 24.367 1.62 30.104 1.5V91.945Zm-26.058 11.924c11.224-8.721 17.958 2.154 17.958 2.154-11.825 10.475-17.958-2.154-17.958-2.154z"/>
            <path
                d="M122.627 88.493c-2.708 1.033-5.95 1.029-8.911 2.023a27.232 27.232 0 0 1-10.301 1.373v23.758c.558-.23 1.092-.364 1.591-.364 5.733 0 16.414 17.363 30.167-1.497 13.753-18.856-5.523-27.966-12.546-25.293Zm-9.454 17.53s6.735-10.872 17.958-2.154c0 0-6.136 12.629-17.958 2.154z"/>
        </g>
    )
}
