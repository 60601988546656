import React, {ErrorInfo} from "react";
import {t} from 'i18next';

type Props = {}
type State = {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        // Display fallback UI
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>{t('error-boundary-default-error')}</h1>;
        }
        return this.props.children;
    }
}

